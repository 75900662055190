:root, .light, .light-theme {
  --gray-1: #fcfcfc;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e0e0e0;
  --gray-6: #d9d9d9;
  --gray-7: #cecece;
  --gray-8: #bbbbbb;
  --gray-9: #8d8d8d;
  --gray-10: #838383;
  --gray-11: #646464;
  --gray-12: #202020;
  --gray-a1: #00000003;
  --gray-a2: #00000006;
  --gray-a3: #0000000f;
  --gray-a4: #00000017;
  --gray-a5: #0000001f;
  --gray-a6: #00000026;
  --gray-a7: #00000031;
  --gray-a8: #00000044;
  --gray-a9: #00000072;
  --gray-a10: #0000007c;
  --gray-a11: #0000009b;
  --gray-a12: #000000df;
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
  --mauve-a1: #55005503;
  --mauve-a2: #2b005506;
  --mauve-a3: #30004010;
  --mauve-a4: #20003618;
  --mauve-a5: #20003820;
  --mauve-a6: #14003527;
  --mauve-a7: #10003332;
  --mauve-a8: #08003145;
  --mauve-a9: #05001d73;
  --mauve-a10: #0500197d;
  --mauve-a11: #0400119c;
  --mauve-a12: #020008e0;
  --slate-1: #fcfcfd;
  --slate-2: #f9f9fb;
  --slate-3: #f0f0f3;
  --slate-4: #e8e8ec;
  --slate-5: #e0e1e6;
  --slate-6: #d9d9e0;
  --slate-7: #cdced6;
  --slate-8: #b9bbc6;
  --slate-9: #8b8d98;
  --slate-10: #80838d;
  --slate-11: #60646c;
  --slate-12: #1c2024;
  --slate-a1: #00005503;
  --slate-a2: #00005506;
  --slate-a3: #0000330f;
  --slate-a4: #00002d17;
  --slate-a5: #0009321f;
  --slate-a6: #00002f26;
  --slate-a7: #00062e32;
  --slate-a8: #00083046;
  --slate-a9: #00051d74;
  --slate-a10: #00071b7f;
  --slate-a11: #0007149f;
  --slate-a12: #000509e3;
  --sage-1: #fbfdfc;
  --sage-2: #f7f9f8;
  --sage-3: #eef1f0;
  --sage-4: #e6e9e8;
  --sage-5: #dfe2e0;
  --sage-6: #d7dad9;
  --sage-7: #cbcfcd;
  --sage-8: #b8bcba;
  --sage-9: #868e8b;
  --sage-10: #7c8481;
  --sage-11: #5f6563;
  --sage-12: #1a211e;
  --sage-a1: #00804004;
  --sage-a2: #00402008;
  --sage-a3: #002d1e11;
  --sage-a4: #001f1519;
  --sage-a5: #00180820;
  --sage-a6: #00140d28;
  --sage-a7: #00140a34;
  --sage-a8: #000f0847;
  --sage-a9: #00110b79;
  --sage-a10: #00100a83;
  --sage-a11: #000a07a0;
  --sage-a12: #000805e5;
  --olive-1: #fcfdfc;
  --olive-2: #f8faf8;
  --olive-3: #eff1ef;
  --olive-4: #e7e9e7;
  --olive-5: #dfe2df;
  --olive-6: #d7dad7;
  --olive-7: #cccfcc;
  --olive-8: #b9bcb8;
  --olive-9: #898e87;
  --olive-10: #7f847d;
  --olive-11: #60655f;
  --olive-12: #1d211c;
  --olive-a1: #00550003;
  --olive-a2: #00490007;
  --olive-a3: #00200010;
  --olive-a4: #00160018;
  --olive-a5: #00180020;
  --olive-a6: #00140028;
  --olive-a7: #000f0033;
  --olive-a8: #040f0047;
  --olive-a9: #050f0078;
  --olive-a10: #040e0082;
  --olive-a11: #020a00a0;
  --olive-a12: #010600e3;
  --sand-1: #fdfdfc;
  --sand-2: #f9f9f8;
  --sand-3: #f1f0ef;
  --sand-4: #e9e8e6;
  --sand-5: #e2e1de;
  --sand-6: #dad9d6;
  --sand-7: #cfceca;
  --sand-8: #bcbbb5;
  --sand-9: #8d8d86;
  --sand-10: #82827c;
  --sand-11: #63635e;
  --sand-12: #21201c;
  --sand-a1: #55550003;
  --sand-a2: #25250007;
  --sand-a3: #20100010;
  --sand-a4: #1f150019;
  --sand-a5: #1f180021;
  --sand-a6: #19130029;
  --sand-a7: #19140035;
  --sand-a8: #1915014a;
  --sand-a9: #0f0f0079;
  --sand-a10: #0c0c0083;
  --sand-a11: #080800a1;
  --sand-a12: #060500e3;
  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;
  --amber-a1: #c0800004;
  --amber-a2: #f4d10016;
  --amber-a3: #ffde003d;
  --amber-a4: #ffd40063;
  --amber-a5: #f8cf0088;
  --amber-a6: #eab5008c;
  --amber-a7: #dc9b009d;
  --amber-a8: #da8a00c9;
  --amber-a9: #ffb300c2;
  --amber-a10: #ffb300e7;
  --amber-a11: #ab6400;
  --amber-a12: #341500dd;
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
  --blue-a1: #0080ff04;
  --blue-a2: #008cff0b;
  --blue-a3: #008ff519;
  --blue-a4: #009eff2a;
  --blue-a5: #0093ff3d;
  --blue-a6: #0088f653;
  --blue-a7: #0083eb71;
  --blue-a8: #0084e6a1;
  --blue-a9: #0090ff;
  --blue-a10: #0086f0fa;
  --blue-a11: #006dcbf2;
  --blue-a12: #002359ee;
  --bronze-1: #fdfcfc;
  --bronze-2: #fdf7f5;
  --bronze-3: #f6edea;
  --bronze-4: #efe4df;
  --bronze-5: #e7d9d3;
  --bronze-6: #dfcdc5;
  --bronze-7: #d3bcb3;
  --bronze-8: #c2a499;
  --bronze-9: #a18072;
  --bronze-10: #957468;
  --bronze-11: #7d5e54;
  --bronze-12: #43302b;
  --bronze-a1: #55000003;
  --bronze-a2: #cc33000a;
  --bronze-a3: #92250015;
  --bronze-a4: #80280020;
  --bronze-a5: #7423002c;
  --bronze-a6: #7324003a;
  --bronze-a7: #6c1f004c;
  --bronze-a8: #671c0066;
  --bronze-a9: #551a008d;
  --bronze-a10: #4c150097;
  --bronze-a11: #3d0f00ab;
  --bronze-a12: #1d0600d4;
  --brown-1: #fefdfc;
  --brown-2: #fcf9f6;
  --brown-3: #f6eee7;
  --brown-4: #f0e4d9;
  --brown-5: #ebdaca;
  --brown-6: #e4cdb7;
  --brown-7: #dcbc9f;
  --brown-8: #cea37e;
  --brown-9: #ad7f58;
  --brown-10: #a07553;
  --brown-11: #815e46;
  --brown-12: #3e332e;
  --brown-a1: #aa550003;
  --brown-a2: #aa550009;
  --brown-a3: #a04b0018;
  --brown-a4: #9b4a0026;
  --brown-a5: #9f4d0035;
  --brown-a6: #a04e0048;
  --brown-a7: #a34e0060;
  --brown-a8: #9f4a0081;
  --brown-a9: #823c00a7;
  --brown-a10: #723300ac;
  --brown-a11: #522100b9;
  --brown-a12: #140600d1;
  --crimson-1: #fffcfd;
  --crimson-2: #fef7f9;
  --crimson-3: #ffe9f0;
  --crimson-4: #fedce7;
  --crimson-5: #facedd;
  --crimson-6: #f3bed1;
  --crimson-7: #eaacc3;
  --crimson-8: #e093b2;
  --crimson-9: #e93d82;
  --crimson-10: #df3478;
  --crimson-11: #cb1d63;
  --crimson-12: #621639;
  --crimson-a1: #ff005503;
  --crimson-a2: #e0004008;
  --crimson-a3: #ff005216;
  --crimson-a4: #f8005123;
  --crimson-a5: #e5004f31;
  --crimson-a6: #d0004b41;
  --crimson-a7: #bf004753;
  --crimson-a8: #b6004a6c;
  --crimson-a9: #e2005bc2;
  --crimson-a10: #d70056cb;
  --crimson-a11: #c4004fe2;
  --crimson-a12: #530026e9;
  --cyan-1: #fafdfe;
  --cyan-2: #f2fafb;
  --cyan-3: #def7f9;
  --cyan-4: #caf1f6;
  --cyan-5: #b5e9f0;
  --cyan-6: #9ddde7;
  --cyan-7: #7dcedc;
  --cyan-8: #3db9cf;
  --cyan-9: #00a2c7;
  --cyan-10: #0797b9;
  --cyan-11: #107d98;
  --cyan-12: #0d3c48;
  --cyan-a1: #0099cc05;
  --cyan-a2: #009db10d;
  --cyan-a3: #00c2d121;
  --cyan-a4: #00bcd435;
  --cyan-a5: #01b4cc4a;
  --cyan-a6: #00a7c162;
  --cyan-a7: #009fbb82;
  --cyan-a8: #00a3c0c2;
  --cyan-a9: #00a2c7;
  --cyan-a10: #0094b7f8;
  --cyan-a11: #007491ef;
  --cyan-a12: #00323ef2;
  --gold-1: #fdfdfc;
  --gold-2: #faf9f2;
  --gold-3: #f2f0e7;
  --gold-4: #eae6db;
  --gold-5: #e1dccf;
  --gold-6: #d8d0bf;
  --gold-7: #cbc0aa;
  --gold-8: #b9a88d;
  --gold-9: #978365;
  --gold-10: #8c7a5e;
  --gold-11: #71624b;
  --gold-12: #3b352b;
  --gold-a1: #55550003;
  --gold-a2: #9d8a000d;
  --gold-a3: #75600018;
  --gold-a4: #6b4e0024;
  --gold-a5: #60460030;
  --gold-a6: #64440040;
  --gold-a7: #63420055;
  --gold-a8: #633d0072;
  --gold-a9: #5332009a;
  --gold-a10: #492d00a1;
  --gold-a11: #362100b4;
  --gold-a12: #130c00d4;
  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #daf1db;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #65ba74;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #2a7e3b;
  --grass-12: #203c25;
  --grass-a1: #00c00004;
  --grass-a2: #0099000a;
  --grass-a3: #00970016;
  --grass-a4: #009f0725;
  --grass-a5: #00930536;
  --grass-a6: #008f0a4d;
  --grass-a7: #018b0f6b;
  --grass-a8: #008d199a;
  --grass-a9: #008619b9;
  --grass-a10: #007b17c1;
  --grass-a11: #006514d5;
  --grass-a12: #002006df;
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
  --green-a1: #00c04004;
  --green-a2: #00a32f0b;
  --green-a3: #00a43319;
  --green-a4: #00a83829;
  --green-a5: #019c393b;
  --green-a6: #00963c52;
  --green-a7: #00914071;
  --green-a8: #00924ba4;
  --green-a9: #008f4acf;
  --green-a10: #008647d4;
  --green-a11: #00713fde;
  --green-a12: #002616e6;
  --indigo-1: #fdfdfe;
  --indigo-2: #f7f9ff;
  --indigo-3: #edf2fe;
  --indigo-4: #e1e9ff;
  --indigo-5: #d2deff;
  --indigo-6: #c1d0ff;
  --indigo-7: #abbdf9;
  --indigo-8: #8da4ef;
  --indigo-9: #3e63dd;
  --indigo-10: #3358d4;
  --indigo-11: #3a5bc7;
  --indigo-12: #1f2d5c;
  --indigo-a1: #00008002;
  --indigo-a2: #0040ff08;
  --indigo-a3: #0047f112;
  --indigo-a4: #0044ff1e;
  --indigo-a5: #0044ff2d;
  --indigo-a6: #003eff3e;
  --indigo-a7: #0037ed54;
  --indigo-a8: #0034dc72;
  --indigo-a9: #0031d2c1;
  --indigo-a10: #002ec9cc;
  --indigo-a11: #002bb7c5;
  --indigo-a12: #001046e0;
  --iris-1: #fdfdff;
  --iris-2: #f8f8ff;
  --iris-3: #f0f1fe;
  --iris-4: #e6e7ff;
  --iris-5: #dadcff;
  --iris-6: #cbcdff;
  --iris-7: #b8baf8;
  --iris-8: #9b9ef0;
  --iris-9: #5b5bd6;
  --iris-10: #5151cd;
  --iris-11: #5753c6;
  --iris-12: #272962;
  --iris-a1: #0000ff02;
  --iris-a2: #0000ff07;
  --iris-a3: #0011ee0f;
  --iris-a4: #000bff19;
  --iris-a5: #000eff25;
  --iris-a6: #000aff34;
  --iris-a7: #0008e647;
  --iris-a8: #0008d964;
  --iris-a9: #0000c0a4;
  --iris-a10: #0000b6ae;
  --iris-a11: #0600abac;
  --iris-a12: #000246d8;
  --jade-1: #fbfefd;
  --jade-2: #f4fbf7;
  --jade-3: #e6f7ed;
  --jade-4: #d6f1e3;
  --jade-5: #c3e9d7;
  --jade-6: #acdec8;
  --jade-7: #8bceb6;
  --jade-8: #56ba9f;
  --jade-9: #29a383;
  --jade-10: #26997b;
  --jade-11: #208368;
  --jade-12: #1d3b31;
  --jade-a1: #00c08004;
  --jade-a2: #00a3460b;
  --jade-a3: #00ae4819;
  --jade-a4: #00a85129;
  --jade-a5: #00a2553c;
  --jade-a6: #009a5753;
  --jade-a7: #00945f74;
  --jade-a8: #00976ea9;
  --jade-a9: #00916bd6;
  --jade-a10: #008764d9;
  --jade-a11: #007152df;
  --jade-a12: #002217e2;
  --lime-1: #fcfdfa;
  --lime-2: #f8faf3;
  --lime-3: #eef6d6;
  --lime-4: #e2f0bd;
  --lime-5: #d3e7a6;
  --lime-6: #c2da91;
  --lime-7: #abc978;
  --lime-8: #8db654;
  --lime-9: #bdee63;
  --lime-10: #b0e64c;
  --lime-11: #5c7c2f;
  --lime-12: #37401c;
  --lime-a1: #66990005;
  --lime-a2: #6b95000c;
  --lime-a3: #96c80029;
  --lime-a4: #8fc60042;
  --lime-a5: #81bb0059;
  --lime-a6: #72aa006e;
  --lime-a7: #61990087;
  --lime-a8: #559200ab;
  --lime-a9: #93e4009c;
  --lime-a10: #8fdc00b3;
  --lime-a11: #375f00d0;
  --lime-a12: #1e2900e3;
  --mint-1: #f9fefd;
  --mint-2: #f2fbf9;
  --mint-3: #ddf9f2;
  --mint-4: #c8f4e9;
  --mint-5: #b3ecde;
  --mint-6: #9ce0d0;
  --mint-7: #7ecfbd;
  --mint-8: #4cbba5;
  --mint-9: #86ead4;
  --mint-10: #7de0cb;
  --mint-11: #027864;
  --mint-12: #16433c;
  --mint-a1: #00d5aa06;
  --mint-a2: #00b18a0d;
  --mint-a3: #00d29e22;
  --mint-a4: #00cc9937;
  --mint-a5: #00c0914c;
  --mint-a6: #00b08663;
  --mint-a7: #00a17d81;
  --mint-a8: #009e7fb3;
  --mint-a9: #00d3a579;
  --mint-a10: #00c39982;
  --mint-a11: #007763fd;
  --mint-a12: #00312ae9;
  --orange-1: #fefcfb;
  --orange-2: #fff7ed;
  --orange-3: #ffefd6;
  --orange-4: #ffdfb5;
  --orange-5: #ffd19a;
  --orange-6: #ffc182;
  --orange-7: #f5ae73;
  --orange-8: #ec9455;
  --orange-9: #f76b15;
  --orange-10: #ef5f00;
  --orange-11: #cc4e00;
  --orange-12: #582d1d;
  --orange-a1: #c0400004;
  --orange-a2: #ff8e0012;
  --orange-a3: #ff9c0029;
  --orange-a4: #ff91014a;
  --orange-a5: #ff8b0065;
  --orange-a6: #ff81007d;
  --orange-a7: #ed6c008c;
  --orange-a8: #e35f00aa;
  --orange-a9: #f65e00ea;
  --orange-a10: #ef5f00;
  --orange-a11: #cc4e00;
  --orange-a12: #431200e2;
  --pink-1: #fffcfe;
  --pink-2: #fef7fb;
  --pink-3: #fee9f5;
  --pink-4: #fbdcef;
  --pink-5: #f6cee7;
  --pink-6: #efbfdd;
  --pink-7: #e7acd0;
  --pink-8: #dd93c2;
  --pink-9: #d6409f;
  --pink-10: #cf3897;
  --pink-11: #c2298a;
  --pink-12: #651249;
  --pink-a1: #ff00aa03;
  --pink-a2: #e0008008;
  --pink-a3: #f4008c16;
  --pink-a4: #e2008b23;
  --pink-a5: #d1008331;
  --pink-a6: #c0007840;
  --pink-a7: #b6006f53;
  --pink-a8: #af006f6c;
  --pink-a9: #c8007fbf;
  --pink-a10: #c2007ac7;
  --pink-a11: #b60074d6;
  --pink-a12: #59003bed;
  --plum-1: #fefcff;
  --plum-2: #fdf7fd;
  --plum-3: #fbebfb;
  --plum-4: #f7def8;
  --plum-5: #f2d1f3;
  --plum-6: #e9c2ec;
  --plum-7: #deade3;
  --plum-8: #cf91d8;
  --plum-9: #ab4aba;
  --plum-10: #a144af;
  --plum-11: #953ea3;
  --plum-12: #53195d;
  --plum-a1: #aa00ff03;
  --plum-a2: #c000c008;
  --plum-a3: #cc00cc14;
  --plum-a4: #c200c921;
  --plum-a5: #b700bd2e;
  --plum-a6: #a400b03d;
  --plum-a7: #9900a852;
  --plum-a8: #9000a56e;
  --plum-a9: #89009eb5;
  --plum-a10: #7f0092bb;
  --plum-a11: #730086c1;
  --plum-a12: #40004be6;
  --purple-1: #fefcfe;
  --purple-2: #fbf7fe;
  --purple-3: #f7edfe;
  --purple-4: #f2e2fc;
  --purple-5: #ead5f9;
  --purple-6: #e0c4f4;
  --purple-7: #d1afec;
  --purple-8: #be93e4;
  --purple-9: #8e4ec6;
  --purple-10: #8347b9;
  --purple-11: #8145b5;
  --purple-12: #402060;
  --purple-a1: #aa00aa03;
  --purple-a2: #8000e008;
  --purple-a3: #8e00f112;
  --purple-a4: #8d00e51d;
  --purple-a5: #8000db2a;
  --purple-a6: #7a01d03b;
  --purple-a7: #6d00c350;
  --purple-a8: #6600c06c;
  --purple-a9: #5c00adb1;
  --purple-a10: #53009eb8;
  --purple-a11: #52009aba;
  --purple-a12: #250049df;
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
  --red-a1: #ff000003;
  --red-a2: #ff000008;
  --red-a3: #f3000d14;
  --red-a4: #ff000824;
  --red-a5: #ff000632;
  --red-a6: #f8000442;
  --red-a7: #df000356;
  --red-a8: #d2000571;
  --red-a9: #db0007b7;
  --red-a10: #d10005c1;
  --red-a11: #c40006d3;
  --red-a12: #55000de8;
  --ruby-1: #fffcfd;
  --ruby-2: #fff7f8;
  --ruby-3: #feeaed;
  --ruby-4: #ffdce1;
  --ruby-5: #ffced6;
  --ruby-6: #f8bfc8;
  --ruby-7: #efacb8;
  --ruby-8: #e592a3;
  --ruby-9: #e54666;
  --ruby-10: #dc3b5d;
  --ruby-11: #ca244d;
  --ruby-12: #64172b;
  --ruby-a1: #ff005503;
  --ruby-a2: #ff002008;
  --ruby-a3: #f3002515;
  --ruby-a4: #ff002523;
  --ruby-a5: #ff002a31;
  --ruby-a6: #e4002440;
  --ruby-a7: #ce002553;
  --ruby-a8: #c300286d;
  --ruby-a9: #db002cb9;
  --ruby-a10: #d2002cc4;
  --ruby-a11: #c10030db;
  --ruby-a12: #550016e8;
  --sky-1: #f9feff;
  --sky-2: #f1fafd;
  --sky-3: #e1f6fd;
  --sky-4: #d1f0fa;
  --sky-5: #bee7f5;
  --sky-6: #a9daed;
  --sky-7: #8dcae3;
  --sky-8: #60b3d7;
  --sky-9: #7ce2fe;
  --sky-10: #74daf8;
  --sky-11: #00749e;
  --sky-12: #1d3e56;
  --sky-a1: #00d5ff06;
  --sky-a2: #00a4db0e;
  --sky-a3: #00b3ee1e;
  --sky-a4: #00ace42e;
  --sky-a5: #00a1d841;
  --sky-a6: #0092ca56;
  --sky-a7: #0089c172;
  --sky-a8: #0085bf9f;
  --sky-a9: #00c7fe83;
  --sky-a10: #00bcf38b;
  --sky-a11: #00749e;
  --sky-a12: #002540e2;
  --teal-1: #fafefd;
  --teal-2: #f3fbf9;
  --teal-3: #e0f8f3;
  --teal-4: #ccf3ea;
  --teal-5: #b8eae0;
  --teal-6: #a1ded2;
  --teal-7: #83cdc1;
  --teal-8: #53b9ab;
  --teal-9: #12a594;
  --teal-10: #0d9b8a;
  --teal-11: #008573;
  --teal-12: #0d3d38;
  --teal-a1: #00cc9905;
  --teal-a2: #00aa800c;
  --teal-a3: #00c69d1f;
  --teal-a4: #00c39633;
  --teal-a5: #00b49047;
  --teal-a6: #00a6855e;
  --teal-a7: #0099807c;
  --teal-a8: #009783ac;
  --teal-a9: #009e8ced;
  --teal-a10: #009684f2;
  --teal-a11: #008573;
  --teal-a12: #00332df2;
  --tomato-1: #fffcfc;
  --tomato-2: #fff8f7;
  --tomato-3: #feebe7;
  --tomato-4: #ffdcd3;
  --tomato-5: #ffcdc2;
  --tomato-6: #fdbdaf;
  --tomato-7: #f5a898;
  --tomato-8: #ec8e7b;
  --tomato-9: #e54d2e;
  --tomato-10: #dd4425;
  --tomato-11: #d13415;
  --tomato-12: #5c271f;
  --tomato-a1: #ff000003;
  --tomato-a2: #ff200008;
  --tomato-a3: #f52b0018;
  --tomato-a4: #ff35002c;
  --tomato-a5: #ff2e003d;
  --tomato-a6: #f92d0050;
  --tomato-a7: #e7280067;
  --tomato-a8: #db250084;
  --tomato-a9: #df2600d1;
  --tomato-a10: #d72400da;
  --tomato-a11: #cd2200ea;
  --tomato-a12: #460900e0;
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
  --violet-a1: #5500aa03;
  --violet-a2: #4900ff07;
  --violet-a3: #4400ee0f;
  --violet-a4: #4300ff1b;
  --violet-a5: #3600ff26;
  --violet-a6: #3100fb35;
  --violet-a7: #2d01dd4a;
  --violet-a8: #2b00d066;
  --violet-a9: #2400b7a9;
  --violet-a10: #2300abb2;
  --violet-a11: #1f0099af;
  --violet-a12: #0b0043d9;
  --yellow-1: #fdfdf9;
  --yellow-2: #fefce9;
  --yellow-3: #fffab8;
  --yellow-4: #fff394;
  --yellow-5: #ffe770;
  --yellow-6: #f3d768;
  --yellow-7: #e4c767;
  --yellow-8: #d5ae39;
  --yellow-9: #ffe629;
  --yellow-10: #ffdc00;
  --yellow-11: #9e6c00;
  --yellow-12: #473b1f;
  --yellow-a1: #aaaa0006;
  --yellow-a2: #f4dd0016;
  --yellow-a3: #ffee0047;
  --yellow-a4: #ffe3016b;
  --yellow-a5: #ffd5008f;
  --yellow-a6: #ebbc0097;
  --yellow-a7: #d2a10098;
  --yellow-a8: #c99700c6;
  --yellow-a9: #ffe100d6;
  --yellow-a10: #ffdc00;
  --yellow-a11: #9e6c00;
  --yellow-a12: #2e2000e0;
  --gray-surface: #ffffffcc;
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: #ffffffcc;
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: #ffffffcc;
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: #ffffffcc;
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: #ffffffcc;
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: #ffffffcc;
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: #fefae4cc;
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: #f1f9ffcc;
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: #fdf5f3cc;
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: #fbf8f4cc;
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: #fef5f8cc;
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: #eff9facc;
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: #f9f8efcc;
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: #f3faf3cc;
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: #f1faf4cc;
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: #f5f8ffcc;
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: #f6f6ffcc;
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: #f1faf5cc;
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: #f6f9f0cc;
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: #effaf8cc;
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: #fff5e9cc;
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: #fef5facc;
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: #fdf5fdcc;
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: #faf5fecc;
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: #fff5f5cc;
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: #fff5f6cc;
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: #eef9fdcc;
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: #f0faf8cc;
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: #fff6f5cc;
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: #f9f6ffcc;
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: #fefbe4cc;
  --yellow-indicator: var(--yellow-10);
  --yellow-track: var(--yellow-10);
}
@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --gray-1: color(display-p3 0.988 0.988 0.988);
      --gray-2: color(display-p3 0.975 0.975 0.975);
      --gray-3: color(display-p3 0.939 0.939 0.939);
      --gray-4: color(display-p3 0.908 0.908 0.908);
      --gray-5: color(display-p3 0.88 0.88 0.88);
      --gray-6: color(display-p3 0.849 0.849 0.849);
      --gray-7: color(display-p3 0.807 0.807 0.807);
      --gray-8: color(display-p3 0.732 0.732 0.732);
      --gray-9: color(display-p3 0.553 0.553 0.553);
      --gray-10: color(display-p3 0.512 0.512 0.512);
      --gray-11: color(display-p3 0.392 0.392 0.392);
      --gray-12: color(display-p3 0.125 0.125 0.125);
      --gray-a1: color(display-p3 0 0 0 / 0.012);
      --gray-a2: color(display-p3 0 0 0 / 0.024);
      --gray-a3: color(display-p3 0 0 0 / 0.063);
      --gray-a4: color(display-p3 0 0 0 / 0.09);
      --gray-a5: color(display-p3 0 0 0 / 0.122);
      --gray-a6: color(display-p3 0 0 0 / 0.153);
      --gray-a7: color(display-p3 0 0 0 / 0.192);
      --gray-a8: color(display-p3 0 0 0 / 0.267);
      --gray-a9: color(display-p3 0 0 0 / 0.447);
      --gray-a10: color(display-p3 0 0 0 / 0.486);
      --gray-a11: color(display-p3 0 0 0 / 0.608);
      --gray-a12: color(display-p3 0 0 0 / 0.875);
      --mauve-1: color(display-p3 0.991 0.988 0.992);
      --mauve-2: color(display-p3 0.98 0.976 0.984);
      --mauve-3: color(display-p3 0.946 0.938 0.952);
      --mauve-4: color(display-p3 0.915 0.906 0.925);
      --mauve-5: color(display-p3 0.886 0.876 0.901);
      --mauve-6: color(display-p3 0.856 0.846 0.875);
      --mauve-7: color(display-p3 0.814 0.804 0.84);
      --mauve-8: color(display-p3 0.735 0.728 0.777);
      --mauve-9: color(display-p3 0.555 0.549 0.596);
      --mauve-10: color(display-p3 0.514 0.508 0.552);
      --mauve-11: color(display-p3 0.395 0.388 0.424);
      --mauve-12: color(display-p3 0.128 0.122 0.147);
      --mauve-a1: color(display-p3 0.349 0.024 0.349 / 0.012);
      --mauve-a2: color(display-p3 0.184 0.024 0.349 / 0.024);
      --mauve-a3: color(display-p3 0.129 0.008 0.255 / 0.063);
      --mauve-a4: color(display-p3 0.094 0.012 0.216 / 0.095);
      --mauve-a5: color(display-p3 0.098 0.008 0.224 / 0.126);
      --mauve-a6: color(display-p3 0.055 0.004 0.18 / 0.153);
      --mauve-a7: color(display-p3 0.067 0.008 0.184 / 0.197);
      --mauve-a8: color(display-p3 0.02 0.004 0.176 / 0.271);
      --mauve-a9: color(display-p3 0.02 0.004 0.106 / 0.451);
      --mauve-a10: color(display-p3 0.012 0.004 0.09 / 0.491);
      --mauve-a11: color(display-p3 0.016 0 0.059 / 0.612);
      --mauve-a12: color(display-p3 0.008 0 0.027 / 0.879);
      --slate-1: color(display-p3 0.988 0.988 0.992);
      --slate-2: color(display-p3 0.976 0.976 0.984);
      --slate-3: color(display-p3 0.94 0.941 0.953);
      --slate-4: color(display-p3 0.908 0.909 0.925);
      --slate-5: color(display-p3 0.88 0.881 0.901);
      --slate-6: color(display-p3 0.85 0.852 0.876);
      --slate-7: color(display-p3 0.805 0.808 0.838);
      --slate-8: color(display-p3 0.727 0.733 0.773);
      --slate-9: color(display-p3 0.547 0.553 0.592);
      --slate-10: color(display-p3 0.503 0.512 0.549);
      --slate-11: color(display-p3 0.379 0.392 0.421);
      --slate-12: color(display-p3 0.113 0.125 0.14);
      --slate-a1: color(display-p3 0.024 0.024 0.349 / 0.012);
      --slate-a2: color(display-p3 0.024 0.024 0.349 / 0.024);
      --slate-a3: color(display-p3 0.004 0.004 0.204 / 0.059);
      --slate-a4: color(display-p3 0.012 0.012 0.184 / 0.091);
      --slate-a5: color(display-p3 0.004 0.039 0.2 / 0.122);
      --slate-a6: color(display-p3 0.008 0.008 0.165 / 0.15);
      --slate-a7: color(display-p3 0.008 0.027 0.184 / 0.197);
      --slate-a8: color(display-p3 0.004 0.031 0.176 / 0.275);
      --slate-a9: color(display-p3 0.004 0.02 0.106 / 0.455);
      --slate-a10: color(display-p3 0.004 0.027 0.098 / 0.499);
      --slate-a11: color(display-p3 0 0.02 0.063 / 0.62);
      --slate-a12: color(display-p3 0 0.012 0.031 / 0.887);
      --sage-1: color(display-p3 0.986 0.992 0.988);
      --sage-2: color(display-p3 0.97 0.977 0.974);
      --sage-3: color(display-p3 0.935 0.944 0.94);
      --sage-4: color(display-p3 0.904 0.913 0.909);
      --sage-5: color(display-p3 0.875 0.885 0.88);
      --sage-6: color(display-p3 0.844 0.854 0.849);
      --sage-7: color(display-p3 0.8 0.811 0.806);
      --sage-8: color(display-p3 0.725 0.738 0.732);
      --sage-9: color(display-p3 0.531 0.556 0.546);
      --sage-10: color(display-p3 0.492 0.515 0.506);
      --sage-11: color(display-p3 0.377 0.395 0.389);
      --sage-12: color(display-p3 0.107 0.129 0.118);
      --sage-a1: color(display-p3 0.024 0.514 0.267 / 0.016);
      --sage-a2: color(display-p3 0.02 0.267 0.145 / 0.032);
      --sage-a3: color(display-p3 0.008 0.184 0.125 / 0.067);
      --sage-a4: color(display-p3 0.012 0.094 0.051 / 0.095);
      --sage-a5: color(display-p3 0.008 0.098 0.035 / 0.126);
      --sage-a6: color(display-p3 0.004 0.078 0.027 / 0.157);
      --sage-a7: color(display-p3 0 0.059 0.039 / 0.2);
      --sage-a8: color(display-p3 0.004 0.047 0.031 / 0.275);
      --sage-a9: color(display-p3 0.004 0.059 0.035 / 0.471);
      --sage-a10: color(display-p3 0 0.047 0.031 / 0.51);
      --sage-a11: color(display-p3 0 0.031 0.02 / 0.624);
      --sage-a12: color(display-p3 0 0.027 0.012 / 0.895);
      --olive-1: color(display-p3 0.989 0.992 0.989);
      --olive-2: color(display-p3 0.974 0.98 0.973);
      --olive-3: color(display-p3 0.939 0.945 0.937);
      --olive-4: color(display-p3 0.907 0.914 0.905);
      --olive-5: color(display-p3 0.878 0.885 0.875);
      --olive-6: color(display-p3 0.846 0.855 0.843);
      --olive-7: color(display-p3 0.803 0.812 0.8);
      --olive-8: color(display-p3 0.727 0.738 0.723);
      --olive-9: color(display-p3 0.541 0.556 0.532);
      --olive-10: color(display-p3 0.5 0.515 0.491);
      --olive-11: color(display-p3 0.38 0.395 0.374);
      --olive-12: color(display-p3 0.117 0.129 0.111);
      --olive-a1: color(display-p3 0.024 0.349 0.024 / 0.012);
      --olive-a2: color(display-p3 0.024 0.302 0.024 / 0.028);
      --olive-a3: color(display-p3 0.008 0.129 0.008 / 0.063);
      --olive-a4: color(display-p3 0.012 0.094 0.012 / 0.095);
      --olive-a5: color(display-p3 0.035 0.098 0.008 / 0.126);
      --olive-a6: color(display-p3 0.027 0.078 0.004 / 0.157);
      --olive-a7: color(display-p3 0.02 0.059 0 / 0.2);
      --olive-a8: color(display-p3 0.02 0.059 0.004 / 0.279);
      --olive-a9: color(display-p3 0.02 0.051 0.004 / 0.467);
      --olive-a10: color(display-p3 0.024 0.047 0 / 0.51);
      --olive-a11: color(display-p3 0.012 0.039 0 / 0.628);
      --olive-a12: color(display-p3 0.008 0.024 0 / 0.891);
      --sand-1: color(display-p3 0.992 0.992 0.989);
      --sand-2: color(display-p3 0.977 0.977 0.973);
      --sand-3: color(display-p3 0.943 0.942 0.936);
      --sand-4: color(display-p3 0.913 0.912 0.903);
      --sand-5: color(display-p3 0.885 0.883 0.873);
      --sand-6: color(display-p3 0.854 0.852 0.839);
      --sand-7: color(display-p3 0.813 0.81 0.794);
      --sand-8: color(display-p3 0.738 0.734 0.713);
      --sand-9: color(display-p3 0.553 0.553 0.528);
      --sand-10: color(display-p3 0.511 0.511 0.488);
      --sand-11: color(display-p3 0.388 0.388 0.37);
      --sand-12: color(display-p3 0.129 0.126 0.111);
      --sand-a1: color(display-p3 0.349 0.349 0.024 / 0.012);
      --sand-a2: color(display-p3 0.161 0.161 0.024 / 0.028);
      --sand-a3: color(display-p3 0.067 0.067 0.008 / 0.063);
      --sand-a4: color(display-p3 0.129 0.129 0.012 / 0.099);
      --sand-a5: color(display-p3 0.098 0.067 0.008 / 0.126);
      --sand-a6: color(display-p3 0.102 0.075 0.004 / 0.161);
      --sand-a7: color(display-p3 0.098 0.098 0.004 / 0.208);
      --sand-a8: color(display-p3 0.086 0.075 0.004 / 0.287);
      --sand-a9: color(display-p3 0.051 0.051 0.004 / 0.471);
      --sand-a10: color(display-p3 0.047 0.047 0 / 0.514);
      --sand-a11: color(display-p3 0.031 0.031 0 / 0.632);
      --sand-a12: color(display-p3 0.024 0.02 0 / 0.891);
      --amber-1: color(display-p3 0.995 0.992 0.985);
      --amber-2: color(display-p3 0.994 0.986 0.921);
      --amber-3: color(display-p3 0.994 0.969 0.782);
      --amber-4: color(display-p3 0.989 0.937 0.65);
      --amber-5: color(display-p3 0.97 0.902 0.527);
      --amber-6: color(display-p3 0.936 0.844 0.506);
      --amber-7: color(display-p3 0.89 0.762 0.443);
      --amber-8: color(display-p3 0.85 0.65 0.3);
      --amber-9: color(display-p3 1 0.77 0.26);
      --amber-10: color(display-p3 0.959 0.741 0.274);
      --amber-11: color(display-p3 0.64 0.4 0);
      --amber-12: color(display-p3 0.294 0.208 0.145);
      --amber-a1: color(display-p3 0.757 0.514 0.024 / 0.016);
      --amber-a2: color(display-p3 0.902 0.804 0.008 / 0.079);
      --amber-a3: color(display-p3 0.965 0.859 0.004 / 0.22);
      --amber-a4: color(display-p3 0.969 0.82 0.004 / 0.35);
      --amber-a5: color(display-p3 0.933 0.796 0.004 / 0.475);
      --amber-a6: color(display-p3 0.875 0.682 0.004 / 0.495);
      --amber-a7: color(display-p3 0.804 0.573 0 / 0.557);
      --amber-a8: color(display-p3 0.788 0.502 0 / 0.699);
      --amber-a9: color(display-p3 1 0.686 0 / 0.742);
      --amber-a10: color(display-p3 0.945 0.643 0 / 0.726);
      --amber-a11: color(display-p3 0.64 0.4 0);
      --amber-a12: color(display-p3 0.294 0.208 0.145);
      --blue-1: color(display-p3 0.986 0.992 0.999);
      --blue-2: color(display-p3 0.96 0.979 0.998);
      --blue-3: color(display-p3 0.912 0.956 0.991);
      --blue-4: color(display-p3 0.853 0.932 1);
      --blue-5: color(display-p3 0.788 0.894 0.998);
      --blue-6: color(display-p3 0.709 0.843 0.976);
      --blue-7: color(display-p3 0.606 0.777 0.947);
      --blue-8: color(display-p3 0.451 0.688 0.917);
      --blue-9: color(display-p3 0.247 0.556 0.969);
      --blue-10: color(display-p3 0.234 0.523 0.912);
      --blue-11: color(display-p3 0.15 0.44 0.84);
      --blue-12: color(display-p3 0.102 0.193 0.379);
      --blue-a1: color(display-p3 0.024 0.514 1 / 0.016);
      --blue-a2: color(display-p3 0.024 0.514 0.906 / 0.04);
      --blue-a3: color(display-p3 0.012 0.506 0.914 / 0.087);
      --blue-a4: color(display-p3 0.008 0.545 1 / 0.146);
      --blue-a5: color(display-p3 0.004 0.502 0.984 / 0.212);
      --blue-a6: color(display-p3 0.004 0.463 0.922 / 0.291);
      --blue-a7: color(display-p3 0.004 0.431 0.863 / 0.393);
      --blue-a8: color(display-p3 0 0.427 0.851 / 0.55);
      --blue-a9: color(display-p3 0 0.412 0.961 / 0.753);
      --blue-a10: color(display-p3 0 0.376 0.886 / 0.765);
      --blue-a11: color(display-p3 0.15 0.44 0.84);
      --blue-a12: color(display-p3 0.102 0.193 0.379);
      --bronze-1: color(display-p3 0.991 0.988 0.988);
      --bronze-2: color(display-p3 0.989 0.97 0.961);
      --bronze-3: color(display-p3 0.958 0.932 0.919);
      --bronze-4: color(display-p3 0.929 0.894 0.877);
      --bronze-5: color(display-p3 0.898 0.853 0.832);
      --bronze-6: color(display-p3 0.861 0.805 0.778);
      --bronze-7: color(display-p3 0.812 0.739 0.706);
      --bronze-8: color(display-p3 0.741 0.647 0.606);
      --bronze-9: color(display-p3 0.611 0.507 0.455);
      --bronze-10: color(display-p3 0.563 0.461 0.414);
      --bronze-11: color(display-p3 0.471 0.373 0.336);
      --bronze-12: color(display-p3 0.251 0.191 0.172);
      --bronze-a1: color(display-p3 0.349 0.024 0.024 / 0.012);
      --bronze-a2: color(display-p3 0.71 0.22 0.024 / 0.04);
      --bronze-a3: color(display-p3 0.482 0.2 0.008 / 0.083);
      --bronze-a4: color(display-p3 0.424 0.133 0.004 / 0.122);
      --bronze-a5: color(display-p3 0.4 0.145 0.004 / 0.169);
      --bronze-a6: color(display-p3 0.388 0.125 0.004 / 0.224);
      --bronze-a7: color(display-p3 0.365 0.11 0.004 / 0.295);
      --bronze-a8: color(display-p3 0.341 0.102 0.004 / 0.393);
      --bronze-a9: color(display-p3 0.29 0.094 0 / 0.546);
      --bronze-a10: color(display-p3 0.255 0.082 0 / 0.585);
      --bronze-a11: color(display-p3 0.471 0.373 0.336);
      --bronze-a12: color(display-p3 0.251 0.191 0.172);
      --brown-1: color(display-p3 0.995 0.992 0.989);
      --brown-2: color(display-p3 0.987 0.976 0.964);
      --brown-3: color(display-p3 0.959 0.936 0.909);
      --brown-4: color(display-p3 0.934 0.897 0.855);
      --brown-5: color(display-p3 0.909 0.856 0.798);
      --brown-6: color(display-p3 0.88 0.808 0.73);
      --brown-7: color(display-p3 0.841 0.742 0.639);
      --brown-8: color(display-p3 0.782 0.647 0.514);
      --brown-9: color(display-p3 0.651 0.505 0.368);
      --brown-10: color(display-p3 0.601 0.465 0.344);
      --brown-11: color(display-p3 0.485 0.374 0.288);
      --brown-12: color(display-p3 0.236 0.202 0.183);
      --brown-a1: color(display-p3 0.675 0.349 0.024 / 0.012);
      --brown-a2: color(display-p3 0.675 0.349 0.024 / 0.036);
      --brown-a3: color(display-p3 0.573 0.314 0.012 / 0.091);
      --brown-a4: color(display-p3 0.545 0.302 0.008 / 0.146);
      --brown-a5: color(display-p3 0.561 0.29 0.004 / 0.204);
      --brown-a6: color(display-p3 0.553 0.294 0.004 / 0.271);
      --brown-a7: color(display-p3 0.557 0.286 0.004 / 0.361);
      --brown-a8: color(display-p3 0.549 0.275 0.004 / 0.487);
      --brown-a9: color(display-p3 0.447 0.22 0 / 0.632);
      --brown-a10: color(display-p3 0.388 0.188 0 / 0.655);
      --brown-a11: color(display-p3 0.485 0.374 0.288);
      --brown-a12: color(display-p3 0.236 0.202 0.183);
      --crimson-1: color(display-p3 0.998 0.989 0.992);
      --crimson-2: color(display-p3 0.991 0.969 0.976);
      --crimson-3: color(display-p3 0.987 0.917 0.941);
      --crimson-4: color(display-p3 0.975 0.866 0.904);
      --crimson-5: color(display-p3 0.953 0.813 0.864);
      --crimson-6: color(display-p3 0.921 0.755 0.817);
      --crimson-7: color(display-p3 0.88 0.683 0.761);
      --crimson-8: color(display-p3 0.834 0.592 0.694);
      --crimson-9: color(display-p3 0.843 0.298 0.507);
      --crimson-10: color(display-p3 0.807 0.266 0.468);
      --crimson-11: color(display-p3 0.731 0.195 0.388);
      --crimson-12: color(display-p3 0.352 0.111 0.221);
      --crimson-a1: color(display-p3 0.675 0.024 0.349 / 0.012);
      --crimson-a2: color(display-p3 0.757 0.02 0.267 / 0.032);
      --crimson-a3: color(display-p3 0.859 0.008 0.294 / 0.083);
      --crimson-a4: color(display-p3 0.827 0.008 0.298 / 0.134);
      --crimson-a5: color(display-p3 0.753 0.008 0.275 / 0.189);
      --crimson-a6: color(display-p3 0.682 0.004 0.247 / 0.244);
      --crimson-a7: color(display-p3 0.62 0.004 0.251 / 0.318);
      --crimson-a8: color(display-p3 0.6 0.004 0.251 / 0.408);
      --crimson-a9: color(display-p3 0.776 0 0.298 / 0.702);
      --crimson-a10: color(display-p3 0.737 0 0.275 / 0.734);
      --crimson-a11: color(display-p3 0.731 0.195 0.388);
      --crimson-a12: color(display-p3 0.352 0.111 0.221);
      --cyan-1: color(display-p3 0.982 0.992 0.996);
      --cyan-2: color(display-p3 0.955 0.981 0.984);
      --cyan-3: color(display-p3 0.888 0.965 0.975);
      --cyan-4: color(display-p3 0.821 0.941 0.959);
      --cyan-5: color(display-p3 0.751 0.907 0.935);
      --cyan-6: color(display-p3 0.671 0.862 0.9);
      --cyan-7: color(display-p3 0.564 0.8 0.854);
      --cyan-8: color(display-p3 0.388 0.715 0.798);
      --cyan-9: color(display-p3 0.282 0.627 0.765);
      --cyan-10: color(display-p3 0.264 0.583 0.71);
      --cyan-11: color(display-p3 0.08 0.48 0.63);
      --cyan-12: color(display-p3 0.108 0.232 0.277);
      --cyan-a1: color(display-p3 0.02 0.608 0.804 / 0.02);
      --cyan-a2: color(display-p3 0.02 0.557 0.647 / 0.044);
      --cyan-a3: color(display-p3 0.004 0.694 0.796 / 0.114);
      --cyan-a4: color(display-p3 0.004 0.678 0.784 / 0.181);
      --cyan-a5: color(display-p3 0.004 0.624 0.733 / 0.248);
      --cyan-a6: color(display-p3 0.004 0.584 0.706 / 0.33);
      --cyan-a7: color(display-p3 0.004 0.541 0.667 / 0.436);
      --cyan-a8: color(display-p3 0 0.533 0.667 / 0.612);
      --cyan-a9: color(display-p3 0 0.482 0.675 / 0.718);
      --cyan-a10: color(display-p3 0 0.435 0.608 / 0.738);
      --cyan-a11: color(display-p3 0.08 0.48 0.63);
      --cyan-a12: color(display-p3 0.108 0.232 0.277);
      --gold-1: color(display-p3 0.992 0.992 0.989);
      --gold-2: color(display-p3 0.98 0.976 0.953);
      --gold-3: color(display-p3 0.947 0.94 0.909);
      --gold-4: color(display-p3 0.914 0.904 0.865);
      --gold-5: color(display-p3 0.88 0.865 0.816);
      --gold-6: color(display-p3 0.84 0.818 0.756);
      --gold-7: color(display-p3 0.788 0.753 0.677);
      --gold-8: color(display-p3 0.715 0.66 0.565);
      --gold-9: color(display-p3 0.579 0.517 0.41);
      --gold-10: color(display-p3 0.538 0.479 0.38);
      --gold-11: color(display-p3 0.433 0.386 0.305);
      --gold-12: color(display-p3 0.227 0.209 0.173);
      --gold-a1: color(display-p3 0.349 0.349 0.024 / 0.012);
      --gold-a2: color(display-p3 0.592 0.514 0.024 / 0.048);
      --gold-a3: color(display-p3 0.4 0.357 0.012 / 0.091);
      --gold-a4: color(display-p3 0.357 0.298 0.008 / 0.134);
      --gold-a5: color(display-p3 0.345 0.282 0.004 / 0.185);
      --gold-a6: color(display-p3 0.341 0.263 0.004 / 0.244);
      --gold-a7: color(display-p3 0.345 0.235 0.004 / 0.322);
      --gold-a8: color(display-p3 0.345 0.22 0.004 / 0.436);
      --gold-a9: color(display-p3 0.286 0.18 0 / 0.589);
      --gold-a10: color(display-p3 0.255 0.161 0 / 0.62);
      --gold-a11: color(display-p3 0.433 0.386 0.305);
      --gold-a12: color(display-p3 0.227 0.209 0.173);
      --grass-1: color(display-p3 0.986 0.996 0.985);
      --grass-2: color(display-p3 0.966 0.983 0.964);
      --grass-3: color(display-p3 0.923 0.965 0.917);
      --grass-4: color(display-p3 0.872 0.94 0.865);
      --grass-5: color(display-p3 0.811 0.908 0.802);
      --grass-6: color(display-p3 0.733 0.864 0.724);
      --grass-7: color(display-p3 0.628 0.803 0.622);
      --grass-8: color(display-p3 0.477 0.72 0.482);
      --grass-9: color(display-p3 0.38 0.647 0.378);
      --grass-10: color(display-p3 0.344 0.598 0.342);
      --grass-11: color(display-p3 0.263 0.488 0.261);
      --grass-12: color(display-p3 0.151 0.233 0.153);
      --grass-a1: color(display-p3 0.024 0.757 0.024 / 0.016);
      --grass-a2: color(display-p3 0.024 0.565 0.024 / 0.036);
      --grass-a3: color(display-p3 0.059 0.576 0.008 / 0.083);
      --grass-a4: color(display-p3 0.035 0.565 0.008 / 0.134);
      --grass-a5: color(display-p3 0.047 0.545 0.008 / 0.197);
      --grass-a6: color(display-p3 0.031 0.502 0.004 / 0.275);
      --grass-a7: color(display-p3 0.012 0.482 0.004 / 0.377);
      --grass-a8: color(display-p3 0 0.467 0.008 / 0.522);
      --grass-a9: color(display-p3 0.008 0.435 0 / 0.624);
      --grass-a10: color(display-p3 0.008 0.388 0 / 0.659);
      --grass-a11: color(display-p3 0.263 0.488 0.261);
      --grass-a12: color(display-p3 0.151 0.233 0.153);
      --green-1: color(display-p3 0.986 0.996 0.989);
      --green-2: color(display-p3 0.963 0.983 0.967);
      --green-3: color(display-p3 0.913 0.964 0.925);
      --green-4: color(display-p3 0.859 0.94 0.879);
      --green-5: color(display-p3 0.796 0.907 0.826);
      --green-6: color(display-p3 0.718 0.863 0.761);
      --green-7: color(display-p3 0.61 0.801 0.675);
      --green-8: color(display-p3 0.451 0.715 0.559);
      --green-9: color(display-p3 0.332 0.634 0.442);
      --green-10: color(display-p3 0.308 0.595 0.417);
      --green-11: color(display-p3 0.19 0.5 0.32);
      --green-12: color(display-p3 0.132 0.228 0.18);
      --green-a1: color(display-p3 0.024 0.757 0.267 / 0.016);
      --green-a2: color(display-p3 0.024 0.565 0.129 / 0.036);
      --green-a3: color(display-p3 0.012 0.596 0.145 / 0.087);
      --green-a4: color(display-p3 0.008 0.588 0.145 / 0.142);
      --green-a5: color(display-p3 0.004 0.541 0.157 / 0.204);
      --green-a6: color(display-p3 0.004 0.518 0.157 / 0.283);
      --green-a7: color(display-p3 0.004 0.486 0.165 / 0.389);
      --green-a8: color(display-p3 0 0.478 0.2 / 0.55);
      --green-a9: color(display-p3 0 0.455 0.165 / 0.667);
      --green-a10: color(display-p3 0 0.416 0.153 / 0.691);
      --green-a11: color(display-p3 0.19 0.5 0.32);
      --green-a12: color(display-p3 0.132 0.228 0.18);
      --indigo-1: color(display-p3 0.992 0.992 0.996);
      --indigo-2: color(display-p3 0.971 0.977 0.998);
      --indigo-3: color(display-p3 0.933 0.948 0.992);
      --indigo-4: color(display-p3 0.885 0.914 1);
      --indigo-5: color(display-p3 0.831 0.87 1);
      --indigo-6: color(display-p3 0.767 0.814 0.995);
      --indigo-7: color(display-p3 0.685 0.74 0.957);
      --indigo-8: color(display-p3 0.569 0.639 0.916);
      --indigo-9: color(display-p3 0.276 0.384 0.837);
      --indigo-10: color(display-p3 0.234 0.343 0.801);
      --indigo-11: color(display-p3 0.256 0.354 0.755);
      --indigo-12: color(display-p3 0.133 0.175 0.348);
      --indigo-a1: color(display-p3 0.02 0.02 0.51 / 0.008);
      --indigo-a2: color(display-p3 0.024 0.161 0.863 / 0.028);
      --indigo-a3: color(display-p3 0.008 0.239 0.886 / 0.067);
      --indigo-a4: color(display-p3 0.004 0.247 1 / 0.114);
      --indigo-a5: color(display-p3 0.004 0.235 1 / 0.169);
      --indigo-a6: color(display-p3 0.004 0.208 0.984 / 0.232);
      --indigo-a7: color(display-p3 0.004 0.176 0.863 / 0.314);
      --indigo-a8: color(display-p3 0.004 0.165 0.812 / 0.432);
      --indigo-a9: color(display-p3 0 0.153 0.773 / 0.726);
      --indigo-a10: color(display-p3 0 0.137 0.737 / 0.765);
      --indigo-a11: color(display-p3 0.256 0.354 0.755);
      --indigo-a12: color(display-p3 0.133 0.175 0.348);
      --iris-1: color(display-p3 0.992 0.992 0.999);
      --iris-2: color(display-p3 0.972 0.973 0.998);
      --iris-3: color(display-p3 0.943 0.945 0.992);
      --iris-4: color(display-p3 0.902 0.906 1);
      --iris-5: color(display-p3 0.857 0.861 1);
      --iris-6: color(display-p3 0.799 0.805 0.987);
      --iris-7: color(display-p3 0.721 0.727 0.955);
      --iris-8: color(display-p3 0.61 0.619 0.918);
      --iris-9: color(display-p3 0.357 0.357 0.81);
      --iris-10: color(display-p3 0.318 0.318 0.774);
      --iris-11: color(display-p3 0.337 0.326 0.748);
      --iris-12: color(display-p3 0.154 0.161 0.371);
      --iris-a1: color(display-p3 0.02 0.02 1 / 0.008);
      --iris-a2: color(display-p3 0.024 0.024 0.863 / 0.028);
      --iris-a3: color(display-p3 0.004 0.071 0.871 / 0.059);
      --iris-a4: color(display-p3 0.012 0.051 1 / 0.099);
      --iris-a5: color(display-p3 0.008 0.035 1 / 0.142);
      --iris-a6: color(display-p3 0 0.02 0.941 / 0.2);
      --iris-a7: color(display-p3 0.004 0.02 0.847 / 0.279);
      --iris-a8: color(display-p3 0.004 0.024 0.788 / 0.389);
      --iris-a9: color(display-p3 0 0 0.706 / 0.644);
      --iris-a10: color(display-p3 0 0 0.667 / 0.683);
      --iris-a11: color(display-p3 0.337 0.326 0.748);
      --iris-a12: color(display-p3 0.154 0.161 0.371);
      --jade-1: color(display-p3 0.986 0.996 0.992);
      --jade-2: color(display-p3 0.962 0.983 0.969);
      --jade-3: color(display-p3 0.912 0.965 0.932);
      --jade-4: color(display-p3 0.858 0.941 0.893);
      --jade-5: color(display-p3 0.795 0.909 0.847);
      --jade-6: color(display-p3 0.715 0.864 0.791);
      --jade-7: color(display-p3 0.603 0.802 0.718);
      --jade-8: color(display-p3 0.44 0.72 0.629);
      --jade-9: color(display-p3 0.319 0.63 0.521);
      --jade-10: color(display-p3 0.299 0.592 0.488);
      --jade-11: color(display-p3 0.15 0.5 0.37);
      --jade-12: color(display-p3 0.142 0.229 0.194);
      --jade-a1: color(display-p3 0.024 0.757 0.514 / 0.016);
      --jade-a2: color(display-p3 0.024 0.612 0.22 / 0.04);
      --jade-a3: color(display-p3 0.012 0.596 0.235 / 0.087);
      --jade-a4: color(display-p3 0.008 0.588 0.255 / 0.142);
      --jade-a5: color(display-p3 0.004 0.561 0.251 / 0.204);
      --jade-a6: color(display-p3 0.004 0.525 0.278 / 0.287);
      --jade-a7: color(display-p3 0.004 0.506 0.29 / 0.397);
      --jade-a8: color(display-p3 0 0.506 0.337 / 0.561);
      --jade-a9: color(display-p3 0 0.459 0.298 / 0.683);
      --jade-a10: color(display-p3 0 0.42 0.271 / 0.702);
      --jade-a11: color(display-p3 0.15 0.5 0.37);
      --jade-a12: color(display-p3 0.142 0.229 0.194);
      --lime-1: color(display-p3 0.989 0.992 0.981);
      --lime-2: color(display-p3 0.975 0.98 0.954);
      --lime-3: color(display-p3 0.939 0.965 0.851);
      --lime-4: color(display-p3 0.896 0.94 0.76);
      --lime-5: color(display-p3 0.843 0.903 0.678);
      --lime-6: color(display-p3 0.778 0.852 0.599);
      --lime-7: color(display-p3 0.694 0.784 0.508);
      --lime-8: color(display-p3 0.585 0.707 0.378);
      --lime-9: color(display-p3 0.78 0.928 0.466);
      --lime-10: color(display-p3 0.734 0.896 0.397);
      --lime-11: color(display-p3 0.386 0.482 0.227);
      --lime-12: color(display-p3 0.222 0.25 0.128);
      --lime-a1: color(display-p3 0.412 0.608 0.02 / 0.02);
      --lime-a2: color(display-p3 0.514 0.592 0.024 / 0.048);
      --lime-a3: color(display-p3 0.584 0.765 0.008 / 0.15);
      --lime-a4: color(display-p3 0.561 0.757 0.004 / 0.24);
      --lime-a5: color(display-p3 0.514 0.698 0.004 / 0.322);
      --lime-a6: color(display-p3 0.443 0.627 0 / 0.4);
      --lime-a7: color(display-p3 0.376 0.561 0.004 / 0.491);
      --lime-a8: color(display-p3 0.333 0.529 0 / 0.624);
      --lime-a9: color(display-p3 0.588 0.867 0 / 0.534);
      --lime-a10: color(display-p3 0.561 0.827 0 / 0.604);
      --lime-a11: color(display-p3 0.386 0.482 0.227);
      --lime-a12: color(display-p3 0.222 0.25 0.128);
      --mint-1: color(display-p3 0.98 0.995 0.992);
      --mint-2: color(display-p3 0.957 0.985 0.977);
      --mint-3: color(display-p3 0.888 0.972 0.95);
      --mint-4: color(display-p3 0.819 0.951 0.916);
      --mint-5: color(display-p3 0.747 0.918 0.873);
      --mint-6: color(display-p3 0.668 0.87 0.818);
      --mint-7: color(display-p3 0.567 0.805 0.744);
      --mint-8: color(display-p3 0.42 0.724 0.649);
      --mint-9: color(display-p3 0.62 0.908 0.834);
      --mint-10: color(display-p3 0.585 0.871 0.797);
      --mint-11: color(display-p3 0.203 0.463 0.397);
      --mint-12: color(display-p3 0.136 0.259 0.236);
      --mint-a1: color(display-p3 0.02 0.804 0.608 / 0.02);
      --mint-a2: color(display-p3 0.02 0.647 0.467 / 0.044);
      --mint-a3: color(display-p3 0.004 0.761 0.553 / 0.114);
      --mint-a4: color(display-p3 0.004 0.741 0.545 / 0.181);
      --mint-a5: color(display-p3 0.004 0.678 0.51 / 0.255);
      --mint-a6: color(display-p3 0.004 0.616 0.463 / 0.334);
      --mint-a7: color(display-p3 0.004 0.549 0.412 / 0.432);
      --mint-a8: color(display-p3 0 0.529 0.392 / 0.581);
      --mint-a9: color(display-p3 0.004 0.765 0.569 / 0.381);
      --mint-a10: color(display-p3 0.004 0.69 0.51 / 0.416);
      --mint-a11: color(display-p3 0.203 0.463 0.397);
      --mint-a12: color(display-p3 0.136 0.259 0.236);
      --orange-1: color(display-p3 0.995 0.988 0.985);
      --orange-2: color(display-p3 0.994 0.968 0.934);
      --orange-3: color(display-p3 0.989 0.938 0.85);
      --orange-4: color(display-p3 1 0.874 0.687);
      --orange-5: color(display-p3 1 0.821 0.583);
      --orange-6: color(display-p3 0.975 0.767 0.545);
      --orange-7: color(display-p3 0.919 0.693 0.486);
      --orange-8: color(display-p3 0.877 0.597 0.379);
      --orange-9: color(display-p3 0.9 0.45 0.2);
      --orange-10: color(display-p3 0.87 0.409 0.164);
      --orange-11: color(display-p3 0.76 0.34 0);
      --orange-12: color(display-p3 0.323 0.185 0.127);
      --orange-a1: color(display-p3 0.757 0.267 0.024 / 0.016);
      --orange-a2: color(display-p3 0.886 0.533 0.008 / 0.067);
      --orange-a3: color(display-p3 0.922 0.584 0.008 / 0.15);
      --orange-a4: color(display-p3 1 0.604 0.004 / 0.314);
      --orange-a5: color(display-p3 1 0.569 0.004 / 0.416);
      --orange-a6: color(display-p3 0.949 0.494 0.004 / 0.455);
      --orange-a7: color(display-p3 0.839 0.408 0 / 0.514);
      --orange-a8: color(display-p3 0.804 0.349 0 / 0.62);
      --orange-a9: color(display-p3 0.878 0.314 0 / 0.8);
      --orange-a10: color(display-p3 0.843 0.29 0 / 0.836);
      --orange-a11: color(display-p3 0.76 0.34 0);
      --orange-a12: color(display-p3 0.323 0.185 0.127);
      --pink-1: color(display-p3 0.998 0.989 0.996);
      --pink-2: color(display-p3 0.992 0.97 0.985);
      --pink-3: color(display-p3 0.981 0.917 0.96);
      --pink-4: color(display-p3 0.963 0.867 0.932);
      --pink-5: color(display-p3 0.939 0.815 0.899);
      --pink-6: color(display-p3 0.907 0.756 0.859);
      --pink-7: color(display-p3 0.869 0.683 0.81);
      --pink-8: color(display-p3 0.825 0.59 0.751);
      --pink-9: color(display-p3 0.775 0.297 0.61);
      --pink-10: color(display-p3 0.748 0.27 0.581);
      --pink-11: color(display-p3 0.698 0.219 0.528);
      --pink-12: color(display-p3 0.363 0.101 0.279);
      --pink-a1: color(display-p3 0.675 0.024 0.675 / 0.012);
      --pink-a2: color(display-p3 0.757 0.02 0.51 / 0.032);
      --pink-a3: color(display-p3 0.765 0.008 0.529 / 0.083);
      --pink-a4: color(display-p3 0.737 0.008 0.506 / 0.134);
      --pink-a5: color(display-p3 0.663 0.004 0.451 / 0.185);
      --pink-a6: color(display-p3 0.616 0.004 0.424 / 0.244);
      --pink-a7: color(display-p3 0.596 0.004 0.412 / 0.318);
      --pink-a8: color(display-p3 0.573 0.004 0.404 / 0.412);
      --pink-a9: color(display-p3 0.682 0 0.447 / 0.702);
      --pink-a10: color(display-p3 0.655 0 0.424 / 0.73);
      --pink-a11: color(display-p3 0.698 0.219 0.528);
      --pink-a12: color(display-p3 0.363 0.101 0.279);
      --plum-1: color(display-p3 0.995 0.988 0.999);
      --plum-2: color(display-p3 0.988 0.971 0.99);
      --plum-3: color(display-p3 0.973 0.923 0.98);
      --plum-4: color(display-p3 0.953 0.875 0.966);
      --plum-5: color(display-p3 0.926 0.825 0.945);
      --plum-6: color(display-p3 0.89 0.765 0.916);
      --plum-7: color(display-p3 0.84 0.686 0.877);
      --plum-8: color(display-p3 0.775 0.58 0.832);
      --plum-9: color(display-p3 0.624 0.313 0.708);
      --plum-10: color(display-p3 0.587 0.29 0.667);
      --plum-11: color(display-p3 0.543 0.263 0.619);
      --plum-12: color(display-p3 0.299 0.114 0.352);
      --plum-a1: color(display-p3 0.675 0.024 1 / 0.012);
      --plum-a2: color(display-p3 0.58 0.024 0.58 / 0.028);
      --plum-a3: color(display-p3 0.655 0.008 0.753 / 0.079);
      --plum-a4: color(display-p3 0.627 0.008 0.722 / 0.126);
      --plum-a5: color(display-p3 0.58 0.004 0.69 / 0.177);
      --plum-a6: color(display-p3 0.537 0.004 0.655 / 0.236);
      --plum-a7: color(display-p3 0.49 0.004 0.616 / 0.314);
      --plum-a8: color(display-p3 0.471 0.004 0.6 / 0.42);
      --plum-a9: color(display-p3 0.451 0 0.576 / 0.687);
      --plum-a10: color(display-p3 0.42 0 0.529 / 0.71);
      --plum-a11: color(display-p3 0.543 0.263 0.619);
      --plum-a12: color(display-p3 0.299 0.114 0.352);
      --purple-1: color(display-p3 0.995 0.988 0.996);
      --purple-2: color(display-p3 0.983 0.971 0.993);
      --purple-3: color(display-p3 0.963 0.931 0.989);
      --purple-4: color(display-p3 0.937 0.888 0.981);
      --purple-5: color(display-p3 0.904 0.837 0.966);
      --purple-6: color(display-p3 0.86 0.774 0.942);
      --purple-7: color(display-p3 0.799 0.69 0.91);
      --purple-8: color(display-p3 0.719 0.583 0.874);
      --purple-9: color(display-p3 0.523 0.318 0.751);
      --purple-10: color(display-p3 0.483 0.289 0.7);
      --purple-11: color(display-p3 0.473 0.281 0.687);
      --purple-12: color(display-p3 0.234 0.132 0.363);
      --purple-a1: color(display-p3 0.675 0.024 0.675 / 0.012);
      --purple-a2: color(display-p3 0.443 0.024 0.722 / 0.028);
      --purple-a3: color(display-p3 0.506 0.008 0.835 / 0.071);
      --purple-a4: color(display-p3 0.451 0.004 0.831 / 0.114);
      --purple-a5: color(display-p3 0.431 0.004 0.788 / 0.165);
      --purple-a6: color(display-p3 0.384 0.004 0.745 / 0.228);
      --purple-a7: color(display-p3 0.357 0.004 0.71 / 0.31);
      --purple-a8: color(display-p3 0.322 0.004 0.702 / 0.416);
      --purple-a9: color(display-p3 0.298 0 0.639 / 0.683);
      --purple-a10: color(display-p3 0.271 0 0.58 / 0.71);
      --purple-a11: color(display-p3 0.473 0.281 0.687);
      --purple-a12: color(display-p3 0.234 0.132 0.363);
      --red-1: color(display-p3 0.998 0.989 0.988);
      --red-2: color(display-p3 0.995 0.971 0.971);
      --red-3: color(display-p3 0.985 0.925 0.925);
      --red-4: color(display-p3 0.999 0.866 0.866);
      --red-5: color(display-p3 0.984 0.812 0.811);
      --red-6: color(display-p3 0.955 0.751 0.749);
      --red-7: color(display-p3 0.915 0.675 0.672);
      --red-8: color(display-p3 0.872 0.575 0.572);
      --red-9: color(display-p3 0.83 0.329 0.324);
      --red-10: color(display-p3 0.798 0.294 0.285);
      --red-11: color(display-p3 0.744 0.234 0.222);
      --red-12: color(display-p3 0.36 0.115 0.143);
      --red-a1: color(display-p3 0.675 0.024 0.024 / 0.012);
      --red-a2: color(display-p3 0.863 0.024 0.024 / 0.028);
      --red-a3: color(display-p3 0.792 0.008 0.008 / 0.075);
      --red-a4: color(display-p3 1 0.008 0.008 / 0.134);
      --red-a5: color(display-p3 0.918 0.008 0.008 / 0.189);
      --red-a6: color(display-p3 0.831 0.02 0.004 / 0.251);
      --red-a7: color(display-p3 0.741 0.016 0.004 / 0.33);
      --red-a8: color(display-p3 0.698 0.012 0.004 / 0.428);
      --red-a9: color(display-p3 0.749 0.008 0 / 0.675);
      --red-a10: color(display-p3 0.714 0.012 0 / 0.714);
      --red-a11: color(display-p3 0.744 0.234 0.222);
      --red-a12: color(display-p3 0.36 0.115 0.143);
      --ruby-1: color(display-p3 0.998 0.989 0.992);
      --ruby-2: color(display-p3 0.995 0.971 0.974);
      --ruby-3: color(display-p3 0.983 0.92 0.928);
      --ruby-4: color(display-p3 0.987 0.869 0.885);
      --ruby-5: color(display-p3 0.968 0.817 0.839);
      --ruby-6: color(display-p3 0.937 0.758 0.786);
      --ruby-7: color(display-p3 0.897 0.685 0.721);
      --ruby-8: color(display-p3 0.851 0.588 0.639);
      --ruby-9: color(display-p3 0.83 0.323 0.408);
      --ruby-10: color(display-p3 0.795 0.286 0.375);
      --ruby-11: color(display-p3 0.728 0.211 0.311);
      --ruby-12: color(display-p3 0.36 0.115 0.171);
      --ruby-a1: color(display-p3 0.675 0.024 0.349 / 0.012);
      --ruby-a2: color(display-p3 0.863 0.024 0.024 / 0.028);
      --ruby-a3: color(display-p3 0.804 0.008 0.11 / 0.079);
      --ruby-a4: color(display-p3 0.91 0.008 0.125 / 0.13);
      --ruby-a5: color(display-p3 0.831 0.004 0.133 / 0.185);
      --ruby-a6: color(display-p3 0.745 0.004 0.118 / 0.244);
      --ruby-a7: color(display-p3 0.678 0.004 0.114 / 0.314);
      --ruby-a8: color(display-p3 0.639 0.004 0.125 / 0.412);
      --ruby-a9: color(display-p3 0.753 0 0.129 / 0.679);
      --ruby-a10: color(display-p3 0.714 0 0.125 / 0.714);
      --ruby-a11: color(display-p3 0.728 0.211 0.311);
      --ruby-a12: color(display-p3 0.36 0.115 0.171);
      --sky-1: color(display-p3 0.98 0.995 0.999);
      --sky-2: color(display-p3 0.953 0.98 0.99);
      --sky-3: color(display-p3 0.899 0.963 0.989);
      --sky-4: color(display-p3 0.842 0.937 0.977);
      --sky-5: color(display-p3 0.777 0.9 0.954);
      --sky-6: color(display-p3 0.701 0.851 0.921);
      --sky-7: color(display-p3 0.604 0.785 0.879);
      --sky-8: color(display-p3 0.457 0.696 0.829);
      --sky-9: color(display-p3 0.585 0.877 0.983);
      --sky-10: color(display-p3 0.555 0.845 0.959);
      --sky-11: color(display-p3 0.193 0.448 0.605);
      --sky-12: color(display-p3 0.145 0.241 0.329);
      --sky-a1: color(display-p3 0.02 0.804 1 / 0.02);
      --sky-a2: color(display-p3 0.024 0.592 0.757 / 0.048);
      --sky-a3: color(display-p3 0.004 0.655 0.886 / 0.102);
      --sky-a4: color(display-p3 0.004 0.604 0.851 / 0.157);
      --sky-a5: color(display-p3 0.004 0.565 0.792 / 0.224);
      --sky-a6: color(display-p3 0.004 0.502 0.737 / 0.299);
      --sky-a7: color(display-p3 0.004 0.459 0.694 / 0.397);
      --sky-a8: color(display-p3 0 0.435 0.682 / 0.542);
      --sky-a9: color(display-p3 0.004 0.71 0.965 / 0.416);
      --sky-a10: color(display-p3 0.004 0.647 0.914 / 0.444);
      --sky-a11: color(display-p3 0.193 0.448 0.605);
      --sky-a12: color(display-p3 0.145 0.241 0.329);
      --teal-1: color(display-p3 0.983 0.996 0.992);
      --teal-2: color(display-p3 0.958 0.983 0.976);
      --teal-3: color(display-p3 0.895 0.971 0.952);
      --teal-4: color(display-p3 0.831 0.949 0.92);
      --teal-5: color(display-p3 0.761 0.914 0.878);
      --teal-6: color(display-p3 0.682 0.864 0.825);
      --teal-7: color(display-p3 0.581 0.798 0.756);
      --teal-8: color(display-p3 0.433 0.716 0.671);
      --teal-9: color(display-p3 0.297 0.637 0.581);
      --teal-10: color(display-p3 0.275 0.599 0.542);
      --teal-11: color(display-p3 0.08 0.5 0.43);
      --teal-12: color(display-p3 0.11 0.235 0.219);
      --teal-a1: color(display-p3 0.024 0.757 0.514 / 0.016);
      --teal-a2: color(display-p3 0.02 0.647 0.467 / 0.044);
      --teal-a3: color(display-p3 0.004 0.741 0.557 / 0.106);
      --teal-a4: color(display-p3 0.004 0.702 0.537 / 0.169);
      --teal-a5: color(display-p3 0.004 0.643 0.494 / 0.24);
      --teal-a6: color(display-p3 0.004 0.569 0.447 / 0.318);
      --teal-a7: color(display-p3 0.004 0.518 0.424 / 0.42);
      --teal-a8: color(display-p3 0 0.506 0.424 / 0.569);
      --teal-a9: color(display-p3 0 0.482 0.404 / 0.702);
      --teal-a10: color(display-p3 0 0.451 0.369 / 0.726);
      --teal-a11: color(display-p3 0.08 0.5 0.43);
      --teal-a12: color(display-p3 0.11 0.235 0.219);
      --tomato-1: color(display-p3 0.998 0.989 0.988);
      --tomato-2: color(display-p3 0.994 0.974 0.969);
      --tomato-3: color(display-p3 0.985 0.924 0.909);
      --tomato-4: color(display-p3 0.996 0.868 0.835);
      --tomato-5: color(display-p3 0.98 0.812 0.77);
      --tomato-6: color(display-p3 0.953 0.75 0.698);
      --tomato-7: color(display-p3 0.917 0.673 0.611);
      --tomato-8: color(display-p3 0.875 0.575 0.502);
      --tomato-9: color(display-p3 0.831 0.345 0.231);
      --tomato-10: color(display-p3 0.802 0.313 0.2);
      --tomato-11: color(display-p3 0.755 0.259 0.152);
      --tomato-12: color(display-p3 0.335 0.165 0.132);
      --tomato-a1: color(display-p3 0.675 0.024 0.024 / 0.012);
      --tomato-a2: color(display-p3 0.757 0.145 0.02 / 0.032);
      --tomato-a3: color(display-p3 0.831 0.184 0.012 / 0.091);
      --tomato-a4: color(display-p3 0.976 0.192 0.004 / 0.165);
      --tomato-a5: color(display-p3 0.918 0.192 0.004 / 0.232);
      --tomato-a6: color(display-p3 0.847 0.173 0.004 / 0.302);
      --tomato-a7: color(display-p3 0.788 0.165 0.004 / 0.389);
      --tomato-a8: color(display-p3 0.749 0.153 0.004 / 0.499);
      --tomato-a9: color(display-p3 0.78 0.149 0 / 0.769);
      --tomato-a10: color(display-p3 0.757 0.141 0 / 0.8);
      --tomato-a11: color(display-p3 0.755 0.259 0.152);
      --tomato-a12: color(display-p3 0.335 0.165 0.132);
      --violet-1: color(display-p3 0.991 0.988 0.995);
      --violet-2: color(display-p3 0.978 0.974 0.998);
      --violet-3: color(display-p3 0.953 0.943 0.993);
      --violet-4: color(display-p3 0.916 0.897 1);
      --violet-5: color(display-p3 0.876 0.851 1);
      --violet-6: color(display-p3 0.825 0.793 0.981);
      --violet-7: color(display-p3 0.752 0.712 0.943);
      --violet-8: color(display-p3 0.654 0.602 0.902);
      --violet-9: color(display-p3 0.417 0.341 0.784);
      --violet-10: color(display-p3 0.381 0.306 0.741);
      --violet-11: color(display-p3 0.383 0.317 0.702);
      --violet-12: color(display-p3 0.179 0.15 0.359);
      --violet-a1: color(display-p3 0.349 0.024 0.675 / 0.012);
      --violet-a2: color(display-p3 0.161 0.024 0.863 / 0.028);
      --violet-a3: color(display-p3 0.204 0.004 0.871 / 0.059);
      --violet-a4: color(display-p3 0.196 0.004 1 / 0.102);
      --violet-a5: color(display-p3 0.165 0.008 1 / 0.15);
      --violet-a6: color(display-p3 0.153 0.004 0.906 / 0.208);
      --violet-a7: color(display-p3 0.141 0.004 0.796 / 0.287);
      --violet-a8: color(display-p3 0.133 0.004 0.753 / 0.397);
      --violet-a9: color(display-p3 0.114 0 0.675 / 0.659);
      --violet-a10: color(display-p3 0.11 0 0.627 / 0.695);
      --violet-a11: color(display-p3 0.383 0.317 0.702);
      --violet-a12: color(display-p3 0.179 0.15 0.359);
      --yellow-1: color(display-p3 0.992 0.992 0.978);
      --yellow-2: color(display-p3 0.995 0.99 0.922);
      --yellow-3: color(display-p3 0.997 0.982 0.749);
      --yellow-4: color(display-p3 0.992 0.953 0.627);
      --yellow-5: color(display-p3 0.984 0.91 0.51);
      --yellow-6: color(display-p3 0.934 0.847 0.474);
      --yellow-7: color(display-p3 0.876 0.785 0.46);
      --yellow-8: color(display-p3 0.811 0.689 0.313);
      --yellow-9: color(display-p3 1 0.92 0.22);
      --yellow-10: color(display-p3 0.977 0.868 0.291);
      --yellow-11: color(display-p3 0.6 0.44 0);
      --yellow-12: color(display-p3 0.271 0.233 0.137);
      --yellow-a1: color(display-p3 0.675 0.675 0.024 / 0.024);
      --yellow-a2: color(display-p3 0.953 0.855 0.008 / 0.079);
      --yellow-a3: color(display-p3 0.988 0.925 0.004 / 0.251);
      --yellow-a4: color(display-p3 0.98 0.875 0.004 / 0.373);
      --yellow-a5: color(display-p3 0.969 0.816 0.004 / 0.491);
      --yellow-a6: color(display-p3 0.875 0.71 0 / 0.526);
      --yellow-a7: color(display-p3 0.769 0.604 0 / 0.542);
      --yellow-a8: color(display-p3 0.725 0.549 0 / 0.687);
      --yellow-a9: color(display-p3 1 0.898 0 / 0.781);
      --yellow-a10: color(display-p3 0.969 0.812 0 / 0.71);
      --yellow-a11: color(display-p3 0.6 0.44 0);
      --yellow-a12: color(display-p3 0.271 0.233 0.137);
      --gray-surface: color(display-p3 1 1 1 / 0.8);
      --mauve-surface: color(display-p3 1 1 1 / 0.8);
      --slate-surface: color(display-p3 1 1 1 / 0.8);
      --sage-surface: color(display-p3 1 1 1 / 0.8);
      --olive-surface: color(display-p3 1 1 1 / 0.8);
      --sand-surface: color(display-p3 1 1 1 / 0.8);
      --amber-surface: color(display-p3 0.9922 0.9843 0.902 / 0.8);
      --blue-surface: color(display-p3 0.9529 0.9765 0.9961 / 0.8);
      --bronze-surface: color(display-p3 0.9843 0.9608 0.9529 / 0.8);
      --brown-surface: color(display-p3 0.9843 0.9725 0.9569 / 0.8);
      --crimson-surface: color(display-p3 0.9922 0.9608 0.9725 / 0.8);
      --cyan-surface: color(display-p3 0.9412 0.9765 0.9804 / 0.8);
      --gold-surface: color(display-p3 0.9765 0.9725 0.9412 / 0.8);
      --grass-surface: color(display-p3 0.9569 0.9804 0.9569 / 0.8);
      --green-surface: color(display-p3 0.9569 0.9804 0.9608 / 0.8);
      --indigo-surface: color(display-p3 0.9647 0.9725 0.9961 / 0.8);
      --iris-surface: color(display-p3 0.9647 0.9647 0.9961 / 0.8);
      --jade-surface: color(display-p3 0.9529 0.9804 0.9608 / 0.8);
      --lime-surface: color(display-p3 0.9725 0.9765 0.9412 / 0.8);
      --mint-surface: color(display-p3 0.9451 0.9804 0.9725 / 0.8);
      --orange-surface: color(display-p3 0.9961 0.9608 0.9176 / 0.8);
      --pink-surface: color(display-p3 0.9922 0.9608 0.9804 / 0.8);
      --plum-surface: color(display-p3 0.9843 0.9647 0.9843 / 0.8);
      --purple-surface: color(display-p3 0.9804 0.9647 0.9922 / 0.8);
      --red-surface: color(display-p3 0.9961 0.9647 0.9647 / 0.8);
      --ruby-surface: color(display-p3 0.9961 0.9647 0.9647 / 0.8);
      --sky-surface: color(display-p3 0.9412 0.9765 0.9843 / 0.8);
      --teal-surface: color(display-p3 0.9451 0.9804 0.9725 / 0.8);
      --tomato-surface: color(display-p3 0.9922 0.9647 0.9608 / 0.8);
      --violet-surface: color(display-p3 0.9725 0.9647 0.9961 / 0.8);
      --yellow-surface: color(display-p3 0.9961 0.9922 0.902 / 0.8);
    }
  }
}
.dark, .dark-theme {
  --gray-1: #111111;
  --gray-2: #191919;
  --gray-3: #222222;
  --gray-4: #2a2a2a;
  --gray-5: #313131;
  --gray-6: #3a3a3a;
  --gray-7: #484848;
  --gray-8: #606060;
  --gray-9: #6e6e6e;
  --gray-10: #7b7b7b;
  --gray-11: #b4b4b4;
  --gray-12: #eeeeee;
  --gray-a1: #00000000;
  --gray-a2: #ffffff09;
  --gray-a3: #ffffff12;
  --gray-a4: #ffffff1b;
  --gray-a5: #ffffff22;
  --gray-a6: #ffffff2c;
  --gray-a7: #ffffff3b;
  --gray-a8: #ffffff55;
  --gray-a9: #ffffff64;
  --gray-a10: #ffffff72;
  --gray-a11: #ffffffaf;
  --gray-a12: #ffffffed;
  --mauve-1: #121113;
  --mauve-2: #1a191b;
  --mauve-3: #232225;
  --mauve-4: #2b292d;
  --mauve-5: #323035;
  --mauve-6: #3c393f;
  --mauve-7: #49474e;
  --mauve-8: #625f69;
  --mauve-9: #6f6d78;
  --mauve-10: #7c7a85;
  --mauve-11: #b5b2bc;
  --mauve-12: #eeeef0;
  --mauve-a1: #00000000;
  --mauve-a2: #f5f4f609;
  --mauve-a3: #ebeaf814;
  --mauve-a4: #eee5f81d;
  --mauve-a5: #efe6fe25;
  --mauve-a6: #f1e6fd30;
  --mauve-a7: #eee9ff40;
  --mauve-a8: #eee7ff5d;
  --mauve-a9: #eae6fd6e;
  --mauve-a10: #ece9fd7c;
  --mauve-a11: #f5f1ffb7;
  --mauve-a12: #fdfdffef;
  --slate-1: #111113;
  --slate-2: #18191b;
  --slate-3: #212225;
  --slate-4: #272a2d;
  --slate-5: #2e3135;
  --slate-6: #363a3f;
  --slate-7: #43484e;
  --slate-8: #5a6169;
  --slate-9: #696e77;
  --slate-10: #777b84;
  --slate-11: #b0b4ba;
  --slate-12: #edeef0;
  --slate-a1: #00000000;
  --slate-a2: #d8f4f609;
  --slate-a3: #ddeaf814;
  --slate-a4: #d3edf81d;
  --slate-a5: #d9edfe25;
  --slate-a6: #d6ebfd30;
  --slate-a7: #d9edff40;
  --slate-a8: #d9edff5d;
  --slate-a9: #dfebfd6d;
  --slate-a10: #e5edfd7b;
  --slate-a11: #f1f7feb5;
  --slate-a12: #fcfdffef;
  --sage-1: #101211;
  --sage-2: #171918;
  --sage-3: #202221;
  --sage-4: #272a29;
  --sage-5: #2e3130;
  --sage-6: #373b39;
  --sage-7: #444947;
  --sage-8: #5b625f;
  --sage-9: #63706b;
  --sage-10: #717d79;
  --sage-11: #adb5b2;
  --sage-12: #eceeed;
  --sage-a1: #00000000;
  --sage-a2: #f0f2f108;
  --sage-a3: #f3f5f412;
  --sage-a4: #f2fefd1a;
  --sage-a5: #f1fbfa22;
  --sage-a6: #edfbf42d;
  --sage-a7: #edfcf73c;
  --sage-a8: #ebfdf657;
  --sage-a9: #dffdf266;
  --sage-a10: #e5fdf674;
  --sage-a11: #f4fefbb0;
  --sage-a12: #fdfffeed;
  --olive-1: #111210;
  --olive-2: #181917;
  --olive-3: #212220;
  --olive-4: #282a27;
  --olive-5: #2f312e;
  --olive-6: #383a36;
  --olive-7: #454843;
  --olive-8: #5c625b;
  --olive-9: #687066;
  --olive-10: #767d74;
  --olive-11: #afb5ad;
  --olive-12: #eceeec;
  --olive-a1: #00000000;
  --olive-a2: #f1f2f008;
  --olive-a3: #f4f5f312;
  --olive-a4: #f3fef21a;
  --olive-a5: #f2fbf122;
  --olive-a6: #f4faed2c;
  --olive-a7: #f2fced3b;
  --olive-a8: #edfdeb57;
  --olive-a9: #ebfde766;
  --olive-a10: #f0fdec74;
  --olive-a11: #f6fef4b0;
  --olive-a12: #fdfffded;
  --sand-1: #111110;
  --sand-2: #191918;
  --sand-3: #222221;
  --sand-4: #2a2a28;
  --sand-5: #31312e;
  --sand-6: #3b3a37;
  --sand-7: #494844;
  --sand-8: #62605b;
  --sand-9: #6f6d66;
  --sand-10: #7c7b74;
  --sand-11: #b5b3ad;
  --sand-12: #eeeeec;
  --sand-a1: #00000000;
  --sand-a2: #f4f4f309;
  --sand-a3: #f6f6f513;
  --sand-a4: #fefef31b;
  --sand-a5: #fbfbeb23;
  --sand-a6: #fffaed2d;
  --sand-a7: #fffbed3c;
  --sand-a8: #fff9eb57;
  --sand-a9: #fffae965;
  --sand-a10: #fffdee73;
  --sand-a11: #fffcf4b0;
  --sand-a12: #fffffded;
  --amber-1: #16120c;
  --amber-2: #1d180f;
  --amber-3: #302008;
  --amber-4: #3f2700;
  --amber-5: #4d3000;
  --amber-6: #5c3d05;
  --amber-7: #714f19;
  --amber-8: #8f6424;
  --amber-9: #ffc53d;
  --amber-10: #ffd60a;
  --amber-11: #ffca16;
  --amber-12: #ffe7b3;
  --amber-a1: #e63c0006;
  --amber-a2: #fd9b000d;
  --amber-a3: #fa820022;
  --amber-a4: #fc820032;
  --amber-a5: #fd8b0041;
  --amber-a6: #fd9b0051;
  --amber-a7: #ffab2567;
  --amber-a8: #ffae3587;
  --amber-a9: #ffc53d;
  --amber-a10: #ffd60a;
  --amber-a11: #ffca16;
  --amber-a12: #ffe7b3;
  --blue-1: #0d1520;
  --blue-2: #111927;
  --blue-3: #0d2847;
  --blue-4: #003362;
  --blue-5: #004074;
  --blue-6: #104d87;
  --blue-7: #205d9e;
  --blue-8: #2870bd;
  --blue-9: #0090ff;
  --blue-10: #3b9eff;
  --blue-11: #70b8ff;
  --blue-12: #c2e6ff;
  --blue-a1: #004df211;
  --blue-a2: #1166fb18;
  --blue-a3: #0077ff3a;
  --blue-a4: #0075ff57;
  --blue-a5: #0081fd6b;
  --blue-a6: #0f89fd7f;
  --blue-a7: #2a91fe98;
  --blue-a8: #3094feb9;
  --blue-a9: #0090ff;
  --blue-a10: #3b9eff;
  --blue-a11: #70b8ff;
  --blue-a12: #c2e6ff;
  --bronze-1: #141110;
  --bronze-2: #1c1917;
  --bronze-3: #262220;
  --bronze-4: #302a27;
  --bronze-5: #3b3330;
  --bronze-6: #493e3a;
  --bronze-7: #5a4c47;
  --bronze-8: #6f5f58;
  --bronze-9: #a18072;
  --bronze-10: #ae8c7e;
  --bronze-11: #d4b3a5;
  --bronze-12: #ede0d9;
  --bronze-a1: #d1110004;
  --bronze-a2: #fbbc910c;
  --bronze-a3: #faceb817;
  --bronze-a4: #facdb622;
  --bronze-a5: #ffd2c12d;
  --bronze-a6: #ffd1c03c;
  --bronze-a7: #fdd0c04f;
  --bronze-a8: #ffd6c565;
  --bronze-a9: #fec7b09b;
  --bronze-a10: #fecab5a9;
  --bronze-a11: #ffd7c6d1;
  --bronze-a12: #fff1e9ec;
  --brown-1: #12110f;
  --brown-2: #1c1816;
  --brown-3: #28211d;
  --brown-4: #322922;
  --brown-5: #3e3128;
  --brown-6: #4d3c2f;
  --brown-7: #614a39;
  --brown-8: #7c5f46;
  --brown-9: #ad7f58;
  --brown-10: #b88c67;
  --brown-11: #dbb594;
  --brown-12: #f2e1ca;
  --brown-a1: #91110002;
  --brown-a2: #fba67c0c;
  --brown-a3: #fcb58c19;
  --brown-a4: #fbbb8a24;
  --brown-a5: #fcb88931;
  --brown-a6: #fdba8741;
  --brown-a7: #ffbb8856;
  --brown-a8: #ffbe8773;
  --brown-a9: #feb87da8;
  --brown-a10: #ffc18cb3;
  --brown-a11: #fed1aad9;
  --brown-a12: #feecd4f2;
  --crimson-1: #191114;
  --crimson-2: #201318;
  --crimson-3: #381525;
  --crimson-4: #4d122f;
  --crimson-5: #5c1839;
  --crimson-6: #6d2545;
  --crimson-7: #873356;
  --crimson-8: #b0436e;
  --crimson-9: #e93d82;
  --crimson-10: #ee518a;
  --crimson-11: #ff92ad;
  --crimson-12: #fdd3e8;
  --crimson-a1: #f4126709;
  --crimson-a2: #f22f7a11;
  --crimson-a3: #fe2a8b2a;
  --crimson-a4: #fd158741;
  --crimson-a5: #fd278f51;
  --crimson-a6: #fe459763;
  --crimson-a7: #fd559b7f;
  --crimson-a8: #fe5b9bab;
  --crimson-a9: #fe418de8;
  --crimson-a10: #ff5693ed;
  --crimson-a11: #ff92ad;
  --crimson-a12: #ffd5eafd;
  --cyan-1: #0b161a;
  --cyan-2: #101b20;
  --cyan-3: #082c36;
  --cyan-4: #003848;
  --cyan-5: #004558;
  --cyan-6: #045468;
  --cyan-7: #12677e;
  --cyan-8: #11809c;
  --cyan-9: #00a2c7;
  --cyan-10: #23afd0;
  --cyan-11: #4ccce6;
  --cyan-12: #b6ecf7;
  --cyan-a1: #0091f70a;
  --cyan-a2: #02a7f211;
  --cyan-a3: #00befd28;
  --cyan-a4: #00baff3b;
  --cyan-a5: #00befd4d;
  --cyan-a6: #00c7fd5e;
  --cyan-a7: #14cdff75;
  --cyan-a8: #11cfff95;
  --cyan-a9: #00cfffc3;
  --cyan-a10: #28d6ffcd;
  --cyan-a11: #52e1fee5;
  --cyan-a12: #bbf3fef7;
  --gold-1: #121211;
  --gold-2: #1b1a17;
  --gold-3: #24231f;
  --gold-4: #2d2b26;
  --gold-5: #38352e;
  --gold-6: #444039;
  --gold-7: #544f46;
  --gold-8: #696256;
  --gold-9: #978365;
  --gold-10: #a39073;
  --gold-11: #cbb99f;
  --gold-12: #e8e2d9;
  --gold-a1: #91911102;
  --gold-a2: #f9e29d0b;
  --gold-a3: #f8ecbb15;
  --gold-a4: #ffeec41e;
  --gold-a5: #feecc22a;
  --gold-a6: #feebcb37;
  --gold-a7: #ffedcd48;
  --gold-a8: #fdeaca5f;
  --gold-a9: #ffdba690;
  --gold-a10: #fedfb09d;
  --gold-a11: #fee7c6c8;
  --gold-a12: #fef7ede7;
  --grass-1: #0e1511;
  --grass-2: #141a15;
  --grass-3: #1b2a1e;
  --grass-4: #1d3a24;
  --grass-5: #25482d;
  --grass-6: #2d5736;
  --grass-7: #366740;
  --grass-8: #3e7949;
  --grass-9: #46a758;
  --grass-10: #53b365;
  --grass-11: #71d083;
  --grass-12: #c2f0c2;
  --grass-a1: #00de1205;
  --grass-a2: #5ef7780a;
  --grass-a3: #70fe8c1b;
  --grass-a4: #57ff802c;
  --grass-a5: #68ff8b3b;
  --grass-a6: #71ff8f4b;
  --grass-a7: #77fd925d;
  --grass-a8: #77fd9070;
  --grass-a9: #65ff82a1;
  --grass-a10: #72ff8dae;
  --grass-a11: #89ff9fcd;
  --grass-a12: #ceffceef;
  --green-1: #0e1512;
  --green-2: #121b17;
  --green-3: #132d21;
  --green-4: #113b29;
  --green-5: #174933;
  --green-6: #20573e;
  --green-7: #28684a;
  --green-8: #2f7c57;
  --green-9: #30a46c;
  --green-10: #33b074;
  --green-11: #3dd68c;
  --green-12: #b1f1cb;
  --green-a1: #00de4505;
  --green-a2: #29f99d0b;
  --green-a3: #22ff991e;
  --green-a4: #11ff992d;
  --green-a5: #2bffa23c;
  --green-a6: #44ffaa4b;
  --green-a7: #50fdac5e;
  --green-a8: #54ffad73;
  --green-a9: #44ffa49e;
  --green-a10: #43fea4ab;
  --green-a11: #46fea5d4;
  --green-a12: #bbffd7f0;
  --indigo-1: #11131f;
  --indigo-2: #141726;
  --indigo-3: #182449;
  --indigo-4: #1d2e62;
  --indigo-5: #253974;
  --indigo-6: #304384;
  --indigo-7: #3a4f97;
  --indigo-8: #435db1;
  --indigo-9: #3e63dd;
  --indigo-10: #5472e4;
  --indigo-11: #9eb1ff;
  --indigo-12: #d6e1ff;
  --indigo-a1: #1133ff0f;
  --indigo-a2: #3354fa17;
  --indigo-a3: #2f62ff3c;
  --indigo-a4: #3566ff57;
  --indigo-a5: #4171fd6b;
  --indigo-a6: #5178fd7c;
  --indigo-a7: #5a7fff90;
  --indigo-a8: #5b81feac;
  --indigo-a9: #4671ffdb;
  --indigo-a10: #5c7efee3;
  --indigo-a11: #9eb1ff;
  --indigo-a12: #d6e1ff;
  --iris-1: #13131e;
  --iris-2: #171625;
  --iris-3: #202248;
  --iris-4: #262a65;
  --iris-5: #303374;
  --iris-6: #3d3e82;
  --iris-7: #4a4a95;
  --iris-8: #5958b1;
  --iris-9: #5b5bd6;
  --iris-10: #6e6ade;
  --iris-11: #b1a9ff;
  --iris-12: #e0dffe;
  --iris-a1: #3636fe0e;
  --iris-a2: #564bf916;
  --iris-a3: #525bff3b;
  --iris-a4: #4d58ff5a;
  --iris-a5: #5b62fd6b;
  --iris-a6: #6d6ffd7a;
  --iris-a7: #7777fe8e;
  --iris-a8: #7b7afeac;
  --iris-a9: #6a6afed4;
  --iris-a10: #7d79ffdc;
  --iris-a11: #b1a9ff;
  --iris-a12: #e1e0fffe;
  --jade-1: #0d1512;
  --jade-2: #121c18;
  --jade-3: #0f2e22;
  --jade-4: #0b3b2c;
  --jade-5: #114837;
  --jade-6: #1b5745;
  --jade-7: #246854;
  --jade-8: #2a7e68;
  --jade-9: #29a383;
  --jade-10: #27b08b;
  --jade-11: #1fd8a4;
  --jade-12: #adf0d4;
  --jade-a1: #00de4505;
  --jade-a2: #27fba60c;
  --jade-a3: #02f99920;
  --jade-a4: #00ffaa2d;
  --jade-a5: #11ffb63b;
  --jade-a6: #34ffc24b;
  --jade-a7: #45fdc75e;
  --jade-a8: #48ffcf75;
  --jade-a9: #38feca9d;
  --jade-a10: #31fec7ab;
  --jade-a11: #21fec0d6;
  --jade-a12: #b8ffe1ef;
  --lime-1: #11130c;
  --lime-2: #151a10;
  --lime-3: #1f2917;
  --lime-4: #29371d;
  --lime-5: #334423;
  --lime-6: #3d522a;
  --lime-7: #496231;
  --lime-8: #577538;
  --lime-9: #bdee63;
  --lime-10: #d4ff70;
  --lime-11: #bde56c;
  --lime-12: #e3f7ba;
  --lime-a1: #11bb0003;
  --lime-a2: #78f7000a;
  --lime-a3: #9bfd4c1a;
  --lime-a4: #a7fe5c29;
  --lime-a5: #affe6537;
  --lime-a6: #b2fe6d46;
  --lime-a7: #b6ff6f57;
  --lime-a8: #b6fd6d6c;
  --lime-a9: #caff69ed;
  --lime-a10: #d4ff70;
  --lime-a11: #d1fe77e4;
  --lime-a12: #e9febff7;
  --mint-1: #0e1515;
  --mint-2: #0f1b1b;
  --mint-3: #092c2b;
  --mint-4: #003a38;
  --mint-5: #004744;
  --mint-6: #105650;
  --mint-7: #1e685f;
  --mint-8: #277f70;
  --mint-9: #86ead4;
  --mint-10: #a8f5e5;
  --mint-11: #58d5ba;
  --mint-12: #c4f5e1;
  --mint-a1: #00dede05;
  --mint-a2: #00f9f90b;
  --mint-a3: #00fff61d;
  --mint-a4: #00fff42c;
  --mint-a5: #00fff23a;
  --mint-a6: #0effeb4a;
  --mint-a7: #34fde55e;
  --mint-a8: #41ffdf76;
  --mint-a9: #92ffe7e9;
  --mint-a10: #aefeedf5;
  --mint-a11: #67ffded2;
  --mint-a12: #cbfee9f5;
  --orange-1: #17120e;
  --orange-2: #1e160f;
  --orange-3: #331e0b;
  --orange-4: #462100;
  --orange-5: #562800;
  --orange-6: #66350c;
  --orange-7: #7e451d;
  --orange-8: #a35829;
  --orange-9: #f76b15;
  --orange-10: #ff801f;
  --orange-11: #ffa057;
  --orange-12: #ffe0c2;
  --orange-a1: #ec360007;
  --orange-a2: #fe6d000e;
  --orange-a3: #fb6a0025;
  --orange-a4: #ff590039;
  --orange-a5: #ff61004a;
  --orange-a6: #fd75045c;
  --orange-a7: #ff832c75;
  --orange-a8: #fe84389d;
  --orange-a9: #fe6d15f7;
  --orange-a10: #ff801f;
  --orange-a11: #ffa057;
  --orange-a12: #ffe0c2;
  --pink-1: #191117;
  --pink-2: #21121d;
  --pink-3: #37172f;
  --pink-4: #4b143d;
  --pink-5: #591c47;
  --pink-6: #692955;
  --pink-7: #833869;
  --pink-8: #a84885;
  --pink-9: #d6409f;
  --pink-10: #de51a8;
  --pink-11: #ff8dcc;
  --pink-12: #fdd1ea;
  --pink-a1: #f412bc09;
  --pink-a2: #f420bb12;
  --pink-a3: #fe37cc29;
  --pink-a4: #fc1ec43f;
  --pink-a5: #fd35c24e;
  --pink-a6: #fd51c75f;
  --pink-a7: #fd62c87b;
  --pink-a8: #ff68c8a2;
  --pink-a9: #fe49bcd4;
  --pink-a10: #ff5cc0dc;
  --pink-a11: #ff8dcc;
  --pink-a12: #ffd3ecfd;
  --plum-1: #181118;
  --plum-2: #201320;
  --plum-3: #351a35;
  --plum-4: #451d47;
  --plum-5: #512454;
  --plum-6: #5e3061;
  --plum-7: #734079;
  --plum-8: #92549c;
  --plum-9: #ab4aba;
  --plum-10: #b658c4;
  --plum-11: #e796f3;
  --plum-12: #f4d4f4;
  --plum-a1: #f112f108;
  --plum-a2: #f22ff211;
  --plum-a3: #fd4cfd27;
  --plum-a4: #f646ff3a;
  --plum-a5: #f455ff48;
  --plum-a6: #f66dff56;
  --plum-a7: #f07cfd70;
  --plum-a8: #ee84ff95;
  --plum-a9: #e961feb6;
  --plum-a10: #ed70ffc0;
  --plum-a11: #f19cfef3;
  --plum-a12: #feddfef4;
  --purple-1: #18111b;
  --purple-2: #1e1523;
  --purple-3: #301c3b;
  --purple-4: #3d224e;
  --purple-5: #48295c;
  --purple-6: #54346b;
  --purple-7: #664282;
  --purple-8: #8457aa;
  --purple-9: #8e4ec6;
  --purple-10: #9a5cd0;
  --purple-11: #d19dff;
  --purple-12: #ecd9fa;
  --purple-a1: #b412f90b;
  --purple-a2: #b744f714;
  --purple-a3: #c150ff2d;
  --purple-a4: #bb53fd42;
  --purple-a5: #be5cfd51;
  --purple-a6: #c16dfd61;
  --purple-a7: #c378fd7a;
  --purple-a8: #c47effa4;
  --purple-a9: #b661ffc2;
  --purple-a10: #bc6fffcd;
  --purple-a11: #d19dff;
  --purple-a12: #f1ddfffa;
  --red-1: #191111;
  --red-2: #201314;
  --red-3: #3b1219;
  --red-4: #500f1c;
  --red-5: #611623;
  --red-6: #72232d;
  --red-7: #8c333a;
  --red-8: #b54548;
  --red-9: #e5484d;
  --red-10: #ec5d5e;
  --red-11: #ff9592;
  --red-12: #ffd1d9;
  --red-a1: #f4121209;
  --red-a2: #f22f3e11;
  --red-a3: #ff173f2d;
  --red-a4: #fe0a3b44;
  --red-a5: #ff204756;
  --red-a6: #ff3e5668;
  --red-a7: #ff536184;
  --red-a8: #ff5d61b0;
  --red-a9: #fe4e54e4;
  --red-a10: #ff6465eb;
  --red-a11: #ff9592;
  --red-a12: #ffd1d9;
  --ruby-1: #191113;
  --ruby-2: #1e1517;
  --ruby-3: #3a141e;
  --ruby-4: #4e1325;
  --ruby-5: #5e1a2e;
  --ruby-6: #6f2539;
  --ruby-7: #883447;
  --ruby-8: #b3445a;
  --ruby-9: #e54666;
  --ruby-10: #ec5a72;
  --ruby-11: #ff949d;
  --ruby-12: #fed2e1;
  --ruby-a1: #f4124a09;
  --ruby-a2: #fe5a7f0e;
  --ruby-a3: #ff235d2c;
  --ruby-a4: #fd195e42;
  --ruby-a5: #fe2d6b53;
  --ruby-a6: #ff447665;
  --ruby-a7: #ff577d80;
  --ruby-a8: #ff5c7cae;
  --ruby-a9: #fe4c70e4;
  --ruby-a10: #ff617beb;
  --ruby-a11: #ff949d;
  --ruby-a12: #ffd3e2fe;
  --sky-1: #0d141f;
  --sky-2: #111a27;
  --sky-3: #112840;
  --sky-4: #113555;
  --sky-5: #154467;
  --sky-6: #1b537b;
  --sky-7: #1f6692;
  --sky-8: #197cae;
  --sky-9: #7ce2fe;
  --sky-10: #a8eeff;
  --sky-11: #75c7f0;
  --sky-12: #c2f3ff;
  --sky-a1: #0044ff0f;
  --sky-a2: #1171fb18;
  --sky-a3: #1184fc33;
  --sky-a4: #128fff49;
  --sky-a5: #1c9dfd5d;
  --sky-a6: #28a5ff72;
  --sky-a7: #2badfe8b;
  --sky-a8: #1db2fea9;
  --sky-a9: #7ce3fffe;
  --sky-a10: #a8eeff;
  --sky-a11: #7cd3ffef;
  --sky-a12: #c2f3ff;
  --teal-1: #0d1514;
  --teal-2: #111c1b;
  --teal-3: #0d2d2a;
  --teal-4: #023b37;
  --teal-5: #084843;
  --teal-6: #145750;
  --teal-7: #1c6961;
  --teal-8: #207e73;
  --teal-9: #12a594;
  --teal-10: #0eb39e;
  --teal-11: #0bd8b6;
  --teal-12: #adf0dd;
  --teal-a1: #00deab05;
  --teal-a2: #12fbe60c;
  --teal-a3: #00ffe61e;
  --teal-a4: #00ffe92d;
  --teal-a5: #00ffea3b;
  --teal-a6: #1cffe84b;
  --teal-a7: #2efde85f;
  --teal-a8: #32ffe775;
  --teal-a9: #13ffe49f;
  --teal-a10: #0dffe0ae;
  --teal-a11: #0afed5d6;
  --teal-a12: #b8ffebef;
  --tomato-1: #181111;
  --tomato-2: #1f1513;
  --tomato-3: #391714;
  --tomato-4: #4e1511;
  --tomato-5: #5e1c16;
  --tomato-6: #6e2920;
  --tomato-7: #853a2d;
  --tomato-8: #ac4d39;
  --tomato-9: #e54d2e;
  --tomato-10: #ec6142;
  --tomato-11: #ff977d;
  --tomato-12: #fbd3cb;
  --tomato-a1: #f1121208;
  --tomato-a2: #ff55330f;
  --tomato-a3: #ff35232b;
  --tomato-a4: #fd201142;
  --tomato-a5: #fe332153;
  --tomato-a6: #ff4f3864;
  --tomato-a7: #fd644a7d;
  --tomato-a8: #fe6d4ea7;
  --tomato-a9: #fe5431e4;
  --tomato-a10: #ff6847eb;
  --tomato-a11: #ff977d;
  --tomato-a12: #ffd6cefb;
  --violet-1: #14121f;
  --violet-2: #1b1525;
  --violet-3: #291f43;
  --violet-4: #33255b;
  --violet-5: #3c2e69;
  --violet-6: #473876;
  --violet-7: #56468b;
  --violet-8: #6958ad;
  --violet-9: #6e56cf;
  --violet-10: #7d66d9;
  --violet-11: #baa7ff;
  --violet-12: #e2ddfe;
  --violet-a1: #4422ff0f;
  --violet-a2: #853ff916;
  --violet-a3: #8354fe36;
  --violet-a4: #7d51fd50;
  --violet-a5: #845ffd5f;
  --violet-a6: #8f6cfd6d;
  --violet-a7: #9879ff83;
  --violet-a8: #977dfea8;
  --violet-a9: #8668ffcc;
  --violet-a10: #9176fed7;
  --violet-a11: #baa7ff;
  --violet-a12: #e3defffe;
  --yellow-1: #14120b;
  --yellow-2: #1b180f;
  --yellow-3: #2d2305;
  --yellow-4: #362b00;
  --yellow-5: #433500;
  --yellow-6: #524202;
  --yellow-7: #665417;
  --yellow-8: #836a21;
  --yellow-9: #ffe629;
  --yellow-10: #ffff57;
  --yellow-11: #f5e147;
  --yellow-12: #f6eeb4;
  --yellow-a1: #d1510004;
  --yellow-a2: #f9b4000b;
  --yellow-a3: #ffaa001e;
  --yellow-a4: #fdb70028;
  --yellow-a5: #febb0036;
  --yellow-a6: #fec40046;
  --yellow-a7: #fdcb225c;
  --yellow-a8: #fdca327b;
  --yellow-a9: #ffe629;
  --yellow-a10: #ffff57;
  --yellow-a11: #fee949f5;
  --yellow-a12: #fef6baf6;
  --gray-surface: #21212180;
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: #22212380;
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: #1f212380;
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: #1e201f80;
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: #1f201e80;
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: #21212080;
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: #271f1380;
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: #11213d80;
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: #27211d80;
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: #271f1b80;
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: #2f151f80;
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: #11252d80;
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: #25231d80;
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: #19231b80;
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: #15251d80;
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: #171d3b80;
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: #1d1b3980;
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: #13271f80;
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: #1b211580;
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: #15272780;
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: #271d1380;
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: #31132980;
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: #2f152f80;
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: #2b173580;
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: #2f151780;
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: #2b191d80;
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: #13233b80;
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: #13272580;
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: #2d191580;
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: #25193980;
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: #231f1380;
  --yellow-indicator: var(--yellow-9);
  --yellow-track: var(--yellow-9);
}
@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --gray-1: color(display-p3 0.067 0.067 0.067);
      --gray-2: color(display-p3 0.098 0.098 0.098);
      --gray-3: color(display-p3 0.135 0.135 0.135);
      --gray-4: color(display-p3 0.163 0.163 0.163);
      --gray-5: color(display-p3 0.192 0.192 0.192);
      --gray-6: color(display-p3 0.228 0.228 0.228);
      --gray-7: color(display-p3 0.283 0.283 0.283);
      --gray-8: color(display-p3 0.375 0.375 0.375);
      --gray-9: color(display-p3 0.431 0.431 0.431);
      --gray-10: color(display-p3 0.484 0.484 0.484);
      --gray-11: color(display-p3 0.706 0.706 0.706);
      --gray-12: color(display-p3 0.933 0.933 0.933);
      --gray-a1: color(display-p3 0 0 0 / 0);
      --gray-a2: color(display-p3 1 1 1 / 0.034);
      --gray-a3: color(display-p3 1 1 1 / 0.071);
      --gray-a4: color(display-p3 1 1 1 / 0.105);
      --gray-a5: color(display-p3 1 1 1 / 0.134);
      --gray-a6: color(display-p3 1 1 1 / 0.172);
      --gray-a7: color(display-p3 1 1 1 / 0.231);
      --gray-a8: color(display-p3 1 1 1 / 0.332);
      --gray-a9: color(display-p3 1 1 1 / 0.391);
      --gray-a10: color(display-p3 1 1 1 / 0.445);
      --gray-a11: color(display-p3 1 1 1 / 0.685);
      --gray-a12: color(display-p3 1 1 1 / 0.929);
      --mauve-1: color(display-p3 0.07 0.067 0.074);
      --mauve-2: color(display-p3 0.101 0.098 0.105);
      --mauve-3: color(display-p3 0.138 0.134 0.144);
      --mauve-4: color(display-p3 0.167 0.161 0.175);
      --mauve-5: color(display-p3 0.196 0.189 0.206);
      --mauve-6: color(display-p3 0.232 0.225 0.245);
      --mauve-7: color(display-p3 0.286 0.277 0.302);
      --mauve-8: color(display-p3 0.383 0.373 0.408);
      --mauve-9: color(display-p3 0.434 0.428 0.467);
      --mauve-10: color(display-p3 0.487 0.48 0.519);
      --mauve-11: color(display-p3 0.707 0.7 0.735);
      --mauve-12: color(display-p3 0.933 0.933 0.94);
      --mauve-a1: color(display-p3 0 0 0 / 0);
      --mauve-a2: color(display-p3 0.996 0.992 1 / 0.034);
      --mauve-a3: color(display-p3 0.937 0.933 0.992 / 0.077);
      --mauve-a4: color(display-p3 0.957 0.918 0.996 / 0.111);
      --mauve-a5: color(display-p3 0.937 0.906 0.996 / 0.145);
      --mauve-a6: color(display-p3 0.953 0.925 0.996 / 0.183);
      --mauve-a7: color(display-p3 0.945 0.929 1 / 0.246);
      --mauve-a8: color(display-p3 0.937 0.918 1 / 0.361);
      --mauve-a9: color(display-p3 0.933 0.918 1 / 0.424);
      --mauve-a10: color(display-p3 0.941 0.925 1 / 0.479);
      --mauve-a11: color(display-p3 0.965 0.961 1 / 0.712);
      --mauve-a12: color(display-p3 0.992 0.992 1 / 0.937);
      --slate-1: color(display-p3 0.067 0.067 0.074);
      --slate-2: color(display-p3 0.095 0.098 0.105);
      --slate-3: color(display-p3 0.13 0.135 0.145);
      --slate-4: color(display-p3 0.156 0.163 0.176);
      --slate-5: color(display-p3 0.183 0.191 0.206);
      --slate-6: color(display-p3 0.215 0.226 0.244);
      --slate-7: color(display-p3 0.265 0.28 0.302);
      --slate-8: color(display-p3 0.357 0.381 0.409);
      --slate-9: color(display-p3 0.415 0.431 0.463);
      --slate-10: color(display-p3 0.469 0.483 0.514);
      --slate-11: color(display-p3 0.692 0.704 0.728);
      --slate-12: color(display-p3 0.93 0.933 0.94);
      --slate-a1: color(display-p3 0 0 0 / 0);
      --slate-a2: color(display-p3 0.875 0.992 1 / 0.034);
      --slate-a3: color(display-p3 0.882 0.933 0.992 / 0.077);
      --slate-a4: color(display-p3 0.882 0.953 0.996 / 0.111);
      --slate-a5: color(display-p3 0.878 0.929 0.996 / 0.145);
      --slate-a6: color(display-p3 0.882 0.949 0.996 / 0.183);
      --slate-a7: color(display-p3 0.882 0.929 1 / 0.246);
      --slate-a8: color(display-p3 0.871 0.937 1 / 0.361);
      --slate-a9: color(display-p3 0.898 0.937 1 / 0.42);
      --slate-a10: color(display-p3 0.918 0.945 1 / 0.475);
      --slate-a11: color(display-p3 0.949 0.969 0.996 / 0.708);
      --slate-a12: color(display-p3 0.988 0.992 1 / 0.937);
      --sage-1: color(display-p3 0.064 0.07 0.067);
      --sage-2: color(display-p3 0.092 0.098 0.094);
      --sage-3: color(display-p3 0.128 0.135 0.131);
      --sage-4: color(display-p3 0.155 0.164 0.159);
      --sage-5: color(display-p3 0.183 0.193 0.188);
      --sage-6: color(display-p3 0.218 0.23 0.224);
      --sage-7: color(display-p3 0.269 0.285 0.277);
      --sage-8: color(display-p3 0.362 0.382 0.373);
      --sage-9: color(display-p3 0.398 0.438 0.421);
      --sage-10: color(display-p3 0.453 0.49 0.474);
      --sage-11: color(display-p3 0.685 0.709 0.697);
      --sage-12: color(display-p3 0.927 0.933 0.93);
      --sage-a1: color(display-p3 0 0 0 / 0);
      --sage-a2: color(display-p3 0.976 0.988 0.984 / 0.03);
      --sage-a3: color(display-p3 0.992 0.945 0.941 / 0.072);
      --sage-a4: color(display-p3 0.988 0.996 0.992 / 0.102);
      --sage-a5: color(display-p3 0.992 1 0.996 / 0.131);
      --sage-a6: color(display-p3 0.973 1 0.976 / 0.173);
      --sage-a7: color(display-p3 0.957 1 0.976 / 0.233);
      --sage-a8: color(display-p3 0.957 1 0.984 / 0.334);
      --sage-a9: color(display-p3 0.902 1 0.957 / 0.397);
      --sage-a10: color(display-p3 0.929 1 0.973 / 0.452);
      --sage-a11: color(display-p3 0.969 1 0.988 / 0.688);
      --sage-a12: color(display-p3 0.992 1 0.996 / 0.929);
      --olive-1: color(display-p3 0.067 0.07 0.063);
      --olive-2: color(display-p3 0.095 0.098 0.091);
      --olive-3: color(display-p3 0.131 0.135 0.126);
      --olive-4: color(display-p3 0.158 0.163 0.153);
      --olive-5: color(display-p3 0.186 0.192 0.18);
      --olive-6: color(display-p3 0.221 0.229 0.215);
      --olive-7: color(display-p3 0.273 0.284 0.266);
      --olive-8: color(display-p3 0.365 0.382 0.359);
      --olive-9: color(display-p3 0.414 0.438 0.404);
      --olive-10: color(display-p3 0.467 0.49 0.458);
      --olive-11: color(display-p3 0.69 0.709 0.682);
      --olive-12: color(display-p3 0.927 0.933 0.926);
      --olive-a1: color(display-p3 0 0 0 / 0);
      --olive-a2: color(display-p3 0.984 0.988 0.976 / 0.03);
      --olive-a3: color(display-p3 0.992 0.996 0.988 / 0.068);
      --olive-a4: color(display-p3 0.953 0.996 0.949 / 0.102);
      --olive-a5: color(display-p3 0.969 1 0.965 / 0.131);
      --olive-a6: color(display-p3 0.973 1 0.969 / 0.169);
      --olive-a7: color(display-p3 0.98 1 0.961 / 0.228);
      --olive-a8: color(display-p3 0.961 1 0.957 / 0.334);
      --olive-a9: color(display-p3 0.949 1 0.922 / 0.397);
      --olive-a10: color(display-p3 0.953 1 0.941 / 0.452);
      --olive-a11: color(display-p3 0.976 1 0.965 / 0.688);
      --olive-a12: color(display-p3 0.992 1 0.992 / 0.929);
      --sand-1: color(display-p3 0.067 0.067 0.063);
      --sand-2: color(display-p3 0.098 0.098 0.094);
      --sand-3: color(display-p3 0.135 0.135 0.129);
      --sand-4: color(display-p3 0.164 0.163 0.156);
      --sand-5: color(display-p3 0.193 0.192 0.183);
      --sand-6: color(display-p3 0.23 0.229 0.217);
      --sand-7: color(display-p3 0.285 0.282 0.267);
      --sand-8: color(display-p3 0.384 0.378 0.357);
      --sand-9: color(display-p3 0.434 0.428 0.403);
      --sand-10: color(display-p3 0.487 0.481 0.456);
      --sand-11: color(display-p3 0.707 0.703 0.68);
      --sand-12: color(display-p3 0.933 0.933 0.926);
      --sand-a1: color(display-p3 0 0 0 / 0);
      --sand-a2: color(display-p3 0.992 0.992 0.988 / 0.034);
      --sand-a3: color(display-p3 0.996 0.996 0.992 / 0.072);
      --sand-a4: color(display-p3 0.992 0.992 0.953 / 0.106);
      --sand-a5: color(display-p3 1 1 0.965 / 0.135);
      --sand-a6: color(display-p3 1 0.976 0.929 / 0.177);
      --sand-a7: color(display-p3 1 0.984 0.929 / 0.236);
      --sand-a8: color(display-p3 1 0.976 0.925 / 0.341);
      --sand-a9: color(display-p3 1 0.98 0.925 / 0.395);
      --sand-a10: color(display-p3 1 0.992 0.933 / 0.45);
      --sand-a11: color(display-p3 1 0.996 0.961 / 0.685);
      --sand-a12: color(display-p3 1 1 0.992 / 0.929);
      --amber-1: color(display-p3 0.082 0.07 0.05);
      --amber-2: color(display-p3 0.111 0.094 0.064);
      --amber-3: color(display-p3 0.178 0.128 0.049);
      --amber-4: color(display-p3 0.239 0.156 0);
      --amber-5: color(display-p3 0.29 0.193 0);
      --amber-6: color(display-p3 0.344 0.245 0.076);
      --amber-7: color(display-p3 0.422 0.314 0.141);
      --amber-8: color(display-p3 0.535 0.399 0.189);
      --amber-9: color(display-p3 1 0.77 0.26);
      --amber-10: color(display-p3 1 0.87 0.15);
      --amber-11: color(display-p3 1 0.8 0.29);
      --amber-12: color(display-p3 0.984 0.909 0.726);
      --amber-a1: color(display-p3 0.992 0.298 0 / 0.017);
      --amber-a2: color(display-p3 0.988 0.651 0 / 0.047);
      --amber-a3: color(display-p3 1 0.6 0 / 0.118);
      --amber-a4: color(display-p3 1 0.557 0 / 0.185);
      --amber-a5: color(display-p3 1 0.592 0 / 0.24);
      --amber-a6: color(display-p3 1 0.659 0.094 / 0.299);
      --amber-a7: color(display-p3 1 0.714 0.263 / 0.383);
      --amber-a8: color(display-p3 0.996 0.729 0.306 / 0.5);
      --amber-a9: color(display-p3 1 0.769 0.259);
      --amber-a10: color(display-p3 1 0.871 0.149);
      --amber-a11: color(display-p3 1 0.8 0.29);
      --amber-a12: color(display-p3 0.984 0.909 0.726);
      --blue-1: color(display-p3 0.057 0.081 0.122);
      --blue-2: color(display-p3 0.072 0.098 0.147);
      --blue-3: color(display-p3 0.078 0.154 0.27);
      --blue-4: color(display-p3 0.033 0.197 0.37);
      --blue-5: color(display-p3 0.08 0.245 0.441);
      --blue-6: color(display-p3 0.14 0.298 0.511);
      --blue-7: color(display-p3 0.195 0.361 0.6);
      --blue-8: color(display-p3 0.239 0.434 0.72);
      --blue-9: color(display-p3 0.247 0.556 0.969);
      --blue-10: color(display-p3 0.344 0.612 0.973);
      --blue-11: color(display-p3 0.49 0.72 1);
      --blue-12: color(display-p3 0.788 0.898 0.99);
      --blue-a1: color(display-p3 0 0.333 1 / 0.059);
      --blue-a2: color(display-p3 0.114 0.435 0.988 / 0.085);
      --blue-a3: color(display-p3 0.122 0.463 1 / 0.219);
      --blue-a4: color(display-p3 0 0.467 1 / 0.324);
      --blue-a5: color(display-p3 0.098 0.51 1 / 0.4);
      --blue-a6: color(display-p3 0.224 0.557 1 / 0.475);
      --blue-a7: color(display-p3 0.294 0.584 1 / 0.572);
      --blue-a8: color(display-p3 0.314 0.592 1 / 0.702);
      --blue-a9: color(display-p3 0.251 0.573 0.996 / 0.967);
      --blue-a10: color(display-p3 0.357 0.631 1 / 0.971);
      --blue-a11: color(display-p3 0.49 0.72 1);
      --blue-a12: color(display-p3 0.788 0.898 0.99);
      --bronze-1: color(display-p3 0.076 0.067 0.063);
      --bronze-2: color(display-p3 0.106 0.097 0.093);
      --bronze-3: color(display-p3 0.147 0.132 0.125);
      --bronze-4: color(display-p3 0.185 0.166 0.156);
      --bronze-5: color(display-p3 0.227 0.202 0.19);
      --bronze-6: color(display-p3 0.278 0.246 0.23);
      --bronze-7: color(display-p3 0.343 0.302 0.281);
      --bronze-8: color(display-p3 0.426 0.374 0.347);
      --bronze-9: color(display-p3 0.611 0.507 0.455);
      --bronze-10: color(display-p3 0.66 0.556 0.504);
      --bronze-11: color(display-p3 0.81 0.707 0.655);
      --bronze-12: color(display-p3 0.921 0.88 0.854);
      --bronze-a1: color(display-p3 0.941 0.067 0 / 0.009);
      --bronze-a2: color(display-p3 0.98 0.8 0.706 / 0.043);
      --bronze-a3: color(display-p3 0.988 0.851 0.761 / 0.085);
      --bronze-a4: color(display-p3 0.996 0.839 0.78 / 0.127);
      --bronze-a5: color(display-p3 0.996 0.863 0.773 / 0.173);
      --bronze-a6: color(display-p3 1 0.863 0.796 / 0.227);
      --bronze-a7: color(display-p3 1 0.867 0.8 / 0.295);
      --bronze-a8: color(display-p3 1 0.859 0.788 / 0.387);
      --bronze-a9: color(display-p3 1 0.82 0.733 / 0.585);
      --bronze-a10: color(display-p3 1 0.839 0.761 / 0.635);
      --bronze-a11: color(display-p3 0.81 0.707 0.655);
      --bronze-a12: color(display-p3 0.921 0.88 0.854);
      --brown-1: color(display-p3 0.071 0.067 0.059);
      --brown-2: color(display-p3 0.107 0.095 0.087);
      --brown-3: color(display-p3 0.151 0.13 0.115);
      --brown-4: color(display-p3 0.191 0.161 0.138);
      --brown-5: color(display-p3 0.235 0.194 0.162);
      --brown-6: color(display-p3 0.291 0.237 0.192);
      --brown-7: color(display-p3 0.365 0.295 0.232);
      --brown-8: color(display-p3 0.469 0.377 0.287);
      --brown-9: color(display-p3 0.651 0.505 0.368);
      --brown-10: color(display-p3 0.697 0.557 0.423);
      --brown-11: color(display-p3 0.835 0.715 0.597);
      --brown-12: color(display-p3 0.938 0.885 0.802);
      --brown-a1: color(display-p3 0.855 0.071 0 / 0.005);
      --brown-a2: color(display-p3 0.98 0.706 0.525 / 0.043);
      --brown-a3: color(display-p3 0.996 0.745 0.576 / 0.093);
      --brown-a4: color(display-p3 1 0.765 0.592 / 0.135);
      --brown-a5: color(display-p3 1 0.761 0.588 / 0.181);
      --brown-a6: color(display-p3 1 0.773 0.592 / 0.24);
      --brown-a7: color(display-p3 0.996 0.776 0.58 / 0.32);
      --brown-a8: color(display-p3 1 0.78 0.573 / 0.433);
      --brown-a9: color(display-p3 1 0.769 0.549 / 0.627);
      --brown-a10: color(display-p3 1 0.792 0.596 / 0.677);
      --brown-a11: color(display-p3 0.835 0.715 0.597);
      --brown-a12: color(display-p3 0.938 0.885 0.802);
      --crimson-1: color(display-p3 0.093 0.068 0.078);
      --crimson-2: color(display-p3 0.117 0.078 0.095);
      --crimson-3: color(display-p3 0.203 0.091 0.143);
      --crimson-4: color(display-p3 0.277 0.087 0.182);
      --crimson-5: color(display-p3 0.332 0.115 0.22);
      --crimson-6: color(display-p3 0.394 0.162 0.268);
      --crimson-7: color(display-p3 0.489 0.222 0.336);
      --crimson-8: color(display-p3 0.638 0.289 0.429);
      --crimson-9: color(display-p3 0.843 0.298 0.507);
      --crimson-10: color(display-p3 0.864 0.364 0.539);
      --crimson-11: color(display-p3 1 0.56 0.66);
      --crimson-12: color(display-p3 0.966 0.834 0.906);
      --crimson-a1: color(display-p3 0.984 0.071 0.463 / 0.03);
      --crimson-a2: color(display-p3 0.996 0.282 0.569 / 0.055);
      --crimson-a3: color(display-p3 0.996 0.227 0.573 / 0.148);
      --crimson-a4: color(display-p3 1 0.157 0.569 / 0.227);
      --crimson-a5: color(display-p3 1 0.231 0.604 / 0.286);
      --crimson-a6: color(display-p3 1 0.337 0.643 / 0.349);
      --crimson-a7: color(display-p3 1 0.416 0.663 / 0.454);
      --crimson-a8: color(display-p3 0.996 0.427 0.651 / 0.614);
      --crimson-a9: color(display-p3 1 0.345 0.596 / 0.832);
      --crimson-a10: color(display-p3 1 0.42 0.62 / 0.853);
      --crimson-a11: color(display-p3 1 0.56 0.66);
      --crimson-a12: color(display-p3 0.966 0.834 0.906);
      --cyan-1: color(display-p3 0.053 0.085 0.098);
      --cyan-2: color(display-p3 0.072 0.105 0.122);
      --cyan-3: color(display-p3 0.073 0.168 0.209);
      --cyan-4: color(display-p3 0.063 0.216 0.277);
      --cyan-5: color(display-p3 0.091 0.267 0.336);
      --cyan-6: color(display-p3 0.137 0.324 0.4);
      --cyan-7: color(display-p3 0.186 0.398 0.484);
      --cyan-8: color(display-p3 0.23 0.496 0.6);
      --cyan-9: color(display-p3 0.282 0.627 0.765);
      --cyan-10: color(display-p3 0.331 0.675 0.801);
      --cyan-11: color(display-p3 0.446 0.79 0.887);
      --cyan-12: color(display-p3 0.757 0.919 0.962);
      --cyan-a1: color(display-p3 0 0.647 0.992 / 0.034);
      --cyan-a2: color(display-p3 0.133 0.733 1 / 0.059);
      --cyan-a3: color(display-p3 0.122 0.741 0.996 / 0.152);
      --cyan-a4: color(display-p3 0.051 0.725 1 / 0.227);
      --cyan-a5: color(display-p3 0.149 0.757 1 / 0.29);
      --cyan-a6: color(display-p3 0.267 0.792 1 / 0.358);
      --cyan-a7: color(display-p3 0.333 0.808 1 / 0.446);
      --cyan-a8: color(display-p3 0.357 0.816 1 / 0.572);
      --cyan-a9: color(display-p3 0.357 0.82 1 / 0.748);
      --cyan-a10: color(display-p3 0.4 0.839 1 / 0.786);
      --cyan-a11: color(display-p3 0.446 0.79 0.887);
      --cyan-a12: color(display-p3 0.757 0.919 0.962);
      --gold-1: color(display-p3 0.071 0.071 0.067);
      --gold-2: color(display-p3 0.104 0.101 0.09);
      --gold-3: color(display-p3 0.141 0.136 0.122);
      --gold-4: color(display-p3 0.177 0.17 0.152);
      --gold-5: color(display-p3 0.217 0.207 0.185);
      --gold-6: color(display-p3 0.265 0.252 0.225);
      --gold-7: color(display-p3 0.327 0.31 0.277);
      --gold-8: color(display-p3 0.407 0.384 0.342);
      --gold-9: color(display-p3 0.579 0.517 0.41);
      --gold-10: color(display-p3 0.628 0.566 0.463);
      --gold-11: color(display-p3 0.784 0.728 0.635);
      --gold-12: color(display-p3 0.906 0.887 0.855);
      --gold-a1: color(display-p3 0.855 0.855 0.071 / 0.005);
      --gold-a2: color(display-p3 0.98 0.89 0.616 / 0.043);
      --gold-a3: color(display-p3 1 0.949 0.753 / 0.08);
      --gold-a4: color(display-p3 1 0.933 0.8 / 0.118);
      --gold-a5: color(display-p3 1 0.949 0.804 / 0.16);
      --gold-a6: color(display-p3 1 0.925 0.8 / 0.215);
      --gold-a7: color(display-p3 1 0.945 0.831 / 0.278);
      --gold-a8: color(display-p3 1 0.937 0.82 / 0.366);
      --gold-a9: color(display-p3 0.996 0.882 0.69 / 0.551);
      --gold-a10: color(display-p3 1 0.894 0.725 / 0.601);
      --gold-a11: color(display-p3 0.784 0.728 0.635);
      --gold-a12: color(display-p3 0.906 0.887 0.855);
      --grass-1: color(display-p3 0.062 0.083 0.067);
      --grass-2: color(display-p3 0.083 0.103 0.085);
      --grass-3: color(display-p3 0.118 0.163 0.122);
      --grass-4: color(display-p3 0.142 0.225 0.15);
      --grass-5: color(display-p3 0.178 0.279 0.186);
      --grass-6: color(display-p3 0.217 0.337 0.224);
      --grass-7: color(display-p3 0.258 0.4 0.264);
      --grass-8: color(display-p3 0.302 0.47 0.305);
      --grass-9: color(display-p3 0.38 0.647 0.378);
      --grass-10: color(display-p3 0.426 0.694 0.426);
      --grass-11: color(display-p3 0.535 0.807 0.542);
      --grass-12: color(display-p3 0.797 0.936 0.776);
      --grass-a1: color(display-p3 0 0.992 0.071 / 0.017);
      --grass-a2: color(display-p3 0.482 0.996 0.584 / 0.038);
      --grass-a3: color(display-p3 0.549 0.992 0.588 / 0.106);
      --grass-a4: color(display-p3 0.51 0.996 0.557 / 0.169);
      --grass-a5: color(display-p3 0.553 1 0.588 / 0.227);
      --grass-a6: color(display-p3 0.584 1 0.608 / 0.29);
      --grass-a7: color(display-p3 0.604 1 0.616 / 0.358);
      --grass-a8: color(display-p3 0.608 1 0.62 / 0.433);
      --grass-a9: color(display-p3 0.573 1 0.569 / 0.622);
      --grass-a10: color(display-p3 0.6 0.996 0.6 / 0.673);
      --grass-a11: color(display-p3 0.535 0.807 0.542);
      --grass-a12: color(display-p3 0.797 0.936 0.776);
      --green-1: color(display-p3 0.062 0.083 0.071);
      --green-2: color(display-p3 0.079 0.106 0.09);
      --green-3: color(display-p3 0.1 0.173 0.133);
      --green-4: color(display-p3 0.115 0.229 0.166);
      --green-5: color(display-p3 0.147 0.282 0.206);
      --green-6: color(display-p3 0.185 0.338 0.25);
      --green-7: color(display-p3 0.227 0.403 0.298);
      --green-8: color(display-p3 0.27 0.479 0.351);
      --green-9: color(display-p3 0.332 0.634 0.442);
      --green-10: color(display-p3 0.357 0.682 0.474);
      --green-11: color(display-p3 0.434 0.828 0.573);
      --green-12: color(display-p3 0.747 0.938 0.807);
      --green-a1: color(display-p3 0 0.992 0.298 / 0.017);
      --green-a2: color(display-p3 0.341 0.98 0.616 / 0.043);
      --green-a3: color(display-p3 0.376 0.996 0.655 / 0.114);
      --green-a4: color(display-p3 0.341 0.996 0.635 / 0.173);
      --green-a5: color(display-p3 0.408 1 0.678 / 0.232);
      --green-a6: color(display-p3 0.475 1 0.706 / 0.29);
      --green-a7: color(display-p3 0.514 1 0.706 / 0.362);
      --green-a8: color(display-p3 0.529 1 0.718 / 0.442);
      --green-a9: color(display-p3 0.502 0.996 0.682 / 0.61);
      --green-a10: color(display-p3 0.506 1 0.682 / 0.66);
      --green-a11: color(display-p3 0.434 0.828 0.573);
      --green-a12: color(display-p3 0.747 0.938 0.807);
      --indigo-1: color(display-p3 0.068 0.074 0.118);
      --indigo-2: color(display-p3 0.081 0.089 0.144);
      --indigo-3: color(display-p3 0.105 0.141 0.275);
      --indigo-4: color(display-p3 0.129 0.18 0.369);
      --indigo-5: color(display-p3 0.163 0.22 0.439);
      --indigo-6: color(display-p3 0.203 0.262 0.5);
      --indigo-7: color(display-p3 0.245 0.309 0.575);
      --indigo-8: color(display-p3 0.285 0.362 0.674);
      --indigo-9: color(display-p3 0.276 0.384 0.837);
      --indigo-10: color(display-p3 0.354 0.445 0.866);
      --indigo-11: color(display-p3 0.63 0.69 1);
      --indigo-12: color(display-p3 0.848 0.881 0.99);
      --indigo-a1: color(display-p3 0.071 0.212 0.996 / 0.055);
      --indigo-a2: color(display-p3 0.251 0.345 0.988 / 0.085);
      --indigo-a3: color(display-p3 0.243 0.404 1 / 0.223);
      --indigo-a4: color(display-p3 0.263 0.42 1 / 0.324);
      --indigo-a5: color(display-p3 0.314 0.451 1 / 0.4);
      --indigo-a6: color(display-p3 0.361 0.49 1 / 0.467);
      --indigo-a7: color(display-p3 0.388 0.51 1 / 0.547);
      --indigo-a8: color(display-p3 0.404 0.518 1 / 0.652);
      --indigo-a9: color(display-p3 0.318 0.451 1 / 0.824);
      --indigo-a10: color(display-p3 0.404 0.506 1 / 0.858);
      --indigo-a11: color(display-p3 0.63 0.69 1);
      --indigo-a12: color(display-p3 0.848 0.881 0.99);
      --iris-1: color(display-p3 0.075 0.075 0.114);
      --iris-2: color(display-p3 0.089 0.086 0.14);
      --iris-3: color(display-p3 0.128 0.134 0.272);
      --iris-4: color(display-p3 0.153 0.165 0.382);
      --iris-5: color(display-p3 0.192 0.201 0.44);
      --iris-6: color(display-p3 0.239 0.241 0.491);
      --iris-7: color(display-p3 0.291 0.289 0.565);
      --iris-8: color(display-p3 0.35 0.345 0.673);
      --iris-9: color(display-p3 0.357 0.357 0.81);
      --iris-10: color(display-p3 0.428 0.416 0.843);
      --iris-11: color(display-p3 0.685 0.662 1);
      --iris-12: color(display-p3 0.878 0.875 0.986);
      --iris-a1: color(display-p3 0.224 0.224 0.992 / 0.051);
      --iris-a2: color(display-p3 0.361 0.314 1 / 0.08);
      --iris-a3: color(display-p3 0.357 0.373 1 / 0.219);
      --iris-a4: color(display-p3 0.325 0.361 1 / 0.337);
      --iris-a5: color(display-p3 0.38 0.4 1 / 0.4);
      --iris-a6: color(display-p3 0.447 0.447 1 / 0.454);
      --iris-a7: color(display-p3 0.486 0.486 1 / 0.534);
      --iris-a8: color(display-p3 0.502 0.494 1 / 0.652);
      --iris-a9: color(display-p3 0.431 0.431 1 / 0.799);
      --iris-a10: color(display-p3 0.502 0.486 1 / 0.832);
      --iris-a11: color(display-p3 0.685 0.662 1);
      --iris-a12: color(display-p3 0.878 0.875 0.986);
      --jade-1: color(display-p3 0.059 0.083 0.071);
      --jade-2: color(display-p3 0.078 0.11 0.094);
      --jade-3: color(display-p3 0.091 0.176 0.138);
      --jade-4: color(display-p3 0.102 0.228 0.177);
      --jade-5: color(display-p3 0.133 0.279 0.221);
      --jade-6: color(display-p3 0.174 0.334 0.273);
      --jade-7: color(display-p3 0.219 0.402 0.335);
      --jade-8: color(display-p3 0.263 0.488 0.411);
      --jade-9: color(display-p3 0.319 0.63 0.521);
      --jade-10: color(display-p3 0.338 0.68 0.555);
      --jade-11: color(display-p3 0.4 0.835 0.656);
      --jade-12: color(display-p3 0.734 0.934 0.838);
      --jade-a1: color(display-p3 0 0.992 0.298 / 0.017);
      --jade-a2: color(display-p3 0.318 0.988 0.651 / 0.047);
      --jade-a3: color(display-p3 0.267 1 0.667 / 0.118);
      --jade-a4: color(display-p3 0.275 0.996 0.702 / 0.173);
      --jade-a5: color(display-p3 0.361 1 0.741 / 0.227);
      --jade-a6: color(display-p3 0.439 1 0.796 / 0.286);
      --jade-a7: color(display-p3 0.49 1 0.804 / 0.362);
      --jade-a8: color(display-p3 0.506 1 0.835 / 0.45);
      --jade-a9: color(display-p3 0.478 0.996 0.816 / 0.606);
      --jade-a10: color(display-p3 0.478 1 0.816 / 0.656);
      --jade-a11: color(display-p3 0.4 0.835 0.656);
      --jade-a12: color(display-p3 0.734 0.934 0.838);
      --lime-1: color(display-p3 0.067 0.073 0.048);
      --lime-2: color(display-p3 0.086 0.1 0.067);
      --lime-3: color(display-p3 0.13 0.16 0.099);
      --lime-4: color(display-p3 0.172 0.214 0.126);
      --lime-5: color(display-p3 0.213 0.266 0.153);
      --lime-6: color(display-p3 0.257 0.321 0.182);
      --lime-7: color(display-p3 0.307 0.383 0.215);
      --lime-8: color(display-p3 0.365 0.456 0.25);
      --lime-9: color(display-p3 0.78 0.928 0.466);
      --lime-10: color(display-p3 0.865 0.995 0.519);
      --lime-11: color(display-p3 0.771 0.893 0.485);
      --lime-12: color(display-p3 0.905 0.966 0.753);
      --lime-a1: color(display-p3 0.067 0.941 0 / 0.009);
      --lime-a2: color(display-p3 0.584 0.996 0.071 / 0.038);
      --lime-a3: color(display-p3 0.69 1 0.38 / 0.101);
      --lime-a4: color(display-p3 0.729 1 0.435 / 0.16);
      --lime-a5: color(display-p3 0.745 1 0.471 / 0.215);
      --lime-a6: color(display-p3 0.769 1 0.482 / 0.274);
      --lime-a7: color(display-p3 0.769 1 0.506 / 0.341);
      --lime-a8: color(display-p3 0.784 1 0.51 / 0.416);
      --lime-a9: color(display-p3 0.839 1 0.502 / 0.925);
      --lime-a10: color(display-p3 0.871 1 0.522 / 0.996);
      --lime-a11: color(display-p3 0.771 0.893 0.485);
      --lime-a12: color(display-p3 0.905 0.966 0.753);
      --mint-1: color(display-p3 0.059 0.082 0.081);
      --mint-2: color(display-p3 0.068 0.104 0.105);
      --mint-3: color(display-p3 0.077 0.17 0.168);
      --mint-4: color(display-p3 0.068 0.224 0.22);
      --mint-5: color(display-p3 0.104 0.275 0.264);
      --mint-6: color(display-p3 0.154 0.332 0.313);
      --mint-7: color(display-p3 0.207 0.403 0.373);
      --mint-8: color(display-p3 0.258 0.49 0.441);
      --mint-9: color(display-p3 0.62 0.908 0.834);
      --mint-10: color(display-p3 0.725 0.954 0.898);
      --mint-11: color(display-p3 0.482 0.825 0.733);
      --mint-12: color(display-p3 0.807 0.955 0.887);
      --mint-a1: color(display-p3 0 0.992 0.992 / 0.017);
      --mint-a2: color(display-p3 0.071 0.98 0.98 / 0.043);
      --mint-a3: color(display-p3 0.176 0.996 0.996 / 0.11);
      --mint-a4: color(display-p3 0.071 0.996 0.973 / 0.169);
      --mint-a5: color(display-p3 0.243 1 0.949 / 0.223);
      --mint-a6: color(display-p3 0.369 1 0.933 / 0.286);
      --mint-a7: color(display-p3 0.459 1 0.914 / 0.362);
      --mint-a8: color(display-p3 0.49 1 0.89 / 0.454);
      --mint-a9: color(display-p3 0.678 0.996 0.914 / 0.904);
      --mint-a10: color(display-p3 0.761 1 0.941 / 0.95);
      --mint-a11: color(display-p3 0.482 0.825 0.733);
      --mint-a12: color(display-p3 0.807 0.955 0.887);
      --orange-1: color(display-p3 0.088 0.07 0.057);
      --orange-2: color(display-p3 0.113 0.089 0.061);
      --orange-3: color(display-p3 0.189 0.12 0.056);
      --orange-4: color(display-p3 0.262 0.132 0);
      --orange-5: color(display-p3 0.315 0.168 0.016);
      --orange-6: color(display-p3 0.376 0.219 0.088);
      --orange-7: color(display-p3 0.465 0.283 0.147);
      --orange-8: color(display-p3 0.601 0.359 0.201);
      --orange-9: color(display-p3 0.9 0.45 0.2);
      --orange-10: color(display-p3 0.98 0.51 0.23);
      --orange-11: color(display-p3 1 0.63 0.38);
      --orange-12: color(display-p3 0.98 0.883 0.775);
      --orange-a1: color(display-p3 0.961 0.247 0 / 0.022);
      --orange-a2: color(display-p3 0.992 0.529 0 / 0.051);
      --orange-a3: color(display-p3 0.996 0.486 0 / 0.131);
      --orange-a4: color(display-p3 0.996 0.384 0 / 0.211);
      --orange-a5: color(display-p3 1 0.455 0 / 0.265);
      --orange-a6: color(display-p3 1 0.529 0.129 / 0.332);
      --orange-a7: color(display-p3 1 0.569 0.251 / 0.429);
      --orange-a8: color(display-p3 1 0.584 0.302 / 0.572);
      --orange-a9: color(display-p3 1 0.494 0.216 / 0.895);
      --orange-a10: color(display-p3 1 0.522 0.235 / 0.979);
      --orange-a11: color(display-p3 1 0.63 0.38);
      --orange-a12: color(display-p3 0.98 0.883 0.775);
      --pink-1: color(display-p3 0.093 0.068 0.089);
      --pink-2: color(display-p3 0.121 0.073 0.11);
      --pink-3: color(display-p3 0.198 0.098 0.179);
      --pink-4: color(display-p3 0.271 0.095 0.231);
      --pink-5: color(display-p3 0.32 0.127 0.273);
      --pink-6: color(display-p3 0.382 0.177 0.326);
      --pink-7: color(display-p3 0.477 0.238 0.405);
      --pink-8: color(display-p3 0.612 0.304 0.51);
      --pink-9: color(display-p3 0.775 0.297 0.61);
      --pink-10: color(display-p3 0.808 0.356 0.645);
      --pink-11: color(display-p3 1 0.535 0.78);
      --pink-12: color(display-p3 0.964 0.826 0.912);
      --pink-a1: color(display-p3 0.984 0.071 0.855 / 0.03);
      --pink-a2: color(display-p3 1 0.2 0.8 / 0.059);
      --pink-a3: color(display-p3 1 0.294 0.886 / 0.139);
      --pink-a4: color(display-p3 1 0.192 0.82 / 0.219);
      --pink-a5: color(display-p3 1 0.282 0.827 / 0.274);
      --pink-a6: color(display-p3 1 0.396 0.835 / 0.337);
      --pink-a7: color(display-p3 1 0.459 0.831 / 0.442);
      --pink-a8: color(display-p3 1 0.478 0.827 / 0.585);
      --pink-a9: color(display-p3 1 0.373 0.784 / 0.761);
      --pink-a10: color(display-p3 1 0.435 0.792 / 0.795);
      --pink-a11: color(display-p3 1 0.535 0.78);
      --pink-a12: color(display-p3 0.964 0.826 0.912);
      --plum-1: color(display-p3 0.09 0.068 0.092);
      --plum-2: color(display-p3 0.118 0.077 0.121);
      --plum-3: color(display-p3 0.192 0.105 0.202);
      --plum-4: color(display-p3 0.25 0.121 0.271);
      --plum-5: color(display-p3 0.293 0.152 0.319);
      --plum-6: color(display-p3 0.343 0.198 0.372);
      --plum-7: color(display-p3 0.424 0.262 0.461);
      --plum-8: color(display-p3 0.54 0.341 0.595);
      --plum-9: color(display-p3 0.624 0.313 0.708);
      --plum-10: color(display-p3 0.666 0.365 0.748);
      --plum-11: color(display-p3 0.86 0.602 0.933);
      --plum-12: color(display-p3 0.936 0.836 0.949);
      --plum-a1: color(display-p3 0.973 0.071 0.973 / 0.026);
      --plum-a2: color(display-p3 0.933 0.267 1 / 0.059);
      --plum-a3: color(display-p3 0.918 0.333 0.996 / 0.148);
      --plum-a4: color(display-p3 0.91 0.318 1 / 0.219);
      --plum-a5: color(display-p3 0.914 0.388 1 / 0.269);
      --plum-a6: color(display-p3 0.906 0.463 1 / 0.328);
      --plum-a7: color(display-p3 0.906 0.529 1 / 0.425);
      --plum-a8: color(display-p3 0.906 0.553 1 / 0.568);
      --plum-a9: color(display-p3 0.875 0.427 1 / 0.69);
      --plum-a10: color(display-p3 0.886 0.471 0.996 / 0.732);
      --plum-a11: color(display-p3 0.86 0.602 0.933);
      --plum-a12: color(display-p3 0.936 0.836 0.949);
      --purple-1: color(display-p3 0.09 0.068 0.103);
      --purple-2: color(display-p3 0.113 0.082 0.134);
      --purple-3: color(display-p3 0.175 0.112 0.224);
      --purple-4: color(display-p3 0.224 0.137 0.297);
      --purple-5: color(display-p3 0.264 0.167 0.349);
      --purple-6: color(display-p3 0.311 0.208 0.406);
      --purple-7: color(display-p3 0.381 0.266 0.496);
      --purple-8: color(display-p3 0.49 0.349 0.649);
      --purple-9: color(display-p3 0.523 0.318 0.751);
      --purple-10: color(display-p3 0.57 0.373 0.791);
      --purple-11: color(display-p3 0.8 0.62 1);
      --purple-12: color(display-p3 0.913 0.854 0.971);
      --purple-a1: color(display-p3 0.686 0.071 0.996 / 0.038);
      --purple-a2: color(display-p3 0.722 0.286 0.996 / 0.072);
      --purple-a3: color(display-p3 0.718 0.349 0.996 / 0.169);
      --purple-a4: color(display-p3 0.702 0.353 1 / 0.248);
      --purple-a5: color(display-p3 0.718 0.404 1 / 0.303);
      --purple-a6: color(display-p3 0.733 0.455 1 / 0.366);
      --purple-a7: color(display-p3 0.753 0.506 1 / 0.458);
      --purple-a8: color(display-p3 0.749 0.522 1 / 0.622);
      --purple-a9: color(display-p3 0.686 0.408 1 / 0.736);
      --purple-a10: color(display-p3 0.71 0.459 1 / 0.778);
      --purple-a11: color(display-p3 0.8 0.62 1);
      --purple-a12: color(display-p3 0.913 0.854 0.971);
      --red-1: color(display-p3 0.093 0.068 0.067);
      --red-2: color(display-p3 0.118 0.077 0.079);
      --red-3: color(display-p3 0.211 0.081 0.099);
      --red-4: color(display-p3 0.287 0.079 0.113);
      --red-5: color(display-p3 0.348 0.11 0.142);
      --red-6: color(display-p3 0.414 0.16 0.183);
      --red-7: color(display-p3 0.508 0.224 0.236);
      --red-8: color(display-p3 0.659 0.298 0.297);
      --red-9: color(display-p3 0.83 0.329 0.324);
      --red-10: color(display-p3 0.861 0.403 0.387);
      --red-11: color(display-p3 1 0.57 0.55);
      --red-12: color(display-p3 0.971 0.826 0.852);
      --red-a1: color(display-p3 0.984 0.071 0.071 / 0.03);
      --red-a2: color(display-p3 0.996 0.282 0.282 / 0.055);
      --red-a3: color(display-p3 1 0.169 0.271 / 0.156);
      --red-a4: color(display-p3 1 0.118 0.267 / 0.236);
      --red-a5: color(display-p3 1 0.212 0.314 / 0.303);
      --red-a6: color(display-p3 1 0.318 0.38 / 0.374);
      --red-a7: color(display-p3 1 0.4 0.424 / 0.475);
      --red-a8: color(display-p3 1 0.431 0.431 / 0.635);
      --red-a9: color(display-p3 1 0.388 0.384 / 0.82);
      --red-a10: color(display-p3 1 0.463 0.447 / 0.853);
      --red-a11: color(display-p3 1 0.57 0.55);
      --red-a12: color(display-p3 0.971 0.826 0.852);
      --ruby-1: color(display-p3 0.093 0.068 0.074);
      --ruby-2: color(display-p3 0.113 0.083 0.089);
      --ruby-3: color(display-p3 0.208 0.088 0.117);
      --ruby-4: color(display-p3 0.279 0.092 0.147);
      --ruby-5: color(display-p3 0.337 0.12 0.18);
      --ruby-6: color(display-p3 0.401 0.166 0.223);
      --ruby-7: color(display-p3 0.495 0.224 0.281);
      --ruby-8: color(display-p3 0.652 0.295 0.359);
      --ruby-9: color(display-p3 0.83 0.323 0.408);
      --ruby-10: color(display-p3 0.857 0.392 0.455);
      --ruby-11: color(display-p3 1 0.57 0.59);
      --ruby-12: color(display-p3 0.968 0.83 0.88);
      --ruby-a1: color(display-p3 0.984 0.071 0.329 / 0.03);
      --ruby-a2: color(display-p3 0.992 0.376 0.529 / 0.051);
      --ruby-a3: color(display-p3 0.996 0.196 0.404 / 0.152);
      --ruby-a4: color(display-p3 1 0.173 0.416 / 0.227);
      --ruby-a5: color(display-p3 1 0.259 0.459 / 0.29);
      --ruby-a6: color(display-p3 1 0.341 0.506 / 0.358);
      --ruby-a7: color(display-p3 1 0.412 0.541 / 0.458);
      --ruby-a8: color(display-p3 1 0.431 0.537 / 0.627);
      --ruby-a9: color(display-p3 1 0.376 0.482 / 0.82);
      --ruby-a10: color(display-p3 1 0.447 0.522 / 0.849);
      --ruby-a11: color(display-p3 1 0.57 0.59);
      --ruby-a12: color(display-p3 0.968 0.83 0.88);
      --sky-1: color(display-p3 0.056 0.078 0.116);
      --sky-2: color(display-p3 0.075 0.101 0.149);
      --sky-3: color(display-p3 0.089 0.154 0.244);
      --sky-4: color(display-p3 0.106 0.207 0.323);
      --sky-5: color(display-p3 0.135 0.261 0.394);
      --sky-6: color(display-p3 0.17 0.322 0.469);
      --sky-7: color(display-p3 0.205 0.394 0.557);
      --sky-8: color(display-p3 0.232 0.48 0.665);
      --sky-9: color(display-p3 0.585 0.877 0.983);
      --sky-10: color(display-p3 0.718 0.925 0.991);
      --sky-11: color(display-p3 0.536 0.772 0.924);
      --sky-12: color(display-p3 0.799 0.947 0.993);
      --sky-a1: color(display-p3 0 0.282 0.996 / 0.055);
      --sky-a2: color(display-p3 0.157 0.467 0.992 / 0.089);
      --sky-a3: color(display-p3 0.192 0.522 0.996 / 0.19);
      --sky-a4: color(display-p3 0.212 0.584 1 / 0.274);
      --sky-a5: color(display-p3 0.259 0.631 1 / 0.349);
      --sky-a6: color(display-p3 0.302 0.655 1 / 0.433);
      --sky-a7: color(display-p3 0.329 0.686 1 / 0.526);
      --sky-a8: color(display-p3 0.325 0.71 1 / 0.643);
      --sky-a9: color(display-p3 0.592 0.894 1 / 0.984);
      --sky-a10: color(display-p3 0.722 0.933 1 / 0.992);
      --sky-a11: color(display-p3 0.536 0.772 0.924);
      --sky-a12: color(display-p3 0.799 0.947 0.993);
      --teal-1: color(display-p3 0.059 0.083 0.079);
      --teal-2: color(display-p3 0.075 0.11 0.107);
      --teal-3: color(display-p3 0.087 0.175 0.165);
      --teal-4: color(display-p3 0.087 0.227 0.214);
      --teal-5: color(display-p3 0.12 0.277 0.261);
      --teal-6: color(display-p3 0.162 0.335 0.314);
      --teal-7: color(display-p3 0.205 0.406 0.379);
      --teal-8: color(display-p3 0.245 0.489 0.453);
      --teal-9: color(display-p3 0.297 0.637 0.581);
      --teal-10: color(display-p3 0.319 0.69 0.62);
      --teal-11: color(display-p3 0.388 0.835 0.719);
      --teal-12: color(display-p3 0.734 0.934 0.87);
      --teal-a1: color(display-p3 0 0.992 0.761 / 0.017);
      --teal-a2: color(display-p3 0.235 0.988 0.902 / 0.047);
      --teal-a3: color(display-p3 0.235 1 0.898 / 0.118);
      --teal-a4: color(display-p3 0.18 0.996 0.929 / 0.173);
      --teal-a5: color(display-p3 0.31 1 0.933 / 0.227);
      --teal-a6: color(display-p3 0.396 1 0.933 / 0.286);
      --teal-a7: color(display-p3 0.443 1 0.925 / 0.366);
      --teal-a8: color(display-p3 0.459 1 0.925 / 0.454);
      --teal-a9: color(display-p3 0.443 0.996 0.906 / 0.61);
      --teal-a10: color(display-p3 0.439 0.996 0.89 / 0.669);
      --teal-a11: color(display-p3 0.388 0.835 0.719);
      --teal-a12: color(display-p3 0.734 0.934 0.87);
      --tomato-1: color(display-p3 0.09 0.068 0.067);
      --tomato-2: color(display-p3 0.115 0.084 0.076);
      --tomato-3: color(display-p3 0.205 0.097 0.083);
      --tomato-4: color(display-p3 0.282 0.099 0.077);
      --tomato-5: color(display-p3 0.339 0.129 0.101);
      --tomato-6: color(display-p3 0.398 0.179 0.141);
      --tomato-7: color(display-p3 0.487 0.245 0.194);
      --tomato-8: color(display-p3 0.629 0.322 0.248);
      --tomato-9: color(display-p3 0.831 0.345 0.231);
      --tomato-10: color(display-p3 0.862 0.415 0.298);
      --tomato-11: color(display-p3 1 0.585 0.455);
      --tomato-12: color(display-p3 0.959 0.833 0.802);
      --tomato-a1: color(display-p3 0.973 0.071 0.071 / 0.026);
      --tomato-a2: color(display-p3 0.992 0.376 0.224 / 0.051);
      --tomato-a3: color(display-p3 0.996 0.282 0.176 / 0.148);
      --tomato-a4: color(display-p3 1 0.204 0.118 / 0.232);
      --tomato-a5: color(display-p3 1 0.286 0.192 / 0.29);
      --tomato-a6: color(display-p3 1 0.392 0.278 / 0.353);
      --tomato-a7: color(display-p3 1 0.459 0.349 / 0.45);
      --tomato-a8: color(display-p3 1 0.49 0.369 / 0.601);
      --tomato-a9: color(display-p3 1 0.408 0.267 / 0.82);
      --tomato-a10: color(display-p3 1 0.478 0.341 / 0.853);
      --tomato-a11: color(display-p3 1 0.585 0.455);
      --tomato-a12: color(display-p3 0.959 0.833 0.802);
      --violet-1: color(display-p3 0.077 0.071 0.118);
      --violet-2: color(display-p3 0.101 0.084 0.141);
      --violet-3: color(display-p3 0.154 0.123 0.256);
      --violet-4: color(display-p3 0.191 0.148 0.345);
      --violet-5: color(display-p3 0.226 0.182 0.396);
      --violet-6: color(display-p3 0.269 0.223 0.449);
      --violet-7: color(display-p3 0.326 0.277 0.53);
      --violet-8: color(display-p3 0.399 0.346 0.656);
      --violet-9: color(display-p3 0.417 0.341 0.784);
      --violet-10: color(display-p3 0.477 0.402 0.823);
      --violet-11: color(display-p3 0.72 0.65 1);
      --violet-12: color(display-p3 0.883 0.867 0.986);
      --violet-a1: color(display-p3 0.282 0.141 0.996 / 0.055);
      --violet-a2: color(display-p3 0.51 0.263 1 / 0.08);
      --violet-a3: color(display-p3 0.494 0.337 0.996 / 0.202);
      --violet-a4: color(display-p3 0.49 0.345 1 / 0.299);
      --violet-a5: color(display-p3 0.525 0.392 1 / 0.353);
      --violet-a6: color(display-p3 0.569 0.455 1 / 0.408);
      --violet-a7: color(display-p3 0.588 0.494 1 / 0.496);
      --violet-a8: color(display-p3 0.596 0.51 1 / 0.631);
      --violet-a9: color(display-p3 0.522 0.424 1 / 0.769);
      --violet-a10: color(display-p3 0.576 0.482 1 / 0.811);
      --violet-a11: color(display-p3 0.72 0.65 1);
      --violet-a12: color(display-p3 0.883 0.867 0.986);
      --yellow-1: color(display-p3 0.078 0.069 0.047);
      --yellow-2: color(display-p3 0.103 0.094 0.063);
      --yellow-3: color(display-p3 0.168 0.137 0.039);
      --yellow-4: color(display-p3 0.209 0.169 0);
      --yellow-5: color(display-p3 0.255 0.209 0);
      --yellow-6: color(display-p3 0.31 0.261 0.07);
      --yellow-7: color(display-p3 0.389 0.331 0.135);
      --yellow-8: color(display-p3 0.497 0.42 0.182);
      --yellow-9: color(display-p3 1 0.92 0.22);
      --yellow-10: color(display-p3 1 1 0.456);
      --yellow-11: color(display-p3 0.948 0.885 0.392);
      --yellow-12: color(display-p3 0.959 0.934 0.731);
      --yellow-a1: color(display-p3 0.973 0.369 0 / 0.013);
      --yellow-a2: color(display-p3 0.996 0.792 0 / 0.038);
      --yellow-a3: color(display-p3 0.996 0.71 0 / 0.11);
      --yellow-a4: color(display-p3 0.996 0.741 0 / 0.152);
      --yellow-a5: color(display-p3 0.996 0.765 0 / 0.202);
      --yellow-a6: color(display-p3 0.996 0.816 0.082 / 0.261);
      --yellow-a7: color(display-p3 1 0.831 0.263 / 0.345);
      --yellow-a8: color(display-p3 1 0.831 0.314 / 0.463);
      --yellow-a9: color(display-p3 1 0.922 0.22);
      --yellow-a10: color(display-p3 1 1 0.455);
      --yellow-a11: color(display-p3 0.948 0.885 0.392);
      --yellow-a12: color(display-p3 0.959 0.934 0.731);
      --gray-surface: color(display-p3 0.1255 0.1255 0.1255 / 0.5);
      --mauve-surface: color(display-p3 0.1333 0.1255 0.1333 / 0.5);
      --slate-surface: color(display-p3 0.1176 0.1255 0.1333 / 0.5);
      --sage-surface: color(display-p3 0.1176 0.1255 0.1176 / 0.5);
      --olive-surface: color(display-p3 0.1176 0.1255 0.1176 / 0.5);
      --sand-surface: color(display-p3 0.1255 0.1255 0.1255 / 0.5);
      --amber-surface: color(display-p3 0.1412 0.1176 0.0784 / 0.5);
      --blue-surface: color(display-p3 0.0706 0.1255 0.2196 / 0.5);
      --bronze-surface: color(display-p3 0.1412 0.1255 0.1176 / 0.5);
      --brown-surface: color(display-p3 0.1412 0.1176 0.102 / 0.5);
      --crimson-surface: color(display-p3 0.1647 0.0863 0.1176 / 0.5);
      --cyan-surface: color(display-p3 0.0784 0.1412 0.1725 / 0.5);
      --gold-surface: color(display-p3 0.1412 0.1333 0.1098 / 0.5);
      --grass-surface: color(display-p3 0.102 0.1333 0.102 / 0.5);
      --green-surface: color(display-p3 0.0941 0.1412 0.1098 / 0.5);
      --indigo-surface: color(display-p3 0.0941 0.1098 0.2196 / 0.5);
      --iris-surface: color(display-p3 0.1098 0.102 0.2118 / 0.5);
      --jade-surface: color(display-p3 0.0863 0.149 0.1176 / 0.5);
      --lime-surface: color(display-p3 0.1098 0.1255 0.0784 / 0.5);
      --mint-surface: color(display-p3 0.0941 0.149 0.1412 / 0.5);
      --orange-surface: color(display-p3 0.1412 0.1098 0.0706 / 0.5);
      --pink-surface: color(display-p3 0.1725 0.0784 0.149 / 0.5);
      --plum-surface: color(display-p3 0.1647 0.0863 0.1725 / 0.5);
      --purple-surface: color(display-p3 0.149 0.0941 0.1961 / 0.5);
      --red-surface: color(display-p3 0.1647 0.0863 0.0863 / 0.5);
      --ruby-surface: color(display-p3 0.1569 0.0941 0.1098 / 0.5);
      --sky-surface: color(display-p3 0.0863 0.1333 0.2196 / 0.5);
      --teal-surface: color(display-p3 0.0863 0.149 0.1412 / 0.5);
      --tomato-surface: color(display-p3 0.1569 0.0941 0.0784 / 0.5);
      --violet-surface: color(display-p3 0.1333 0.102 0.2118 / 0.5);
      --yellow-surface: color(display-p3 0.1333 0.1176 0.0706 / 0.5);
    }
  }
}
:root {
  --gray-contrast: white;
  --mauve-contrast: white;
  --slate-contrast: white;
  --sage-contrast: white;
  --olive-contrast: white;
  --sand-contrast: white;
  --amber-contrast: #21201c;
  --blue-contrast: white;
  --bronze-contrast: white;
  --brown-contrast: white;
  --crimson-contrast: white;
  --cyan-contrast: white;
  --gold-contrast: white;
  --grass-contrast: white;
  --green-contrast: white;
  --indigo-contrast: white;
  --iris-contrast: white;
  --jade-contrast: white;
  --lime-contrast: #1d211c;
  --mint-contrast: #1a211e;
  --orange-contrast: white;
  --pink-contrast: white;
  --plum-contrast: white;
  --purple-contrast: white;
  --red-contrast: white;
  --ruby-contrast: white;
  --sky-contrast: #1c2024;
  --teal-contrast: white;
  --tomato-contrast: white;
  --violet-contrast: white;
  --yellow-contrast: #21201c;
  --black-a1: rgba(0, 0, 0, 0.05);
  --black-a2: rgba(0, 0, 0, 0.1);
  --black-a3: rgba(0, 0, 0, 0.15);
  --black-a4: rgba(0, 0, 0, 0.2);
  --black-a5: rgba(0, 0, 0, 0.3);
  --black-a6: rgba(0, 0, 0, 0.4);
  --black-a7: rgba(0, 0, 0, 0.5);
  --black-a8: rgba(0, 0, 0, 0.6);
  --black-a9: rgba(0, 0, 0, 0.7);
  --black-a10: rgba(0, 0, 0, 0.8);
  --black-a11: rgba(0, 0, 0, 0.9);
  --black-a12: rgba(0, 0, 0, 0.95);
  --white-a1: rgba(255, 255, 255, 0.05);
  --white-a2: rgba(255, 255, 255, 0.1);
  --white-a3: rgba(255, 255, 255, 0.15);
  --white-a4: rgba(255, 255, 255, 0.2);
  --white-a5: rgba(255, 255, 255, 0.3);
  --white-a6: rgba(255, 255, 255, 0.4);
  --white-a7: rgba(255, 255, 255, 0.5);
  --white-a8: rgba(255, 255, 255, 0.6);
  --white-a9: rgba(255, 255, 255, 0.7);
  --white-a10: rgba(255, 255, 255, 0.8);
  --white-a11: rgba(255, 255, 255, 0.9);
  --white-a12: rgba(255, 255, 255, 0.95);
}
@supports (color: color-mix(in oklab, white, black)) {
  .dark,
.dark-theme {
    --amber-track: color-mix(in oklab, var(--amber-8), var(--amber-9) 75%);
    --lime-track: color-mix(in oklab, var(--lime-8), var(--lime-9) 65%);
    --mint-track: color-mix(in oklab, var(--mint-8), var(--mint-9) 65%);
    --sky-track: color-mix(in oklab, var(--sky-8), var(--sky-9) 65%);
    --yellow-track: color-mix(in oklab, var(--yellow-8), var(--yellow-9) 65%);
  }
}
@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: color(display-p3 0 0 0 / 0.05);
      --black-a2: color(display-p3 0 0 0 / 0.1);
      --black-a3: color(display-p3 0 0 0 / 0.15);
      --black-a4: color(display-p3 0 0 0 / 0.2);
      --black-a5: color(display-p3 0 0 0 / 0.3);
      --black-a6: color(display-p3 0 0 0 / 0.4);
      --black-a7: color(display-p3 0 0 0 / 0.5);
      --black-a8: color(display-p3 0 0 0 / 0.6);
      --black-a9: color(display-p3 0 0 0 / 0.7);
      --black-a10: color(display-p3 0 0 0 / 0.8);
      --black-a11: color(display-p3 0 0 0 / 0.9);
      --black-a12: color(display-p3 0 0 0 / 0.95);
      --white-a1: color(display-p3 1 1 1 / 0.05);
      --white-a2: color(display-p3 1 1 1 / 0.1);
      --white-a3: color(display-p3 1 1 1 / 0.15);
      --white-a4: color(display-p3 1 1 1 / 0.2);
      --white-a5: color(display-p3 1 1 1 / 0.3);
      --white-a6: color(display-p3 1 1 1 / 0.4);
      --white-a7: color(display-p3 1 1 1 / 0.5);
      --white-a8: color(display-p3 1 1 1 / 0.6);
      --white-a9: color(display-p3 1 1 1 / 0.7);
      --white-a10: color(display-p3 1 1 1 / 0.8);
      --white-a11: color(display-p3 1 1 1 / 0.9);
      --white-a12: color(display-p3 1 1 1 / 0.95);
    }
  }
}
:where(.radix-themes) {
  --color-background: white;
  --color-overlay: var(--black-a6);
  --color-panel-solid: white;
  --color-panel-translucent: rgba(255, 255, 255, 0.7);
  --color-surface: rgba(255, 255, 255, 0.85);
  --color-transparent: rgb(0 0 0 / 0);
  --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
  --shadow-2: 0 0 0 1px var(--gray-a3), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --shadow-3: 0 0 0 1px var(--gray-a3), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
  --shadow-4: 0 0 0 1px var(--gray-a3), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
  --shadow-5: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
  --shadow-6: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
  --base-button-classic-after-inset: 2px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--gray-a4), inset 0 -2px 1px var(--gray-a3);
  --base-button-classic-box-shadow-bottom: inset 0 4px 2px -2px var(--white-a9), inset 0 2px 1px -1px var(--white-a9);
  --base-button-classic-disabled-box-shadow: var(--base-button-classic-box-shadow-top), var(--base-button-classic-box-shadow-bottom);
  --base-button-classic-active-filter: brightness(0.92) saturate(1.1);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.1) brightness(1.1);
  --base-button-classic-high-contrast-active-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --base-button-solid-active-filter: brightness(0.92) saturate(1.1);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.1) brightness(1.1);
  --base-button-solid-high-contrast-active-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a2), inset 0 0.05em var(--white-a12), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.05em var(--gray-a6), 0 0 0 0.05em var(--gray-a5), 0 0.08em 0.17em var(--gray-a7);
  --progress-indicator-after-linear-gradient: var(--white-a5), var(--white-a9), var(--white-a5);
  --segmented-control-indicator-background-color: var(--color-background);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 2px 1px var(--white-a11), inset 0 -2px 1px var(--gray-a4) ;
  --slider-range-high-contrast-background-image: linear-gradient(var(--black-a8), var(--black-a8));
  --slider-disabled-blend-mode: multiply;
  --switch-disabled-blend-mode: multiply;
  --switch-high-contrast-checked-color-overlay: var(--black-a8);
  --switch-high-contrast-checked-active-before-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --switch-surface-checked-active-filter: brightness(0.92) saturate(1.1);
  --switch-surface-checked-active-filter: brightness(0.92) saturate(1.1);
  --base-card-surface-box-shadow: 0 0 0 1px var(--gray-a5);
  --base-card-surface-hover-box-shadow: 0 0 0 1px var(--gray-a7);
  --base-card-surface-active-box-shadow: 0 0 0 1px var(--gray-a6);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a2), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 1px 1px 1px var(--black-a1), 0 2px 1px -1px var(--gray-a3), 0 2px 3px -2px var(--black-a1), 0 3px 12px -4px var(--gray-a3), 0 4px 16px -8px var(--black-a1);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 1px 1px 0 var(--black-a1), 0 2px 1px -2px var(--gray-a3), 0 2px 3px -3px var(--black-a1), 0 3px 12px -5px var(--gray-a3), 0 4px 16px -9px var(--black-a1);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a4), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a4), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-border-color: var(--gray-a3);
  --base-card-classic-hover-border-color: var(--gray-a3);
  --base-card-classic-active-border-color: var(--gray-a4);
}
:is(.dark, .dark-theme),
:is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, 0.25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a3), inset 0 0.05em var(--gray-a11), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.1em var(--black-a11), 0 0 0 0.075em var(--gray-a7), 0 0.08em 0.17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9) ;
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}
@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .radix-themes {
      --color-transparent: color(display-p3 0 0 0 / 0);
    }
  }
}
.radix-themes:where(.light, .light-theme),
  :root:where(:has(.radix-themes[data-is-root-theme='true']:where(.light, .light-theme))) {
  color-scheme: light;
}
.radix-themes:where(.dark, .dark-theme),
  :root:where(:has(.radix-themes[data-is-root-theme='true']:where(.dark, .dark-theme))) {
  color-scheme: dark;
}
.radix-themes,
[data-accent-color]:where(:not([data-accent-color=''], [data-accent-color='gray'])) {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}
.radix-themes ::selection {
  background-color: var(--focus-a5);
}
.radix-themes:where([data-has-background='true']) {
  background-color: var(--color-background);
}
.radix-themes:where([data-panel-background='solid']) {
  --color-panel: var(--color-panel-solid);
  --backdrop-filter-panel: none;
}
.radix-themes:where([data-panel-background='translucent']) {
  --color-panel: var(--color-panel-translucent);
  --backdrop-filter-panel: blur(64px);
}
[data-accent-color='amber'] {
  --accent-1: var(--amber-1);
  --accent-2: var(--amber-2);
  --accent-3: var(--amber-3);
  --accent-4: var(--amber-4);
  --accent-5: var(--amber-5);
  --accent-6: var(--amber-6);
  --accent-7: var(--amber-7);
  --accent-8: var(--amber-8);
  --accent-9: var(--amber-9);
  --accent-10: var(--amber-10);
  --accent-11: var(--amber-11);
  --accent-12: var(--amber-12);
  --accent-a1: var(--amber-a1);
  --accent-a2: var(--amber-a2);
  --accent-a3: var(--amber-a3);
  --accent-a4: var(--amber-a4);
  --accent-a5: var(--amber-a5);
  --accent-a6: var(--amber-a6);
  --accent-a7: var(--amber-a7);
  --accent-a8: var(--amber-a8);
  --accent-a9: var(--amber-a9);
  --accent-a10: var(--amber-a10);
  --accent-a11: var(--amber-a11);
  --accent-a12: var(--amber-a12);
  --accent-contrast: var(--amber-contrast);
  --accent-surface: var(--amber-surface);
  --accent-indicator: var(--amber-indicator);
  --accent-track: var(--amber-track);
}
[data-accent-color='blue'] {
  --accent-1: var(--blue-1);
  --accent-2: var(--blue-2);
  --accent-3: var(--blue-3);
  --accent-4: var(--blue-4);
  --accent-5: var(--blue-5);
  --accent-6: var(--blue-6);
  --accent-7: var(--blue-7);
  --accent-8: var(--blue-8);
  --accent-9: var(--blue-9);
  --accent-10: var(--blue-10);
  --accent-11: var(--blue-11);
  --accent-12: var(--blue-12);
  --accent-a1: var(--blue-a1);
  --accent-a2: var(--blue-a2);
  --accent-a3: var(--blue-a3);
  --accent-a4: var(--blue-a4);
  --accent-a5: var(--blue-a5);
  --accent-a6: var(--blue-a6);
  --accent-a7: var(--blue-a7);
  --accent-a8: var(--blue-a8);
  --accent-a9: var(--blue-a9);
  --accent-a10: var(--blue-a10);
  --accent-a11: var(--blue-a11);
  --accent-a12: var(--blue-a12);
  --accent-contrast: var(--blue-contrast);
  --accent-surface: var(--blue-surface);
  --accent-indicator: var(--blue-indicator);
  --accent-track: var(--blue-track);
}
[data-accent-color='bronze'] {
  --accent-1: var(--bronze-1);
  --accent-2: var(--bronze-2);
  --accent-3: var(--bronze-3);
  --accent-4: var(--bronze-4);
  --accent-5: var(--bronze-5);
  --accent-6: var(--bronze-6);
  --accent-7: var(--bronze-7);
  --accent-8: var(--bronze-8);
  --accent-9: var(--bronze-9);
  --accent-10: var(--bronze-10);
  --accent-11: var(--bronze-11);
  --accent-12: var(--bronze-12);
  --accent-a1: var(--bronze-a1);
  --accent-a2: var(--bronze-a2);
  --accent-a3: var(--bronze-a3);
  --accent-a4: var(--bronze-a4);
  --accent-a5: var(--bronze-a5);
  --accent-a6: var(--bronze-a6);
  --accent-a7: var(--bronze-a7);
  --accent-a8: var(--bronze-a8);
  --accent-a9: var(--bronze-a9);
  --accent-a10: var(--bronze-a10);
  --accent-a11: var(--bronze-a11);
  --accent-a12: var(--bronze-a12);
  --accent-contrast: var(--bronze-contrast);
  --accent-surface: var(--bronze-surface);
  --accent-indicator: var(--bronze-indicator);
  --accent-track: var(--bronze-track);
}
[data-accent-color='brown'] {
  --accent-1: var(--brown-1);
  --accent-2: var(--brown-2);
  --accent-3: var(--brown-3);
  --accent-4: var(--brown-4);
  --accent-5: var(--brown-5);
  --accent-6: var(--brown-6);
  --accent-7: var(--brown-7);
  --accent-8: var(--brown-8);
  --accent-9: var(--brown-9);
  --accent-10: var(--brown-10);
  --accent-11: var(--brown-11);
  --accent-12: var(--brown-12);
  --accent-a1: var(--brown-a1);
  --accent-a2: var(--brown-a2);
  --accent-a3: var(--brown-a3);
  --accent-a4: var(--brown-a4);
  --accent-a5: var(--brown-a5);
  --accent-a6: var(--brown-a6);
  --accent-a7: var(--brown-a7);
  --accent-a8: var(--brown-a8);
  --accent-a9: var(--brown-a9);
  --accent-a10: var(--brown-a10);
  --accent-a11: var(--brown-a11);
  --accent-a12: var(--brown-a12);
  --accent-contrast: var(--brown-contrast);
  --accent-surface: var(--brown-surface);
  --accent-indicator: var(--brown-indicator);
  --accent-track: var(--brown-track);
}
[data-accent-color='crimson'] {
  --accent-1: var(--crimson-1);
  --accent-2: var(--crimson-2);
  --accent-3: var(--crimson-3);
  --accent-4: var(--crimson-4);
  --accent-5: var(--crimson-5);
  --accent-6: var(--crimson-6);
  --accent-7: var(--crimson-7);
  --accent-8: var(--crimson-8);
  --accent-9: var(--crimson-9);
  --accent-10: var(--crimson-10);
  --accent-11: var(--crimson-11);
  --accent-12: var(--crimson-12);
  --accent-a1: var(--crimson-a1);
  --accent-a2: var(--crimson-a2);
  --accent-a3: var(--crimson-a3);
  --accent-a4: var(--crimson-a4);
  --accent-a5: var(--crimson-a5);
  --accent-a6: var(--crimson-a6);
  --accent-a7: var(--crimson-a7);
  --accent-a8: var(--crimson-a8);
  --accent-a9: var(--crimson-a9);
  --accent-a10: var(--crimson-a10);
  --accent-a11: var(--crimson-a11);
  --accent-a12: var(--crimson-a12);
  --accent-contrast: var(--crimson-contrast);
  --accent-surface: var(--crimson-surface);
  --accent-indicator: var(--crimson-indicator);
  --accent-track: var(--crimson-track);
}
[data-accent-color='cyan'] {
  --accent-1: var(--cyan-1);
  --accent-2: var(--cyan-2);
  --accent-3: var(--cyan-3);
  --accent-4: var(--cyan-4);
  --accent-5: var(--cyan-5);
  --accent-6: var(--cyan-6);
  --accent-7: var(--cyan-7);
  --accent-8: var(--cyan-8);
  --accent-9: var(--cyan-9);
  --accent-10: var(--cyan-10);
  --accent-11: var(--cyan-11);
  --accent-12: var(--cyan-12);
  --accent-a1: var(--cyan-a1);
  --accent-a2: var(--cyan-a2);
  --accent-a3: var(--cyan-a3);
  --accent-a4: var(--cyan-a4);
  --accent-a5: var(--cyan-a5);
  --accent-a6: var(--cyan-a6);
  --accent-a7: var(--cyan-a7);
  --accent-a8: var(--cyan-a8);
  --accent-a9: var(--cyan-a9);
  --accent-a10: var(--cyan-a10);
  --accent-a11: var(--cyan-a11);
  --accent-a12: var(--cyan-a12);
  --accent-contrast: var(--cyan-contrast);
  --accent-surface: var(--cyan-surface);
  --accent-indicator: var(--cyan-indicator);
  --accent-track: var(--cyan-track);
}
[data-accent-color='gold'] {
  --accent-1: var(--gold-1);
  --accent-2: var(--gold-2);
  --accent-3: var(--gold-3);
  --accent-4: var(--gold-4);
  --accent-5: var(--gold-5);
  --accent-6: var(--gold-6);
  --accent-7: var(--gold-7);
  --accent-8: var(--gold-8);
  --accent-9: var(--gold-9);
  --accent-10: var(--gold-10);
  --accent-11: var(--gold-11);
  --accent-12: var(--gold-12);
  --accent-a1: var(--gold-a1);
  --accent-a2: var(--gold-a2);
  --accent-a3: var(--gold-a3);
  --accent-a4: var(--gold-a4);
  --accent-a5: var(--gold-a5);
  --accent-a6: var(--gold-a6);
  --accent-a7: var(--gold-a7);
  --accent-a8: var(--gold-a8);
  --accent-a9: var(--gold-a9);
  --accent-a10: var(--gold-a10);
  --accent-a11: var(--gold-a11);
  --accent-a12: var(--gold-a12);
  --accent-contrast: var(--gold-contrast);
  --accent-surface: var(--gold-surface);
  --accent-indicator: var(--gold-indicator);
  --accent-track: var(--gold-track);
}
[data-accent-color='grass'] {
  --accent-1: var(--grass-1);
  --accent-2: var(--grass-2);
  --accent-3: var(--grass-3);
  --accent-4: var(--grass-4);
  --accent-5: var(--grass-5);
  --accent-6: var(--grass-6);
  --accent-7: var(--grass-7);
  --accent-8: var(--grass-8);
  --accent-9: var(--grass-9);
  --accent-10: var(--grass-10);
  --accent-11: var(--grass-11);
  --accent-12: var(--grass-12);
  --accent-a1: var(--grass-a1);
  --accent-a2: var(--grass-a2);
  --accent-a3: var(--grass-a3);
  --accent-a4: var(--grass-a4);
  --accent-a5: var(--grass-a5);
  --accent-a6: var(--grass-a6);
  --accent-a7: var(--grass-a7);
  --accent-a8: var(--grass-a8);
  --accent-a9: var(--grass-a9);
  --accent-a10: var(--grass-a10);
  --accent-a11: var(--grass-a11);
  --accent-a12: var(--grass-a12);
  --accent-contrast: var(--grass-contrast);
  --accent-surface: var(--grass-surface);
  --accent-indicator: var(--grass-indicator);
  --accent-track: var(--grass-track);
}
[data-accent-color='gray'] {
  --accent-1: var(--gray-1);
  --accent-2: var(--gray-2);
  --accent-3: var(--gray-3);
  --accent-4: var(--gray-4);
  --accent-5: var(--gray-5);
  --accent-6: var(--gray-6);
  --accent-7: var(--gray-7);
  --accent-8: var(--gray-8);
  --accent-9: var(--gray-9);
  --accent-10: var(--gray-10);
  --accent-11: var(--gray-11);
  --accent-12: var(--gray-12);
  --accent-a1: var(--gray-a1);
  --accent-a2: var(--gray-a2);
  --accent-a3: var(--gray-a3);
  --accent-a4: var(--gray-a4);
  --accent-a5: var(--gray-a5);
  --accent-a6: var(--gray-a6);
  --accent-a7: var(--gray-a7);
  --accent-a8: var(--gray-a8);
  --accent-a9: var(--gray-a9);
  --accent-a10: var(--gray-a10);
  --accent-a11: var(--gray-a11);
  --accent-a12: var(--gray-a12);
  --accent-contrast: var(--gray-contrast);
  --accent-surface: var(--gray-surface);
  --accent-indicator: var(--gray-indicator);
  --accent-track: var(--gray-track);
}
[data-accent-color='green'] {
  --accent-1: var(--green-1);
  --accent-2: var(--green-2);
  --accent-3: var(--green-3);
  --accent-4: var(--green-4);
  --accent-5: var(--green-5);
  --accent-6: var(--green-6);
  --accent-7: var(--green-7);
  --accent-8: var(--green-8);
  --accent-9: var(--green-9);
  --accent-10: var(--green-10);
  --accent-11: var(--green-11);
  --accent-12: var(--green-12);
  --accent-a1: var(--green-a1);
  --accent-a2: var(--green-a2);
  --accent-a3: var(--green-a3);
  --accent-a4: var(--green-a4);
  --accent-a5: var(--green-a5);
  --accent-a6: var(--green-a6);
  --accent-a7: var(--green-a7);
  --accent-a8: var(--green-a8);
  --accent-a9: var(--green-a9);
  --accent-a10: var(--green-a10);
  --accent-a11: var(--green-a11);
  --accent-a12: var(--green-a12);
  --accent-contrast: var(--green-contrast);
  --accent-surface: var(--green-surface);
  --accent-indicator: var(--green-indicator);
  --accent-track: var(--green-track);
}
[data-accent-color='indigo'] {
  --accent-1: var(--indigo-1);
  --accent-2: var(--indigo-2);
  --accent-3: var(--indigo-3);
  --accent-4: var(--indigo-4);
  --accent-5: var(--indigo-5);
  --accent-6: var(--indigo-6);
  --accent-7: var(--indigo-7);
  --accent-8: var(--indigo-8);
  --accent-9: var(--indigo-9);
  --accent-10: var(--indigo-10);
  --accent-11: var(--indigo-11);
  --accent-12: var(--indigo-12);
  --accent-a1: var(--indigo-a1);
  --accent-a2: var(--indigo-a2);
  --accent-a3: var(--indigo-a3);
  --accent-a4: var(--indigo-a4);
  --accent-a5: var(--indigo-a5);
  --accent-a6: var(--indigo-a6);
  --accent-a7: var(--indigo-a7);
  --accent-a8: var(--indigo-a8);
  --accent-a9: var(--indigo-a9);
  --accent-a10: var(--indigo-a10);
  --accent-a11: var(--indigo-a11);
  --accent-a12: var(--indigo-a12);
  --accent-contrast: var(--indigo-contrast);
  --accent-surface: var(--indigo-surface);
  --accent-indicator: var(--indigo-indicator);
  --accent-track: var(--indigo-track);
}
[data-accent-color='iris'] {
  --accent-1: var(--iris-1);
  --accent-2: var(--iris-2);
  --accent-3: var(--iris-3);
  --accent-4: var(--iris-4);
  --accent-5: var(--iris-5);
  --accent-6: var(--iris-6);
  --accent-7: var(--iris-7);
  --accent-8: var(--iris-8);
  --accent-9: var(--iris-9);
  --accent-10: var(--iris-10);
  --accent-11: var(--iris-11);
  --accent-12: var(--iris-12);
  --accent-a1: var(--iris-a1);
  --accent-a2: var(--iris-a2);
  --accent-a3: var(--iris-a3);
  --accent-a4: var(--iris-a4);
  --accent-a5: var(--iris-a5);
  --accent-a6: var(--iris-a6);
  --accent-a7: var(--iris-a7);
  --accent-a8: var(--iris-a8);
  --accent-a9: var(--iris-a9);
  --accent-a10: var(--iris-a10);
  --accent-a11: var(--iris-a11);
  --accent-a12: var(--iris-a12);
  --accent-contrast: var(--iris-contrast);
  --accent-surface: var(--iris-surface);
  --accent-indicator: var(--iris-indicator);
  --accent-track: var(--iris-track);
}
[data-accent-color='jade'] {
  --accent-1: var(--jade-1);
  --accent-2: var(--jade-2);
  --accent-3: var(--jade-3);
  --accent-4: var(--jade-4);
  --accent-5: var(--jade-5);
  --accent-6: var(--jade-6);
  --accent-7: var(--jade-7);
  --accent-8: var(--jade-8);
  --accent-9: var(--jade-9);
  --accent-10: var(--jade-10);
  --accent-11: var(--jade-11);
  --accent-12: var(--jade-12);
  --accent-a1: var(--jade-a1);
  --accent-a2: var(--jade-a2);
  --accent-a3: var(--jade-a3);
  --accent-a4: var(--jade-a4);
  --accent-a5: var(--jade-a5);
  --accent-a6: var(--jade-a6);
  --accent-a7: var(--jade-a7);
  --accent-a8: var(--jade-a8);
  --accent-a9: var(--jade-a9);
  --accent-a10: var(--jade-a10);
  --accent-a11: var(--jade-a11);
  --accent-a12: var(--jade-a12);
  --accent-contrast: var(--jade-contrast);
  --accent-surface: var(--jade-surface);
  --accent-indicator: var(--jade-indicator);
  --accent-track: var(--jade-track);
}
[data-accent-color='lime'] {
  --accent-1: var(--lime-1);
  --accent-2: var(--lime-2);
  --accent-3: var(--lime-3);
  --accent-4: var(--lime-4);
  --accent-5: var(--lime-5);
  --accent-6: var(--lime-6);
  --accent-7: var(--lime-7);
  --accent-8: var(--lime-8);
  --accent-9: var(--lime-9);
  --accent-10: var(--lime-10);
  --accent-11: var(--lime-11);
  --accent-12: var(--lime-12);
  --accent-a1: var(--lime-a1);
  --accent-a2: var(--lime-a2);
  --accent-a3: var(--lime-a3);
  --accent-a4: var(--lime-a4);
  --accent-a5: var(--lime-a5);
  --accent-a6: var(--lime-a6);
  --accent-a7: var(--lime-a7);
  --accent-a8: var(--lime-a8);
  --accent-a9: var(--lime-a9);
  --accent-a10: var(--lime-a10);
  --accent-a11: var(--lime-a11);
  --accent-a12: var(--lime-a12);
  --accent-contrast: var(--lime-contrast);
  --accent-surface: var(--lime-surface);
  --accent-indicator: var(--lime-indicator);
  --accent-track: var(--lime-track);
}
[data-accent-color='mint'] {
  --accent-1: var(--mint-1);
  --accent-2: var(--mint-2);
  --accent-3: var(--mint-3);
  --accent-4: var(--mint-4);
  --accent-5: var(--mint-5);
  --accent-6: var(--mint-6);
  --accent-7: var(--mint-7);
  --accent-8: var(--mint-8);
  --accent-9: var(--mint-9);
  --accent-10: var(--mint-10);
  --accent-11: var(--mint-11);
  --accent-12: var(--mint-12);
  --accent-a1: var(--mint-a1);
  --accent-a2: var(--mint-a2);
  --accent-a3: var(--mint-a3);
  --accent-a4: var(--mint-a4);
  --accent-a5: var(--mint-a5);
  --accent-a6: var(--mint-a6);
  --accent-a7: var(--mint-a7);
  --accent-a8: var(--mint-a8);
  --accent-a9: var(--mint-a9);
  --accent-a10: var(--mint-a10);
  --accent-a11: var(--mint-a11);
  --accent-a12: var(--mint-a12);
  --accent-contrast: var(--mint-contrast);
  --accent-surface: var(--mint-surface);
  --accent-indicator: var(--mint-indicator);
  --accent-track: var(--mint-track);
}
[data-accent-color='orange'] {
  --accent-1: var(--orange-1);
  --accent-2: var(--orange-2);
  --accent-3: var(--orange-3);
  --accent-4: var(--orange-4);
  --accent-5: var(--orange-5);
  --accent-6: var(--orange-6);
  --accent-7: var(--orange-7);
  --accent-8: var(--orange-8);
  --accent-9: var(--orange-9);
  --accent-10: var(--orange-10);
  --accent-11: var(--orange-11);
  --accent-12: var(--orange-12);
  --accent-a1: var(--orange-a1);
  --accent-a2: var(--orange-a2);
  --accent-a3: var(--orange-a3);
  --accent-a4: var(--orange-a4);
  --accent-a5: var(--orange-a5);
  --accent-a6: var(--orange-a6);
  --accent-a7: var(--orange-a7);
  --accent-a8: var(--orange-a8);
  --accent-a9: var(--orange-a9);
  --accent-a10: var(--orange-a10);
  --accent-a11: var(--orange-a11);
  --accent-a12: var(--orange-a12);
  --accent-contrast: var(--orange-contrast);
  --accent-surface: var(--orange-surface);
  --accent-indicator: var(--orange-indicator);
  --accent-track: var(--orange-track);
}
[data-accent-color='pink'] {
  --accent-1: var(--pink-1);
  --accent-2: var(--pink-2);
  --accent-3: var(--pink-3);
  --accent-4: var(--pink-4);
  --accent-5: var(--pink-5);
  --accent-6: var(--pink-6);
  --accent-7: var(--pink-7);
  --accent-8: var(--pink-8);
  --accent-9: var(--pink-9);
  --accent-10: var(--pink-10);
  --accent-11: var(--pink-11);
  --accent-12: var(--pink-12);
  --accent-a1: var(--pink-a1);
  --accent-a2: var(--pink-a2);
  --accent-a3: var(--pink-a3);
  --accent-a4: var(--pink-a4);
  --accent-a5: var(--pink-a5);
  --accent-a6: var(--pink-a6);
  --accent-a7: var(--pink-a7);
  --accent-a8: var(--pink-a8);
  --accent-a9: var(--pink-a9);
  --accent-a10: var(--pink-a10);
  --accent-a11: var(--pink-a11);
  --accent-a12: var(--pink-a12);
  --accent-contrast: var(--pink-contrast);
  --accent-surface: var(--pink-surface);
  --accent-indicator: var(--pink-indicator);
  --accent-track: var(--pink-track);
}
[data-accent-color='plum'] {
  --accent-1: var(--plum-1);
  --accent-2: var(--plum-2);
  --accent-3: var(--plum-3);
  --accent-4: var(--plum-4);
  --accent-5: var(--plum-5);
  --accent-6: var(--plum-6);
  --accent-7: var(--plum-7);
  --accent-8: var(--plum-8);
  --accent-9: var(--plum-9);
  --accent-10: var(--plum-10);
  --accent-11: var(--plum-11);
  --accent-12: var(--plum-12);
  --accent-a1: var(--plum-a1);
  --accent-a2: var(--plum-a2);
  --accent-a3: var(--plum-a3);
  --accent-a4: var(--plum-a4);
  --accent-a5: var(--plum-a5);
  --accent-a6: var(--plum-a6);
  --accent-a7: var(--plum-a7);
  --accent-a8: var(--plum-a8);
  --accent-a9: var(--plum-a9);
  --accent-a10: var(--plum-a10);
  --accent-a11: var(--plum-a11);
  --accent-a12: var(--plum-a12);
  --accent-contrast: var(--plum-contrast);
  --accent-surface: var(--plum-surface);
  --accent-indicator: var(--plum-indicator);
  --accent-track: var(--plum-track);
}
[data-accent-color='purple'] {
  --accent-1: var(--purple-1);
  --accent-2: var(--purple-2);
  --accent-3: var(--purple-3);
  --accent-4: var(--purple-4);
  --accent-5: var(--purple-5);
  --accent-6: var(--purple-6);
  --accent-7: var(--purple-7);
  --accent-8: var(--purple-8);
  --accent-9: var(--purple-9);
  --accent-10: var(--purple-10);
  --accent-11: var(--purple-11);
  --accent-12: var(--purple-12);
  --accent-a1: var(--purple-a1);
  --accent-a2: var(--purple-a2);
  --accent-a3: var(--purple-a3);
  --accent-a4: var(--purple-a4);
  --accent-a5: var(--purple-a5);
  --accent-a6: var(--purple-a6);
  --accent-a7: var(--purple-a7);
  --accent-a8: var(--purple-a8);
  --accent-a9: var(--purple-a9);
  --accent-a10: var(--purple-a10);
  --accent-a11: var(--purple-a11);
  --accent-a12: var(--purple-a12);
  --accent-contrast: var(--purple-contrast);
  --accent-surface: var(--purple-surface);
  --accent-indicator: var(--purple-indicator);
  --accent-track: var(--purple-track);
}
[data-accent-color='red'] {
  --accent-1: var(--red-1);
  --accent-2: var(--red-2);
  --accent-3: var(--red-3);
  --accent-4: var(--red-4);
  --accent-5: var(--red-5);
  --accent-6: var(--red-6);
  --accent-7: var(--red-7);
  --accent-8: var(--red-8);
  --accent-9: var(--red-9);
  --accent-10: var(--red-10);
  --accent-11: var(--red-11);
  --accent-12: var(--red-12);
  --accent-a1: var(--red-a1);
  --accent-a2: var(--red-a2);
  --accent-a3: var(--red-a3);
  --accent-a4: var(--red-a4);
  --accent-a5: var(--red-a5);
  --accent-a6: var(--red-a6);
  --accent-a7: var(--red-a7);
  --accent-a8: var(--red-a8);
  --accent-a9: var(--red-a9);
  --accent-a10: var(--red-a10);
  --accent-a11: var(--red-a11);
  --accent-a12: var(--red-a12);
  --accent-contrast: var(--red-contrast);
  --accent-surface: var(--red-surface);
  --accent-indicator: var(--red-indicator);
  --accent-track: var(--red-track);
}
[data-accent-color='ruby'] {
  --accent-1: var(--ruby-1);
  --accent-2: var(--ruby-2);
  --accent-3: var(--ruby-3);
  --accent-4: var(--ruby-4);
  --accent-5: var(--ruby-5);
  --accent-6: var(--ruby-6);
  --accent-7: var(--ruby-7);
  --accent-8: var(--ruby-8);
  --accent-9: var(--ruby-9);
  --accent-10: var(--ruby-10);
  --accent-11: var(--ruby-11);
  --accent-12: var(--ruby-12);
  --accent-a1: var(--ruby-a1);
  --accent-a2: var(--ruby-a2);
  --accent-a3: var(--ruby-a3);
  --accent-a4: var(--ruby-a4);
  --accent-a5: var(--ruby-a5);
  --accent-a6: var(--ruby-a6);
  --accent-a7: var(--ruby-a7);
  --accent-a8: var(--ruby-a8);
  --accent-a9: var(--ruby-a9);
  --accent-a10: var(--ruby-a10);
  --accent-a11: var(--ruby-a11);
  --accent-a12: var(--ruby-a12);
  --accent-contrast: var(--ruby-contrast);
  --accent-surface: var(--ruby-surface);
  --accent-indicator: var(--ruby-indicator);
  --accent-track: var(--ruby-track);
}
[data-accent-color='sky'] {
  --accent-1: var(--sky-1);
  --accent-2: var(--sky-2);
  --accent-3: var(--sky-3);
  --accent-4: var(--sky-4);
  --accent-5: var(--sky-5);
  --accent-6: var(--sky-6);
  --accent-7: var(--sky-7);
  --accent-8: var(--sky-8);
  --accent-9: var(--sky-9);
  --accent-10: var(--sky-10);
  --accent-11: var(--sky-11);
  --accent-12: var(--sky-12);
  --accent-a1: var(--sky-a1);
  --accent-a2: var(--sky-a2);
  --accent-a3: var(--sky-a3);
  --accent-a4: var(--sky-a4);
  --accent-a5: var(--sky-a5);
  --accent-a6: var(--sky-a6);
  --accent-a7: var(--sky-a7);
  --accent-a8: var(--sky-a8);
  --accent-a9: var(--sky-a9);
  --accent-a10: var(--sky-a10);
  --accent-a11: var(--sky-a11);
  --accent-a12: var(--sky-a12);
  --accent-contrast: var(--sky-contrast);
  --accent-surface: var(--sky-surface);
  --accent-indicator: var(--sky-indicator);
  --accent-track: var(--sky-track);
}
[data-accent-color='teal'] {
  --accent-1: var(--teal-1);
  --accent-2: var(--teal-2);
  --accent-3: var(--teal-3);
  --accent-4: var(--teal-4);
  --accent-5: var(--teal-5);
  --accent-6: var(--teal-6);
  --accent-7: var(--teal-7);
  --accent-8: var(--teal-8);
  --accent-9: var(--teal-9);
  --accent-10: var(--teal-10);
  --accent-11: var(--teal-11);
  --accent-12: var(--teal-12);
  --accent-a1: var(--teal-a1);
  --accent-a2: var(--teal-a2);
  --accent-a3: var(--teal-a3);
  --accent-a4: var(--teal-a4);
  --accent-a5: var(--teal-a5);
  --accent-a6: var(--teal-a6);
  --accent-a7: var(--teal-a7);
  --accent-a8: var(--teal-a8);
  --accent-a9: var(--teal-a9);
  --accent-a10: var(--teal-a10);
  --accent-a11: var(--teal-a11);
  --accent-a12: var(--teal-a12);
  --accent-contrast: var(--teal-contrast);
  --accent-surface: var(--teal-surface);
  --accent-indicator: var(--teal-indicator);
  --accent-track: var(--teal-track);
}
[data-accent-color='tomato'] {
  --accent-1: var(--tomato-1);
  --accent-2: var(--tomato-2);
  --accent-3: var(--tomato-3);
  --accent-4: var(--tomato-4);
  --accent-5: var(--tomato-5);
  --accent-6: var(--tomato-6);
  --accent-7: var(--tomato-7);
  --accent-8: var(--tomato-8);
  --accent-9: var(--tomato-9);
  --accent-10: var(--tomato-10);
  --accent-11: var(--tomato-11);
  --accent-12: var(--tomato-12);
  --accent-a1: var(--tomato-a1);
  --accent-a2: var(--tomato-a2);
  --accent-a3: var(--tomato-a3);
  --accent-a4: var(--tomato-a4);
  --accent-a5: var(--tomato-a5);
  --accent-a6: var(--tomato-a6);
  --accent-a7: var(--tomato-a7);
  --accent-a8: var(--tomato-a8);
  --accent-a9: var(--tomato-a9);
  --accent-a10: var(--tomato-a10);
  --accent-a11: var(--tomato-a11);
  --accent-a12: var(--tomato-a12);
  --accent-contrast: var(--tomato-contrast);
  --accent-surface: var(--tomato-surface);
  --accent-indicator: var(--tomato-indicator);
  --accent-track: var(--tomato-track);
}
[data-accent-color='violet'] {
  --accent-1: var(--violet-1);
  --accent-2: var(--violet-2);
  --accent-3: var(--violet-3);
  --accent-4: var(--violet-4);
  --accent-5: var(--violet-5);
  --accent-6: var(--violet-6);
  --accent-7: var(--violet-7);
  --accent-8: var(--violet-8);
  --accent-9: var(--violet-9);
  --accent-10: var(--violet-10);
  --accent-11: var(--violet-11);
  --accent-12: var(--violet-12);
  --accent-a1: var(--violet-a1);
  --accent-a2: var(--violet-a2);
  --accent-a3: var(--violet-a3);
  --accent-a4: var(--violet-a4);
  --accent-a5: var(--violet-a5);
  --accent-a6: var(--violet-a6);
  --accent-a7: var(--violet-a7);
  --accent-a8: var(--violet-a8);
  --accent-a9: var(--violet-a9);
  --accent-a10: var(--violet-a10);
  --accent-a11: var(--violet-a11);
  --accent-a12: var(--violet-a12);
  --accent-contrast: var(--violet-contrast);
  --accent-surface: var(--violet-surface);
  --accent-indicator: var(--violet-indicator);
  --accent-track: var(--violet-track);
}
[data-accent-color='yellow'] {
  --accent-1: var(--yellow-1);
  --accent-2: var(--yellow-2);
  --accent-3: var(--yellow-3);
  --accent-4: var(--yellow-4);
  --accent-5: var(--yellow-5);
  --accent-6: var(--yellow-6);
  --accent-7: var(--yellow-7);
  --accent-8: var(--yellow-8);
  --accent-9: var(--yellow-9);
  --accent-10: var(--yellow-10);
  --accent-11: var(--yellow-11);
  --accent-12: var(--yellow-12);
  --accent-a1: var(--yellow-a1);
  --accent-a2: var(--yellow-a2);
  --accent-a3: var(--yellow-a3);
  --accent-a4: var(--yellow-a4);
  --accent-a5: var(--yellow-a5);
  --accent-a6: var(--yellow-a6);
  --accent-a7: var(--yellow-a7);
  --accent-a8: var(--yellow-a8);
  --accent-a9: var(--yellow-a9);
  --accent-a10: var(--yellow-a10);
  --accent-a11: var(--yellow-a11);
  --accent-a12: var(--yellow-a12);
  --accent-contrast: var(--yellow-contrast);
  --accent-surface: var(--yellow-surface);
  --accent-indicator: var(--yellow-indicator);
  --accent-track: var(--yellow-track);
}
.radix-themes:where([data-gray-color='mauve']) {
  --gray-1: var(--mauve-1);
  --gray-2: var(--mauve-2);
  --gray-3: var(--mauve-3);
  --gray-4: var(--mauve-4);
  --gray-5: var(--mauve-5);
  --gray-6: var(--mauve-6);
  --gray-7: var(--mauve-7);
  --gray-8: var(--mauve-8);
  --gray-9: var(--mauve-9);
  --gray-10: var(--mauve-10);
  --gray-11: var(--mauve-11);
  --gray-12: var(--mauve-12);
  --gray-a1: var(--mauve-a1);
  --gray-a2: var(--mauve-a2);
  --gray-a3: var(--mauve-a3);
  --gray-a4: var(--mauve-a4);
  --gray-a5: var(--mauve-a5);
  --gray-a6: var(--mauve-a6);
  --gray-a7: var(--mauve-a7);
  --gray-a8: var(--mauve-a8);
  --gray-a9: var(--mauve-a9);
  --gray-a10: var(--mauve-a10);
  --gray-a11: var(--mauve-a11);
  --gray-a12: var(--mauve-a12);
  --gray-contrast: var(--mauve-contrast);
  --gray-surface: var(--mauve-surface);
  --gray-indicator: var(--mauve-indicator);
  --gray-track: var(--mauve-track);
}
.radix-themes:where([data-gray-color='olive']) {
  --gray-1: var(--olive-1);
  --gray-2: var(--olive-2);
  --gray-3: var(--olive-3);
  --gray-4: var(--olive-4);
  --gray-5: var(--olive-5);
  --gray-6: var(--olive-6);
  --gray-7: var(--olive-7);
  --gray-8: var(--olive-8);
  --gray-9: var(--olive-9);
  --gray-10: var(--olive-10);
  --gray-11: var(--olive-11);
  --gray-12: var(--olive-12);
  --gray-a1: var(--olive-a1);
  --gray-a2: var(--olive-a2);
  --gray-a3: var(--olive-a3);
  --gray-a4: var(--olive-a4);
  --gray-a5: var(--olive-a5);
  --gray-a6: var(--olive-a6);
  --gray-a7: var(--olive-a7);
  --gray-a8: var(--olive-a8);
  --gray-a9: var(--olive-a9);
  --gray-a10: var(--olive-a10);
  --gray-a11: var(--olive-a11);
  --gray-a12: var(--olive-a12);
  --gray-contrast: var(--olive-contrast);
  --gray-surface: var(--olive-surface);
  --gray-indicator: var(--olive-indicator);
  --gray-track: var(--olive-track);
}
.radix-themes:where([data-gray-color='sage']) {
  --gray-1: var(--sage-1);
  --gray-2: var(--sage-2);
  --gray-3: var(--sage-3);
  --gray-4: var(--sage-4);
  --gray-5: var(--sage-5);
  --gray-6: var(--sage-6);
  --gray-7: var(--sage-7);
  --gray-8: var(--sage-8);
  --gray-9: var(--sage-9);
  --gray-10: var(--sage-10);
  --gray-11: var(--sage-11);
  --gray-12: var(--sage-12);
  --gray-a1: var(--sage-a1);
  --gray-a2: var(--sage-a2);
  --gray-a3: var(--sage-a3);
  --gray-a4: var(--sage-a4);
  --gray-a5: var(--sage-a5);
  --gray-a6: var(--sage-a6);
  --gray-a7: var(--sage-a7);
  --gray-a8: var(--sage-a8);
  --gray-a9: var(--sage-a9);
  --gray-a10: var(--sage-a10);
  --gray-a11: var(--sage-a11);
  --gray-a12: var(--sage-a12);
  --gray-contrast: var(--sage-contrast);
  --gray-surface: var(--sage-surface);
  --gray-indicator: var(--sage-indicator);
  --gray-track: var(--sage-track);
}
.radix-themes:where([data-gray-color='sand']) {
  --gray-1: var(--sand-1);
  --gray-2: var(--sand-2);
  --gray-3: var(--sand-3);
  --gray-4: var(--sand-4);
  --gray-5: var(--sand-5);
  --gray-6: var(--sand-6);
  --gray-7: var(--sand-7);
  --gray-8: var(--sand-8);
  --gray-9: var(--sand-9);
  --gray-10: var(--sand-10);
  --gray-11: var(--sand-11);
  --gray-12: var(--sand-12);
  --gray-a1: var(--sand-a1);
  --gray-a2: var(--sand-a2);
  --gray-a3: var(--sand-a3);
  --gray-a4: var(--sand-a4);
  --gray-a5: var(--sand-a5);
  --gray-a6: var(--sand-a6);
  --gray-a7: var(--sand-a7);
  --gray-a8: var(--sand-a8);
  --gray-a9: var(--sand-a9);
  --gray-a10: var(--sand-a10);
  --gray-a11: var(--sand-a11);
  --gray-a12: var(--sand-a12);
  --gray-contrast: var(--sand-contrast);
  --gray-surface: var(--sand-surface);
  --gray-indicator: var(--sand-indicator);
  --gray-track: var(--sand-track);
}
.radix-themes:where([data-gray-color='slate']) {
  --gray-1: var(--slate-1);
  --gray-2: var(--slate-2);
  --gray-3: var(--slate-3);
  --gray-4: var(--slate-4);
  --gray-5: var(--slate-5);
  --gray-6: var(--slate-6);
  --gray-7: var(--slate-7);
  --gray-8: var(--slate-8);
  --gray-9: var(--slate-9);
  --gray-10: var(--slate-10);
  --gray-11: var(--slate-11);
  --gray-12: var(--slate-12);
  --gray-a1: var(--slate-a1);
  --gray-a2: var(--slate-a2);
  --gray-a3: var(--slate-a3);
  --gray-a4: var(--slate-a4);
  --gray-a5: var(--slate-a5);
  --gray-a6: var(--slate-a6);
  --gray-a7: var(--slate-a7);
  --gray-a8: var(--slate-a8);
  --gray-a9: var(--slate-a9);
  --gray-a10: var(--slate-a10);
  --gray-a11: var(--slate-a11);
  --gray-a12: var(--slate-a12);
  --gray-contrast: var(--slate-contrast);
  --gray-surface: var(--slate-surface);
  --gray-indicator: var(--slate-indicator);
  --gray-track: var(--slate-track);
}
.radix-themes {
  --cursor-button: default;
  --cursor-checkbox: default;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: default;
  --cursor-radio: default;
  --cursor-slider-thumb: default;
  --cursor-slider-thumb-active: default;
  --cursor-switch: default;
  --space-1: calc(4px * var(--scaling));
  --space-2: calc(8px * var(--scaling));
  --space-3: calc(12px * var(--scaling));
  --space-4: calc(16px * var(--scaling));
  --space-5: calc(24px * var(--scaling));
  --space-6: calc(32px * var(--scaling));
  --space-7: calc(40px * var(--scaling));
  --space-8: calc(48px * var(--scaling));
  --space-9: calc(64px * var(--scaling));
  --font-size-1: calc(12px * var(--scaling));
  --font-size-2: calc(14px * var(--scaling));
  --font-size-3: calc(16px * var(--scaling));
  --font-size-4: calc(18px * var(--scaling));
  --font-size-5: calc(20px * var(--scaling));
  --font-size-6: calc(24px * var(--scaling));
  --font-size-7: calc(28px * var(--scaling));
  --font-size-8: calc(35px * var(--scaling));
  --font-size-9: calc(60px * var(--scaling));
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-1: calc(16px * var(--scaling));
  --line-height-2: calc(20px * var(--scaling));
  --line-height-3: calc(24px * var(--scaling));
  --line-height-4: calc(26px * var(--scaling));
  --line-height-5: calc(28px * var(--scaling));
  --line-height-6: calc(30px * var(--scaling));
  --line-height-7: calc(36px * var(--scaling));
  --line-height-8: calc(40px * var(--scaling));
  --line-height-9: calc(60px * var(--scaling));
  --letter-spacing-1: 0.0025em;
  --letter-spacing-2: 0em;
  --letter-spacing-3: 0em;
  --letter-spacing-4: -0.0025em;
  --letter-spacing-5: -0.005em;
  --letter-spacing-6: -0.00625em;
  --letter-spacing-7: -0.0075em;
  --letter-spacing-8: -0.01em;
  --letter-spacing-9: -0.025em;
  --default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)', Roboto, 'Helvetica Neue', 'Open Sans (Custom)', system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --default-font-size: var(--font-size-3);
  --default-font-style: normal;
  --default-font-weight: var(--font-weight-regular);
  --default-line-height: 1.5;
  --default-letter-spacing: 0em;
  --default-leading-trim-start: 0.42em;
  --default-leading-trim-end: 0.36em;
  --heading-font-family: var(--default-font-family);
  --heading-font-size-adjust: 1;
  --heading-font-style: normal;
  --heading-leading-trim-start: var(--default-leading-trim-start);
  --heading-leading-trim-end: var(--default-leading-trim-end);
  --heading-letter-spacing: 0em;
  --heading-line-height-1: calc(16px * var(--scaling));
  --heading-line-height-2: calc(18px * var(--scaling));
  --heading-line-height-3: calc(22px * var(--scaling));
  --heading-line-height-4: calc(24px * var(--scaling));
  --heading-line-height-5: calc(26px * var(--scaling));
  --heading-line-height-6: calc(30px * var(--scaling));
  --heading-line-height-7: calc(36px * var(--scaling));
  --heading-line-height-8: calc(40px * var(--scaling));
  --heading-line-height-9: calc(60px * var(--scaling));
  --code-font-family: 'Menlo', 'Consolas (Custom)', 'Bitstream Vera Sans Mono', monospace, 'Apple Color Emoji', 'Segoe UI Emoji';
  --code-font-size-adjust: 0.95;
  --code-font-style: normal;
  --code-font-weight: inherit;
  --code-letter-spacing: -0.007em;
  --code-padding-top: 0.1em;
  --code-padding-bottom: 0.1em;
  --code-padding-left: 0.25em;
  --code-padding-right: 0.25em;
  --strong-font-family: var(--default-font-family);
  --strong-font-size-adjust: 1;
  --strong-font-style: inherit;
  --strong-font-weight: var(--font-weight-bold);
  --strong-letter-spacing: 0em;
  --em-font-family: 'Times New Roman', 'Times', serif;
  --em-font-size-adjust: 1.18;
  --em-font-style: italic;
  --em-font-weight: inherit;
  --em-letter-spacing: -0.025em;
  --quote-font-family: 'Times New Roman', 'Times', serif;
  --quote-font-size-adjust: 1.18;
  --quote-font-style: italic;
  --quote-font-weight: inherit;
  --quote-letter-spacing: -0.025em;
  --tab-active-letter-spacing: -0.01em;
  --tab-active-word-spacing: 0em;
  --tab-inactive-letter-spacing: 0em;
  --tab-inactive-word-spacing: 0em;
  overflow-wrap: break-word;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--default-font-weight);
  font-style: var(--default-font-style);
  line-height: var(--default-line-height);
  letter-spacing: var(--default-letter-spacing);
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --container-1: 448px;
  --container-2: 688px;
  --container-3: 880px;
  --container-4: 1136px;
  --scrollarea-scrollbar-horizontal-margin-top: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-left: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-right: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-top: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-left: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-right: var(--space-1);
  --segmented-control-transition-duration: 100ms;
  --spinner-animation-duration: 800ms;
  --spinner-opacity: 0.65;
  color: var(--gray-12);
}
.radix-themes:where([data-scaling='90%']) {
  --scaling: 0.9;
}
.radix-themes:where([data-scaling='95%']) {
  --scaling: 0.95;
}
.radix-themes:where([data-scaling='100%']) {
  --scaling: 1;
}
.radix-themes:where([data-scaling='105%']) {
  --scaling: 1.05;
}
.radix-themes:where([data-scaling='110%']) {
  --scaling: 1.1;
}
[data-radius] {
  --radius-1: calc(3px * var(--scaling) * var(--radius-factor));
  --radius-2: calc(4px * var(--scaling) * var(--radius-factor));
  --radius-3: calc(6px * var(--scaling) * var(--radius-factor));
  --radius-4: calc(8px * var(--scaling) * var(--radius-factor));
  --radius-5: calc(12px * var(--scaling) * var(--radius-factor));
  --radius-6: calc(16px * var(--scaling) * var(--radius-factor));
}
[data-radius='none'] {
  --radius-factor: 0;
  --radius-full: 0px;
  --radius-thumb: 0.5px;
}
[data-radius='small'] {
  --radius-factor: 0.75;
  --radius-full: 0px;
  --radius-thumb: 0.5px;
}
[data-radius='medium'] {
  --radius-factor: 1;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}
[data-radius='large'] {
  --radius-factor: 1.5;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}
[data-radius='full'] {
  --radius-factor: 1.5;
  --radius-full: 9999px;
  --radius-thumb: 9999px;
}
@supports (color: color-mix(in oklab, white, black)) {
  :where(.radix-themes) {
    --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
    --base-card-surface-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a5), var(--gray-5) 25%);
    --base-card-surface-hover-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a7), var(--gray-7) 25%);
    --base-card-surface-active-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a4), var(--gray-4) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
  }
}
@supports (color: color-mix(in oklab, white, black)) {
  :is(.dark, .dark-theme),
  :is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 300;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Semilight'), local('Segoe UI');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 300;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Semilight Italic'), local('Segoe UI Italic');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 400;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 400;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Italic');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 500;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Semibold'), local('Segoe UI');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 500;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Semibold Italic'), local('Segoe UI Italic');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 700;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Bold');
}
@font-face {
  font-family: 'Segoe UI (Custom)';
  font-weight: 700;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local('Segoe UI Bold Italic');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 300;
  descent-override: 35%;
  src: local('Open Sans Light'), local('Open Sans Regular');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 300;
  font-style: italic;
  descent-override: 35%;
  src: local('Open Sans Light Italic'), local('Open Sans Italic');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 400;
  descent-override: 35%;
  src: local('Open Sans Regular');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 400;
  font-style: italic;
  descent-override: 35%;
  src: local('Open Sans Italic');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 500;
  descent-override: 35%;
  src: local('Open Sans Medium'), local('Open Sans Regular');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 500;
  font-style: italic;
  descent-override: 35%;
  src: local('Open Sans Medium Italic'), local('Open Sans Italic');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 700;
  descent-override: 35%;
  src: local('Open Sans Bold');
}
@font-face {
  font-family: 'Open Sans (Custom)';
  font-weight: 700;
  font-style: italic;
  descent-override: 35%;
  src: local('Open Sans Bold Italic');
}
@font-face {
  font-family: 'Consolas (Custom)';
  font-weight: 400;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local('Consolas');
}
@font-face {
  font-family: 'Consolas (Custom)';
  font-weight: 400;
  font-style: italic;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local('Consolas Italic');
}
@font-face {
  font-family: 'Consolas (Custom)';
  font-weight: 700;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local('Consolas Bold');
}
@font-face {
  font-family: 'Consolas (Custom)';
  font-weight: 700;
  font-style: italic;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local('Consolas Bold Italic');
}
.rt-reset:where(body, blockquote, dl, dd, figure, p) {
  margin: 0;
}
.rt-reset:where(address, b, cite, code, dfn, em, i, kbd, q, samp, small, strong, var) {
  font: unset;
}
.rt-reset:where(h1, h2, h3, h4, h5, h6) {
  font: unset;
  margin: 0;
}
.rt-reset:where(a) {
  all: unset;
  -webkit-tap-highlight-color: transparent;
}
.rt-reset:where(
      button,
      select,

      
      [type='button'],
      [type='image'],
      [type='reset'],
      [type='submit'],

      
      [type='checkbox'],
      [type='color'],
      [type='radio'],
      [type='range']
    ) {
  all: unset;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
}
.rt-reset:where(label) {
  -webkit-tap-highlight-color: transparent;
}
.rt-reset:where(select) {
  font-weight: normal;
  font-style: normal;
  text-align: start;
}
.rt-reset:where(
      textarea,
      input:not(
          
          [type='button'],
          [type='image'],
          [type='reset'],
          [type='submit'],

          
          [type='checkbox'],
          [type='color'],
          [type='radio'],
          [type='range']
        )
    ) {
  all: unset;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  font-weight: normal;
  font-style: normal;
  text-align: start;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
  white-space: pre-wrap;
}
.rt-reset:where(:focus) {
  outline: none;
}
.rt-reset::placeholder {
  color: unset;
  opacity: unset;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
}
.rt-reset:where(table) {
  all: unset;
  display: table;
  text-indent: initial;
}
.rt-reset:where(caption) {
  text-align: inherit;
}
.rt-reset:where(td) {
  padding: 0;
}
.rt-reset:where(th) {
  font-weight: unset;
  text-align: inherit;
  padding: 0;
}
.rt-reset:where(abbr, acronym) {
  text-decoration: none;
}
.rt-reset:where(canvas, object, picture, summary) {
  display: block;
}
.rt-reset:where(del, s) {
  text-decoration: unset;
}
.rt-reset:where(fieldset, hr) {
  all: unset;
  display: block;
}
.rt-reset:where(legend) {
  padding: 0;
  border: none;
  cursor: default;
}
.rt-reset:where(li) {
  display: block;
  text-align: unset;
}
.rt-reset:where(ol, ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rt-reset:where(iframe) {
  display: block;
  border: none;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.rt-reset:where(ins, u) {
  text-decoration: none;
}
.rt-reset:where(img) {
  display: block;
  max-width: 100%;
}
.rt-reset:where(svg) {
  display: block;
  max-width: 100%;
  flex-shrink: 0;
}
.rt-reset:where(mark) {
  all: unset;
}
.rt-reset:where(pre) {
  font: unset;
  margin: unset;
}
.rt-reset:where(q)::before,
  .rt-reset:where(q)::after {
  content: '';
}
.rt-reset:where(sub, sup) {
  font: unset;
  vertical-align: unset;
}
.rt-reset:where(details) ::marker,
  .rt-reset:where(summary)::marker {
  content: none;
}
.rt-reset:where(video) {
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.rt-reset:where(:-webkit-any-link) {
  cursor: var(--cursor-link);
}
.rt-reset:where(:any-link) {
  cursor: var(--cursor-link);
}
.rt-reset:where(button) {
  cursor: var(--cursor-button);
}
.rt-reset:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}
.rt-reset:where(input[type='checkbox']) {
  cursor: var(--cursor-checkbox);
}
.rt-reset:where(input[type='radio']) {
  cursor: var(--cursor-radio);
}
.rt-reset,
  .rt-reset::before,
  .rt-reset::after {
  box-sizing: border-box;
}
@keyframes rt-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes rt-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes rt-slide-from-top {
  from {
    transform: translateY(4px) scale(0.97);
  }
  to {
    transform: translateY(0) scale(1);
  }
}
@keyframes rt-slide-to-top {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY(4px) scale(0.97);
  }
}
@keyframes rt-slide-from-bottom {
  from {
    transform: translateY(-4px) scale(0.97);
  }
  to {
    transform: translateY(0) scale(1);
  }
}
@keyframes rt-slide-to-bottom {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY(-4px) scale(0.97);
  }
}
@keyframes rt-slide-from-left {
  from {
    transform: translateX(4px) scale(0.97);
  }
  to {
    transform: translateX(0) scale(1);
  }
}
@keyframes rt-slide-to-left {
  from {
    transform: translateX(0) scale(1);
  }
  to {
    transform: translateX(4px) scale(0.97);
  }
}
@keyframes rt-slide-from-right {
  from {
    transform: translateX(-4px) scale(0.97);
  }
  to {
    transform: translateX(0) scale(1);
  }
}
@keyframes rt-slide-to-right {
  from {
    transform: translateX(0) scale(1);
  }
  to {
    transform: translateX(-4px) scale(0.97);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .rt-PopperContent {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }
  .rt-PopperContent:where([data-state='open']) {
    animation-duration: 160ms;
  }
  .rt-PopperContent:where([data-state='open']):where([data-side='top']) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }
  .rt-PopperContent:where([data-state='open']):where([data-side='bottom']) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }
  .rt-PopperContent:where([data-state='open']):where([data-side='left']) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }
  .rt-PopperContent:where([data-state='open']):where([data-side='right']) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }
  .rt-PopperContent:where([data-state='closed']) {
    animation-duration: 100ms;
  }
  .rt-PopperContent:where([data-state='closed']):where([data-side='top']) {
    animation-name: rt-slide-to-top, rt-fade-out;
  }
  .rt-PopperContent:where([data-state='closed']):where([data-side='bottom']) {
    animation-name: rt-slide-to-bottom, rt-fade-out;
  }
  .rt-PopperContent:where([data-state='closed']):where([data-side='left']) {
    animation-name: rt-slide-to-left, rt-fade-out;
  }
  .rt-PopperContent:where([data-state='closed']):where([data-side='right']) {
    animation-name: rt-slide-to-right, rt-fade-out;
  }
}
.rt-Box {
  box-sizing: border-box;
  display: block;
}
.rt-Flex {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.rt-Grid {
  box-sizing: border-box;
  display: grid;
  align-items: stretch;
  justify-content: flex-start;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: none;
}
.rt-Section {
  box-sizing: border-box;
  flex-shrink: 0;
}
.rt-Section:where(.rt-r-size-1) {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}
.rt-Section:where(.rt-r-size-2) {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}
.rt-Section:where(.rt-r-size-3) {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}
.rt-Section:where(.rt-r-size-4) {
  padding-top: calc(80px * var(--scaling));
  padding-bottom: calc(80px * var(--scaling));
}
@media (min-width: 520px) {
  .rt-Section:where(.xs\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .rt-Section:where(.xs\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .rt-Section:where(.xs\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .rt-Section:where(.xs\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}
@media (min-width: 768px) {
  .rt-Section:where(.sm\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .rt-Section:where(.sm\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .rt-Section:where(.sm\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .rt-Section:where(.sm\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}
@media (min-width: 1024px) {
  .rt-Section:where(.md\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .rt-Section:where(.md\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .rt-Section:where(.md\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .rt-Section:where(.md\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}
@media (min-width: 1280px) {
  .rt-Section:where(.lg\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .rt-Section:where(.lg\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .rt-Section:where(.lg\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .rt-Section:where(.lg\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}
@media (min-width: 1640px) {
  .rt-Section:where(.xl\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .rt-Section:where(.xl\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .rt-Section:where(.xl\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .rt-Section:where(.xl\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}
.rt-Container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
}
.rt-ContainerInner {
  width: 100%;
}
:where(.rt-Container.rt-r-size-1) .rt-ContainerInner {
  max-width: var(--container-1);
}
:where(.rt-Container.rt-r-size-2) .rt-ContainerInner {
  max-width: var(--container-2);
}
:where(.rt-Container.rt-r-size-3) .rt-ContainerInner {
  max-width: var(--container-3);
}
:where(.rt-Container.rt-r-size-4) .rt-ContainerInner {
  max-width: var(--container-4);
}
@media (min-width: 520px) {
  :where(.rt-Container.xs\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }
  :where(.rt-Container.xs\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }
  :where(.rt-Container.xs\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }
  :where(.rt-Container.xs\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}
@media (min-width: 768px) {
  :where(.rt-Container.sm\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }
  :where(.rt-Container.sm\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }
  :where(.rt-Container.sm\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }
  :where(.rt-Container.sm\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}
@media (min-width: 1024px) {
  :where(.rt-Container.md\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }
  :where(.rt-Container.md\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }
  :where(.rt-Container.md\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }
  :where(.rt-Container.md\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}
@media (min-width: 1280px) {
  :where(.rt-Container.lg\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }
  :where(.rt-Container.lg\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }
  :where(.rt-Container.lg\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }
  :where(.rt-Container.lg\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}
@media (min-width: 1640px) {
  :where(.rt-Container.xl\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }
  :where(.rt-Container.xl\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }
  :where(.rt-Container.xl\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }
  :where(.rt-Container.xl\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}
.rt-Skeleton {
  --skeleton-radius: var(--skeleton-radius-override);
  --skeleton-height: var(--skeleton-height-override);
  border-radius: var(--radius-1);
  animation: rt-skeleton-pulse 1000ms infinite alternate-reverse !important;
  background-image: none !important;
  background-clip: border-box !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-decoration-break: clone !important;
          box-decoration-break: clone !important;
  color: transparent !important;
  outline: none !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
          -moz-user-select: none !important;
       user-select: none !important;
  cursor: default !important;
}
.rt-Skeleton:where([data-inline-skeleton]) {
  line-height: 0;
  font-family: Arial, sans-serif !important;
}
:where(.rt-Skeleton:empty) {
  display: block;
  height: var(--space-3);
}
.rt-Skeleton > *,
.rt-Skeleton::after,
.rt-Skeleton::before {
  visibility: hidden !important;
}
@keyframes rt-skeleton-pulse {
  from {
    background-color: var(--gray-a3);
  }
  to {
    background-color: var(--gray-a4);
  }
}
.rt-Text {
  line-height: var(--line-height, var(--default-line-height));
  letter-spacing: var(--letter-spacing, inherit);
}
:where(.rt-Text) {
  margin: 0;
}
.rt-Text:where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-Text:where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Text:where(.rt-high-contrast) {
  color: var(--accent-12);
}
@media (pointer: coarse) {
  .rt-Text:where(label) {
    -webkit-tap-highlight-color: transparent;
  }
  .rt-Text:where(label):where(:active) {
    outline: 0.75em solid var(--gray-a4);
    outline-offset: -0.6em;
  }
}
.rt-Text:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  --line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}
.rt-Text:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}
.rt-Text:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}
.rt-Text:where(.rt-r-size-4) {
  font-size: var(--font-size-4);
  --line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}
.rt-Text:where(.rt-r-size-5) {
  font-size: var(--font-size-5);
  --line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}
.rt-Text:where(.rt-r-size-6) {
  font-size: var(--font-size-6);
  --line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}
.rt-Text:where(.rt-r-size-7) {
  font-size: var(--font-size-7);
  --line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}
.rt-Text:where(.rt-r-size-8) {
  font-size: var(--font-size-8);
  --line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}
.rt-Text:where(.rt-r-size-9) {
  font-size: var(--font-size-9);
  --line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}
@media (min-width: 520px) {
  .rt-Text:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Text:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Text:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Text:where(.xs\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Text:where(.xs\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Text:where(.xs\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Text:where(.xs\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Text:where(.xs\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Text:where(.xs\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 768px) {
  .rt-Text:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Text:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Text:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Text:where(.sm\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Text:where(.sm\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Text:where(.sm\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Text:where(.sm\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Text:where(.sm\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Text:where(.sm\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1024px) {
  .rt-Text:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Text:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Text:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Text:where(.md\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Text:where(.md\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Text:where(.md\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Text:where(.md\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Text:where(.md\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Text:where(.md\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1280px) {
  .rt-Text:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Text:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Text:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Text:where(.lg\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Text:where(.lg\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Text:where(.lg\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Text:where(.lg\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Text:where(.lg\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Text:where(.lg\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1640px) {
  .rt-Text:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Text:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Text:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Text:where(.xl\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Text:where(.xl\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Text:where(.xl\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Text:where(.xl\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Text:where(.xl\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Text:where(.xl\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
.rt-BaseDialogOverlay {
  position: fixed;
  inset: 0;
}
.rt-BaseDialogOverlay::before {
  position: fixed;
  content: '';
  inset: 0;
  background-color: var(--color-overlay);
}
.rt-BaseDialogScroll {
  display: flex;
  overflow: auto;
  position: absolute;
  inset: 0;
}
.rt-BaseDialogScrollPadding {
  flex-grow: 1;
  margin: auto;
  padding-top: var(--space-6);
  padding-bottom: max(var(--space-6), 6vh);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.rt-BaseDialogScrollPadding:where(.rt-r-align-start) {
  margin-top: 0;
}
.rt-BaseDialogScrollPadding:where(.rt-r-align-center) {
  margin-top: auto;
}
@media (min-width: 520px) {
  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-start) {
    margin-top: 0;
  }
  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-center) {
    margin-top: auto;
  }
}
@media (min-width: 768px) {
  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-start) {
    margin-top: 0;
  }
  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-center) {
    margin-top: auto;
  }
}
@media (min-width: 1024px) {
  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-start) {
    margin-top: 0;
  }
  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-center) {
    margin-top: auto;
  }
}
@media (min-width: 1280px) {
  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-start) {
    margin-top: 0;
  }
  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-center) {
    margin-top: auto;
  }
}
@media (min-width: 1640px) {
  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-start) {
    margin-top: 0;
  }
  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-center) {
    margin-top: auto;
  }
}
.rt-BaseDialogContent {
  margin: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  --inset-padding-top: var(--dialog-content-padding);
  --inset-padding-right: var(--dialog-content-padding);
  --inset-padding-bottom: var(--dialog-content-padding);
  --inset-padding-left: var(--dialog-content-padding);
  padding: var(--dialog-content-padding);
  box-sizing: border-box;
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-6);
  outline: none;
}
.rt-BaseDialogContent:where(.rt-r-size-1) {
  --dialog-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}
.rt-BaseDialogContent:where(.rt-r-size-2) {
  --dialog-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}
.rt-BaseDialogContent:where(.rt-r-size-3) {
  --dialog-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}
.rt-BaseDialogContent:where(.rt-r-size-4) {
  --dialog-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}
@media (min-width: 520px) {
  .rt-BaseDialogContent:where(.xs\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.xs\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.xs\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-BaseDialogContent:where(.xs\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 768px) {
  .rt-BaseDialogContent:where(.sm\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.sm\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.sm\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-BaseDialogContent:where(.sm\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1024px) {
  .rt-BaseDialogContent:where(.md\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.md\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.md\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-BaseDialogContent:where(.md\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1280px) {
  .rt-BaseDialogContent:where(.lg\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.lg\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.lg\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-BaseDialogContent:where(.lg\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1640px) {
  .rt-BaseDialogContent:where(.xl\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.xl\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-BaseDialogContent:where(.xl\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-BaseDialogContent:where(.xl\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (prefers-reduced-motion: no-preference) {
  @keyframes rt-dialog-overlay-no-op {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes rt-dialog-content-show {
    from {
      opacity: 0;
      transform: translateY(5px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }
  @keyframes rt-dialog-content-hide {
    from {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
    to {
      opacity: 0;
      transform: translateY(5px) scale(0.99);
    }
  }
  .rt-BaseDialogOverlay:where([data-state='closed']) {
    animation: rt-dialog-overlay-no-op 160ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .rt-BaseDialogOverlay:where([data-state='open'])::before {
    animation: rt-fade-in 200ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .rt-BaseDialogOverlay:where([data-state='closed'])::before {
    opacity: 0;
    animation: rt-fade-out 160ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .rt-BaseDialogContent:where([data-state='open']) {
    animation: rt-dialog-content-show 200ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .rt-BaseDialogContent:where([data-state='closed']) {
    opacity: 0;
    animation: rt-dialog-content-hide 100ms cubic-bezier(0.16, 1, 0.3, 1);
  }
}
.rt-AvatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex-shrink: 0;
}
.rt-AvatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
.rt-AvatarFallback {
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: inherit;
  text-transform: uppercase;
}
.rt-AvatarFallback:where(.rt-one-letter) {
  font-size: var(--avatar-fallback-one-letter-font-size);
}
.rt-AvatarFallback:where(.rt-two-letters) {
  font-size: var(--avatar-fallback-two-letters-font-size, var(--avatar-fallback-one-letter-font-size));
}
.rt-AvatarRoot:where(.rt-r-size-1) {
  --avatar-size: var(--space-5);
  --avatar-fallback-one-letter-font-size: var(--font-size-2);
  --avatar-fallback-two-letters-font-size: var(--font-size-1);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-1);
}
.rt-AvatarRoot:where(.rt-r-size-2) {
  --avatar-size: var(--space-6);
  --avatar-fallback-one-letter-font-size: var(--font-size-3);
  --avatar-fallback-two-letters-font-size: var(--font-size-2);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-2);
}
.rt-AvatarRoot:where(.rt-r-size-3) {
  --avatar-size: var(--space-7);
  --avatar-fallback-one-letter-font-size: var(--font-size-4);
  --avatar-fallback-two-letters-font-size: var(--font-size-3);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-3);
}
.rt-AvatarRoot:where(.rt-r-size-4) {
  --avatar-size: var(--space-8);
  --avatar-fallback-one-letter-font-size: var(--font-size-5);
  --avatar-fallback-two-letters-font-size: var(--font-size-4);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-4);
}
.rt-AvatarRoot:where(.rt-r-size-5) {
  --avatar-size: var(--space-9);
  --avatar-fallback-one-letter-font-size: var(--font-size-6);
  border-radius: max(var(--radius-4), var(--radius-full));
  letter-spacing: var(--letter-spacing-6);
}
.rt-AvatarRoot:where(.rt-r-size-6) {
  --avatar-size: 80px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}
.rt-AvatarRoot:where(.rt-r-size-7) {
  --avatar-size: 96px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}
.rt-AvatarRoot:where(.rt-r-size-8) {
  --avatar-size: 128px;
  --avatar-fallback-one-letter-font-size: var(--font-size-8);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-8);
}
.rt-AvatarRoot:where(.rt-r-size-9) {
  --avatar-size: 160px;
  --avatar-fallback-one-letter-font-size: var(--font-size-9);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-9);
}
@media (min-width: 520px) {
  .rt-AvatarRoot:where(.xs\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }
  .rt-AvatarRoot:where(.xs\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 768px) {
  .rt-AvatarRoot:where(.sm\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }
  .rt-AvatarRoot:where(.sm\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1024px) {
  .rt-AvatarRoot:where(.md\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }
  .rt-AvatarRoot:where(.md\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1280px) {
  .rt-AvatarRoot:where(.lg\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }
  .rt-AvatarRoot:where(.lg\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1640px) {
  .rt-AvatarRoot:where(.xl\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }
  .rt-AvatarRoot:where(.xl\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}
.rt-AvatarRoot:where(.rt-variant-solid) :where(.rt-AvatarFallback) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
.rt-AvatarRoot:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}
.rt-AvatarRoot:where(.rt-variant-soft) :where(.rt-AvatarFallback) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}
.rt-AvatarRoot:where(.rt-variant-soft):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  color: var(--accent-12);
}
.rt-Badge {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  flex-shrink: 0;
  line-height: 1;
  height: -moz-fit-content;
  height: fit-content;
}
.rt-Badge:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-1), var(--radius-full));
}
.rt-Badge:where(.rt-r-size-2) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: var(--space-1) var(--space-2);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-2), var(--radius-full));
}
.rt-Badge:where(.rt-r-size-3) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  padding: var(--space-1) calc(var(--space-2) * 1.25);
  gap: var(--space-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}
@media (min-width: 520px) {
  .rt-Badge:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-Badge:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-Badge:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}
@media (min-width: 768px) {
  .rt-Badge:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-Badge:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-Badge:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}
@media (min-width: 1024px) {
  .rt-Badge:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-Badge:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-Badge:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}
@media (min-width: 1280px) {
  .rt-Badge:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-Badge:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-Badge:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}
@media (min-width: 1640px) {
  .rt-Badge:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-Badge:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-Badge:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}
.rt-Badge:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
.rt-Badge:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}
.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}
.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}
.rt-Badge:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  color: var(--accent-a11);
}
.rt-Badge:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-Badge:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}
.rt-Badge:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-Badge:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}
.rt-Badge:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}
.rt-Blockquote {
  box-sizing: border-box;
  border-left: max(var(--space-1), 0.25em) solid var(--accent-a6);
  padding-left: min(var(--space-5), max(var(--space-3), 0.5em));
}
.rt-BaseButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  vertical-align: top;
  font-family: var(--default-font-family);
  font-style: normal;
  text-align: center;
}
.rt-BaseButton:where([data-disabled]) {
  --spinner-opacity: 1;
}
.rt-BaseButton:where(.rt-loading) {
  position: relative;
}
.rt-BaseButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
}
.rt-BaseButton:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
}
.rt-BaseButton:where(.rt-r-size-1) {
  --base-button-classic-active-padding-top: 1px;
  --base-button-height: var(--space-5);
  border-radius: max(var(--radius-1), var(--radius-full));
}
.rt-BaseButton:where(.rt-r-size-2) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-6);
  border-radius: max(var(--radius-2), var(--radius-full));
}
.rt-BaseButton:where(.rt-r-size-3) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-7);
  border-radius: max(var(--radius-3), var(--radius-full));
}
.rt-BaseButton:where(.rt-r-size-4) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-8);
  border-radius: max(var(--radius-4), var(--radius-full));
}
@media (min-width: 520px) {
  .rt-BaseButton:where(.xs\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-BaseButton:where(.xs\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-BaseButton:where(.xs\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-BaseButton:where(.xs\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}
@media (min-width: 768px) {
  .rt-BaseButton:where(.sm\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-BaseButton:where(.sm\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-BaseButton:where(.sm\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-BaseButton:where(.sm\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}
@media (min-width: 1024px) {
  .rt-BaseButton:where(.md\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-BaseButton:where(.md\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-BaseButton:where(.md\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-BaseButton:where(.md\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}
@media (min-width: 1280px) {
  .rt-BaseButton:where(.lg\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-BaseButton:where(.lg\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-BaseButton:where(.lg\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-BaseButton:where(.lg\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}
@media (min-width: 1640px) {
  .rt-BaseButton:where(.xl\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-BaseButton:where(.xl\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-BaseButton:where(.xl\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-BaseButton:where(.xl\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}
.rt-BaseButton:where(.rt-variant-classic) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
  position: relative;
  z-index: 0;
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-9) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-9), var(--base-button-classic-box-shadow-bottom);
}
.rt-BaseButton:where(.rt-variant-classic)::after {
  content: '';
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  inset: 0;
  z-index: -1;
  border: var(--base-button-classic-after-inset) solid transparent;
  background-clip: content-box;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a1), transparent, var(--white-a2));
  box-shadow: inset 0 2px 3px -1px var(--white-a4);
}
.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-12) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-12), var(--base-button-classic-box-shadow-bottom);
}
.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast)::after {
  background-image: linear-gradient(var(--black-a3), transparent, var(--white-a2));
}
@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'])) {
    outline: 0.5em solid var(--accent-a4);
    outline-offset: 0;
  }
}
.rt-BaseButton:where(.rt-variant-classic):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-classic):where(:hover)::after {
    background-color: var(--accent-10);
    background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
  }
  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast) {
    filter: var(--base-button-classic-high-contrast-hover-filter);
  }
  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast)::after {
    background-color: var(--accent-12);
    background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
  }
}
.rt-BaseButton:where(.rt-variant-classic):where([data-state='open'])::after {
  background-color: var(--accent-10);
  background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
}
.rt-BaseButton:where(.rt-variant-classic):where([data-state='open']):where(.rt-high-contrast) {
  filter: var(--base-button-classic-high-contrast-hover-filter);
}
.rt-BaseButton:where(.rt-variant-classic):where([data-state='open']):where(.rt-high-contrast)::after {
  background-color: var(--accent-12);
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
}
.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'], [data-disabled])) {
  background-color: var(--accent-9);
  background-image: linear-gradient(var(--black-a1), transparent);
  padding-top: var(--base-button-classic-active-padding-top);
  box-shadow: inset 0 4px 2px -2px var(--gray-a4), inset 0 1px 1px var(--gray-a7), inset 0 0 0 1px var(--gray-a5), inset 0 0 0 1px var(--accent-9), inset 0 3px 2px var(--gray-a3), inset 0 0 0 1px var(--white-a7), inset 0 -2px 1px var(--white-a5);
}
.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'], [data-disabled]))::after {
  box-shadow: none;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a2), transparent, var(--white-a3));
}
.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'], [data-disabled])):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  filter: var(--base-button-classic-high-contrast-active-filter);
  box-shadow: var(--base-button__classic-active__shadow-front-layer), inset 0 0 0 1px var(--accent-12), var(--base-button__classic-active__shadow-bottom-layer);
}
.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'], [data-disabled])):where(.rt-high-contrast)::after {
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a3));
}
.rt-BaseButton:where(.rt-variant-classic):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-2);
  background-image: none;
  box-shadow: var(--base-button-classic-disabled-box-shadow);
  outline: none;
  filter: none;
}
.rt-BaseButton:where(.rt-variant-classic):where([data-disabled])::after {
  box-shadow: none;
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}
.rt-BaseButton:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(:hover) {
    background-color: var(--accent-10);
  }
}
.rt-BaseButton:where(.rt-variant-solid):where([data-state='open']) {
  background-color: var(--accent-10);
}
.rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state='open'])) {
  background-color: var(--accent-10);
  filter: var(--base-button-solid-active-filter);
}
@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state='open'])) {
    outline: 0.5em solid var(--accent-a4);
    outline-offset: 0;
  }
}
.rt-BaseButton:where(.rt-variant-solid):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}
.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where([data-state='open']) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-hover-filter);
}
.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:active:not([data-state='open'])) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-active-filter);
}
.rt-BaseButton:where(.rt-variant-solid):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
  outline: none;
  filter: none;
}
.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost) {
  color: var(--accent-a11);
}
.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}
.rt-BaseButton:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}
.rt-BaseButton:where(.rt-variant-soft):where(:focus-visible) {
  outline: 2px solid var(--accent-8);
  outline-offset: -1px;
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}
.rt-BaseButton:where(.rt-variant-soft):where([data-state='open']) {
  background-color: var(--accent-a4);
}
.rt-BaseButton:where(.rt-variant-soft):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a5);
}
.rt-BaseButton:where(.rt-variant-soft):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}
.rt-BaseButton:where(.rt-variant-ghost):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-BaseButton:where(.rt-variant-ghost):where([data-state='open']) {
  background-color: var(--accent-a3);
}
.rt-BaseButton:where(.rt-variant-ghost):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a4);
}
.rt-BaseButton:where(.rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: transparent;
}
.rt-BaseButton:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-outline):where(:hover) {
    background-color: var(--accent-a2);
  }
}
.rt-BaseButton:where(.rt-variant-outline):where([data-state='open']) {
  background-color: var(--accent-a2);
}
.rt-BaseButton:where(.rt-variant-outline):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a3);
}
.rt-BaseButton:where(.rt-variant-outline):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-BaseButton:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}
.rt-BaseButton:where(.rt-variant-outline):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
  background-color: transparent;
}
.rt-BaseButton:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a7);
  color: var(--accent-a11);
}
@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--accent-a8);
  }
}
.rt-BaseButton:where(.rt-variant-surface):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}
.rt-BaseButton:where(.rt-variant-surface):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a3);
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}
.rt-BaseButton:where(.rt-variant-surface):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-BaseButton:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-BaseButton:where(.rt-variant-surface):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a2);
}
.rt-Button:where(:not(.rt-variant-ghost)) :where(svg) {
  opacity: 0.9;
}
.rt-Button:where(.rt-variant-ghost) {
  padding: var(--button-ghost-padding-y) var(--button-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--button-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right) - var(--button-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--button-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left) - var(--button-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}
:where(.rt-Button:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}
.rt-Button:where(.rt-r-size-1) {
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}
.rt-Button:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.rt-Button:where(.rt-r-size-1):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}
.rt-Button:where(.rt-r-size-2) {
  gap: var(--space-2);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}
.rt-Button:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.rt-Button:where(.rt-r-size-2):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}
.rt-Button:where(.rt-r-size-3) {
  gap: var(--space-3);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}
.rt-Button:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.rt-Button:where(.rt-r-size-3):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-3);
  --button-ghost-padding-y: calc(var(--space-1) * 1.5);
}
.rt-Button:where(.rt-r-size-4) {
  gap: var(--space-3);
  font-size: var(--font-size-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-4);
}
.rt-Button:where(.rt-r-size-4):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.rt-Button:where(.rt-r-size-4):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-4);
  --button-ghost-padding-y: var(--space-2);
}
@media (min-width: 520px) {
  .rt-Button:where(.xs\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-Button:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-Button:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.xs\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-Button:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-Button:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.xs\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-Button:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-Button:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-Button:where(.xs\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-Button:where(.xs\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .rt-Button:where(.xs\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}
@media (min-width: 768px) {
  .rt-Button:where(.sm\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-Button:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-Button:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.sm\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-Button:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-Button:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.sm\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-Button:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-Button:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-Button:where(.sm\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-Button:where(.sm\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .rt-Button:where(.sm\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}
@media (min-width: 1024px) {
  .rt-Button:where(.md\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-Button:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-Button:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.md\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-Button:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-Button:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.md\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-Button:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-Button:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-Button:where(.md\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-Button:where(.md\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .rt-Button:where(.md\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}
@media (min-width: 1280px) {
  .rt-Button:where(.lg\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-Button:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-Button:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.lg\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-Button:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-Button:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.lg\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-Button:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-Button:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-Button:where(.lg\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-Button:where(.lg\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .rt-Button:where(.lg\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}
@media (min-width: 1640px) {
  .rt-Button:where(.xl\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-Button:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-Button:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.xl\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-Button:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-Button:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }
  .rt-Button:where(.xl\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-Button:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-Button:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-Button:where(.xl\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }
  .rt-Button:where(.xl\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .rt-Button:where(.xl\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}
.rt-Button:where(:not(.rt-variant-ghost)) {
  font-weight: var(--font-weight-medium);
}
.rt-CalloutRoot {
  box-sizing: border-box;
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: var(--accent-a11);
}
.rt-CalloutRoot:where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-CalloutIcon {
  display: flex;
  align-items: center;
  grid-column-start: -2;
  height: var(--callout-icon-height);
}
.rt-CalloutRoot > :where(:not(.rt-CalloutIcon)) {
  grid-column-start: -1;
}
.rt-CalloutRoot:where(.rt-r-size-1) {
  row-gap: var(--space-2);
  column-gap: var(--space-2);
  padding: var(--space-3);
  border-radius: var(--radius-3);
  --callout-icon-height: var(--line-height-2);
}
.rt-CalloutRoot:where(.rt-r-size-2) {
  row-gap: var(--space-2);
  column-gap: var(--space-3);
  padding: var(--space-4);
  border-radius: var(--radius-4);
  --callout-icon-height: var(--line-height-2);
}
.rt-CalloutRoot:where(.rt-r-size-3) {
  row-gap: var(--space-3);
  column-gap: var(--space-4);
  padding: var(--space-5);
  border-radius: var(--radius-5);
  --callout-icon-height: var(--line-height-3);
}
@media (min-width: 520px) {
  .rt-CalloutRoot:where(.xs\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.xs\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.xs\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}
@media (min-width: 768px) {
  .rt-CalloutRoot:where(.sm\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.sm\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.sm\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}
@media (min-width: 1024px) {
  .rt-CalloutRoot:where(.md\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.md\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.md\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}
@media (min-width: 1280px) {
  .rt-CalloutRoot:where(.lg\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.lg\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.lg\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}
@media (min-width: 1640px) {
  .rt-CalloutRoot:where(.xl\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.xl\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }
  .rt-CalloutRoot:where(.xl\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}
.rt-CalloutRoot:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}
.rt-CalloutRoot:where(.rt-variant-surface) {
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  background-color: var(--accent-a2);
}
.rt-CalloutRoot:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a7);
}
.rt-BaseCard {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: var(--base-card-border-radius);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  font-style: normal;
  text-align: start;
  --inset-border-width: var(--base-card-border-width);
  --inset-border-radius: var(--base-card-border-radius);
  padding-top: var(--base-card-padding-top);
  padding-right: var(--base-card-padding-right);
  padding-bottom: var(--base-card-padding-bottom);
  padding-left: var(--base-card-padding-left);
  box-sizing: border-box;
  --inset-padding-top: calc(var(--base-card-padding-top) - var(--base-card-border-width));
  --inset-padding-right: calc(var(--base-card-padding-right) - var(--base-card-border-width));
  --inset-padding-bottom: calc(var(--base-card-padding-bottom) - var(--base-card-border-width));
  --inset-padding-left: calc(var(--base-card-padding-left) - var(--base-card-border-width));
  contain: paint;
}
.rt-BaseCard::before,
  .rt-BaseCard::after {
  content: '';
  position: absolute;
  pointer-events: none;
  transition: inherit;
  border-radius: calc(var(--base-card-border-radius) - var(--base-card-border-width));
  inset: var(--base-card-border-width);
}
.rt-BaseCard::before {
  z-index: -1;
}
.rt-Card {
  --base-card-padding-top: var(--card-padding);
  --base-card-padding-right: var(--card-padding);
  --base-card-padding-bottom: var(--card-padding);
  --base-card-padding-left: var(--card-padding);
  --base-card-border-radius: var(--card-border-radius);
  --base-card-border-width: var(--card-border-width);
}
.rt-Card:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-Card:where(:focus-visible)::after {
  outline: inherit;
}
.rt-Card:where(:focus-visible):where(:active:not([data-state='open']))::before {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
}
.rt-Card:where(.rt-r-size-1) {
  --card-padding: var(--space-3);
  --card-border-radius: var(--radius-4);
}
.rt-Card:where(.rt-r-size-2) {
  --card-padding: var(--space-4);
  --card-border-radius: var(--radius-4);
}
.rt-Card:where(.rt-r-size-3) {
  --card-padding: var(--space-5);
  --card-border-radius: var(--radius-5);
}
.rt-Card:where(.rt-r-size-4) {
  --card-padding: var(--space-6);
  --card-border-radius: var(--radius-5);
}
.rt-Card:where(.rt-r-size-5) {
  --card-padding: var(--space-8);
  --card-border-radius: var(--radius-6);
}
@media (min-width: 520px) {
  .rt-Card:where(.xs\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.xs\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.xs\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.xs\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.xs\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}
@media (min-width: 768px) {
  .rt-Card:where(.sm\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.sm\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.sm\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.sm\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.sm\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}
@media (min-width: 1024px) {
  .rt-Card:where(.md\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.md\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.md\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.md\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.md\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}
@media (min-width: 1280px) {
  .rt-Card:where(.lg\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.lg\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.lg\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.lg\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.lg\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}
@media (min-width: 1640px) {
  .rt-Card:where(.xl\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.xl\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }
  .rt-Card:where(.xl\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.xl\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }
  .rt-Card:where(.xl\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}
.rt-Card:where(.rt-variant-surface) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
}
.rt-Card:where(.rt-variant-surface)::before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
          backdrop-filter: var(--backdrop-filter-panel);
}
.rt-Card:where(.rt-variant-surface)::after {
  box-shadow: var(--base-card-surface-box-shadow);
}
@media (hover: hover) {
  .rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
  .rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}
.rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}
.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}
.rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}
.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}
.rt-Card:where(.rt-variant-classic) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}
.rt-Card:where(.rt-variant-classic)::before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
          backdrop-filter: var(--backdrop-filter-panel);
}
.rt-Card:where(.rt-variant-classic)::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}
@media (hover: hover) {
  .rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  .rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}
.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where([data-state='open']) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
}
.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state='open']) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
}
.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}
.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}
.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:active:not([data-state='open'])) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
}
.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state='open'])) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
}
.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}
.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}
.rt-Card:where(.rt-variant-ghost) {
  --card-border-width: 0px;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--card-padding));
  --margin-right-override: calc(var(--margin-right) - var(--card-padding));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--card-padding));
  --margin-left-override: calc(var(--margin-left) - var(--card-padding));
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
}
:where(.rt-Card:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}
@media (hover: hover) {
  .rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }
  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }
  .rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }
  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }
}
.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where([data-state='open']) {
  background-color: var(--gray-a3);
}
.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state='open']) {
  background-color: var(--gray-a3);
}
.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where([data-state='open']):where(:focus-visible) {
  background-color: var(--focus-a2);
}
.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state='open']):where(:focus-visible) {
  background-color: var(--focus-a2);
}
.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:active:not([data-state='open'])) {
  background-color: var(--gray-a4);
}
.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state='open'])) {
  background-color: var(--gray-a4);
}
.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:active:not([data-state='open'])):where(:focus-visible) {
  background-color: var(--focus-a2);
}
.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state='open'])):where(:focus-visible) {
  background-color: var(--focus-a2);
}
@media (pointer: coarse) {
  .rt-Card:where(:-webkit-any-link, button, label):where(:active:not(:focus-visible, [data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
  .rt-Card:where(:any-link, button, label):where(:active:not(:focus-visible, [data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}
.rt-BaseCheckboxRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  flex-shrink: 0;
  cursor: var(--cursor-checkbox);
  height: var(--skeleton-height, var(--line-height, var(--checkbox-size)));
  --skeleton-height-override: var(--checkbox-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--checkbox-border-radius);
}
.rt-BaseCheckboxRoot::before {
  content: '';
  display: block;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  border-radius: var(--checkbox-border-radius);
}
.rt-BaseCheckboxIndicator {
  position: absolute;
  width: var(--checkbox-indicator-size);
  height: var(--checkbox-indicator-size);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.rt-BaseCheckboxRoot:where(.rt-r-size-1) {
  --checkbox-size: calc(var(--space-4) * 0.875);
  --checkbox-indicator-size: calc(9px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * 0.875);
}
.rt-BaseCheckboxRoot:where(.rt-r-size-2) {
  --checkbox-size: var(--space-4);
  --checkbox-indicator-size: calc(10px * var(--scaling));
  --checkbox-border-radius: var(--radius-1);
}
.rt-BaseCheckboxRoot:where(.rt-r-size-3) {
  --checkbox-size: calc(var(--space-4) * 1.25);
  --checkbox-indicator-size: calc(12px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * 1.25);
}
@media (min-width: 520px) {
  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }
  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }
  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}
@media (min-width: 768px) {
  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }
  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }
  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}
@media (min-width: 1024px) {
  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }
  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }
  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}
@media (min-width: 1280px) {
  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }
  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }
  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}
@media (min-width: 1640px) {
  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }
  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }
  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate'])::before {
  background-color: var(--accent-indicator);
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast)::before {
  background-color: var(--accent-12);
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled)::before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: transparent;
}
.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--shadow-1);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate'])::before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a1));
  box-shadow: inset 0 0.5px 0.5px var(--white-a4), inset 0 -0.5px 0.5px var(--black-a4);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast)::before {
  background-color: var(--accent-12);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled)::before {
  box-shadow: var(--shadow-1);
  background-color: transparent;
  background-image: none;
}
.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}
.rt-BaseCheckboxRoot:where(.rt-variant-soft)::before {
  background-color: var(--accent-a5);
}
.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-a11);
}
.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-12);
}
.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled)::before {
  background-color: transparent;
}
.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}
.rt-CheckboxCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}
.rt-CheckboxCardsItem:where(:has(:focus-visible)) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-CheckboxCardsItem:where(:has(:focus-visible))::after {
  outline: inherit;
}
.rt-CheckboxCardsItem > * {
  pointer-events: none;
}
.rt-CheckboxCardsItem > :where(svg) {
  flex-shrink: 0;
}
.rt-CheckboxCardCheckbox {
  position: absolute;
  right: var(--checkbox-cards-item-padding-left);
}
.rt-CheckboxCardsItem {
  --checkbox-cards-item-padding-right: calc(var(--checkbox-cards-item-padding-left) * 2 + var(--checkbox-cards-item-checkbox-size));
  --base-card-padding-top: var(--checkbox-cards-item-padding-top);
  --base-card-padding-right: var(--checkbox-cards-item-padding-right);
  --base-card-padding-bottom: var(--checkbox-cards-item-padding-bottom);
  --base-card-padding-left: var(--checkbox-cards-item-padding-left);
  --base-card-border-radius: var(--checkbox-cards-item-border-radius);
  --base-card-border-width: var(--checkbox-cards-item-border-width);
  display: flex;
  align-items: center;
  gap: var(--space-2);
  cursor: var(--cursor-button);
  -webkit-tap-highlight-color: transparent;
}
.rt-CheckboxCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-3);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
}
.rt-CheckboxCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
  --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
  --checkbox-cards-item-padding-left: var(--space-4);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: var(--space-4);
}
.rt-CheckboxCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-5);
  --checkbox-cards-item-border-radius: var(--radius-4);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
}
@media (min-width: 520px) {
  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }
  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }
  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 768px) {
  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }
  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }
  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1024px) {
  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }
  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }
  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1280px) {
  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }
  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }
  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1640px) {
  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }
  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }
  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}
:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
}
:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem::before {
  background-color: var(--checkbox-cards-item-background-color);
}
:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem::after {
  box-shadow: var(--base-card-surface-box-shadow);
}
@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}
:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}
:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem::before {
  background-color: var(--checkbox-cards-item-background-color);
}
:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}
@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}
@media (pointer: coarse) {
  .rt-CheckboxCardsItem:where(:active:not(:focus-visible))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}
.rt-CheckboxCardsItem:where(:has(:disabled)) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}
.rt-CheckboxCardsItem:where(:has(:disabled))::before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-CheckboxCardsItem:where(:has(:disabled))::selection {
  background-color: var(--gray-a5);
}
.rt-CheckboxGroupRoot {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}
.rt-CheckboxGroupItem {
  display: flex;
  gap: 0.5em;
  width: -moz-fit-content;
  width: fit-content;
}
.rt-CheckboxGroupItemCheckbox:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
.rt-CheckboxGroupItemCheckbox:where(:disabled) {
  cursor: var(--cursor-disabled);
}
.rt-CheckboxGroupItemCheckbox:where(:disabled)::before {
  background-color: var(--gray-a3);
}
.rt-CheckboxGroupItemInner {
  min-width: 0;
}
.rt-CheckboxRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
.rt-CheckboxRoot:where(:disabled) {
  cursor: var(--cursor-disabled);
}
.rt-CheckboxRoot:where(:disabled)::before {
  background-color: var(--gray-a3);
}
.rt-Code {
  --code-variant-font-size-adjust: calc(var(--code-font-size-adjust) * 0.95);
  font-family: var(--code-font-family);
  font-size: calc(var(--code-variant-font-size-adjust) * 1em);
  font-style: var(--code-font-style);
  font-weight: var(--code-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--code-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  border-radius: calc((0.5px + 0.2em) * var(--radius-factor));
  box-sizing: border-box;
  padding-top: var(--code-padding-top);
  padding-left: var(--code-padding-left);
  padding-bottom: var(--code-padding-bottom);
  padding-right: var(--code-padding-right);
  height: -moz-fit-content;
  height: fit-content;
}
.rt-Code :where(.rt-Code) {
  font-size: inherit;
}
.rt-Code:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}
.rt-Code:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}
.rt-Code:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}
.rt-Code:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}
.rt-Code:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}
.rt-Code:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}
.rt-Code:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}
.rt-Code:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}
.rt-Code:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}
@media (min-width: 520px) {
  .rt-Code:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Code:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Code:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Code:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Code:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Code:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Code:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Code:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Code:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 768px) {
  .rt-Code:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Code:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Code:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Code:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Code:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Code:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Code:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Code:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Code:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1024px) {
  .rt-Code:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Code:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Code:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Code:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Code:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Code:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Code:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Code:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Code:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1280px) {
  .rt-Code:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Code:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Code:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Code:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Code:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Code:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Code:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Code:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Code:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1640px) {
  .rt-Code:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Code:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Code:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Code:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Code:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Code:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Code:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Code:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Code:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}
.rt-Code:where(.rt-variant-ghost) {
  --code-variant-font-size-adjust: var(--code-font-size-adjust);
  padding: 0;
}
.rt-Code:where(.rt-variant-ghost):where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-Code:where(.rt-variant-ghost):where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Code:where(.rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-Code:where(.rt-variant-solid) {
  background-color: var(--accent-a9);
  color: var(--accent-contrast);
}
.rt-Code:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}
.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}
.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}
.rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button) {
  isolation: isolate;
}
:where(.rt-Link) .rt-Code:where(.rt-variant-solid),
  .rt-Code:where(.rt-variant-solid):where(:any-link, button) {
  isolation: isolate;
}
@media (hover: hover) {
  .rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(:hover), .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }
  .rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(.rt-high-contrast:hover), .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}
.rt-Code:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}
.rt-Code:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-Code:where(.rt-variant-soft):where(:-webkit-any-link, button) {
  isolation: isolate;
}
:where(.rt-Link) .rt-Code:where(.rt-variant-soft),
  .rt-Code:where(.rt-variant-soft):where(:any-link, button) {
  isolation: isolate;
}
@media (hover: hover) {
  .rt-Code:where(.rt-variant-soft):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-soft):where(:hover), .rt-Code:where(.rt-variant-soft):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }
}
.rt-Code:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 max(1px, 0.033em) var(--accent-a8);
  color: var(--accent-a11);
}
.rt-Code:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 max(1px, 0.033em) var(--accent-a7), inset 0 0 0 max(1px, 0.033em) var(--gray-a11);
  color: var(--accent-12);
}
.rt-Code:where(.rt-variant-outline):where(:-webkit-any-link, button) {
  isolation: isolate;
}
:where(.rt-Link) .rt-Code:where(.rt-variant-outline),
  .rt-Code:where(.rt-variant-outline):where(:any-link, button) {
  isolation: isolate;
}
@media (hover: hover) {
  .rt-Code:where(.rt-variant-outline):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-outline):where(:hover), .rt-Code:where(.rt-variant-outline):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }
}
.rt-BaseMenuContent {
  --scrollarea-scrollbar-vertical-margin-top: var(--base-menu-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--base-menu-content-padding);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--base-menu-bg);
  --base-menu-bg: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
}
.rt-BaseMenuViewport {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--base-menu-content-padding);
  box-sizing: border-box;
}
:where(.rt-BaseMenuContent:has(.rt-ScrollAreaScrollbar[data-orientation='vertical'])) .rt-BaseMenuViewport {
  padding-right: var(--space-3);
}
.rt-BaseMenuItem {
  display: flex;
  align-items: center;
  gap: var(--space-2);
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  position: relative;
  outline: none;
  scroll-margin: var(--base-menu-content-padding) 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  cursor: var(--cursor-menu-item);
}
.rt-BaseMenuShortcut {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: var(--space-4);
  color: var(--gray-a11);
}
.rt-BaseMenuSubTriggerIcon {
  color: var(--gray-12);
  margin-right: calc(-2px * var(--scaling));
}
.rt-BaseMenuItemIndicator {
  position: absolute;
  left: 0;
  width: var(--base-menu-item-padding-left);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rt-BaseMenuSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--base-menu-item-padding-left);
  margin-right: var(--base-menu-item-padding-right);
  background-color: var(--gray-a6);
}
.rt-BaseMenuLabel {
  display: flex;
  align-items: center;
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  color: var(--gray-a10);
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  cursor: default;
}
:where(.rt-BaseMenuItem) + .rt-BaseMenuLabel {
  margin-top: var(--space-2);
}
.rt-BaseMenuArrow {
  fill: var(--base-menu-bg);
}
.rt-BaseMenuContent:where(.rt-r-size-1) {
  --base-menu-content-padding: var(--space-1);
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  --base-menu-item-padding-right: var(--space-2);
  --base-menu-item-height: var(--space-5);
  border-radius: var(--radius-3);
}
.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}
.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}
.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}
.rt-BaseMenuContent:where(.rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-2);
}
.rt-BaseMenuContent:where(.rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
}
.rt-BaseMenuContent:where(.rt-r-size-2) {
  --base-menu-content-padding: var(--space-2);
  --base-menu-item-padding-left: var(--space-3);
  --base-menu-item-padding-right: var(--space-3);
  --base-menu-item-height: var(--space-6);
  border-radius: var(--radius-4);
}
.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: var(--radius-2);
}
.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}
.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}
.rt-BaseMenuContent:where(.rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-3);
}
.rt-BaseMenuContent:where(.rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: var(--space-5);
}
@media (min-width: 520px) {
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }
  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}
@media (min-width: 768px) {
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }
  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}
@media (min-width: 1024px) {
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }
  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}
@media (min-width: 1280px) {
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }
  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}
@media (min-width: 1640px) {
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }
  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}
.rt-BaseMenuItem:where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-BaseMenuItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}
.rt-BaseMenuItem:where([data-disabled], [data-highlighted]) :where(.rt-BaseMenuShortcut), .rt-BaseMenuSubTrigger:where([data-state='open']) :where(.rt-BaseMenuShortcut) {
  color: inherit;
}
.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuSubTrigger[data-state='open']) {
  background-color: var(--gray-a3);
}
.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-contrast);
}
.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}
.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-1);
}
.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]):where([data-accent-color]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuSubTrigger[data-state='open']) {
  background-color: var(--accent-a3);
}
.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-a4);
}
.rt-ContextMenuContent {
  max-height: var(--radix-context-menu-content-available-height);
  transform-origin: var(--radix-context-menu-content-transform-origin);
}
.rt-DataListRoot {
  overflow-wrap: anywhere;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  font-style: normal;
  text-align: start;
  --data-list-leading-trim-start: calc(var(--default-leading-trim-start) - var(--line-height) / 2);
  --data-list-leading-trim-end: calc(var(--default-leading-trim-end) - var(--line-height) / 2);
}
.rt-DataListLabel {
  display: flex;
  color: var(--gray-a11);
}
.rt-DataListLabel:where(.rt-high-contrast) {
  color: var(--gray-12);
}
.rt-DataListLabel:where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-DataListLabel:where([data-accent-color]):where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-DataListValue {
  display: flex;
  margin: 0;
  min-width: 0px;
  margin-top: var(--data-list-value-margin-top);
  margin-bottom: var(--data-list-value-margin-bottom);
}
.rt-DataListItem {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}
:where(.rt-DataListItem:first-child) .rt-DataListValue {
  margin-top: var(--data-list-first-item-value-margin-top);
}
:where(.rt-DataListItem:last-child) .rt-DataListValue {
  margin-bottom: var(--data-list-last-item-value-margin-bottom);
}
.rt-DataListRoot:where(.rt-r-size-1) {
  gap: var(--space-3);
}
.rt-DataListRoot:where(.rt-r-size-2) {
  gap: var(--space-4);
}
.rt-DataListRoot:where(.rt-r-size-3) {
  gap: calc(var(--space-4) * 1.25);
}
@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-size-1) {
    gap: var(--space-3);
  }
  .rt-DataListRoot:where(.xs\:rt-r-size-2) {
    gap: var(--space-4);
  }
  .rt-DataListRoot:where(.xs\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-size-1) {
    gap: var(--space-3);
  }
  .rt-DataListRoot:where(.sm\:rt-r-size-2) {
    gap: var(--space-4);
  }
  .rt-DataListRoot:where(.sm\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-size-1) {
    gap: var(--space-3);
  }
  .rt-DataListRoot:where(.md\:rt-r-size-2) {
    gap: var(--space-4);
  }
  .rt-DataListRoot:where(.md\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-size-1) {
    gap: var(--space-3);
  }
  .rt-DataListRoot:where(.lg\:rt-r-size-2) {
    gap: var(--space-4);
  }
  .rt-DataListRoot:where(.lg\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-size-1) {
    gap: var(--space-3);
  }
  .rt-DataListRoot:where(.xl\:rt-r-size-2) {
    gap: var(--space-4);
  }
  .rt-DataListRoot:where(.xl\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}
.rt-DataListRoot:where(.rt-r-orientation-vertical) {
  display: flex;
  flex-direction: column;
}
.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListItem) {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}
.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListLabel) {
  min-width: 0px;
}
.rt-DataListRoot:where(.rt-r-orientation-horizontal) {
  display: grid;
  grid-template-columns: auto 1fr;
}
.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListItem) {
  --data-list-value-margin-top: var(--data-list-value-trim-start);
  --data-list-value-margin-bottom: var(--data-list-value-trim-end);
  --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
  --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
  display: grid;
  grid-template-columns: inherit;
  grid-template-columns: subgrid;
  grid-gap: inherit;
  gap: inherit;
  grid-column: span 2;
  align-items: baseline;
}
.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
  min-width: 120px;
}
@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }
  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }
  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }
  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}
@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }
  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }
  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }
  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}
@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }
  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }
  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }
  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}
@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }
  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }
  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }
  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}
@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }
  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }
  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }
  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}
.rt-DataListLabel::before, .rt-DataListValue::before {
  content: '‍';
}
.rt-DataListItem:where(.rt-r-ai-baseline) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}
.rt-DataListItem:where(.rt-r-ai-start) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}
.rt-DataListItem:where(.rt-r-ai-center) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: -0.25em;
  --data-list-last-item-value-trim-end: -0.25em;
}
.rt-DataListItem:where(.rt-r-ai-end) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}
.rt-DataListItem:where(.rt-r-ai-stretch) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}
@media (min-width: 520px) {
  .rt-DataListItem:where(.xs\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xs\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xs\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }
  .rt-DataListItem:where(.xs\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xs\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}
@media (min-width: 768px) {
  .rt-DataListItem:where(.sm\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.sm\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.sm\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }
  .rt-DataListItem:where(.sm\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.sm\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}
@media (min-width: 1024px) {
  .rt-DataListItem:where(.md\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.md\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.md\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }
  .rt-DataListItem:where(.md\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.md\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}
@media (min-width: 1280px) {
  .rt-DataListItem:where(.lg\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.lg\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.lg\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }
  .rt-DataListItem:where(.lg\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.lg\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}
@media (min-width: 1640px) {
  .rt-DataListItem:where(.xl\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xl\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xl\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }
  .rt-DataListItem:where(.xl\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
  .rt-DataListItem:where(.xl\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}
.rt-DataListItem:where(:first-child) {
  margin-top: var(--leading-trim-start);
}
.rt-DataListItem:where(:last-child) {
  margin-bottom: var(--leading-trim-end);
}
.rt-DataListRoot:where(.rt-r-trim-normal) {
  --leading-trim-start: initial;
  --leading-trim-end: initial;
}
.rt-DataListRoot:where(.rt-r-trim-start) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: initial;
}
.rt-DataListRoot:where(.rt-r-trim-end) {
  --leading-trim-start: initial;
  --leading-trim-end: var(--data-list-leading-trim-end);
}
.rt-DataListRoot:where(.rt-r-trim-both) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: var(--data-list-leading-trim-end);
}
@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.xs\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.xs\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
  .rt-DataListRoot:where(.xs\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}
@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.sm\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.sm\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
  .rt-DataListRoot:where(.sm\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}
@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.md\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.md\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
  .rt-DataListRoot:where(.md\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}
@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.lg\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.lg\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
  .rt-DataListRoot:where(.lg\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}
@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.xl\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }
  .rt-DataListRoot:where(.xl\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
  .rt-DataListRoot:where(.xl\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}
.rt-DropdownMenuContent {
  max-height: var(--radix-dropdown-menu-content-available-height);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
}
.rt-Em {
  box-sizing: border-box;
  font-family: var(--em-font-family);
  font-size: calc(var(--em-font-size-adjust) * 1em);
  font-style: var(--em-font-style);
  font-weight: var(--em-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--em-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
}
.rt-Em :where(.rt-Em) {
  font-size: inherit;
}
.rt-Heading {
  --leading-trim-start: var(--heading-leading-trim-start);
  --leading-trim-end: var(--heading-leading-trim-end);
  font-family: var(--heading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height);
}
:where(.rt-Heading) {
  margin: 0;
}
.rt-Heading:where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-Heading:where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Heading:where(.rt-high-contrast) {
  color: var(--accent-12);
}
.rt-Heading:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-1);
  letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-2);
  letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-3);
  letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-4);
  letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-5);
  letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-6);
  letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-7);
  letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-8);
  letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
}
.rt-Heading:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-9);
  letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
}
@media (min-width: 520px) {
  .rt-Heading:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}
@media (min-width: 768px) {
  .rt-Heading:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}
@media (min-width: 1024px) {
  .rt-Heading:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}
@media (min-width: 1280px) {
  .rt-Heading:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}
@media (min-width: 1640px) {
  .rt-Heading:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }
  .rt-Heading:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}
.rt-HoverCardContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-4);
  overflow: auto;
  position: relative;
  --inset-padding-top: var(--hover-card-content-padding);
  --inset-padding-right: var(--hover-card-content-padding);
  --inset-padding-bottom: var(--hover-card-content-padding);
  --inset-padding-left: var(--hover-card-content-padding);
  padding: var(--hover-card-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-hover-card-content-transform-origin);
}
.rt-HoverCardContent:where(.rt-r-size-1) {
  --hover-card-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}
.rt-HoverCardContent:where(.rt-r-size-2) {
  --hover-card-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}
.rt-HoverCardContent:where(.rt-r-size-3) {
  --hover-card-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}
@media (min-width: 520px) {
  .rt-HoverCardContent:where(.xs\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.xs\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.xs\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 768px) {
  .rt-HoverCardContent:where(.sm\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.sm\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.sm\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1024px) {
  .rt-HoverCardContent:where(.md\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.md\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.md\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1280px) {
  .rt-HoverCardContent:where(.lg\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.lg\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.lg\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1640px) {
  .rt-HoverCardContent:where(.xl\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.xl\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-HoverCardContent:where(.xl\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}
.rt-IconButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
  width: var(--base-button-height);
}
.rt-IconButton:where(.rt-variant-ghost) {
  padding: var(--icon-button-ghost-padding);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--icon-button-ghost-padding));
  --margin-right-override: calc(var(--margin-right) - var(--icon-button-ghost-padding));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--icon-button-ghost-padding));
  --margin-left-override: calc(var(--margin-left) - var(--icon-button-ghost-padding));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}
:where(.rt-IconButton:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}
.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-1) {
  --icon-button-ghost-padding: var(--space-1);
}
.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-2) {
  --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
}
.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-3) {
  --icon-button-ghost-padding: var(--space-2);
}
.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-4) {
  --icon-button-ghost-padding: var(--space-3);
}
@media (min-width: 520px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}
@media (min-width: 768px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}
@media (min-width: 1024px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}
@media (min-width: 1280px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}
@media (min-width: 1640px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}
.rt-Inset {
  box-sizing: border-box;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  overflow: hidden;
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
}
:where(.rt-Inset) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}
.rt-Inset:where(.rt-r-clip-border-box) {
  --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
  --inset-padding-top-calc: var(--inset-padding-top, 0px);
  --inset-padding-right-calc: var(--inset-padding-right, 0px);
  --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
  --inset-padding-left-calc: var(--inset-padding-left, 0px);
}
.rt-Inset:where(.rt-r-clip-padding-box) {
  --inset-border-radius-calc: var(--inset-border-radius, 0px);
  --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
  --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
  --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
  --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
}
.rt-Inset:where(.rt-r-side-top) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rt-Inset:where(.rt-r-side-bottom) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: var(--inset-border-radius-calc);
}
.rt-Inset:where(.rt-r-side-left) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  --margin-right-override: var(--margin-right);
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: 0px;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: 0px;
}
.rt-Inset:where(.rt-r-side-right) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-top-left-radius: 0px;
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: var(--inset-border-radius-calc);
}
.rt-Inset:where(.rt-r-side-x) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-radius: 0px;
}
.rt-Inset:where(.rt-r-side-y) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: var(--margin-right);
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-radius: 0px;
}
.rt-Inset:where(.rt-r-side-all) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-radius: var(--inset-border-radius-calc);
}
@media (min-width: 520px) {
  .rt-Inset:where(.xs\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }
  .rt-Inset:where(.xs\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }
  .rt-Inset:where(.xs\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.xs\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.xs\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.xs\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.xs\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }
  .rt-Inset:where(.xs\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }
  .rt-Inset:where(.xs\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}
@media (min-width: 768px) {
  .rt-Inset:where(.sm\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }
  .rt-Inset:where(.sm\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }
  .rt-Inset:where(.sm\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.sm\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.sm\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.sm\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.sm\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }
  .rt-Inset:where(.sm\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }
  .rt-Inset:where(.sm\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}
@media (min-width: 1024px) {
  .rt-Inset:where(.md\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }
  .rt-Inset:where(.md\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }
  .rt-Inset:where(.md\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.md\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.md\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.md\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.md\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }
  .rt-Inset:where(.md\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }
  .rt-Inset:where(.md\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}
@media (min-width: 1280px) {
  .rt-Inset:where(.lg\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }
  .rt-Inset:where(.lg\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }
  .rt-Inset:where(.lg\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.lg\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.lg\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.lg\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.lg\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }
  .rt-Inset:where(.lg\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }
  .rt-Inset:where(.lg\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}
@media (min-width: 1640px) {
  .rt-Inset:where(.xl\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }
  .rt-Inset:where(.xl\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }
  .rt-Inset:where(.xl\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.xl\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.xl\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }
  .rt-Inset:where(.xl\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }
  .rt-Inset:where(.xl\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }
  .rt-Inset:where(.xl\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }
  .rt-Inset:where(.xl\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}
.rt-Kbd {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: var(--default-font-family);
  font-weight: normal;
  vertical-align: text-top;
  white-space: nowrap;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  position: relative;
  top: -0.03em;
  font-size: 0.75em;
  min-width: 1.75em;
  line-height: 1.7em;
  box-sizing: border-box;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.05em;
  word-spacing: -0.1em;
  border-radius: calc(var(--radius-factor) * 0.35em);
  letter-spacing: var(--letter-spacing, var(--default-letter-spacing));
  height: -moz-fit-content;
  height: fit-content;
  color: var(--gray-12);
  background-color: var(--gray-1);
  box-shadow: var(--kbd-box-shadow);
  transition: box-shadow 120ms, background-color 120ms;
}
@media (hover: hover) {
  .rt-Kbd:where(:-webkit-any-link, button):where(:hover) {
    transition-duration: 40ms, 40ms;
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
  }
  .rt-Kbd:where(:any-link, button):where(:hover) {
    transition-duration: 40ms, 40ms;
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
  }
}
.rt-Kbd:where(:-webkit-any-link, button):where([data-state='open']) {
  transition-duration: 40ms, 40ms;
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
}
.rt-Kbd:where(:any-link, button):where([data-state='open']) {
  transition-duration: 40ms, 40ms;
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
}
.rt-Kbd:where(:-webkit-any-link, button):where(:active:not([data-state='open'])) {
  padding-top: 0.05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
  background-color: var(--gray-2);
  box-shadow: inset 0 0.05em var(--black-a3), 0 0 0 0.05em var(--gray-a7);
}
.rt-Kbd:where(:any-link, button):where(:active:not([data-state='open'])) {
  padding-top: 0.05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
  background-color: var(--gray-2);
  box-shadow: inset 0 0.05em var(--black-a3), 0 0 0 0.05em var(--gray-a7);
}
.rt-Kbd:where(:-webkit-any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}
.rt-Kbd:where(:any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}
.rt-Kbd:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * 0.8);
  --letter-spacing: var(--letter-spacing-1);
}
.rt-Kbd:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * 0.8);
  --letter-spacing: var(--letter-spacing-2);
}
.rt-Kbd:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * 0.8);
  --letter-spacing: var(--letter-spacing-3);
}
.rt-Kbd:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * 0.8);
  --letter-spacing: var(--letter-spacing-4);
}
.rt-Kbd:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * 0.8);
  --letter-spacing: var(--letter-spacing-5);
}
.rt-Kbd:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * 0.8);
  --letter-spacing: var(--letter-spacing-6);
}
.rt-Kbd:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * 0.8);
  --letter-spacing: var(--letter-spacing-7);
}
.rt-Kbd:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * 0.8);
  --letter-spacing: var(--letter-spacing-8);
}
.rt-Kbd:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * 0.8);
  --letter-spacing: var(--letter-spacing-9);
}
@media (min-width: 520px) {
  .rt-Kbd:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Kbd:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Kbd:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Kbd:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Kbd:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Kbd:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Kbd:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Kbd:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Kbd:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 768px) {
  .rt-Kbd:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Kbd:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Kbd:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Kbd:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Kbd:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Kbd:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Kbd:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Kbd:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Kbd:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1024px) {
  .rt-Kbd:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Kbd:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Kbd:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Kbd:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Kbd:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Kbd:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Kbd:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Kbd:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Kbd:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1280px) {
  .rt-Kbd:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Kbd:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Kbd:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Kbd:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Kbd:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Kbd:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Kbd:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Kbd:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Kbd:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}
@media (min-width: 1640px) {
  .rt-Kbd:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }
  .rt-Kbd:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }
  .rt-Kbd:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }
  .rt-Kbd:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }
  .rt-Kbd:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }
  .rt-Kbd:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }
  .rt-Kbd:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }
  .rt-Kbd:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }
  .rt-Kbd:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}
.rt-Link:where(:-webkit-any-link, button) {
  cursor: var(--cursor-link);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, 0.05em));
  text-underline-offset: calc(0.025em + 2px);
  -webkit-text-decoration-color: var(--accent-a5);
          text-decoration-color: var(--accent-a5);
}
.rt-Link:where(:any-link, button) {
  cursor: var(--cursor-link);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, 0.05em));
  text-underline-offset: calc(0.025em + 2px);
  -webkit-text-decoration-color: var(--accent-a5);
          text-decoration-color: var(--accent-a5);
}
.rt-Link:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}
:where([data-accent-color]:not(.radix-themes, .rt-high-contrast)) .rt-Link:where([data-accent-color='']) {
  color: var(--accent-12);
}
@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:-webkit-any-link, button) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
            text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
  .rt-Link:where(:any-link, button) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
            text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
}
@media (pointer: coarse) {
  .rt-Link:where(:-webkit-any-link, button):where(:active:not(:focus-visible, [data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
  .rt-Link:where(:any-link, button):where(:active:not(:focus-visible, [data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
}
@media (hover: hover) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(:hover) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(:hover) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
}
.rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast), :where([data-accent-color]:not(.radix-themes, .rt-high-contrast)) .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
          text-decoration-color: var(--accent-a6);
}
.rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast),
      :where([data-accent-color]:not(.radix-themes, .rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
          text-decoration-color: var(--accent-a6);
}
@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast), :where([data-accent-color]:not(.radix-themes, .rt-high-contrast)) .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
            text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast),
      :where([data-accent-color]:not(.radix-themes, .rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
            text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
}
@media (hover: hover) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-hover):where(:hover) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-hover):where(:hover) {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
}
.rt-Link:where(:-webkit-any-link, button):where(.rt-underline-always) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.rt-Link:where(:any-link, button):where(.rt-underline-always) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.rt-Link:where(:focus-visible) {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  border-radius: calc(0.07em * var(--radius-factor));
  outline-color: var(--focus-8);
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 2px;
}
.rt-Link:where(:has(.rt-Code:not(.rt-variant-ghost):only-child)) {
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
}
.rt-PopoverContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
  min-width: var(--radix-popover-trigger-width);
  outline: 0;
  overflow: auto;
  position: relative;
  --inset-padding-top: var(--popover-content-padding);
  --inset-padding-right: var(--popover-content-padding);
  --inset-padding-bottom: var(--popover-content-padding);
  --inset-padding-left: var(--popover-content-padding);
  padding: var(--popover-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-popover-content-transform-origin);
}
.rt-PopoverContent:where(.rt-r-size-1) {
  --popover-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}
.rt-PopoverContent:where(.rt-r-size-2) {
  --popover-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}
.rt-PopoverContent:where(.rt-r-size-3) {
  --popover-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}
.rt-PopoverContent:where(.rt-r-size-4) {
  --popover-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}
@media (min-width: 520px) {
  .rt-PopoverContent:where(.xs\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.xs\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.xs\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-PopoverContent:where(.xs\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 768px) {
  .rt-PopoverContent:where(.sm\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.sm\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.sm\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-PopoverContent:where(.sm\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1024px) {
  .rt-PopoverContent:where(.md\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.md\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.md\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-PopoverContent:where(.md\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1280px) {
  .rt-PopoverContent:where(.lg\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.lg\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.lg\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-PopoverContent:where(.lg\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
@media (min-width: 1640px) {
  .rt-PopoverContent:where(.xl\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.xl\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }
  .rt-PopoverContent:where(.xl\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
  .rt-PopoverContent:where(.xl\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}
.rt-ProgressRoot {
  --progress-value: 0;
  --progress-max: 100;
  --progress-duration: 5s;
  pointer-events: none;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: var(--progress-height);
  border-radius: max(calc(var(--radius-factor) * var(--progress-height) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
}
.rt-ProgressRoot::after {
  position: absolute;
  inset: 0;
  content: '';
  border-radius: inherit;
}
.rt-ProgressIndicator {
  display: block;
  height: 100%;
  width: 100%;
  transform: scaleX(calc(var(--progress-value) / var(--progress-max)));
  transform-origin: left center;
  transition: transform 120ms;
}
.rt-ProgressIndicator:where([data-state='indeterminate']) {
  animation-name: rt-progress-indicator-indeterminate-grow, var(--progress-indicator-indeterminate-animation-start), var(--progress-indicator-indeterminate-animation-repeat);
  animation-delay: 0s, calc(var(--progress-duration) + 5s), calc(var(--progress-duration) + 7.5s);
  animation-duration: var(--progress-duration), 2.5s, 5s;
  animation-iteration-count: 1, 1, infinite;
  animation-fill-mode: both, none, none;
  animation-direction: normal, normal, alternate;
}
.rt-ProgressIndicator:where([data-state='indeterminate'])::after {
  position: absolute;
  inset: 0;
  content: '';
  width: 400%;
  animation-name: rt-progress-indicator-indeterminate-shine-from-left;
  animation-delay: calc(var(--progress-duration) + 5s);
  animation-duration: 5s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  background-image: linear-gradient(to right, transparent 25%, var(--progress-indicator-after-linear-gradient), transparent 75%);
}
@keyframes rt-progress-indicator-indeterminate-grow {
  0% {
    transform: scaleX(0.01);
  }
  20% {
    transform: scaleX(0.1);
  }
  30% {
    transform: scaleX(0.6);
  }
  40%,
  50% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes rt-progress-indicator-indeterminate-shine-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.rt-ProgressRoot:where(.rt-r-size-1) {
  --progress-height: var(--space-1);
}
.rt-ProgressRoot:where(.rt-r-size-2) {
  --progress-height: calc(var(--space-2) * 0.75);
}
.rt-ProgressRoot:where(.rt-r-size-3) {
  --progress-height: var(--space-2);
}
@media (min-width: 520px) {
  .rt-ProgressRoot:where(.xs\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }
  .rt-ProgressRoot:where(.xs\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }
  .rt-ProgressRoot:where(.xs\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}
@media (min-width: 768px) {
  .rt-ProgressRoot:where(.sm\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }
  .rt-ProgressRoot:where(.sm\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }
  .rt-ProgressRoot:where(.sm\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}
@media (min-width: 1024px) {
  .rt-ProgressRoot:where(.md\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }
  .rt-ProgressRoot:where(.md\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }
  .rt-ProgressRoot:where(.md\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}
@media (min-width: 1280px) {
  .rt-ProgressRoot:where(.lg\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }
  .rt-ProgressRoot:where(.lg\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }
  .rt-ProgressRoot:where(.lg\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}
@media (min-width: 1640px) {
  .rt-ProgressRoot:where(.xl\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }
  .rt-ProgressRoot:where(.xl\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }
  .rt-ProgressRoot:where(.xl\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}
.rt-ProgressRoot:where(.rt-variant-surface) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-surface-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-surface-indeterminate-pulse;
  background-color: var(--gray-a3);
}
.rt-ProgressRoot:where(.rt-variant-surface)::after {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}
.rt-ProgressRoot:where(.rt-variant-surface) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}
@keyframes rt-progress-indicator-surface-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}
@keyframes rt-progress-indicator-surface-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }
  100% {
    background-color: var(--accent-track);
  }
}
.rt-ProgressRoot:where(.rt-variant-classic) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-classic-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-classic-indeterminate-pulse;
  background-color: var(--gray-a3);
}
.rt-ProgressRoot:where(.rt-variant-classic)::after {
  box-shadow: var(--shadow-1);
}
.rt-ProgressRoot:where(.rt-variant-classic) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}
@keyframes rt-progress-indicator-classic-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}
@keyframes rt-progress-indicator-classic-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }
  100% {
    background-color: var(--accent-track);
  }
}
.rt-ProgressRoot:where(.rt-variant-soft) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-soft-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-soft-indeterminate-pulse;
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}
.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5));
  background-color: var(--accent-8);
}
.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator)::after {
  opacity: 0.75;
}
@keyframes rt-progress-indicator-soft-indeterminate-fade {
  100% {
    background-color: var(--accent-5);
  }
}
@keyframes rt-progress-indicator-soft-indeterminate-pulse {
  0% {
    background-color: var(--accent-5);
  }
  100% {
    background-color: var(--accent-7);
  }
}
.rt-ProgressRoot:where(.rt-high-contrast) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-high-contrast-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-high-contrast-indeterminate-pulse;
}
.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-12);
}
.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator)::after {
  opacity: 0.75;
}
@keyframes rt-progress-indicator-high-contrast-indeterminate-fade {
  100% {
    opacity: 0.8;
  }
}
@keyframes rt-progress-indicator-high-contrast-indeterminate-pulse {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.rt-Quote {
  box-sizing: border-box;
  font-family: var(--quote-font-family);
  font-size: calc(var(--quote-font-size-adjust) * 1em);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--quote-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
}
.rt-Quote :where(.rt-Quote) {
  font-size: inherit;
}
.rt-RadioCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}
.rt-RadioCardsItem {
  --base-card-padding-top: var(--radio-cards-item-padding-y);
  --base-card-padding-right: var(--radio-cards-item-padding-x);
  --base-card-padding-bottom: var(--radio-cards-item-padding-y);
  --base-card-padding-left: var(--radio-cards-item-padding-x);
  --base-card-border-radius: var(--radio-cards-item-border-radius);
  --base-card-border-width: var(--radio-cards-item-border-width);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
}
.rt-RadioCardsItem > * {
  pointer-events: none;
}
.rt-RadioCardsItem > :where(svg) {
  flex-shrink: 0;
}
.rt-RadioCardsItem::after {
  outline-offset: -1px;
}
.rt-RadioCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-3);
  --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
  --radio-cards-item-border-radius: var(--radius-3);
}
.rt-RadioCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-4);
  --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
  --radio-cards-item-border-radius: var(--radius-3);
}
.rt-RadioCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --radio-cards-item-padding-x: var(--space-5);
  --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
  --radio-cards-item-border-radius: var(--radius-4);
}
@media (min-width: 520px) {
  .rt-RadioCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}
@media (min-width: 768px) {
  .rt-RadioCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}
@media (min-width: 1024px) {
  .rt-RadioCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}
@media (min-width: 1280px) {
  .rt-RadioCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}
@media (min-width: 1640px) {
  .rt-RadioCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }
  .rt-RadioCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}
:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
}
:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem::before {
  background-color: var(--radio-cards-item-background-color);
}
:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem::after {
  box-shadow: var(--base-card-surface-box-shadow);
}
@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}
:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}
:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem::before {
  background-color: var(--radio-cards-item-background-color);
}
:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}
@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}
.rt-RadioCardsItem:where([data-state='checked'])::after {
  outline: 2px solid var(--accent-indicator);
}
:where(.rt-RadioCardsRoot.rt-high-contrast) .rt-RadioCardsItem:where([data-state='checked'])::after {
  outline-color: var(--accent-12);
}
.rt-RadioCardsItem:where(:focus-visible)::after {
  outline: 2px solid var(--focus-8);
}
.rt-RadioCardsItem:where(:focus-visible):where([data-state='checked'])::before {
  background-image: linear-gradient(var(--focus-a3), var(--focus-a3));
}
.rt-RadioCardsItem:where(:focus-visible):where([data-state='checked'])::after {
  outline-color: var(--focus-10);
}
.rt-RadioCardsItem:where(:disabled) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}
.rt-RadioCardsItem:where(:disabled)::selection {
  background-color: var(--gray-a5);
}
.rt-RadioCardsItem:where(:disabled)::before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-RadioCardsItem:where(:disabled)::after {
  outline-color: var(--gray-8);
}
.rt-RadioGroupRoot {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}
.rt-RadioGroupItem {
  display: flex;
  gap: 0.5em;
  width: -moz-fit-content;
  width: fit-content;
}
.rt-RadioGroupItemInner {
  min-width: 0;
}
.rt-BaseRadioRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  flex-shrink: 0;
  cursor: var(--cursor-radio);
  height: var(--skeleton-height, var(--line-height, var(--radio-size)));
  --skeleton-height-override: var(--radio-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: 100%;
}
.rt-BaseRadioRoot:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}
.rt-BaseRadioRoot::before {
  content: '';
  display: block;
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
}
.rt-BaseRadioRoot::after {
  pointer-events: none;
  position: absolute;
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
  transform: scale(0.4);
}
.rt-BaseRadioRoot:where(:checked, [data-state='checked'])::after {
  content: '';
}
.rt-BaseRadioRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
.rt-BaseRadioRoot:where(.rt-r-size-1) {
  --radio-size: calc(var(--space-4) * 0.875);
}
.rt-BaseRadioRoot:where(.rt-r-size-2) {
  --radio-size: var(--space-4);
}
.rt-BaseRadioRoot:where(.rt-r-size-3) {
  --radio-size: calc(var(--space-4) * 1.25);
}
@media (min-width: 520px) {
  .rt-BaseRadioRoot:where(.xs\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }
  .rt-BaseRadioRoot:where(.xs\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }
  .rt-BaseRadioRoot:where(.xs\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 768px) {
  .rt-BaseRadioRoot:where(.sm\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }
  .rt-BaseRadioRoot:where(.sm\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }
  .rt-BaseRadioRoot:where(.sm\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1024px) {
  .rt-BaseRadioRoot:where(.md\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }
  .rt-BaseRadioRoot:where(.md\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }
  .rt-BaseRadioRoot:where(.md\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1280px) {
  .rt-BaseRadioRoot:where(.lg\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }
  .rt-BaseRadioRoot:where(.lg\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }
  .rt-BaseRadioRoot:where(.lg\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}
@media (min-width: 1640px) {
  .rt-BaseRadioRoot:where(.xl\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }
  .rt-BaseRadioRoot:where(.xl\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }
  .rt-BaseRadioRoot:where(.xl\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(:not(:checked), [data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-indicator);
}
.rt-BaseRadioRoot:where(.rt-variant-surface)::after {
  background-color: var(--accent-contrast);
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-12);
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast)::after {
  background-color: var(--accent-1);
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled])::before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a3);
}
.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(:not(:checked), [data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-7), var(--shadow-1);
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a3));
  box-shadow: inset 0 0.5px 0.5px var(--white-a4), inset 0 -0.5px 0.5px var(--black-a4);
}
.rt-BaseRadioRoot:where(.rt-variant-classic)::after {
  background-color: var(--accent-contrast);
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-12);
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast)::after {
  background-color: var(--accent-1);
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled])::before {
  box-shadow: var(--shadow-1);
  background-color: var(--gray-a3);
  background-image: none;
}
.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}
.rt-BaseRadioRoot:where(.rt-variant-soft)::before {
  background-color: var(--accent-a4);
}
.rt-BaseRadioRoot:where(.rt-variant-soft)::after {
  background-color: var(--accent-a11);
}
.rt-BaseRadioRoot:where(.rt-variant-soft):where(.rt-high-contrast)::after {
  background-color: var(--accent-12);
}
.rt-BaseRadioRoot:where(.rt-variant-soft):where(:focus-visible)::before {
  outline-color: var(--accent-a8);
}
.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled])::before {
  background-color: var(--gray-a3);
}
.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}
.rt-ScrollAreaRoot {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.rt-ScrollAreaViewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.rt-ScrollAreaViewport:where(:focus-visible) + :where(.rt-ScrollAreaViewportFocusRing) {
  position: absolute;
  inset: 0;
  pointer-events: none;
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
}
.rt-ScrollAreaViewport:where(:has(.rt-ScrollAreaScrollbar[data-orientation='horizontal'])) {
  overscroll-behavior-x: contain;
}
.rt-ScrollAreaViewport > * {
  display: block !important;
  width: -moz-fit-content;
  width: fit-content;
  flex-grow: 1;
}
.rt-ScrollAreaScrollbar {
  display: flex;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  touch-action: none;
  background-color: var(--gray-a3);
  border-radius: var(--scrollarea-scrollbar-border-radius);
  animation-duration: 120ms;
  animation-timing-function: ease-out;
}
.rt-ScrollAreaScrollbar:where([data-orientation='vertical']) {
  flex-direction: column;
  width: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-vertical-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-vertical-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-vertical-margin-left);
  margin-right: var(--scrollarea-scrollbar-vertical-margin-right);
}
.rt-ScrollAreaScrollbar:where([data-orientation='horizontal']) {
  flex-direction: row;
  height: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-horizontal-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-horizontal-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-horizontal-margin-left);
  margin-right: var(--scrollarea-scrollbar-horizontal-margin-right);
}
.rt-ScrollAreaThumb {
  position: relative;
  background-color: var(--gray-a8);
  border-radius: inherit;
  transition: background-color 100ms;
}
.rt-ScrollAreaThumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: var(--space-4);
  min-height: var(--space-4);
}
.rt-ScrollAreaScrollbar:where(.rt-r-size-1) {
  --scrollarea-scrollbar-size: var(--space-1);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}
.rt-ScrollAreaScrollbar:where(.rt-r-size-2) {
  --scrollarea-scrollbar-size: var(--space-2);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}
.rt-ScrollAreaScrollbar:where(.rt-r-size-3) {
  --scrollarea-scrollbar-size: var(--space-3);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}
@media (min-width: 520px) {
  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}
@media (min-width: 768px) {
  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}
@media (min-width: 1024px) {
  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}
@media (min-width: 1280px) {
  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}
@media (min-width: 1640px) {
  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}
.rt-ScrollAreaScrollbar:where([data-state='visible']) {
  animation-name: rt-fade-in;
}
.rt-ScrollAreaScrollbar:where([data-state='hidden']) {
  animation-name: rt-fade-out;
}
@media (hover: hover) {
  .rt-ScrollAreaThumb:where(:hover) {
    background-color: var(--gray-a9);
  }
}
.rt-SegmentedControlRoot {
  display: inline-grid;
  vertical-align: top;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: stretch;
  color: var(--gray-12);
  background-color: var(--color-surface);
  background-image: linear-gradient(var(--gray-a3), var(--gray-a3));
  position: relative;
  min-width: max-content;
  font-family: var(--default-font-family);
  font-style: normal;
  text-align: center;
  isolation: isolate;
  border-radius: var(--segmented-control-border-radius);
}
.rt-SegmentedControlRoot:where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-3);
}
.rt-SegmentedControlItem {
  display: flex;
  align-items: stretch;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
}
.rt-SegmentedControlItem:where(:first-child) {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.rt-SegmentedControlItem:where(:nth-last-child(2)) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.rt-SegmentedControlItem:where(:focus-visible) {
  border-radius: inherit;
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-SegmentedControlItemLabel :where(svg) {
  flex-shrink: 0;
}
@media (hover: hover) {
  :where(.rt-SegmentedControlItem[data-state='off']:not([disabled]):hover) .rt-SegmentedControlItemLabel {
    background-color: var(--gray-a2);
  }
}
.rt-SegmentedControlItemLabelInactive {
  position: absolute;
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
  opacity: 1;
  transition-timing-function: ease-out;
}
:where(.rt-SegmentedControlItem[data-state='on']) .rt-SegmentedControlItemLabelInactive {
  opacity: 0;
  transition-timing-function: ease-in;
}
.rt-SegmentedControlItemLabelActive {
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
  opacity: 0;
  transition-timing-function: ease-in;
}
:where(.rt-SegmentedControlItem[data-state='on']) .rt-SegmentedControlItemLabelActive {
  opacity: 1;
  transition-timing-function: ease-out;
}
.rt-SegmentedControlItemSeparator {
  z-index: -1;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  width: 1px;
  background-color: var(--gray-a4);
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  transition-timing-function: ease-out;
}
:where(.rt-SegmentedControlItem:first-child) .rt-SegmentedControlItemSeparator,
  :where(.rt-SegmentedControlItem:where([data-state='on'], :focus-visible)) .rt-SegmentedControlItemSeparator,
  :where(.rt-SegmentedControlItem:where([data-state='on'], :focus-visible)) + * .rt-SegmentedControlItemSeparator {
  opacity: 0;
  transition-timing-function: ease-in;
}
:where(.rt-SegmentedControlRoot:has(:focus-visible)) .rt-SegmentedControlItemSeparator {
  transition-duration: 0ms;
}
.rt-SegmentedControlIndicator {
  display: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-duration: var(--segmented-control-transition-duration);
}
.rt-SegmentedControlIndicator::before {
  inset: 1px;
  position: absolute;
  border-radius: max(0.5px, calc(var(--segmented-control-border-radius) - 1px));
  background-color: var(--segmented-control-indicator-background-color);
  content: '';
}
:where(.rt-SegmentedControlItem[data-state='on']) ~ .rt-SegmentedControlIndicator {
  display: block;
}
:where(.rt-SegmentedControlItem[disabled]) ~ .rt-SegmentedControlIndicator {
  --segmented-control-indicator-background-color: var(--gray-a3);
}
:where(.rt-SegmentedControlItem[disabled]) ~ .rt-SegmentedControlIndicator::before {
  inset: 0px;
  box-shadow: none;
}
.rt-SegmentedControlIndicator:where(:nth-child(2)) {
  width: calc(100% / 1);
}
.rt-SegmentedControlIndicator:where(:nth-child(3)) {
  width: calc(100% / 2);
}
.rt-SegmentedControlIndicator:where(:nth-child(4)) {
  width: calc(100% / 3);
}
.rt-SegmentedControlIndicator:where(:nth-child(5)) {
  width: calc(100% / 4);
}
.rt-SegmentedControlIndicator:where(:nth-child(6)) {
  width: calc(100% / 5);
}
.rt-SegmentedControlIndicator:where(:nth-child(7)) {
  width: calc(100% / 6);
}
.rt-SegmentedControlIndicator:where(:nth-child(8)) {
  width: calc(100% / 7);
}
.rt-SegmentedControlIndicator:where(:nth-child(9)) {
  width: calc(100% / 8);
}
.rt-SegmentedControlIndicator:where(:nth-child(10)) {
  width: calc(100% / 9);
}
.rt-SegmentedControlIndicator:where(:nth-child(11)) {
  width: calc(100% / 10);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(1)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(0%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(2)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(100%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(3)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(200%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(4)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(300%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(5)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(400%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(6)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(500%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(7)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(600%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(8)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(700%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(9)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(800%);
}
:where(.rt-SegmentedControlItem[data-state='on']:nth-child(10)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(900%);
}
.rt-SegmentedControlItemLabel {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}
.rt-SegmentedControlRoot:where(.rt-r-size-1) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-5);
}
.rt-SegmentedControlRoot:where(.rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
  gap: var(--space-1);
}
.rt-SegmentedControlRoot:where(.rt-r-size-2) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-6);
}
.rt-SegmentedControlRoot:where(.rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-2);
}
.rt-SegmentedControlRoot:where(.rt-r-size-3) {
  --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
  height: var(--space-7);
}
.rt-SegmentedControlRoot:where(.rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-3);
}
@media (min-width: 520px) {
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}
@media (min-width: 768px) {
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}
@media (min-width: 1024px) {
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}
@media (min-width: 1280px) {
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}
@media (min-width: 1640px) {
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}
.rt-SegmentedControlRoot:where(.rt-variant-surface) :where(.rt-SegmentedControlItem:not([disabled])) ~ :where(.rt-SegmentedControlIndicator)::before {
  box-shadow: 0 0 0 1px var(--gray-a4);
}
.rt-SegmentedControlRoot:where(.rt-variant-classic) :where(.rt-SegmentedControlItem:not([disabled])) ~ :where(.rt-SegmentedControlIndicator)::before {
  box-shadow: var(--shadow-2);
}
.rt-SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  vertical-align: top;
  line-height: var(--height);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: start;
  color: var(--gray-12);
}
.rt-SelectTrigger:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-SelectTriggerInner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rt-SelectIcon {
  flex-shrink: 0;
}
:where(.rt-SelectTrigger:not(.rt-variant-ghost)) .rt-SelectIcon {
  opacity: 0.9;
}
.rt-SelectContent:where([data-side]) {
  min-width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  transform-origin: var(--radix-select-content-transform-origin);
}
.rt-SelectViewport {
  box-sizing: border-box;
  padding: var(--select-content-padding);
}
:where(.rt-SelectContent:has(.rt-ScrollAreaScrollbar[data-orientation='vertical'])) .rt-SelectViewport {
  padding-right: var(--space-3);
}
.rt-SelectItem {
  display: flex;
  align-items: center;
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  position: relative;
  box-sizing: border-box;
  outline: none;
  scroll-margin: var(--select-content-padding) 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  cursor: var(--cursor-menu-item);
}
.rt-SelectItemIndicator {
  position: absolute;
  left: 0;
  width: var(--select-item-indicator-width);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rt-SelectSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--select-item-indicator-width);
  margin-right: var(--select-separator-margin-right);
  background-color: var(--gray-a6);
}
.rt-SelectLabel {
  display: flex;
  align-items: center;
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  color: var(--gray-a10);
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  cursor: default;
}
:where(.rt-SelectItem) + .rt-SelectLabel {
  margin-top: var(--space-2);
}
.rt-SelectTrigger:where(:not(.rt-variant-ghost)) {
  box-sizing: border-box;
  height: var(--select-trigger-height);
}
.rt-SelectTrigger:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--select-trigger-ghost-padding-y) var(--select-trigger-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--select-trigger-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right) - var(--select-trigger-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--select-trigger-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left) - var(--select-trigger-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}
:where(.rt-SelectTrigger:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}
.rt-SelectTrigger:where(.rt-r-size-1) {
  --select-trigger-height: var(--space-5);
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: max(var(--radius-1), var(--radius-full));
}
.rt-SelectTrigger:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.rt-SelectTrigger:where(.rt-r-size-1):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}
.rt-SelectTrigger:where(.rt-r-size-2) {
  --select-trigger-height: var(--space-6);
  gap: calc(var(--space-1) * 1.5);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}
.rt-SelectTrigger:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.rt-SelectTrigger:where(.rt-r-size-2):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}
.rt-SelectTrigger:where(.rt-r-size-3) {
  --select-trigger-height: var(--space-7);
  gap: var(--space-2);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
  border-radius: max(var(--radius-3), var(--radius-full));
}
.rt-SelectTrigger:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.rt-SelectTrigger:where(.rt-r-size-3):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-3);
  --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
}
.rt-SelectTrigger:where(.rt-r-size-3) :where(.rt-SelectIcon) {
  width: 11px;
  height: 11px;
}
@media (min-width: 520px) {
  .rt-SelectTrigger:where(.xs\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-SelectTrigger:where(.xs\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 768px) {
  .rt-SelectTrigger:where(.sm\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-SelectTrigger:where(.sm\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 1024px) {
  .rt-SelectTrigger:where(.md\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-SelectTrigger:where(.md\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 1280px) {
  .rt-SelectTrigger:where(.lg\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-SelectTrigger:where(.lg\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}
@media (min-width: 1640px) {
  .rt-SelectTrigger:where(.xl\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }
  .rt-SelectTrigger:where(.xl\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}
.rt-SelectContent:where(.rt-r-size-1) {
  --select-content-padding: var(--space-1);
  --select-item-height: var(--space-5);
  --select-item-indicator-width: calc(var(--space-5) / 1.2);
  --select-separator-margin-right: var(--space-2);
  border-radius: var(--radius-3);
}
.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
}
.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}
.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}
.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) {
  --select-content-padding: var(--space-2);
  --select-item-height: var(--space-6);
  --select-item-indicator-width: var(--space-5);
  --select-separator-margin-right: var(--space-3);
  border-radius: var(--radius-4);
}
.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
}
.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectItem) {
  line-height: var(--line-height-2);
  border-radius: var(--radius-2);
}
.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItem) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}
.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}
.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItem) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}
.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}
@media (min-width: 520px) {
  .rt-SelectContent:where(.xs\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}
@media (min-width: 768px) {
  .rt-SelectContent:where(.sm\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}
@media (min-width: 1024px) {
  .rt-SelectContent:where(.md\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }
  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }
  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }
  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }
  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}
@media (min-width: 1280px) {
  .rt-SelectContent:where(.lg\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}
@media (min-width: 1640px) {
  .rt-SelectContent:where(.xl\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }
  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}
.rt-SelectTrigger:where(.rt-variant-surface) {
  color: var(--gray-12);
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}
@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a8);
  }
}
.rt-SelectTrigger:where(.rt-variant-surface):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--gray-a8);
}
.rt-SelectTrigger:where(.rt-variant-surface):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a2);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
}
.rt-SelectTrigger:where(.rt-variant-surface):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}
.rt-SelectTrigger:where(.rt-variant-classic) {
  color: var(--gray-12);
  background-image: linear-gradient(var(--gray-2), var(--gray-1));
  box-shadow: var(--select-trigger-classic-box-shadow);
  position: relative;
  z-index: 0;
}
.rt-SelectTrigger:where(.rt-variant-classic)::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border: 2px solid transparent;
  background-clip: content-box;
  border-radius: inherit;
  pointer-events: none;
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1) 130%), linear-gradient(var(--color-surface), transparent);
}
@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
  }
  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover)::before {
    background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
  }
}
.rt-SelectTrigger:where(.rt-variant-classic):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
}
.rt-SelectTrigger:where(.rt-variant-classic):where([data-state='open'])::before {
  background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
}
.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-2);
  background-image: none;
  box-shadow: var(--base-button-classic-disabled-box-shadow);
}
.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled)::before {
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}
.rt-SelectTrigger:where(.rt-variant-classic):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}
.rt-SelectTrigger:where(.rt-variant-soft),
.rt-SelectTrigger:where(.rt-variant-ghost) {
  color: var(--accent-12);
}
.rt-SelectTrigger:where(.rt-variant-soft):where([data-placeholder]) :where(.rt-SelectTriggerInner), .rt-SelectTrigger:where(.rt-variant-ghost):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--accent-12);
  opacity: 0.6;
}
.rt-SelectTrigger:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}
@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}
.rt-SelectTrigger:where(.rt-variant-soft):where([data-state='open']) {
  background-color: var(--accent-a4);
}
.rt-SelectTrigger:where(.rt-variant-soft):where(:focus-visible) {
  outline-color: var(--accent-8);
}
.rt-SelectTrigger:where(.rt-variant-soft):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a3);
}
@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}
.rt-SelectTrigger:where(.rt-variant-ghost):where([data-state='open']) {
  background-color: var(--accent-a3);
}
.rt-SelectTrigger:where(.rt-variant-ghost):where(:disabled) {
  color: var(--gray-a11);
  background-color: transparent;
}
.rt-SelectTrigger:where(:disabled) :where(.rt-SelectIcon) {
  color: var(--gray-a9);
}
.rt-SelectContent {
  box-shadow: var(--shadow-5);
  --scrollarea-scrollbar-vertical-margin-top: var(--select-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--select-content-padding);
  overflow: hidden;
  background-color: var(--color-panel-solid);
}
.rt-SelectItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}
.rt-SelectContent:where(.rt-variant-solid) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}
.rt-SelectContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}
.rt-SelectContent:where(.rt-variant-soft) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-a4);
}
.rt-Separator {
  display: block;
  background-color: var(--accent-a6);
}
.rt-Separator:where(.rt-r-orientation-horizontal) {
  width: var(--separator-size);
  height: 1px;
}
.rt-Separator:where(.rt-r-orientation-vertical) {
  width: 1px;
  height: var(--separator-size);
}
@media (min-width: 520px) {
  .rt-Separator:where(.xs\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }
  .rt-Separator:where(.xs\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}
@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }
  .rt-Separator:where(.sm\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}
@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }
  .rt-Separator:where(.md\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}
@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }
  .rt-Separator:where(.lg\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}
@media (min-width: 1640px) {
  .rt-Separator:where(.xl\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }
  .rt-Separator:where(.xl\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}
.rt-Separator:where(.rt-r-size-1) {
  --separator-size: var(--space-4);
}
.rt-Separator:where(.rt-r-size-2) {
  --separator-size: var(--space-6);
}
.rt-Separator:where(.rt-r-size-3) {
  --separator-size: var(--space-9);
}
.rt-Separator:where(.rt-r-size-4) {
  --separator-size: 100%;
}
@media (min-width: 520px) {
  .rt-Separator:where(.xs\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }
  .rt-Separator:where(.xs\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }
  .rt-Separator:where(.xs\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }
  .rt-Separator:where(.xs\:rt-r-size-4) {
    --separator-size: 100%;
  }
}
@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }
  .rt-Separator:where(.sm\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }
  .rt-Separator:where(.sm\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }
  .rt-Separator:where(.sm\:rt-r-size-4) {
    --separator-size: 100%;
  }
}
@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }
  .rt-Separator:where(.md\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }
  .rt-Separator:where(.md\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }
  .rt-Separator:where(.md\:rt-r-size-4) {
    --separator-size: 100%;
  }
}
@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }
  .rt-Separator:where(.lg\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }
  .rt-Separator:where(.lg\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }
  .rt-Separator:where(.lg\:rt-r-size-4) {
    --separator-size: 100%;
  }
}
@media (min-width: 1640px) {
  .rt-Separator:where(.xl\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }
  .rt-Separator:where(.xl\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }
  .rt-Separator:where(.xl\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }
  .rt-Separator:where(.xl\:rt-r-size-4) {
    --separator-size: 100%;
  }
}
.rt-SliderRoot {
  --slider-thumb-size: calc(var(--slider-track-size) + var(--space-1));
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-radius: max(calc(var(--radius-factor) * var(--slider-track-size) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  touch-action: none;
}
.rt-SliderRoot:where([data-orientation='horizontal']) {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: var(--slider-track-size);
}
.rt-SliderRoot:where([data-orientation='vertical']) {
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  flex-direction: column;
  width: var(--slider-track-size);
}
.rt-SliderTrack {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  border-radius: inherit;
}
.rt-SliderTrack:where([data-orientation='horizontal']) {
  height: var(--slider-track-size);
}
.rt-SliderTrack:where([data-orientation='vertical']) {
  width: var(--slider-track-size);
}
.rt-SliderRange {
  position: absolute;
  border-radius: inherit;
}
.rt-SliderRange:where([data-orientation='horizontal']) {
  height: 100%;
}
.rt-SliderRange:where([data-orientation='vertical']) {
  width: 100%;
}
.rt-SliderThumb {
  display: block;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  outline: 0;
}
.rt-SliderThumb::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: calc(var(--slider-thumb-size) * 3);
  height: calc(var(--slider-thumb-size) * 3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rt-SliderThumb::after {
  content: '';
  position: absolute;
  inset: calc(-0.25 * var(--slider-track-size));
  background-color: white;
  border-radius: max(var(--radius-1), var(--radius-thumb));
  box-shadow: var(--slider-thumb-box-shadow);
  cursor: var(--cursor-slider-thumb);
}
.rt-SliderThumb:where(:focus-visible)::after {
  box-shadow: var(--slider-thumb-box-shadow), 0 0 0 3px var(--accent-3), 0 0 0 5px var(--focus-8);
}
.rt-SliderThumb:where(:active) {
  cursor: var(--cursor-slider-thumb-active);
}
.rt-SliderRoot:where(.rt-r-size-1) {
  --slider-track-size: calc(var(--space-2) * 0.75);
}
.rt-SliderRoot:where(.rt-r-size-2) {
  --slider-track-size: var(--space-2);
}
.rt-SliderRoot:where(.rt-r-size-3) {
  --slider-track-size: calc(var(--space-2) * 1.25);
}
@media (min-width: 520px) {
  .rt-SliderRoot:where(.xs\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }
  .rt-SliderRoot:where(.xs\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }
  .rt-SliderRoot:where(.xs\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}
@media (min-width: 768px) {
  .rt-SliderRoot:where(.sm\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }
  .rt-SliderRoot:where(.sm\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }
  .rt-SliderRoot:where(.sm\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}
@media (min-width: 1024px) {
  .rt-SliderRoot:where(.md\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }
  .rt-SliderRoot:where(.md\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }
  .rt-SliderRoot:where(.md\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}
@media (min-width: 1280px) {
  .rt-SliderRoot:where(.lg\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }
  .rt-SliderRoot:where(.lg\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }
  .rt-SliderRoot:where(.lg\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}
@media (min-width: 1640px) {
  .rt-SliderRoot:where(.xl\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }
  .rt-SliderRoot:where(.xl\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }
  .rt-SliderRoot:where(.xl\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}
.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}
.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack):where([data-disabled]) {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}
.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}
.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a4);
}
.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  position: relative;
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack)::before {
  content: '';
  inset: 0;
  position: absolute;
  border-radius: inherit;
  box-shadow: var(--shadow-1);
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack):where([data-disabled])::before {
  opacity: 0.5;
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}
.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}
.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack) {
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}
.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack):where([data-disabled]) {
  background-color: var(--gray-a4);
  background-image: none;
}
.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderRange) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5)), var(--slider-range-high-contrast-background-image);
  background-color: var(--accent-6);
}
.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 0 0 1px var(--gray-a2), 0 0 0 1px var(--accent-a2), 0 1px 2px var(--gray-a4), 0 1px 3px -0.5px var(--gray-a3);
}
.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-5);
}
.rt-SliderRoot:where(:not(.rt-high-contrast)) {
  --slider-range-high-contrast-background-image: none;
}
.rt-SliderRoot:where([data-disabled]) {
  cursor: var(--cursor-disabled);
  mix-blend-mode: var(--slider-disabled-blend-mode);
}
.rt-SliderRange:where([data-disabled]) {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.rt-SliderThumb:where([data-disabled]),
  .rt-SliderThumb:where([data-disabled])::after {
  cursor: var(--cursor-disabled);
}
.rt-Spinner {
  display: block;
  position: relative;
  opacity: var(--spinner-opacity);
}
.rt-SpinnerLeaf {
  position: absolute;
  top: 0;
  left: calc(50% - 12.5% / 2);
  width: 12.5%;
  height: 100%;
  animation: rt-spinner-leaf-fade var(--spinner-animation-duration) linear infinite;
}
.rt-SpinnerLeaf::before {
  content: '';
  display: block;
  width: 100%;
  height: 30%;
  border-radius: var(--radius-1);
  background-color: currentColor;
}
.rt-SpinnerLeaf:where(:nth-child(1)) {
  transform: rotate(0deg);
  animation-delay: calc(-8 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(2)) {
  transform: rotate(45deg);
  animation-delay: calc(-7 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(3)) {
  transform: rotate(90deg);
  animation-delay: calc(-6 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(4)) {
  transform: rotate(135deg);
  animation-delay: calc(-5 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(5)) {
  transform: rotate(180deg);
  animation-delay: calc(-4 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(6)) {
  transform: rotate(225deg);
  animation-delay: calc(-3 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(7)) {
  transform: rotate(270deg);
  animation-delay: calc(-2 / 8 * var(--spinner-animation-duration));
}
.rt-SpinnerLeaf:where(:nth-child(8)) {
  transform: rotate(315deg);
  animation-delay: calc(-1 / 8 * var(--spinner-animation-duration));
}
@keyframes rt-spinner-leaf-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
.rt-Spinner:where(.rt-r-size-1) {
  width: var(--space-3);
  height: var(--space-3);
}
.rt-Spinner:where(.rt-r-size-2) {
  width: var(--space-4);
  height: var(--space-4);
}
.rt-Spinner:where(.rt-r-size-3) {
  width: calc(1.25 * var(--space-4));
  height: calc(1.25 * var(--space-4));
}
@media (min-width: 520px) {
  .rt-Spinner:where(.xs\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }
  .rt-Spinner:where(.xs\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }
  .rt-Spinner:where(.xs\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}
@media (min-width: 768px) {
  .rt-Spinner:where(.sm\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }
  .rt-Spinner:where(.sm\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }
  .rt-Spinner:where(.sm\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}
@media (min-width: 1024px) {
  .rt-Spinner:where(.md\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }
  .rt-Spinner:where(.md\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }
  .rt-Spinner:where(.md\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}
@media (min-width: 1280px) {
  .rt-Spinner:where(.lg\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }
  .rt-Spinner:where(.lg\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }
  .rt-Spinner:where(.lg\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}
@media (min-width: 1640px) {
  .rt-Spinner:where(.xl\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }
  .rt-Spinner:where(.xl\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }
  .rt-Spinner:where(.xl\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}
.rt-Strong {
  font-family: var(--strong-font-family);
  font-size: calc(var(--strong-font-size-adjust) * 1em);
  font-style: var(--strong-font-style);
  font-weight: var(--strong-font-weight);
  letter-spacing: calc(var(--strong-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
}
.rt-Strong :where(.rt-Strong) {
  font-size: inherit;
}
.rt-SwitchRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  flex-shrink: 0;
  height: var(--skeleton-height, var(--line-height, var(--switch-height)));
  --skeleton-height-override: var(--switch-height);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--switch-border-radius);
  --switch-width: calc(var(--switch-height) * 1.75);
  --switch-thumb-inset: 1px;
  --switch-thumb-size: calc(var(--switch-height) - var(--switch-thumb-inset) * 2);
  --switch-thumb-translate-x: calc(var(--switch-width) - var(--switch-height));
}
.rt-SwitchRoot::before {
  content: '';
  display: block;
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-border-radius);
  transition: background-position, background-color, box-shadow, filter;
  transition-timing-function: linear, ease-in-out, ease-in-out, ease-in-out;
  background-repeat: no-repeat;
  background-size: calc(var(--switch-width) * 2 + var(--switch-height)) 100%;
  cursor: var(--cursor-switch);
}
.rt-SwitchRoot:where([data-state='unchecked'])::before {
  transition-duration: 120ms, 140ms, 140ms, 140ms;
  background-position-x: 100%;
}
.rt-SwitchRoot:where([data-state='checked'])::before {
  transition-duration: 160ms, 140ms, 140ms, 140ms;
  background-position: 0%;
}
.rt-SwitchRoot:where(:active)::before {
  transition-duration: 30ms;
}
.rt-SwitchRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}
.rt-SwitchRoot:where([data-disabled])::before {
  cursor: var(--cursor-disabled);
}
.rt-SwitchThumb {
  background-color: white;
  position: absolute;
  left: var(--switch-thumb-inset);
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  border-radius: calc(var(--switch-border-radius) - var(--switch-thumb-inset));
  transition: transform 140ms cubic-bezier(0.45, 0.05, 0.55, 0.95), box-shadow 140ms ease-in-out;
}
.rt-SwitchThumb:where([data-state='checked']) {
  transform: translateX(var(--switch-thumb-translate-x));
}
.rt-SwitchRoot:where(.rt-r-size-1) {
  --switch-height: var(--space-4);
  --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
}
.rt-SwitchRoot:where(.rt-r-size-2) {
  --switch-height: calc(var(--space-5) * 5 / 6);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}
.rt-SwitchRoot:where(.rt-r-size-3) {
  --switch-height: var(--space-5);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}
@media (min-width: 520px) {
  .rt-SwitchRoot:where(.xs\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.xs\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.xs\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}
@media (min-width: 768px) {
  .rt-SwitchRoot:where(.sm\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.sm\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.sm\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}
@media (min-width: 1024px) {
  .rt-SwitchRoot:where(.md\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.md\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.md\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}
@media (min-width: 1280px) {
  .rt-SwitchRoot:where(.lg\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.lg\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.lg\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}
@media (min-width: 1640px) {
  .rt-SwitchRoot:where(.xl\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.xl\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
  .rt-SwitchRoot:where(.xl\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}
.rt-SwitchRoot:where(.rt-variant-surface)::before {
  background-color: var(--gray-a3);
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}
.rt-SwitchRoot:where(.rt-variant-surface):where(:active)::before {
  background-color: var(--gray-a4);
}
.rt-SwitchRoot:where(.rt-variant-surface):where([data-state='checked']:active)::before {
  filter: var(--switch-surface-checked-active-filter);
}
.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast)::before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}
.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}
.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}
.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a3);
}
.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 0 1px 1px var(--black-a2), 0 1px 1px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}
.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='checked']):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}
.rt-SwitchRoot:where(.rt-variant-classic)::before {
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  background-color: var(--gray-a4);
  box-shadow: var(--shadow-1);
}
.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='unchecked']:active)::before {
  background-color: var(--gray-a5);
}
.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='checked'])::before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='checked']:active)::before {
  filter: var(--switch-surface-checked-active-filter);
}
.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast)::before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}
.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}
.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}
.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a5);
  box-shadow: var(--shadow-1);
  opacity: 0.5;
}
.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 1px 3px var(--black-a3), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='checked']):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}
.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}
.rt-SwitchRoot:where(.rt-variant-soft)::before {
  background-image: linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--gray-a2) 40%, var(--gray-a3) 60%);
}
.rt-SwitchRoot:where(.rt-variant-soft):where([data-state='unchecked'])::before {
  background-color: var(--gray-a3);
}
.rt-SwitchRoot:where(.rt-variant-soft):where(:active)::before {
  background-color: var(--gray-a4);
}
.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast)::before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--accent-a3) 40%, var(--gray-a3) 60%);
}
.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}
.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}
.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a4);
}
.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb) {
  filter: saturate(0.45);
}
.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a1), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}
.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a2), 0 1px 3px var(--accent-a3), 0 2px 4px -1px var(--accent-a3);
}
.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-disabled]) {
  filter: none;
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}
.rt-BaseTabList::-webkit-scrollbar {
  display: none;
}
.rt-BaseTabListTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  box-sizing: border-box;
  height: var(--tab-height);
  padding-left: var(--tab-padding-x);
  padding-right: var(--tab-padding-x);
  color: var(--gray-a11);
}
.rt-BaseTabListTriggerInner,
.rt-BaseTabListTriggerInnerHidden {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--tab-inner-padding-y) var(--tab-inner-padding-x);
  border-radius: var(--tab-inner-border-radius);
}
.rt-BaseTabListTriggerInner {
  position: absolute;
}
:where(.rt-BaseTabListTrigger[data-state='inactive'], .rt-TabNavLink:not([data-active])) .rt-BaseTabListTriggerInner {
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
}
:where(.rt-BaseTabListTrigger[data-state='active'], .rt-TabNavLink[data-active]) .rt-BaseTabListTriggerInner {
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}
.rt-BaseTabListTriggerInnerHidden {
  visibility: hidden;
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}
.rt-BaseTabList:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  --tab-height: var(--space-6);
  --tab-padding-x: var(--space-1);
  --tab-inner-padding-x: var(--space-1);
  --tab-inner-padding-y: calc(var(--space-1) * 0.5);
  --tab-inner-border-radius: var(--radius-1);
}
.rt-BaseTabList:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  --tab-height: var(--space-7);
  --tab-padding-x: var(--space-2);
  --tab-inner-padding-x: var(--space-2);
  --tab-inner-padding-y: var(--space-1);
  --tab-inner-border-radius: var(--radius-2);
}
@media (min-width: 520px) {
  .rt-BaseTabList:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }
  .rt-BaseTabList:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}
@media (min-width: 768px) {
  .rt-BaseTabList:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }
  .rt-BaseTabList:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}
@media (min-width: 1024px) {
  .rt-BaseTabList:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }
  .rt-BaseTabList:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}
@media (min-width: 1280px) {
  .rt-BaseTabList:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }
  .rt-BaseTabList:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}
@media (min-width: 1640px) {
  .rt-BaseTabList:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }
  .rt-BaseTabList:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}
.rt-BaseTabList {
  box-shadow: inset 0 -1px 0 0 var(--gray-a5);
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  font-family: var(--default-font-family);
  font-style: normal;
  scrollbar-width: none;
}
@media (hover: hover) {
  .rt-BaseTabListTrigger:where(:hover) {
    color: var(--gray-12);
  }
  .rt-BaseTabListTrigger:where(:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--gray-a3);
  }
  .rt-BaseTabListTrigger:where(:focus-visible:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--accent-a3);
  }
}
.rt-BaseTabListTrigger:where([data-state='active'], [data-active]) {
  color: var(--gray-12);
}
.rt-BaseTabListTrigger:where(:focus-visible) :where(.rt-BaseTabListTriggerInner) {
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
}
.rt-BaseTabListTrigger:where([data-state='active'], [data-active])::before {
  box-sizing: border-box;
  content: '';
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--accent-indicator);
}
:where(.rt-BaseTabList.rt-high-contrast) .rt-BaseTabListTrigger:where([data-state='active'], [data-active])::before {
  background-color: var(--accent-12);
}
.rt-TabNavItem {
  display: flex;
}
.rt-TableRootTable {
  --table-row-background-color: transparent;
  --table-row-box-shadow: inset 0 -1px var(--gray-a5);
  width: 100%;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  border-radius: calc(var(--table-border-radius) - 1px);
  border-spacing: 0;
  box-sizing: border-box;
  height: 0;
}
.rt-TableHeader {
  vertical-align: inherit;
}
.rt-TableBody {
  vertical-align: inherit;
}
.rt-TableRow {
  vertical-align: inherit;
  color: var(--gray-12);
}
.rt-TableCell {
  background-color: var(--table-row-background-color);
  box-shadow: var(--table-row-box-shadow);
  box-sizing: border-box;
  vertical-align: inherit;
  padding: var(--table-cell-padding);
  height: var(--table-cell-min-height);
}
.rt-Inset :where(.rt-TableCell:first-child) {
  padding-left: var(--inset-padding-left, var(--table-cell-padding));
}
.rt-Inset :where(.rt-TableCell:last-child) {
  padding-right: var(--inset-padding-right, var(--table-cell-padding));
}
.rt-TableColumnHeaderCell {
  font-weight: bold;
}
.rt-TableRowHeaderCell {
  font-weight: normal;
}
.rt-TableRoot:where(.rt-r-size-1) {
  --table-border-radius: var(--radius-3);
  --table-cell-padding: var(--space-2);
  --table-cell-min-height: calc(36px * var(--scaling));
}
.rt-TableRoot:where(.rt-r-size-1) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}
.rt-TableRoot:where(.rt-r-size-2) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3);
  --table-cell-min-height: calc(44px * var(--scaling));
}
.rt-TableRoot:where(.rt-r-size-2) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}
.rt-TableRoot:where(.rt-r-size-3) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3) var(--space-4);
  --table-cell-min-height: var(--space-8);
}
.rt-TableRoot:where(.rt-r-size-3) :where(.rt-TableRootTable) {
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
}
@media (min-width: 520px) {
  .rt-TableRoot:where(.xs\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }
  .rt-TableRoot:where(.xs\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.xs\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }
  .rt-TableRoot:where(.xs\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.xs\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }
  .rt-TableRoot:where(.xs\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}
@media (min-width: 768px) {
  .rt-TableRoot:where(.sm\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }
  .rt-TableRoot:where(.sm\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.sm\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }
  .rt-TableRoot:where(.sm\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.sm\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }
  .rt-TableRoot:where(.sm\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}
@media (min-width: 1024px) {
  .rt-TableRoot:where(.md\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }
  .rt-TableRoot:where(.md\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.md\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }
  .rt-TableRoot:where(.md\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.md\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }
  .rt-TableRoot:where(.md\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}
@media (min-width: 1280px) {
  .rt-TableRoot:where(.lg\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }
  .rt-TableRoot:where(.lg\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.lg\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }
  .rt-TableRoot:where(.lg\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.lg\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }
  .rt-TableRoot:where(.lg\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}
@media (min-width: 1640px) {
  .rt-TableRoot:where(.xl\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }
  .rt-TableRoot:where(.xl\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.xl\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }
  .rt-TableRoot:where(.xl\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }
  .rt-TableRoot:where(.xl\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }
  .rt-TableRoot:where(.xl\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}
.rt-TableRoot:where(.rt-variant-surface) {
  box-sizing: border-box;
  border: 1px solid var(--gray-a5);
  border-radius: var(--table-border-radius);
  background-color: var(--color-panel);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
          backdrop-filter: var(--backdrop-filter-panel);
  background-clip: padding-box;
  position: relative;
}
@supports (box-shadow: 0 0 0 1px color-mix(in oklab, white, black)) {
  .rt-TableRoot:where(.rt-variant-surface) {
    border-color: color-mix(in oklab, var(--gray-a5), var(--gray-6));
  }
}
.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) {
  overflow: hidden;
}
.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableHeader) {
  --table-row-background-color: var(--gray-a2);
}
.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableBody) :where(.rt-TableRow:last-child) {
  --table-row-box-shadow: none;
}
.rt-TableRoot:where(.rt-variant-ghost) {
  --scrollarea-scrollbar-horizontal-margin-left: 0;
  --scrollarea-scrollbar-horizontal-margin-right: 0;
}
.rt-TabsContent {
  position: relative;
  outline: 0;
}
.rt-TabsContent:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}
.rt-TextAreaRoot:where(:focus-within) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}
.rt-TextAreaInput::-webkit-scrollbar {
  width: var(--space-3);
  height: var(--space-3);
}
.rt-TextAreaInput::-webkit-scrollbar-track,
  .rt-TextAreaInput::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: var(--space-1) solid transparent;
  border-radius: var(--space-3);
}
.rt-TextAreaInput::-webkit-scrollbar-track {
  background-color: var(--gray-a3);
}
.rt-TextAreaInput::-webkit-scrollbar-thumb {
  background-color: var(--gray-a8);
}
@media (hover: hover) {
  :where(.rt-TextAreaInput:not(:disabled))::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray-a9);
  }
}
.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: var(--gray-12);
}
.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]) {
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: var(--gray-12);
}
.rt-TextAreaRoot {
  box-sizing: border-box;
  padding: var(--text-area-border-width);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: start;
  overflow: hidden;
}
.rt-TextAreaInput {
  padding: var(--text-area-padding-y) var(--text-area-padding-x);
  border-radius: inherit;
  resize: none;
  display: block;
  width: 100%;
  flex-grow: 1;
  cursor: auto;
}
.rt-TextAreaRoot:where(.rt-r-size-1) {
  min-height: var(--space-8);
  border-radius: var(--radius-2);
}
.rt-TextAreaRoot:where(.rt-r-size-1) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}
.rt-TextAreaRoot:where(.rt-r-size-2) {
  min-height: var(--space-9);
  border-radius: var(--radius-2);
}
.rt-TextAreaRoot:where(.rt-r-size-2) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}
.rt-TextAreaRoot:where(.rt-r-size-3) {
  min-height: 80px;
  border-radius: var(--radius-3);
}
.rt-TextAreaRoot:where(.rt-r-size-3) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}
@media (min-width: 520px) {
  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }
  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}
@media (min-width: 768px) {
  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }
  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}
@media (min-width: 1024px) {
  .rt-TextAreaRoot:where(.md\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.md\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextAreaRoot:where(.md\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.md\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextAreaRoot:where(.md\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }
  .rt-TextAreaRoot:where(.md\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}
@media (min-width: 1280px) {
  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }
  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}
@media (min-width: 1640px) {
  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }
  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }
  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}
.rt-TextAreaRoot:where(.rt-variant-surface) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a7);
  color: var(--gray-12);
}
.rt-TextAreaRoot:where(.rt-variant-surface) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}
.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}
.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}
.rt-TextAreaRoot:where(.rt-variant-classic) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}
.rt-TextAreaRoot:where(.rt-variant-classic) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}
.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-TextAreaRoot:where(.rt-variant-soft) {
  --text-area-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}
.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::selection {
  background-color: var(--accent-a5);
}
.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::placeholder {
  color: var(--accent-12);
  opacity: 0.65;
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:focus-within) {
  outline-color: var(--accent-8);
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}
.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}
.rt-TextAreaInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}
.rt-TextAreaInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}
.rt-TextAreaInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}
.rt-TextAreaInput:where(:disabled, :read-only)::placeholder {
  opacity: 0.5;
}
.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}
.rt-TextAreaInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}
.rt-TextAreaInput:where(:disabled, :-moz-read-only)::selection {
  background-color: var(--gray-a5);
}
.rt-TextAreaInput:where(:disabled, :read-only)::selection {
  background-color: var(--gray-a5);
}
.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  outline-color: var(--gray-8);
}
.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  outline-color: var(--gray-8);
}
@supports selector(:has(*)) {
  .rt-TextFieldRoot:where(:has(.rt-TextFieldInput:focus)) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}
@supports not selector(:has(*)) {
  .rt-TextFieldRoot:where(:focus-within) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}
.rt-TextFieldRoot::selection {
  background-color: var(--text-field-selection-color);
}
.rt-TextFieldInput {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: inherit;
  border-radius: calc(var(--text-field-border-radius) - var(--text-field-border-width));
  text-indent: var(--text-field-padding);
}
.rt-TextFieldInput:where([type='number']) {
  -moz-appearance: textfield;
}
.rt-TextFieldInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
}
.rt-TextFieldInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
          appearance: none;
}
.rt-TextFieldInput::selection {
  background-color: var(--text-field-selection-color);
}
.rt-TextFieldInput::-webkit-calendar-picker-indicator {
  box-sizing: content-box;
  width: var(--text-field-native-icon-size);
  height: var(--text-field-native-icon-size);
  padding: var(--space-1);
  margin-left: 0;
  margin-right: calc(var(--space-1) * -1);
  border-radius: calc(var(--text-field-border-radius) - 2px);
}
.rt-TextFieldInput:where(:not([type='time']))::-webkit-calendar-picker-indicator {
  margin-left: var(--space-1);
}
.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:hover) {
  background-color: var(--gray-a3);
}
.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:focus-visible) {
  outline: 2px solid var(--text-field-focus-color);
}
.rt-TextFieldInput::-webkit-datetime-edit-ampm-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-day-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-hour-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-millisecond-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-minute-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-month-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-second-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-week-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-year-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}
@supports selector(:has(*)) {
  .rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: var(--gray-12);
  }
  .rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]) {
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: var(--gray-12);
  }
}
.rt-TextFieldSlot {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: text;
}
.rt-TextFieldSlot:where(:not([data-side='right'])) {
  order: -1;
  margin-left: calc(var(--text-field-border-width) * -1);
  margin-right: 0;
}
.rt-TextFieldSlot:where([data-side='right']),
  
  :where(.rt-TextFieldSlot:not([data-side='right'])) ~ .rt-TextFieldSlot:where(:not([data-side='left'])) {
  order: 0;
  margin-left: 0;
  margin-right: calc(var(--text-field-border-width) * -1);
}
.rt-TextFieldRoot {
  box-sizing: border-box;
  height: var(--text-field-height);
  padding: var(--text-field-border-width);
  border-radius: var(--text-field-border-radius);
  display: flex;
  align-items: stretch;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: start;
}
.rt-TextFieldInput:where([type='date'], [type='datetime-local'], [type='time'], [type='week'], [type='month']) {
  text-indent: 0;
  padding-left: var(--text-field-padding);
  padding-right: var(--text-field-padding);
}
.rt-TextFieldInput:where(:has(~ .rt-TextFieldSlot:not([data-side='right']))) {
  text-indent: 0;
  padding-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rt-TextFieldInput:where(:has(
    ~ .rt-TextFieldSlot[data-side='right'],
    ~ .rt-TextFieldSlot:not([data-side='right']) ~ .rt-TextFieldSlot:not([data-side='left'])
  )) {
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rt-TextFieldRoot:where(.rt-r-size-1) {
  --text-field-height: var(--space-5);
  --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-3);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
}
.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}
.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput) {
  padding-bottom: 0px;
}
.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 0px;
  margin-right: -2px;
}
.rt-TextFieldRoot:where(.rt-r-size-2) {
  --text-field-height: var(--space-6);
  --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}
.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput) {
  padding-bottom: 0.5px;
}
.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 2px;
  margin-right: 0px;
}
.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.rt-TextFieldRoot:where(.rt-r-size-3) {
  --text-field-height: var(--space-7);
  --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-3), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}
.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput) {
  padding-bottom: 0px;
}
.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 5px;
  margin-right: 0px;
}
.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldSlot) {
  gap: var(--space-3);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
@media (min-width: 520px) {
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
@media (min-width: 768px) {
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
@media (min-width: 1024px) {
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
@media (min-width: 1280px) {
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
@media (min-width: 1640px) {
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }
  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
.rt-TextFieldRoot:where(.rt-variant-surface) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a7);
  color: var(--gray-12);
}
.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}
.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}
.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}
.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}
.rt-TextFieldRoot:where(.rt-variant-classic) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}
.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}
.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}
.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}
.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}
.rt-TextFieldRoot:where(.rt-variant-soft) {
  --text-field-selection-color: var(--accent-a5);
  --text-field-focus-color: var(--accent-8);
  --text-field-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}
.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldInput)::placeholder {
  color: var(--accent-12);
  opacity: 0.6;
}
.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot) {
  color: var(--accent-12);
}
.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}
.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled, :read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}
.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}
.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}
.rt-TextFieldInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}
.rt-TextFieldInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}
.rt-TextFieldInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}
.rt-TextFieldInput:where(:disabled, :read-only)::placeholder {
  opacity: 0.5;
}
.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}
.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}
.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}
.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}
.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}
.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}
.rt-ThemePanelShortcut:where(:focus-visible) {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--accent-9);
}
.rt-ThemePanelSwatch,
.rt-ThemePanelRadioCard {
  position: relative;
}
.rt-ThemePanelSwatchInput,
.rt-ThemePanelRadioCardInput {
  -webkit-appearance: none;
          -moz-appearance: none;
       appearance: none;
  margin: 0;
  outline: none;
  outline-width: 2px;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}
.rt-ThemePanelSwatch {
  width: var(--space-5);
  height: var(--space-5);
  border-radius: 100%;
}
.rt-ThemePanelSwatchInput {
  outline-offset: 2px;
}
.rt-ThemePanelSwatchInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}
.rt-ThemePanelSwatchInput:where(:focus-visible) {
  outline-style: solid;
  outline-color: var(--accent-9);
}
.rt-ThemePanelRadioCard {
  border-radius: var(--radius-1);
  box-shadow: 0 0 0 1px var(--gray-7);
}
.rt-ThemePanelRadioCardInput {
  outline-offset: -1px;
}
.rt-ThemePanelRadioCardInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}
.rt-ThemePanelRadioCardInput:where(:focus-visible) {
  background-color: var(--accent-a3);
  outline-style: solid;
  outline-color: var(--accent-9);
}
.rt-TooltipContent {
  box-sizing: border-box;
  padding: var(--space-1) var(--space-2);
  background-color: var(--gray-12);
  border-radius: var(--radius-2);
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation-duration: 140ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
@media (prefers-reduced-motion: no-preference) {
  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='top']) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }
  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='bottom']) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }
  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='left']) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }
  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='right']) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }
}
.rt-TooltipText {
  color: var(--gray-1);
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  cursor: default;
}
.rt-TooltipArrow {
  fill: var(--gray-12);
}
.radix-themes:where([data-is-root-theme='true']) {
  position: relative;
  z-index: 0;
  min-height: 100vh;
}
@supports (min-height: 100dvh) {
  .radix-themes:where([data-is-root-theme='true']) {
    min-height: 100dvh;
  }
}
.rt-r-ai-start {
  align-items: flex-start;
}
.rt-r-ai-center {
  align-items: center;
}
.rt-r-ai-end {
  align-items: flex-end;
}
.rt-r-ai-baseline {
  align-items: baseline;
}
.rt-r-ai-stretch {
  align-items: stretch;
}
@media (min-width: 520px) {
  .xs\:rt-r-ai-start {
    align-items: flex-start;
  }
  .xs\:rt-r-ai-center {
    align-items: center;
  }
  .xs\:rt-r-ai-end {
    align-items: flex-end;
  }
  .xs\:rt-r-ai-baseline {
    align-items: baseline;
  }
  .xs\:rt-r-ai-stretch {
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-ai-start {
    align-items: flex-start;
  }
  .sm\:rt-r-ai-center {
    align-items: center;
  }
  .sm\:rt-r-ai-end {
    align-items: flex-end;
  }
  .sm\:rt-r-ai-baseline {
    align-items: baseline;
  }
  .sm\:rt-r-ai-stretch {
    align-items: stretch;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-ai-start {
    align-items: flex-start;
  }
  .md\:rt-r-ai-center {
    align-items: center;
  }
  .md\:rt-r-ai-end {
    align-items: flex-end;
  }
  .md\:rt-r-ai-baseline {
    align-items: baseline;
  }
  .md\:rt-r-ai-stretch {
    align-items: stretch;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-ai-start {
    align-items: flex-start;
  }
  .lg\:rt-r-ai-center {
    align-items: center;
  }
  .lg\:rt-r-ai-end {
    align-items: flex-end;
  }
  .lg\:rt-r-ai-baseline {
    align-items: baseline;
  }
  .lg\:rt-r-ai-stretch {
    align-items: stretch;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-ai-start {
    align-items: flex-start;
  }
  .xl\:rt-r-ai-center {
    align-items: center;
  }
  .xl\:rt-r-ai-end {
    align-items: flex-end;
  }
  .xl\:rt-r-ai-baseline {
    align-items: baseline;
  }
  .xl\:rt-r-ai-stretch {
    align-items: stretch;
  }
}
.rt-r-as-start {
  align-self: flex-start;
}
.rt-r-as-center {
  align-self: center;
}
.rt-r-as-end {
  align-self: flex-end;
}
.rt-r-as-baseline {
  align-self: baseline;
}
.rt-r-as-stretch {
  align-self: stretch;
}
@media (min-width: 520px) {
  .xs\:rt-r-as-start {
    align-self: flex-start;
  }
  .xs\:rt-r-as-center {
    align-self: center;
  }
  .xs\:rt-r-as-end {
    align-self: flex-end;
  }
  .xs\:rt-r-as-baseline {
    align-self: baseline;
  }
  .xs\:rt-r-as-stretch {
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-as-start {
    align-self: flex-start;
  }
  .sm\:rt-r-as-center {
    align-self: center;
  }
  .sm\:rt-r-as-end {
    align-self: flex-end;
  }
  .sm\:rt-r-as-baseline {
    align-self: baseline;
  }
  .sm\:rt-r-as-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-as-start {
    align-self: flex-start;
  }
  .md\:rt-r-as-center {
    align-self: center;
  }
  .md\:rt-r-as-end {
    align-self: flex-end;
  }
  .md\:rt-r-as-baseline {
    align-self: baseline;
  }
  .md\:rt-r-as-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-as-start {
    align-self: flex-start;
  }
  .lg\:rt-r-as-center {
    align-self: center;
  }
  .lg\:rt-r-as-end {
    align-self: flex-end;
  }
  .lg\:rt-r-as-baseline {
    align-self: baseline;
  }
  .lg\:rt-r-as-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-as-start {
    align-self: flex-start;
  }
  .xl\:rt-r-as-center {
    align-self: center;
  }
  .xl\:rt-r-as-end {
    align-self: flex-end;
  }
  .xl\:rt-r-as-baseline {
    align-self: baseline;
  }
  .xl\:rt-r-as-stretch {
    align-self: stretch;
  }
}
.rt-r-display-block {
  display: block;
}
.rt-r-display-inline {
  display: inline;
}
.rt-r-display-inline-block {
  display: inline-block;
}
.rt-r-display-flex {
  display: flex;
}
.rt-r-display-inline-flex {
  display: inline-flex;
}
.rt-r-display-grid {
  display: grid;
}
.rt-r-display-inline-grid {
  display: inline-grid;
}
.rt-r-display-none {
  display: none;
}
.rt-r-display-contents {
  display: contents;
}
@media (min-width: 520px) {
  .xs\:rt-r-display-block {
    display: block;
  }
  .xs\:rt-r-display-inline {
    display: inline;
  }
  .xs\:rt-r-display-inline-block {
    display: inline-block;
  }
  .xs\:rt-r-display-flex {
    display: flex;
  }
  .xs\:rt-r-display-inline-flex {
    display: inline-flex;
  }
  .xs\:rt-r-display-grid {
    display: grid;
  }
  .xs\:rt-r-display-inline-grid {
    display: inline-grid;
  }
  .xs\:rt-r-display-none {
    display: none;
  }
  .xs\:rt-r-display-contents {
    display: contents;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-display-block {
    display: block;
  }
  .sm\:rt-r-display-inline {
    display: inline;
  }
  .sm\:rt-r-display-inline-block {
    display: inline-block;
  }
  .sm\:rt-r-display-flex {
    display: flex;
  }
  .sm\:rt-r-display-inline-flex {
    display: inline-flex;
  }
  .sm\:rt-r-display-grid {
    display: grid;
  }
  .sm\:rt-r-display-inline-grid {
    display: inline-grid;
  }
  .sm\:rt-r-display-none {
    display: none;
  }
  .sm\:rt-r-display-contents {
    display: contents;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-display-block {
    display: block;
  }
  .md\:rt-r-display-inline {
    display: inline;
  }
  .md\:rt-r-display-inline-block {
    display: inline-block;
  }
  .md\:rt-r-display-flex {
    display: flex;
  }
  .md\:rt-r-display-inline-flex {
    display: inline-flex;
  }
  .md\:rt-r-display-grid {
    display: grid;
  }
  .md\:rt-r-display-inline-grid {
    display: inline-grid;
  }
  .md\:rt-r-display-none {
    display: none;
  }
  .md\:rt-r-display-contents {
    display: contents;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-display-block {
    display: block;
  }
  .lg\:rt-r-display-inline {
    display: inline;
  }
  .lg\:rt-r-display-inline-block {
    display: inline-block;
  }
  .lg\:rt-r-display-flex {
    display: flex;
  }
  .lg\:rt-r-display-inline-flex {
    display: inline-flex;
  }
  .lg\:rt-r-display-grid {
    display: grid;
  }
  .lg\:rt-r-display-inline-grid {
    display: inline-grid;
  }
  .lg\:rt-r-display-none {
    display: none;
  }
  .lg\:rt-r-display-contents {
    display: contents;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-display-block {
    display: block;
  }
  .xl\:rt-r-display-inline {
    display: inline;
  }
  .xl\:rt-r-display-inline-block {
    display: inline-block;
  }
  .xl\:rt-r-display-flex {
    display: flex;
  }
  .xl\:rt-r-display-inline-flex {
    display: inline-flex;
  }
  .xl\:rt-r-display-grid {
    display: grid;
  }
  .xl\:rt-r-display-inline-grid {
    display: inline-grid;
  }
  .xl\:rt-r-display-none {
    display: none;
  }
  .xl\:rt-r-display-contents {
    display: contents;
  }
}
.rt-r-fb {
  flex-basis: var(--flex-basis);
}
@media (min-width: 520px) {
  .xs\:rt-r-fb {
    flex-basis: var(--flex-basis-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-fb {
    flex-basis: var(--flex-basis-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-fb {
    flex-basis: var(--flex-basis-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-fb {
    flex-basis: var(--flex-basis-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-fb {
    flex-basis: var(--flex-basis-xl);
  }
}
.rt-r-fd-row {
  flex-direction: row;
}
.rt-r-fd-column {
  flex-direction: column;
}
.rt-r-fd-row-reverse {
  flex-direction: row-reverse;
}
.rt-r-fd-column-reverse {
  flex-direction: column-reverse;
}
@media (min-width: 520px) {
  .xs\:rt-r-fd-row {
    flex-direction: row;
  }
  .xs\:rt-r-fd-column {
    flex-direction: column;
  }
  .xs\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }
  .xs\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-fd-row {
    flex-direction: row;
  }
  .sm\:rt-r-fd-column {
    flex-direction: column;
  }
  .sm\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-fd-row {
    flex-direction: row;
  }
  .md\:rt-r-fd-column {
    flex-direction: column;
  }
  .md\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }
  .md\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-fd-row {
    flex-direction: row;
  }
  .lg\:rt-r-fd-column {
    flex-direction: column;
  }
  .lg\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-fd-row {
    flex-direction: row;
  }
  .xl\:rt-r-fd-column {
    flex-direction: column;
  }
  .xl\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }
  .xl\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}
.rt-r-fg {
  flex-grow: var(--flex-grow)
}
.rt-r-fg-0 {
  flex-grow: 0;
}
.rt-r-fg-1 {
  flex-grow: 1;
}
@media (min-width: 520px) {
  .xs\:rt-r-fg {
    flex-grow: var(--flex-grow-xs)
  }
  .xs\:rt-r-fg-0 {
    flex-grow: 0;
  }
  .xs\:rt-r-fg-1 {
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-fg {
    flex-grow: var(--flex-grow-sm)
  }
  .sm\:rt-r-fg-0 {
    flex-grow: 0;
  }
  .sm\:rt-r-fg-1 {
    flex-grow: 1;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-fg {
    flex-grow: var(--flex-grow-md)
  }
  .md\:rt-r-fg-0 {
    flex-grow: 0;
  }
  .md\:rt-r-fg-1 {
    flex-grow: 1;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-fg {
    flex-grow: var(--flex-grow-lg)
  }
  .lg\:rt-r-fg-0 {
    flex-grow: 0;
  }
  .lg\:rt-r-fg-1 {
    flex-grow: 1;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-fg {
    flex-grow: var(--flex-grow-xl)
  }
  .xl\:rt-r-fg-0 {
    flex-grow: 0;
  }
  .xl\:rt-r-fg-1 {
    flex-grow: 1;
  }
}
.rt-r-fs {
  flex-shrink: var(--flex-shrink)
}
.rt-r-fs-0 {
  flex-shrink: 0;
}
.rt-r-fs-1 {
  flex-shrink: 1;
}
@media (min-width: 520px) {
  .xs\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xs)
  }
  .xs\:rt-r-fs-0 {
    flex-shrink: 0;
  }
  .xs\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-fs {
    flex-shrink: var(--flex-shrink-sm)
  }
  .sm\:rt-r-fs-0 {
    flex-shrink: 0;
  }
  .sm\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-fs {
    flex-shrink: var(--flex-shrink-md)
  }
  .md\:rt-r-fs-0 {
    flex-shrink: 0;
  }
  .md\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-fs {
    flex-shrink: var(--flex-shrink-lg)
  }
  .lg\:rt-r-fs-0 {
    flex-shrink: 0;
  }
  .lg\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xl)
  }
  .xl\:rt-r-fs-0 {
    flex-shrink: 0;
  }
  .xl\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}
.rt-r-fw-nowrap {
  flex-wrap: nowrap;
}
.rt-r-fw-wrap {
  flex-wrap: wrap;
}
.rt-r-fw-wrap-reverse {
  flex-wrap: wrap-reverse;
}
@media (min-width: 520px) {
  .xs\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }
  .xs\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }
  .xs\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }
  .sm\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }
  .sm\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }
  .md\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }
  .md\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }
  .lg\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }
  .lg\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }
  .xl\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }
  .xl\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
.rt-r-gap {
  gap: var(--gap)
}
.rt-r-gap-0 {
  gap: 0;
}
.rt-r-gap-1 {
  gap: var(--space-1);
}
.rt-r-gap-2 {
  gap: var(--space-2);
}
.rt-r-gap-3 {
  gap: var(--space-3);
}
.rt-r-gap-4 {
  gap: var(--space-4);
}
.rt-r-gap-5 {
  gap: var(--space-5);
}
.rt-r-gap-6 {
  gap: var(--space-6);
}
.rt-r-gap-7 {
  gap: var(--space-7);
}
.rt-r-gap-8 {
  gap: var(--space-8);
}
.rt-r-gap-9 {
  gap: var(--space-9);
}
.rt-r-cg {
  column-gap: var(--column-gap)
}
.rt-r-cg-0 {
  column-gap: 0;
}
.rt-r-cg-1 {
  column-gap: var(--space-1);
}
.rt-r-cg-2 {
  column-gap: var(--space-2);
}
.rt-r-cg-3 {
  column-gap: var(--space-3);
}
.rt-r-cg-4 {
  column-gap: var(--space-4);
}
.rt-r-cg-5 {
  column-gap: var(--space-5);
}
.rt-r-cg-6 {
  column-gap: var(--space-6);
}
.rt-r-cg-7 {
  column-gap: var(--space-7);
}
.rt-r-cg-8 {
  column-gap: var(--space-8);
}
.rt-r-cg-9 {
  column-gap: var(--space-9);
}
.rt-r-rg {
  row-gap: var(--row-gap)
}
.rt-r-rg-0 {
  row-gap: 0;
}
.rt-r-rg-1 {
  row-gap: var(--space-1);
}
.rt-r-rg-2 {
  row-gap: var(--space-2);
}
.rt-r-rg-3 {
  row-gap: var(--space-3);
}
.rt-r-rg-4 {
  row-gap: var(--space-4);
}
.rt-r-rg-5 {
  row-gap: var(--space-5);
}
.rt-r-rg-6 {
  row-gap: var(--space-6);
}
.rt-r-rg-7 {
  row-gap: var(--space-7);
}
.rt-r-rg-8 {
  row-gap: var(--space-8);
}
.rt-r-rg-9 {
  row-gap: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-gap {
    gap: var(--gap-xs)
  }
  .xs\:rt-r-gap-0 {
    gap: 0;
  }
  .xs\:rt-r-gap-1 {
    gap: var(--space-1);
  }
  .xs\:rt-r-gap-2 {
    gap: var(--space-2);
  }
  .xs\:rt-r-gap-3 {
    gap: var(--space-3);
  }
  .xs\:rt-r-gap-4 {
    gap: var(--space-4);
  }
  .xs\:rt-r-gap-5 {
    gap: var(--space-5);
  }
  .xs\:rt-r-gap-6 {
    gap: var(--space-6);
  }
  .xs\:rt-r-gap-7 {
    gap: var(--space-7);
  }
  .xs\:rt-r-gap-8 {
    gap: var(--space-8);
  }
  .xs\:rt-r-gap-9 {
    gap: var(--space-9);
  }
  .xs\:rt-r-cg {
    column-gap: var(--column-gap-xs)
  }
  .xs\:rt-r-cg-0 {
    column-gap: 0;
  }
  .xs\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }
  .xs\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }
  .xs\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }
  .xs\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }
  .xs\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }
  .xs\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }
  .xs\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }
  .xs\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }
  .xs\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }
  .xs\:rt-r-rg {
    row-gap: var(--row-gap-xs)
  }
  .xs\:rt-r-rg-0 {
    row-gap: 0;
  }
  .xs\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }
  .xs\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }
  .xs\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }
  .xs\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }
  .xs\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }
  .xs\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }
  .xs\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }
  .xs\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }
  .xs\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gap {
    gap: var(--gap-sm)
  }
  .sm\:rt-r-gap-0 {
    gap: 0;
  }
  .sm\:rt-r-gap-1 {
    gap: var(--space-1);
  }
  .sm\:rt-r-gap-2 {
    gap: var(--space-2);
  }
  .sm\:rt-r-gap-3 {
    gap: var(--space-3);
  }
  .sm\:rt-r-gap-4 {
    gap: var(--space-4);
  }
  .sm\:rt-r-gap-5 {
    gap: var(--space-5);
  }
  .sm\:rt-r-gap-6 {
    gap: var(--space-6);
  }
  .sm\:rt-r-gap-7 {
    gap: var(--space-7);
  }
  .sm\:rt-r-gap-8 {
    gap: var(--space-8);
  }
  .sm\:rt-r-gap-9 {
    gap: var(--space-9);
  }
  .sm\:rt-r-cg {
    column-gap: var(--column-gap-sm)
  }
  .sm\:rt-r-cg-0 {
    column-gap: 0;
  }
  .sm\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }
  .sm\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }
  .sm\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }
  .sm\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }
  .sm\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }
  .sm\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }
  .sm\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }
  .sm\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }
  .sm\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }
  .sm\:rt-r-rg {
    row-gap: var(--row-gap-sm)
  }
  .sm\:rt-r-rg-0 {
    row-gap: 0;
  }
  .sm\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }
  .sm\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }
  .sm\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }
  .sm\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }
  .sm\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }
  .sm\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }
  .sm\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }
  .sm\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }
  .sm\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gap {
    gap: var(--gap-md)
  }
  .md\:rt-r-gap-0 {
    gap: 0;
  }
  .md\:rt-r-gap-1 {
    gap: var(--space-1);
  }
  .md\:rt-r-gap-2 {
    gap: var(--space-2);
  }
  .md\:rt-r-gap-3 {
    gap: var(--space-3);
  }
  .md\:rt-r-gap-4 {
    gap: var(--space-4);
  }
  .md\:rt-r-gap-5 {
    gap: var(--space-5);
  }
  .md\:rt-r-gap-6 {
    gap: var(--space-6);
  }
  .md\:rt-r-gap-7 {
    gap: var(--space-7);
  }
  .md\:rt-r-gap-8 {
    gap: var(--space-8);
  }
  .md\:rt-r-gap-9 {
    gap: var(--space-9);
  }
  .md\:rt-r-cg {
    column-gap: var(--column-gap-md)
  }
  .md\:rt-r-cg-0 {
    column-gap: 0;
  }
  .md\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }
  .md\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }
  .md\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }
  .md\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }
  .md\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }
  .md\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }
  .md\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }
  .md\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }
  .md\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }
  .md\:rt-r-rg {
    row-gap: var(--row-gap-md)
  }
  .md\:rt-r-rg-0 {
    row-gap: 0;
  }
  .md\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }
  .md\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }
  .md\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }
  .md\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }
  .md\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }
  .md\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }
  .md\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }
  .md\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }
  .md\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gap {
    gap: var(--gap-lg)
  }
  .lg\:rt-r-gap-0 {
    gap: 0;
  }
  .lg\:rt-r-gap-1 {
    gap: var(--space-1);
  }
  .lg\:rt-r-gap-2 {
    gap: var(--space-2);
  }
  .lg\:rt-r-gap-3 {
    gap: var(--space-3);
  }
  .lg\:rt-r-gap-4 {
    gap: var(--space-4);
  }
  .lg\:rt-r-gap-5 {
    gap: var(--space-5);
  }
  .lg\:rt-r-gap-6 {
    gap: var(--space-6);
  }
  .lg\:rt-r-gap-7 {
    gap: var(--space-7);
  }
  .lg\:rt-r-gap-8 {
    gap: var(--space-8);
  }
  .lg\:rt-r-gap-9 {
    gap: var(--space-9);
  }
  .lg\:rt-r-cg {
    column-gap: var(--column-gap-lg)
  }
  .lg\:rt-r-cg-0 {
    column-gap: 0;
  }
  .lg\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }
  .lg\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }
  .lg\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }
  .lg\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }
  .lg\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }
  .lg\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }
  .lg\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }
  .lg\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }
  .lg\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }
  .lg\:rt-r-rg {
    row-gap: var(--row-gap-lg)
  }
  .lg\:rt-r-rg-0 {
    row-gap: 0;
  }
  .lg\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }
  .lg\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }
  .lg\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }
  .lg\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }
  .lg\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }
  .lg\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }
  .lg\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }
  .lg\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }
  .lg\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gap {
    gap: var(--gap-xl)
  }
  .xl\:rt-r-gap-0 {
    gap: 0;
  }
  .xl\:rt-r-gap-1 {
    gap: var(--space-1);
  }
  .xl\:rt-r-gap-2 {
    gap: var(--space-2);
  }
  .xl\:rt-r-gap-3 {
    gap: var(--space-3);
  }
  .xl\:rt-r-gap-4 {
    gap: var(--space-4);
  }
  .xl\:rt-r-gap-5 {
    gap: var(--space-5);
  }
  .xl\:rt-r-gap-6 {
    gap: var(--space-6);
  }
  .xl\:rt-r-gap-7 {
    gap: var(--space-7);
  }
  .xl\:rt-r-gap-8 {
    gap: var(--space-8);
  }
  .xl\:rt-r-gap-9 {
    gap: var(--space-9);
  }
  .xl\:rt-r-cg {
    column-gap: var(--column-gap-xl)
  }
  .xl\:rt-r-cg-0 {
    column-gap: 0;
  }
  .xl\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }
  .xl\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }
  .xl\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }
  .xl\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }
  .xl\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }
  .xl\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }
  .xl\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }
  .xl\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }
  .xl\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }
  .xl\:rt-r-rg {
    row-gap: var(--row-gap-xl)
  }
  .xl\:rt-r-rg-0 {
    row-gap: 0;
  }
  .xl\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }
  .xl\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }
  .xl\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }
  .xl\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }
  .xl\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }
  .xl\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }
  .xl\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }
  .xl\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }
  .xl\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}
.rt-r-ga {
  grid-area: var(--grid-area);
}
@media (min-width: 520px) {
  .xs\:rt-r-ga {
    grid-area: var(--grid-area-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-ga {
    grid-area: var(--grid-area-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-ga {
    grid-area: var(--grid-area-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-ga {
    grid-area: var(--grid-area-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-ga {
    grid-area: var(--grid-area-xl);
  }
}
.rt-r-gaf-row {
  grid-auto-flow: row;
}
.rt-r-gaf-column {
  grid-auto-flow: column;
}
.rt-r-gaf-dense {
  grid-auto-flow: dense;
}
.rt-r-gaf-row-dense {
  grid-auto-flow: row dense;
}
.rt-r-gaf-column-dense {
  grid-auto-flow: column dense;
}
@media (min-width: 520px) {
  .xs\:rt-r-gaf-row {
    grid-auto-flow: row;
  }
  .xs\:rt-r-gaf-column {
    grid-auto-flow: column;
  }
  .xs\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }
  .xs\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }
  .xs\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gaf-row {
    grid-auto-flow: row;
  }
  .sm\:rt-r-gaf-column {
    grid-auto-flow: column;
  }
  .sm\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }
  .sm\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }
  .sm\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gaf-row {
    grid-auto-flow: row;
  }
  .md\:rt-r-gaf-column {
    grid-auto-flow: column;
  }
  .md\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }
  .md\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }
  .md\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gaf-row {
    grid-auto-flow: row;
  }
  .lg\:rt-r-gaf-column {
    grid-auto-flow: column;
  }
  .lg\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }
  .lg\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }
  .lg\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gaf-row {
    grid-auto-flow: row;
  }
  .xl\:rt-r-gaf-column {
    grid-auto-flow: column;
  }
  .xl\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }
  .xl\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }
  .xl\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}
.rt-r-gc {
  grid-column: var(--grid-column)
}
.rt-r-gc-1 {
  grid-column: 1;
}
.rt-r-gc-2 {
  grid-column: 2;
}
.rt-r-gc-3 {
  grid-column: 3;
}
.rt-r-gc-4 {
  grid-column: 4;
}
.rt-r-gc-5 {
  grid-column: 5;
}
.rt-r-gc-6 {
  grid-column: 6;
}
.rt-r-gc-7 {
  grid-column: 7;
}
.rt-r-gc-8 {
  grid-column: 8;
}
.rt-r-gc-9 {
  grid-column: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-gc {
    grid-column: var(--grid-column-xs)
  }
  .xs\:rt-r-gc-1 {
    grid-column: 1;
  }
  .xs\:rt-r-gc-2 {
    grid-column: 2;
  }
  .xs\:rt-r-gc-3 {
    grid-column: 3;
  }
  .xs\:rt-r-gc-4 {
    grid-column: 4;
  }
  .xs\:rt-r-gc-5 {
    grid-column: 5;
  }
  .xs\:rt-r-gc-6 {
    grid-column: 6;
  }
  .xs\:rt-r-gc-7 {
    grid-column: 7;
  }
  .xs\:rt-r-gc-8 {
    grid-column: 8;
  }
  .xs\:rt-r-gc-9 {
    grid-column: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gc {
    grid-column: var(--grid-column-sm)
  }
  .sm\:rt-r-gc-1 {
    grid-column: 1;
  }
  .sm\:rt-r-gc-2 {
    grid-column: 2;
  }
  .sm\:rt-r-gc-3 {
    grid-column: 3;
  }
  .sm\:rt-r-gc-4 {
    grid-column: 4;
  }
  .sm\:rt-r-gc-5 {
    grid-column: 5;
  }
  .sm\:rt-r-gc-6 {
    grid-column: 6;
  }
  .sm\:rt-r-gc-7 {
    grid-column: 7;
  }
  .sm\:rt-r-gc-8 {
    grid-column: 8;
  }
  .sm\:rt-r-gc-9 {
    grid-column: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gc {
    grid-column: var(--grid-column-md)
  }
  .md\:rt-r-gc-1 {
    grid-column: 1;
  }
  .md\:rt-r-gc-2 {
    grid-column: 2;
  }
  .md\:rt-r-gc-3 {
    grid-column: 3;
  }
  .md\:rt-r-gc-4 {
    grid-column: 4;
  }
  .md\:rt-r-gc-5 {
    grid-column: 5;
  }
  .md\:rt-r-gc-6 {
    grid-column: 6;
  }
  .md\:rt-r-gc-7 {
    grid-column: 7;
  }
  .md\:rt-r-gc-8 {
    grid-column: 8;
  }
  .md\:rt-r-gc-9 {
    grid-column: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gc {
    grid-column: var(--grid-column-lg)
  }
  .lg\:rt-r-gc-1 {
    grid-column: 1;
  }
  .lg\:rt-r-gc-2 {
    grid-column: 2;
  }
  .lg\:rt-r-gc-3 {
    grid-column: 3;
  }
  .lg\:rt-r-gc-4 {
    grid-column: 4;
  }
  .lg\:rt-r-gc-5 {
    grid-column: 5;
  }
  .lg\:rt-r-gc-6 {
    grid-column: 6;
  }
  .lg\:rt-r-gc-7 {
    grid-column: 7;
  }
  .lg\:rt-r-gc-8 {
    grid-column: 8;
  }
  .lg\:rt-r-gc-9 {
    grid-column: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gc {
    grid-column: var(--grid-column-xl)
  }
  .xl\:rt-r-gc-1 {
    grid-column: 1;
  }
  .xl\:rt-r-gc-2 {
    grid-column: 2;
  }
  .xl\:rt-r-gc-3 {
    grid-column: 3;
  }
  .xl\:rt-r-gc-4 {
    grid-column: 4;
  }
  .xl\:rt-r-gc-5 {
    grid-column: 5;
  }
  .xl\:rt-r-gc-6 {
    grid-column: 6;
  }
  .xl\:rt-r-gc-7 {
    grid-column: 7;
  }
  .xl\:rt-r-gc-8 {
    grid-column: 8;
  }
  .xl\:rt-r-gc-9 {
    grid-column: 9;
  }
}
.rt-r-gcs {
  grid-column-start: var(--grid-column-start)
}
.rt-r-gcs-1 {
  grid-column-start: 1;
}
.rt-r-gcs-2 {
  grid-column-start: 2;
}
.rt-r-gcs-3 {
  grid-column-start: 3;
}
.rt-r-gcs-4 {
  grid-column-start: 4;
}
.rt-r-gcs-5 {
  grid-column-start: 5;
}
.rt-r-gcs-6 {
  grid-column-start: 6;
}
.rt-r-gcs-7 {
  grid-column-start: 7;
}
.rt-r-gcs-8 {
  grid-column-start: 8;
}
.rt-r-gcs-9 {
  grid-column-start: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xs)
  }
  .xs\:rt-r-gcs-1 {
    grid-column-start: 1;
  }
  .xs\:rt-r-gcs-2 {
    grid-column-start: 2;
  }
  .xs\:rt-r-gcs-3 {
    grid-column-start: 3;
  }
  .xs\:rt-r-gcs-4 {
    grid-column-start: 4;
  }
  .xs\:rt-r-gcs-5 {
    grid-column-start: 5;
  }
  .xs\:rt-r-gcs-6 {
    grid-column-start: 6;
  }
  .xs\:rt-r-gcs-7 {
    grid-column-start: 7;
  }
  .xs\:rt-r-gcs-8 {
    grid-column-start: 8;
  }
  .xs\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-sm)
  }
  .sm\:rt-r-gcs-1 {
    grid-column-start: 1;
  }
  .sm\:rt-r-gcs-2 {
    grid-column-start: 2;
  }
  .sm\:rt-r-gcs-3 {
    grid-column-start: 3;
  }
  .sm\:rt-r-gcs-4 {
    grid-column-start: 4;
  }
  .sm\:rt-r-gcs-5 {
    grid-column-start: 5;
  }
  .sm\:rt-r-gcs-6 {
    grid-column-start: 6;
  }
  .sm\:rt-r-gcs-7 {
    grid-column-start: 7;
  }
  .sm\:rt-r-gcs-8 {
    grid-column-start: 8;
  }
  .sm\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-md)
  }
  .md\:rt-r-gcs-1 {
    grid-column-start: 1;
  }
  .md\:rt-r-gcs-2 {
    grid-column-start: 2;
  }
  .md\:rt-r-gcs-3 {
    grid-column-start: 3;
  }
  .md\:rt-r-gcs-4 {
    grid-column-start: 4;
  }
  .md\:rt-r-gcs-5 {
    grid-column-start: 5;
  }
  .md\:rt-r-gcs-6 {
    grid-column-start: 6;
  }
  .md\:rt-r-gcs-7 {
    grid-column-start: 7;
  }
  .md\:rt-r-gcs-8 {
    grid-column-start: 8;
  }
  .md\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-lg)
  }
  .lg\:rt-r-gcs-1 {
    grid-column-start: 1;
  }
  .lg\:rt-r-gcs-2 {
    grid-column-start: 2;
  }
  .lg\:rt-r-gcs-3 {
    grid-column-start: 3;
  }
  .lg\:rt-r-gcs-4 {
    grid-column-start: 4;
  }
  .lg\:rt-r-gcs-5 {
    grid-column-start: 5;
  }
  .lg\:rt-r-gcs-6 {
    grid-column-start: 6;
  }
  .lg\:rt-r-gcs-7 {
    grid-column-start: 7;
  }
  .lg\:rt-r-gcs-8 {
    grid-column-start: 8;
  }
  .lg\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xl)
  }
  .xl\:rt-r-gcs-1 {
    grid-column-start: 1;
  }
  .xl\:rt-r-gcs-2 {
    grid-column-start: 2;
  }
  .xl\:rt-r-gcs-3 {
    grid-column-start: 3;
  }
  .xl\:rt-r-gcs-4 {
    grid-column-start: 4;
  }
  .xl\:rt-r-gcs-5 {
    grid-column-start: 5;
  }
  .xl\:rt-r-gcs-6 {
    grid-column-start: 6;
  }
  .xl\:rt-r-gcs-7 {
    grid-column-start: 7;
  }
  .xl\:rt-r-gcs-8 {
    grid-column-start: 8;
  }
  .xl\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}
.rt-r-gce {
  grid-column-end: var(--grid-column-end)
}
.rt-r-gce-1 {
  grid-column-end: 1;
}
.rt-r-gce-2 {
  grid-column-end: 2;
}
.rt-r-gce-3 {
  grid-column-end: 3;
}
.rt-r-gce-4 {
  grid-column-end: 4;
}
.rt-r-gce-5 {
  grid-column-end: 5;
}
.rt-r-gce-6 {
  grid-column-end: 6;
}
.rt-r-gce-7 {
  grid-column-end: 7;
}
.rt-r-gce-8 {
  grid-column-end: 8;
}
.rt-r-gce-9 {
  grid-column-end: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xs)
  }
  .xs\:rt-r-gce-1 {
    grid-column-end: 1;
  }
  .xs\:rt-r-gce-2 {
    grid-column-end: 2;
  }
  .xs\:rt-r-gce-3 {
    grid-column-end: 3;
  }
  .xs\:rt-r-gce-4 {
    grid-column-end: 4;
  }
  .xs\:rt-r-gce-5 {
    grid-column-end: 5;
  }
  .xs\:rt-r-gce-6 {
    grid-column-end: 6;
  }
  .xs\:rt-r-gce-7 {
    grid-column-end: 7;
  }
  .xs\:rt-r-gce-8 {
    grid-column-end: 8;
  }
  .xs\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gce {
    grid-column-end: var(--grid-column-end-sm)
  }
  .sm\:rt-r-gce-1 {
    grid-column-end: 1;
  }
  .sm\:rt-r-gce-2 {
    grid-column-end: 2;
  }
  .sm\:rt-r-gce-3 {
    grid-column-end: 3;
  }
  .sm\:rt-r-gce-4 {
    grid-column-end: 4;
  }
  .sm\:rt-r-gce-5 {
    grid-column-end: 5;
  }
  .sm\:rt-r-gce-6 {
    grid-column-end: 6;
  }
  .sm\:rt-r-gce-7 {
    grid-column-end: 7;
  }
  .sm\:rt-r-gce-8 {
    grid-column-end: 8;
  }
  .sm\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gce {
    grid-column-end: var(--grid-column-end-md)
  }
  .md\:rt-r-gce-1 {
    grid-column-end: 1;
  }
  .md\:rt-r-gce-2 {
    grid-column-end: 2;
  }
  .md\:rt-r-gce-3 {
    grid-column-end: 3;
  }
  .md\:rt-r-gce-4 {
    grid-column-end: 4;
  }
  .md\:rt-r-gce-5 {
    grid-column-end: 5;
  }
  .md\:rt-r-gce-6 {
    grid-column-end: 6;
  }
  .md\:rt-r-gce-7 {
    grid-column-end: 7;
  }
  .md\:rt-r-gce-8 {
    grid-column-end: 8;
  }
  .md\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gce {
    grid-column-end: var(--grid-column-end-lg)
  }
  .lg\:rt-r-gce-1 {
    grid-column-end: 1;
  }
  .lg\:rt-r-gce-2 {
    grid-column-end: 2;
  }
  .lg\:rt-r-gce-3 {
    grid-column-end: 3;
  }
  .lg\:rt-r-gce-4 {
    grid-column-end: 4;
  }
  .lg\:rt-r-gce-5 {
    grid-column-end: 5;
  }
  .lg\:rt-r-gce-6 {
    grid-column-end: 6;
  }
  .lg\:rt-r-gce-7 {
    grid-column-end: 7;
  }
  .lg\:rt-r-gce-8 {
    grid-column-end: 8;
  }
  .lg\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xl)
  }
  .xl\:rt-r-gce-1 {
    grid-column-end: 1;
  }
  .xl\:rt-r-gce-2 {
    grid-column-end: 2;
  }
  .xl\:rt-r-gce-3 {
    grid-column-end: 3;
  }
  .xl\:rt-r-gce-4 {
    grid-column-end: 4;
  }
  .xl\:rt-r-gce-5 {
    grid-column-end: 5;
  }
  .xl\:rt-r-gce-6 {
    grid-column-end: 6;
  }
  .xl\:rt-r-gce-7 {
    grid-column-end: 7;
  }
  .xl\:rt-r-gce-8 {
    grid-column-end: 8;
  }
  .xl\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}
.rt-r-gr {
  grid-row: var(--grid-row)
}
.rt-r-gr-1 {
  grid-row: 1;
}
.rt-r-gr-2 {
  grid-row: 2;
}
.rt-r-gr-3 {
  grid-row: 3;
}
.rt-r-gr-4 {
  grid-row: 4;
}
.rt-r-gr-5 {
  grid-row: 5;
}
.rt-r-gr-6 {
  grid-row: 6;
}
.rt-r-gr-7 {
  grid-row: 7;
}
.rt-r-gr-8 {
  grid-row: 8;
}
.rt-r-gr-9 {
  grid-row: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-gr {
    grid-row: var(--grid-row-xs)
  }
  .xs\:rt-r-gr-1 {
    grid-row: 1;
  }
  .xs\:rt-r-gr-2 {
    grid-row: 2;
  }
  .xs\:rt-r-gr-3 {
    grid-row: 3;
  }
  .xs\:rt-r-gr-4 {
    grid-row: 4;
  }
  .xs\:rt-r-gr-5 {
    grid-row: 5;
  }
  .xs\:rt-r-gr-6 {
    grid-row: 6;
  }
  .xs\:rt-r-gr-7 {
    grid-row: 7;
  }
  .xs\:rt-r-gr-8 {
    grid-row: 8;
  }
  .xs\:rt-r-gr-9 {
    grid-row: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gr {
    grid-row: var(--grid-row-sm)
  }
  .sm\:rt-r-gr-1 {
    grid-row: 1;
  }
  .sm\:rt-r-gr-2 {
    grid-row: 2;
  }
  .sm\:rt-r-gr-3 {
    grid-row: 3;
  }
  .sm\:rt-r-gr-4 {
    grid-row: 4;
  }
  .sm\:rt-r-gr-5 {
    grid-row: 5;
  }
  .sm\:rt-r-gr-6 {
    grid-row: 6;
  }
  .sm\:rt-r-gr-7 {
    grid-row: 7;
  }
  .sm\:rt-r-gr-8 {
    grid-row: 8;
  }
  .sm\:rt-r-gr-9 {
    grid-row: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gr {
    grid-row: var(--grid-row-md)
  }
  .md\:rt-r-gr-1 {
    grid-row: 1;
  }
  .md\:rt-r-gr-2 {
    grid-row: 2;
  }
  .md\:rt-r-gr-3 {
    grid-row: 3;
  }
  .md\:rt-r-gr-4 {
    grid-row: 4;
  }
  .md\:rt-r-gr-5 {
    grid-row: 5;
  }
  .md\:rt-r-gr-6 {
    grid-row: 6;
  }
  .md\:rt-r-gr-7 {
    grid-row: 7;
  }
  .md\:rt-r-gr-8 {
    grid-row: 8;
  }
  .md\:rt-r-gr-9 {
    grid-row: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gr {
    grid-row: var(--grid-row-lg)
  }
  .lg\:rt-r-gr-1 {
    grid-row: 1;
  }
  .lg\:rt-r-gr-2 {
    grid-row: 2;
  }
  .lg\:rt-r-gr-3 {
    grid-row: 3;
  }
  .lg\:rt-r-gr-4 {
    grid-row: 4;
  }
  .lg\:rt-r-gr-5 {
    grid-row: 5;
  }
  .lg\:rt-r-gr-6 {
    grid-row: 6;
  }
  .lg\:rt-r-gr-7 {
    grid-row: 7;
  }
  .lg\:rt-r-gr-8 {
    grid-row: 8;
  }
  .lg\:rt-r-gr-9 {
    grid-row: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gr {
    grid-row: var(--grid-row-xl)
  }
  .xl\:rt-r-gr-1 {
    grid-row: 1;
  }
  .xl\:rt-r-gr-2 {
    grid-row: 2;
  }
  .xl\:rt-r-gr-3 {
    grid-row: 3;
  }
  .xl\:rt-r-gr-4 {
    grid-row: 4;
  }
  .xl\:rt-r-gr-5 {
    grid-row: 5;
  }
  .xl\:rt-r-gr-6 {
    grid-row: 6;
  }
  .xl\:rt-r-gr-7 {
    grid-row: 7;
  }
  .xl\:rt-r-gr-8 {
    grid-row: 8;
  }
  .xl\:rt-r-gr-9 {
    grid-row: 9;
  }
}
.rt-r-grs {
  grid-row-start: var(--grid-row-start)
}
.rt-r-grs-1 {
  grid-row-start: 1;
}
.rt-r-grs-2 {
  grid-row-start: 2;
}
.rt-r-grs-3 {
  grid-row-start: 3;
}
.rt-r-grs-4 {
  grid-row-start: 4;
}
.rt-r-grs-5 {
  grid-row-start: 5;
}
.rt-r-grs-6 {
  grid-row-start: 6;
}
.rt-r-grs-7 {
  grid-row-start: 7;
}
.rt-r-grs-8 {
  grid-row-start: 8;
}
.rt-r-grs-9 {
  grid-row-start: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xs)
  }
  .xs\:rt-r-grs-1 {
    grid-row-start: 1;
  }
  .xs\:rt-r-grs-2 {
    grid-row-start: 2;
  }
  .xs\:rt-r-grs-3 {
    grid-row-start: 3;
  }
  .xs\:rt-r-grs-4 {
    grid-row-start: 4;
  }
  .xs\:rt-r-grs-5 {
    grid-row-start: 5;
  }
  .xs\:rt-r-grs-6 {
    grid-row-start: 6;
  }
  .xs\:rt-r-grs-7 {
    grid-row-start: 7;
  }
  .xs\:rt-r-grs-8 {
    grid-row-start: 8;
  }
  .xs\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-grs {
    grid-row-start: var(--grid-row-start-sm)
  }
  .sm\:rt-r-grs-1 {
    grid-row-start: 1;
  }
  .sm\:rt-r-grs-2 {
    grid-row-start: 2;
  }
  .sm\:rt-r-grs-3 {
    grid-row-start: 3;
  }
  .sm\:rt-r-grs-4 {
    grid-row-start: 4;
  }
  .sm\:rt-r-grs-5 {
    grid-row-start: 5;
  }
  .sm\:rt-r-grs-6 {
    grid-row-start: 6;
  }
  .sm\:rt-r-grs-7 {
    grid-row-start: 7;
  }
  .sm\:rt-r-grs-8 {
    grid-row-start: 8;
  }
  .sm\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-grs {
    grid-row-start: var(--grid-row-start-md)
  }
  .md\:rt-r-grs-1 {
    grid-row-start: 1;
  }
  .md\:rt-r-grs-2 {
    grid-row-start: 2;
  }
  .md\:rt-r-grs-3 {
    grid-row-start: 3;
  }
  .md\:rt-r-grs-4 {
    grid-row-start: 4;
  }
  .md\:rt-r-grs-5 {
    grid-row-start: 5;
  }
  .md\:rt-r-grs-6 {
    grid-row-start: 6;
  }
  .md\:rt-r-grs-7 {
    grid-row-start: 7;
  }
  .md\:rt-r-grs-8 {
    grid-row-start: 8;
  }
  .md\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-grs {
    grid-row-start: var(--grid-row-start-lg)
  }
  .lg\:rt-r-grs-1 {
    grid-row-start: 1;
  }
  .lg\:rt-r-grs-2 {
    grid-row-start: 2;
  }
  .lg\:rt-r-grs-3 {
    grid-row-start: 3;
  }
  .lg\:rt-r-grs-4 {
    grid-row-start: 4;
  }
  .lg\:rt-r-grs-5 {
    grid-row-start: 5;
  }
  .lg\:rt-r-grs-6 {
    grid-row-start: 6;
  }
  .lg\:rt-r-grs-7 {
    grid-row-start: 7;
  }
  .lg\:rt-r-grs-8 {
    grid-row-start: 8;
  }
  .lg\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xl)
  }
  .xl\:rt-r-grs-1 {
    grid-row-start: 1;
  }
  .xl\:rt-r-grs-2 {
    grid-row-start: 2;
  }
  .xl\:rt-r-grs-3 {
    grid-row-start: 3;
  }
  .xl\:rt-r-grs-4 {
    grid-row-start: 4;
  }
  .xl\:rt-r-grs-5 {
    grid-row-start: 5;
  }
  .xl\:rt-r-grs-6 {
    grid-row-start: 6;
  }
  .xl\:rt-r-grs-7 {
    grid-row-start: 7;
  }
  .xl\:rt-r-grs-8 {
    grid-row-start: 8;
  }
  .xl\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}
.rt-r-gre {
  grid-row-end: var(--grid-row-end)
}
.rt-r-gre-1 {
  grid-row-end: 1;
}
.rt-r-gre-2 {
  grid-row-end: 2;
}
.rt-r-gre-3 {
  grid-row-end: 3;
}
.rt-r-gre-4 {
  grid-row-end: 4;
}
.rt-r-gre-5 {
  grid-row-end: 5;
}
.rt-r-gre-6 {
  grid-row-end: 6;
}
.rt-r-gre-7 {
  grid-row-end: 7;
}
.rt-r-gre-8 {
  grid-row-end: 8;
}
.rt-r-gre-9 {
  grid-row-end: 9;
}
@media (min-width: 520px) {
  .xs\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xs)
  }
  .xs\:rt-r-gre-1 {
    grid-row-end: 1;
  }
  .xs\:rt-r-gre-2 {
    grid-row-end: 2;
  }
  .xs\:rt-r-gre-3 {
    grid-row-end: 3;
  }
  .xs\:rt-r-gre-4 {
    grid-row-end: 4;
  }
  .xs\:rt-r-gre-5 {
    grid-row-end: 5;
  }
  .xs\:rt-r-gre-6 {
    grid-row-end: 6;
  }
  .xs\:rt-r-gre-7 {
    grid-row-end: 7;
  }
  .xs\:rt-r-gre-8 {
    grid-row-end: 8;
  }
  .xs\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gre {
    grid-row-end: var(--grid-row-end-sm)
  }
  .sm\:rt-r-gre-1 {
    grid-row-end: 1;
  }
  .sm\:rt-r-gre-2 {
    grid-row-end: 2;
  }
  .sm\:rt-r-gre-3 {
    grid-row-end: 3;
  }
  .sm\:rt-r-gre-4 {
    grid-row-end: 4;
  }
  .sm\:rt-r-gre-5 {
    grid-row-end: 5;
  }
  .sm\:rt-r-gre-6 {
    grid-row-end: 6;
  }
  .sm\:rt-r-gre-7 {
    grid-row-end: 7;
  }
  .sm\:rt-r-gre-8 {
    grid-row-end: 8;
  }
  .sm\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gre {
    grid-row-end: var(--grid-row-end-md)
  }
  .md\:rt-r-gre-1 {
    grid-row-end: 1;
  }
  .md\:rt-r-gre-2 {
    grid-row-end: 2;
  }
  .md\:rt-r-gre-3 {
    grid-row-end: 3;
  }
  .md\:rt-r-gre-4 {
    grid-row-end: 4;
  }
  .md\:rt-r-gre-5 {
    grid-row-end: 5;
  }
  .md\:rt-r-gre-6 {
    grid-row-end: 6;
  }
  .md\:rt-r-gre-7 {
    grid-row-end: 7;
  }
  .md\:rt-r-gre-8 {
    grid-row-end: 8;
  }
  .md\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gre {
    grid-row-end: var(--grid-row-end-lg)
  }
  .lg\:rt-r-gre-1 {
    grid-row-end: 1;
  }
  .lg\:rt-r-gre-2 {
    grid-row-end: 2;
  }
  .lg\:rt-r-gre-3 {
    grid-row-end: 3;
  }
  .lg\:rt-r-gre-4 {
    grid-row-end: 4;
  }
  .lg\:rt-r-gre-5 {
    grid-row-end: 5;
  }
  .lg\:rt-r-gre-6 {
    grid-row-end: 6;
  }
  .lg\:rt-r-gre-7 {
    grid-row-end: 7;
  }
  .lg\:rt-r-gre-8 {
    grid-row-end: 8;
  }
  .lg\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xl)
  }
  .xl\:rt-r-gre-1 {
    grid-row-end: 1;
  }
  .xl\:rt-r-gre-2 {
    grid-row-end: 2;
  }
  .xl\:rt-r-gre-3 {
    grid-row-end: 3;
  }
  .xl\:rt-r-gre-4 {
    grid-row-end: 4;
  }
  .xl\:rt-r-gre-5 {
    grid-row-end: 5;
  }
  .xl\:rt-r-gre-6 {
    grid-row-end: 6;
  }
  .xl\:rt-r-gre-7 {
    grid-row-end: 7;
  }
  .xl\:rt-r-gre-8 {
    grid-row-end: 8;
  }
  .xl\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}
.rt-r-gta {
  grid-template-areas: var(--grid-template-areas);
}
@media (min-width: 520px) {
  .xs\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xl);
  }
}
.rt-r-gtc {
  grid-template-columns: var(--grid-template-columns)
}
.rt-r-gtc-1 {
  grid-template-columns: minmax(0, 1fr);
}
.rt-r-gtc-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.rt-r-gtc-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.rt-r-gtc-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.rt-r-gtc-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.rt-r-gtc-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.rt-r-gtc-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.rt-r-gtc-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.rt-r-gtc-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
@media (min-width: 520px) {
  .xs\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xs)
  }
  .xs\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }
  .xs\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xs\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-sm)
  }
  .sm\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }
  .sm\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-md)
  }
  .md\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }
  .md\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-lg)
  }
  .lg\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }
  .lg\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xl)
  }
  .xl\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }
  .xl\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xl\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
.rt-r-gtr {
  grid-template-rows: var(--grid-template-rows)
}
.rt-r-gtr-1 {
  grid-template-rows: minmax(0, 1fr);
}
.rt-r-gtr-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.rt-r-gtr-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.rt-r-gtr-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.rt-r-gtr-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.rt-r-gtr-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.rt-r-gtr-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
.rt-r-gtr-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}
.rt-r-gtr-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}
@media (min-width: 520px) {
  .xs\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xs)
  }
  .xs\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }
  .xs\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
  .xs\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-sm)
  }
  .sm\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }
  .sm\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
  .sm\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-md)
  }
  .md\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }
  .md\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
  .md\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-lg)
  }
  .lg\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }
  .lg\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
  .lg\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xl)
  }
  .xl\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }
  .xl\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }
  .xl\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
.rt-r-h {
  height: var(--height);
}
@media (min-width: 520px) {
  .xs\:rt-r-h {
    height: var(--height-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-h {
    height: var(--height-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-h {
    height: var(--height-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-h {
    height: var(--height-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-h {
    height: var(--height-xl);
  }
}
.rt-r-min-h {
  min-height: var(--min-height);
}
@media (min-width: 520px) {
  .xs\:rt-r-min-h {
    min-height: var(--min-height-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-min-h {
    min-height: var(--min-height-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-min-h {
    min-height: var(--min-height-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-min-h {
    min-height: var(--min-height-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-min-h {
    min-height: var(--min-height-xl);
  }
}
.rt-r-max-h {
  max-height: var(--max-height);
}
@media (min-width: 520px) {
  .xs\:rt-r-max-h {
    max-height: var(--max-height-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-max-h {
    max-height: var(--max-height-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-max-h {
    max-height: var(--max-height-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-max-h {
    max-height: var(--max-height-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-max-h {
    max-height: var(--max-height-xl);
  }
}
.rt-r-inset {
  inset: var(--inset)
}
.rt-r-inset-0 {
  inset: 0;
}
.rt-r-inset-1 {
  inset: var(--space-1);
}
.rt-r-inset-2 {
  inset: var(--space-2);
}
.rt-r-inset-3 {
  inset: var(--space-3);
}
.rt-r-inset-4 {
  inset: var(--space-4);
}
.rt-r-inset-5 {
  inset: var(--space-5);
}
.rt-r-inset-6 {
  inset: var(--space-6);
}
.rt-r-inset-7 {
  inset: var(--space-7);
}
.rt-r-inset-8 {
  inset: var(--space-8);
}
.rt-r-inset-9 {
  inset: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-inset {
    inset: var(--inset-xs)
  }
  .xs\:rt-r-inset-0 {
    inset: 0;
  }
  .xs\:rt-r-inset-1 {
    inset: var(--space-1);
  }
  .xs\:rt-r-inset-2 {
    inset: var(--space-2);
  }
  .xs\:rt-r-inset-3 {
    inset: var(--space-3);
  }
  .xs\:rt-r-inset-4 {
    inset: var(--space-4);
  }
  .xs\:rt-r-inset-5 {
    inset: var(--space-5);
  }
  .xs\:rt-r-inset-6 {
    inset: var(--space-6);
  }
  .xs\:rt-r-inset-7 {
    inset: var(--space-7);
  }
  .xs\:rt-r-inset-8 {
    inset: var(--space-8);
  }
  .xs\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-inset {
    inset: var(--inset-sm)
  }
  .sm\:rt-r-inset-0 {
    inset: 0;
  }
  .sm\:rt-r-inset-1 {
    inset: var(--space-1);
  }
  .sm\:rt-r-inset-2 {
    inset: var(--space-2);
  }
  .sm\:rt-r-inset-3 {
    inset: var(--space-3);
  }
  .sm\:rt-r-inset-4 {
    inset: var(--space-4);
  }
  .sm\:rt-r-inset-5 {
    inset: var(--space-5);
  }
  .sm\:rt-r-inset-6 {
    inset: var(--space-6);
  }
  .sm\:rt-r-inset-7 {
    inset: var(--space-7);
  }
  .sm\:rt-r-inset-8 {
    inset: var(--space-8);
  }
  .sm\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-inset {
    inset: var(--inset-md)
  }
  .md\:rt-r-inset-0 {
    inset: 0;
  }
  .md\:rt-r-inset-1 {
    inset: var(--space-1);
  }
  .md\:rt-r-inset-2 {
    inset: var(--space-2);
  }
  .md\:rt-r-inset-3 {
    inset: var(--space-3);
  }
  .md\:rt-r-inset-4 {
    inset: var(--space-4);
  }
  .md\:rt-r-inset-5 {
    inset: var(--space-5);
  }
  .md\:rt-r-inset-6 {
    inset: var(--space-6);
  }
  .md\:rt-r-inset-7 {
    inset: var(--space-7);
  }
  .md\:rt-r-inset-8 {
    inset: var(--space-8);
  }
  .md\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-inset {
    inset: var(--inset-lg)
  }
  .lg\:rt-r-inset-0 {
    inset: 0;
  }
  .lg\:rt-r-inset-1 {
    inset: var(--space-1);
  }
  .lg\:rt-r-inset-2 {
    inset: var(--space-2);
  }
  .lg\:rt-r-inset-3 {
    inset: var(--space-3);
  }
  .lg\:rt-r-inset-4 {
    inset: var(--space-4);
  }
  .lg\:rt-r-inset-5 {
    inset: var(--space-5);
  }
  .lg\:rt-r-inset-6 {
    inset: var(--space-6);
  }
  .lg\:rt-r-inset-7 {
    inset: var(--space-7);
  }
  .lg\:rt-r-inset-8 {
    inset: var(--space-8);
  }
  .lg\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-inset {
    inset: var(--inset-xl)
  }
  .xl\:rt-r-inset-0 {
    inset: 0;
  }
  .xl\:rt-r-inset-1 {
    inset: var(--space-1);
  }
  .xl\:rt-r-inset-2 {
    inset: var(--space-2);
  }
  .xl\:rt-r-inset-3 {
    inset: var(--space-3);
  }
  .xl\:rt-r-inset-4 {
    inset: var(--space-4);
  }
  .xl\:rt-r-inset-5 {
    inset: var(--space-5);
  }
  .xl\:rt-r-inset-6 {
    inset: var(--space-6);
  }
  .xl\:rt-r-inset-7 {
    inset: var(--space-7);
  }
  .xl\:rt-r-inset-8 {
    inset: var(--space-8);
  }
  .xl\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}
.rt-r-top {
  top: var(--top)
}
.rt-r-top-0 {
  top: 0;
}
.rt-r-top-1 {
  top: var(--space-1);
}
.rt-r-top-2 {
  top: var(--space-2);
}
.rt-r-top-3 {
  top: var(--space-3);
}
.rt-r-top-4 {
  top: var(--space-4);
}
.rt-r-top-5 {
  top: var(--space-5);
}
.rt-r-top-6 {
  top: var(--space-6);
}
.rt-r-top-7 {
  top: var(--space-7);
}
.rt-r-top-8 {
  top: var(--space-8);
}
.rt-r-top-9 {
  top: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-top {
    top: var(--top-xs)
  }
  .xs\:rt-r-top-0 {
    top: 0;
  }
  .xs\:rt-r-top-1 {
    top: var(--space-1);
  }
  .xs\:rt-r-top-2 {
    top: var(--space-2);
  }
  .xs\:rt-r-top-3 {
    top: var(--space-3);
  }
  .xs\:rt-r-top-4 {
    top: var(--space-4);
  }
  .xs\:rt-r-top-5 {
    top: var(--space-5);
  }
  .xs\:rt-r-top-6 {
    top: var(--space-6);
  }
  .xs\:rt-r-top-7 {
    top: var(--space-7);
  }
  .xs\:rt-r-top-8 {
    top: var(--space-8);
  }
  .xs\:rt-r-top-9 {
    top: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-top {
    top: var(--top-sm)
  }
  .sm\:rt-r-top-0 {
    top: 0;
  }
  .sm\:rt-r-top-1 {
    top: var(--space-1);
  }
  .sm\:rt-r-top-2 {
    top: var(--space-2);
  }
  .sm\:rt-r-top-3 {
    top: var(--space-3);
  }
  .sm\:rt-r-top-4 {
    top: var(--space-4);
  }
  .sm\:rt-r-top-5 {
    top: var(--space-5);
  }
  .sm\:rt-r-top-6 {
    top: var(--space-6);
  }
  .sm\:rt-r-top-7 {
    top: var(--space-7);
  }
  .sm\:rt-r-top-8 {
    top: var(--space-8);
  }
  .sm\:rt-r-top-9 {
    top: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-top {
    top: var(--top-md)
  }
  .md\:rt-r-top-0 {
    top: 0;
  }
  .md\:rt-r-top-1 {
    top: var(--space-1);
  }
  .md\:rt-r-top-2 {
    top: var(--space-2);
  }
  .md\:rt-r-top-3 {
    top: var(--space-3);
  }
  .md\:rt-r-top-4 {
    top: var(--space-4);
  }
  .md\:rt-r-top-5 {
    top: var(--space-5);
  }
  .md\:rt-r-top-6 {
    top: var(--space-6);
  }
  .md\:rt-r-top-7 {
    top: var(--space-7);
  }
  .md\:rt-r-top-8 {
    top: var(--space-8);
  }
  .md\:rt-r-top-9 {
    top: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-top {
    top: var(--top-lg)
  }
  .lg\:rt-r-top-0 {
    top: 0;
  }
  .lg\:rt-r-top-1 {
    top: var(--space-1);
  }
  .lg\:rt-r-top-2 {
    top: var(--space-2);
  }
  .lg\:rt-r-top-3 {
    top: var(--space-3);
  }
  .lg\:rt-r-top-4 {
    top: var(--space-4);
  }
  .lg\:rt-r-top-5 {
    top: var(--space-5);
  }
  .lg\:rt-r-top-6 {
    top: var(--space-6);
  }
  .lg\:rt-r-top-7 {
    top: var(--space-7);
  }
  .lg\:rt-r-top-8 {
    top: var(--space-8);
  }
  .lg\:rt-r-top-9 {
    top: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-top {
    top: var(--top-xl)
  }
  .xl\:rt-r-top-0 {
    top: 0;
  }
  .xl\:rt-r-top-1 {
    top: var(--space-1);
  }
  .xl\:rt-r-top-2 {
    top: var(--space-2);
  }
  .xl\:rt-r-top-3 {
    top: var(--space-3);
  }
  .xl\:rt-r-top-4 {
    top: var(--space-4);
  }
  .xl\:rt-r-top-5 {
    top: var(--space-5);
  }
  .xl\:rt-r-top-6 {
    top: var(--space-6);
  }
  .xl\:rt-r-top-7 {
    top: var(--space-7);
  }
  .xl\:rt-r-top-8 {
    top: var(--space-8);
  }
  .xl\:rt-r-top-9 {
    top: var(--space-9);
  }
}
.rt-r-right {
  right: var(--right)
}
.rt-r-right-0 {
  right: 0;
}
.rt-r-right-1 {
  right: var(--space-1);
}
.rt-r-right-2 {
  right: var(--space-2);
}
.rt-r-right-3 {
  right: var(--space-3);
}
.rt-r-right-4 {
  right: var(--space-4);
}
.rt-r-right-5 {
  right: var(--space-5);
}
.rt-r-right-6 {
  right: var(--space-6);
}
.rt-r-right-7 {
  right: var(--space-7);
}
.rt-r-right-8 {
  right: var(--space-8);
}
.rt-r-right-9 {
  right: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-right {
    right: var(--right-xs)
  }
  .xs\:rt-r-right-0 {
    right: 0;
  }
  .xs\:rt-r-right-1 {
    right: var(--space-1);
  }
  .xs\:rt-r-right-2 {
    right: var(--space-2);
  }
  .xs\:rt-r-right-3 {
    right: var(--space-3);
  }
  .xs\:rt-r-right-4 {
    right: var(--space-4);
  }
  .xs\:rt-r-right-5 {
    right: var(--space-5);
  }
  .xs\:rt-r-right-6 {
    right: var(--space-6);
  }
  .xs\:rt-r-right-7 {
    right: var(--space-7);
  }
  .xs\:rt-r-right-8 {
    right: var(--space-8);
  }
  .xs\:rt-r-right-9 {
    right: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-right {
    right: var(--right-sm)
  }
  .sm\:rt-r-right-0 {
    right: 0;
  }
  .sm\:rt-r-right-1 {
    right: var(--space-1);
  }
  .sm\:rt-r-right-2 {
    right: var(--space-2);
  }
  .sm\:rt-r-right-3 {
    right: var(--space-3);
  }
  .sm\:rt-r-right-4 {
    right: var(--space-4);
  }
  .sm\:rt-r-right-5 {
    right: var(--space-5);
  }
  .sm\:rt-r-right-6 {
    right: var(--space-6);
  }
  .sm\:rt-r-right-7 {
    right: var(--space-7);
  }
  .sm\:rt-r-right-8 {
    right: var(--space-8);
  }
  .sm\:rt-r-right-9 {
    right: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-right {
    right: var(--right-md)
  }
  .md\:rt-r-right-0 {
    right: 0;
  }
  .md\:rt-r-right-1 {
    right: var(--space-1);
  }
  .md\:rt-r-right-2 {
    right: var(--space-2);
  }
  .md\:rt-r-right-3 {
    right: var(--space-3);
  }
  .md\:rt-r-right-4 {
    right: var(--space-4);
  }
  .md\:rt-r-right-5 {
    right: var(--space-5);
  }
  .md\:rt-r-right-6 {
    right: var(--space-6);
  }
  .md\:rt-r-right-7 {
    right: var(--space-7);
  }
  .md\:rt-r-right-8 {
    right: var(--space-8);
  }
  .md\:rt-r-right-9 {
    right: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-right {
    right: var(--right-lg)
  }
  .lg\:rt-r-right-0 {
    right: 0;
  }
  .lg\:rt-r-right-1 {
    right: var(--space-1);
  }
  .lg\:rt-r-right-2 {
    right: var(--space-2);
  }
  .lg\:rt-r-right-3 {
    right: var(--space-3);
  }
  .lg\:rt-r-right-4 {
    right: var(--space-4);
  }
  .lg\:rt-r-right-5 {
    right: var(--space-5);
  }
  .lg\:rt-r-right-6 {
    right: var(--space-6);
  }
  .lg\:rt-r-right-7 {
    right: var(--space-7);
  }
  .lg\:rt-r-right-8 {
    right: var(--space-8);
  }
  .lg\:rt-r-right-9 {
    right: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-right {
    right: var(--right-xl)
  }
  .xl\:rt-r-right-0 {
    right: 0;
  }
  .xl\:rt-r-right-1 {
    right: var(--space-1);
  }
  .xl\:rt-r-right-2 {
    right: var(--space-2);
  }
  .xl\:rt-r-right-3 {
    right: var(--space-3);
  }
  .xl\:rt-r-right-4 {
    right: var(--space-4);
  }
  .xl\:rt-r-right-5 {
    right: var(--space-5);
  }
  .xl\:rt-r-right-6 {
    right: var(--space-6);
  }
  .xl\:rt-r-right-7 {
    right: var(--space-7);
  }
  .xl\:rt-r-right-8 {
    right: var(--space-8);
  }
  .xl\:rt-r-right-9 {
    right: var(--space-9);
  }
}
.rt-r-bottom {
  bottom: var(--bottom)
}
.rt-r-bottom-0 {
  bottom: 0;
}
.rt-r-bottom-1 {
  bottom: var(--space-1);
}
.rt-r-bottom-2 {
  bottom: var(--space-2);
}
.rt-r-bottom-3 {
  bottom: var(--space-3);
}
.rt-r-bottom-4 {
  bottom: var(--space-4);
}
.rt-r-bottom-5 {
  bottom: var(--space-5);
}
.rt-r-bottom-6 {
  bottom: var(--space-6);
}
.rt-r-bottom-7 {
  bottom: var(--space-7);
}
.rt-r-bottom-8 {
  bottom: var(--space-8);
}
.rt-r-bottom-9 {
  bottom: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-bottom {
    bottom: var(--bottom-xs)
  }
  .xs\:rt-r-bottom-0 {
    bottom: 0;
  }
  .xs\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }
  .xs\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }
  .xs\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }
  .xs\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }
  .xs\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }
  .xs\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }
  .xs\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }
  .xs\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }
  .xs\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-bottom {
    bottom: var(--bottom-sm)
  }
  .sm\:rt-r-bottom-0 {
    bottom: 0;
  }
  .sm\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }
  .sm\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }
  .sm\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }
  .sm\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }
  .sm\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }
  .sm\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }
  .sm\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }
  .sm\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }
  .sm\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-bottom {
    bottom: var(--bottom-md)
  }
  .md\:rt-r-bottom-0 {
    bottom: 0;
  }
  .md\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }
  .md\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }
  .md\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }
  .md\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }
  .md\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }
  .md\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }
  .md\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }
  .md\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }
  .md\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-bottom {
    bottom: var(--bottom-lg)
  }
  .lg\:rt-r-bottom-0 {
    bottom: 0;
  }
  .lg\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }
  .lg\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }
  .lg\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }
  .lg\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }
  .lg\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }
  .lg\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }
  .lg\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }
  .lg\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }
  .lg\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-bottom {
    bottom: var(--bottom-xl)
  }
  .xl\:rt-r-bottom-0 {
    bottom: 0;
  }
  .xl\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }
  .xl\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }
  .xl\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }
  .xl\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }
  .xl\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }
  .xl\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }
  .xl\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }
  .xl\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }
  .xl\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}
.rt-r-left {
  left: var(--left)
}
.rt-r-left-0 {
  left: 0;
}
.rt-r-left-1 {
  left: var(--space-1);
}
.rt-r-left-2 {
  left: var(--space-2);
}
.rt-r-left-3 {
  left: var(--space-3);
}
.rt-r-left-4 {
  left: var(--space-4);
}
.rt-r-left-5 {
  left: var(--space-5);
}
.rt-r-left-6 {
  left: var(--space-6);
}
.rt-r-left-7 {
  left: var(--space-7);
}
.rt-r-left-8 {
  left: var(--space-8);
}
.rt-r-left-9 {
  left: var(--space-9);
}
@media (min-width: 520px) {
  .xs\:rt-r-left {
    left: var(--left-xs)
  }
  .xs\:rt-r-left-0 {
    left: 0;
  }
  .xs\:rt-r-left-1 {
    left: var(--space-1);
  }
  .xs\:rt-r-left-2 {
    left: var(--space-2);
  }
  .xs\:rt-r-left-3 {
    left: var(--space-3);
  }
  .xs\:rt-r-left-4 {
    left: var(--space-4);
  }
  .xs\:rt-r-left-5 {
    left: var(--space-5);
  }
  .xs\:rt-r-left-6 {
    left: var(--space-6);
  }
  .xs\:rt-r-left-7 {
    left: var(--space-7);
  }
  .xs\:rt-r-left-8 {
    left: var(--space-8);
  }
  .xs\:rt-r-left-9 {
    left: var(--space-9);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-left {
    left: var(--left-sm)
  }
  .sm\:rt-r-left-0 {
    left: 0;
  }
  .sm\:rt-r-left-1 {
    left: var(--space-1);
  }
  .sm\:rt-r-left-2 {
    left: var(--space-2);
  }
  .sm\:rt-r-left-3 {
    left: var(--space-3);
  }
  .sm\:rt-r-left-4 {
    left: var(--space-4);
  }
  .sm\:rt-r-left-5 {
    left: var(--space-5);
  }
  .sm\:rt-r-left-6 {
    left: var(--space-6);
  }
  .sm\:rt-r-left-7 {
    left: var(--space-7);
  }
  .sm\:rt-r-left-8 {
    left: var(--space-8);
  }
  .sm\:rt-r-left-9 {
    left: var(--space-9);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-left {
    left: var(--left-md)
  }
  .md\:rt-r-left-0 {
    left: 0;
  }
  .md\:rt-r-left-1 {
    left: var(--space-1);
  }
  .md\:rt-r-left-2 {
    left: var(--space-2);
  }
  .md\:rt-r-left-3 {
    left: var(--space-3);
  }
  .md\:rt-r-left-4 {
    left: var(--space-4);
  }
  .md\:rt-r-left-5 {
    left: var(--space-5);
  }
  .md\:rt-r-left-6 {
    left: var(--space-6);
  }
  .md\:rt-r-left-7 {
    left: var(--space-7);
  }
  .md\:rt-r-left-8 {
    left: var(--space-8);
  }
  .md\:rt-r-left-9 {
    left: var(--space-9);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-left {
    left: var(--left-lg)
  }
  .lg\:rt-r-left-0 {
    left: 0;
  }
  .lg\:rt-r-left-1 {
    left: var(--space-1);
  }
  .lg\:rt-r-left-2 {
    left: var(--space-2);
  }
  .lg\:rt-r-left-3 {
    left: var(--space-3);
  }
  .lg\:rt-r-left-4 {
    left: var(--space-4);
  }
  .lg\:rt-r-left-5 {
    left: var(--space-5);
  }
  .lg\:rt-r-left-6 {
    left: var(--space-6);
  }
  .lg\:rt-r-left-7 {
    left: var(--space-7);
  }
  .lg\:rt-r-left-8 {
    left: var(--space-8);
  }
  .lg\:rt-r-left-9 {
    left: var(--space-9);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-left {
    left: var(--left-xl)
  }
  .xl\:rt-r-left-0 {
    left: 0;
  }
  .xl\:rt-r-left-1 {
    left: var(--space-1);
  }
  .xl\:rt-r-left-2 {
    left: var(--space-2);
  }
  .xl\:rt-r-left-3 {
    left: var(--space-3);
  }
  .xl\:rt-r-left-4 {
    left: var(--space-4);
  }
  .xl\:rt-r-left-5 {
    left: var(--space-5);
  }
  .xl\:rt-r-left-6 {
    left: var(--space-6);
  }
  .xl\:rt-r-left-7 {
    left: var(--space-7);
  }
  .xl\:rt-r-left-8 {
    left: var(--space-8);
  }
  .xl\:rt-r-left-9 {
    left: var(--space-9);
  }
}
.rt-r-jc-start {
  justify-content: flex-start;
}
.rt-r-jc-center {
  justify-content: center;
}
.rt-r-jc-end {
  justify-content: flex-end;
}
.rt-r-jc-space-between {
  justify-content: space-between;
}
@media (min-width: 520px) {
  .xs\:rt-r-jc-start {
    justify-content: flex-start;
  }
  .xs\:rt-r-jc-center {
    justify-content: center;
  }
  .xs\:rt-r-jc-end {
    justify-content: flex-end;
  }
  .xs\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-jc-start {
    justify-content: flex-start;
  }
  .sm\:rt-r-jc-center {
    justify-content: center;
  }
  .sm\:rt-r-jc-end {
    justify-content: flex-end;
  }
  .sm\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-jc-start {
    justify-content: flex-start;
  }
  .md\:rt-r-jc-center {
    justify-content: center;
  }
  .md\:rt-r-jc-end {
    justify-content: flex-end;
  }
  .md\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-jc-start {
    justify-content: flex-start;
  }
  .lg\:rt-r-jc-center {
    justify-content: center;
  }
  .lg\:rt-r-jc-end {
    justify-content: flex-end;
  }
  .lg\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-jc-start {
    justify-content: flex-start;
  }
  .xl\:rt-r-jc-center {
    justify-content: center;
  }
  .xl\:rt-r-jc-end {
    justify-content: flex-end;
  }
  .xl\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}
.rt-r-m,
.rt-r-m-0,
.rt-r-m-1,
.rt-r-m-2,
.rt-r-m-3,
.rt-r-m-4,
.rt-r-m-5,
.rt-r-m-6,
.rt-r-m-7,
.rt-r-m-8,
.rt-r-m-9,
.-rt-r-m-1,
.-rt-r-m-2,
.-rt-r-m-3,
.-rt-r-m-4,
.-rt-r-m-5,
.-rt-r-m-6,
.-rt-r-m-7,
.-rt-r-m-8,
.-rt-r-m-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-right: var(--margin-right-override, var(--margin-right));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  margin-left: var(--margin-left-override, var(--margin-left));
}
.rt-r-m {
  --margin-top: var(--m);
  --margin-right: var(--m);
  --margin-bottom: var(--m);
  --margin-left: var(--m)
}
.rt-r-m-0 {
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
}
.rt-r-m-1 {
  --margin-top: var(--space-1);
  --margin-right: var(--space-1);
  --margin-bottom: var(--space-1);
  --margin-left: var(--space-1);
}
.rt-r-m-2 {
  --margin-top: var(--space-2);
  --margin-right: var(--space-2);
  --margin-bottom: var(--space-2);
  --margin-left: var(--space-2);
}
.rt-r-m-3 {
  --margin-top: var(--space-3);
  --margin-right: var(--space-3);
  --margin-bottom: var(--space-3);
  --margin-left: var(--space-3);
}
.rt-r-m-4 {
  --margin-top: var(--space-4);
  --margin-right: var(--space-4);
  --margin-bottom: var(--space-4);
  --margin-left: var(--space-4);
}
.rt-r-m-5 {
  --margin-top: var(--space-5);
  --margin-right: var(--space-5);
  --margin-bottom: var(--space-5);
  --margin-left: var(--space-5);
}
.rt-r-m-6 {
  --margin-top: var(--space-6);
  --margin-right: var(--space-6);
  --margin-bottom: var(--space-6);
  --margin-left: var(--space-6);
}
.rt-r-m-7 {
  --margin-top: var(--space-7);
  --margin-right: var(--space-7);
  --margin-bottom: var(--space-7);
  --margin-left: var(--space-7);
}
.rt-r-m-8 {
  --margin-top: var(--space-8);
  --margin-right: var(--space-8);
  --margin-bottom: var(--space-8);
  --margin-left: var(--space-8);
}
.rt-r-m-9 {
  --margin-top: var(--space-9);
  --margin-right: var(--space-9);
  --margin-bottom: var(--space-9);
  --margin-left: var(--space-9);
}
.-rt-r-m-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
  --margin-left: calc(-1 * var(--space-1));
}
.-rt-r-m-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
  --margin-left: calc(-1 * var(--space-2));
}
.-rt-r-m-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
  --margin-left: calc(-1 * var(--space-3));
}
.-rt-r-m-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
  --margin-left: calc(-1 * var(--space-4));
}
.-rt-r-m-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
  --margin-left: calc(-1 * var(--space-5));
}
.-rt-r-m-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
  --margin-left: calc(-1 * var(--space-6));
}
.-rt-r-m-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
  --margin-left: calc(-1 * var(--space-7));
}
.-rt-r-m-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
  --margin-left: calc(-1 * var(--space-8));
}
.-rt-r-m-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
  --margin-left: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-m,
  .xs\:rt-r-m-0,
  .xs\:rt-r-m-1,
  .xs\:rt-r-m-2,
  .xs\:rt-r-m-3,
  .xs\:rt-r-m-4,
  .xs\:rt-r-m-5,
  .xs\:rt-r-m-6,
  .xs\:rt-r-m-7,
  .xs\:rt-r-m-8,
  .xs\:rt-r-m-9,
  .xs\:-rt-r-m-1,
  .xs\:-rt-r-m-2,
  .xs\:-rt-r-m-3,
  .xs\:-rt-r-m-4,
  .xs\:-rt-r-m-5,
  .xs\:-rt-r-m-6,
  .xs\:-rt-r-m-7,
  .xs\:-rt-r-m-8,
  .xs\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .xs\:rt-r-m {
    --margin-top: var(--m-xs);
    --margin-right: var(--m-xs);
    --margin-bottom: var(--m-xs);
    --margin-left: var(--m-xs)
  }
  .xs\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }
  .xs\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }
  .xs\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }
  .xs\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }
  .xs\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }
  .xs\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }
  .xs\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }
  .xs\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }
  .xs\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }
  .xs\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }
  .xs\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-m,
  .sm\:rt-r-m-0,
  .sm\:rt-r-m-1,
  .sm\:rt-r-m-2,
  .sm\:rt-r-m-3,
  .sm\:rt-r-m-4,
  .sm\:rt-r-m-5,
  .sm\:rt-r-m-6,
  .sm\:rt-r-m-7,
  .sm\:rt-r-m-8,
  .sm\:rt-r-m-9,
  .sm\:-rt-r-m-1,
  .sm\:-rt-r-m-2,
  .sm\:-rt-r-m-3,
  .sm\:-rt-r-m-4,
  .sm\:-rt-r-m-5,
  .sm\:-rt-r-m-6,
  .sm\:-rt-r-m-7,
  .sm\:-rt-r-m-8,
  .sm\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .sm\:rt-r-m {
    --margin-top: var(--m-sm);
    --margin-right: var(--m-sm);
    --margin-bottom: var(--m-sm);
    --margin-left: var(--m-sm)
  }
  .sm\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }
  .sm\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }
  .sm\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }
  .sm\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }
  .sm\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }
  .sm\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }
  .sm\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }
  .sm\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }
  .sm\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }
  .sm\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }
  .sm\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-m,
  .md\:rt-r-m-0,
  .md\:rt-r-m-1,
  .md\:rt-r-m-2,
  .md\:rt-r-m-3,
  .md\:rt-r-m-4,
  .md\:rt-r-m-5,
  .md\:rt-r-m-6,
  .md\:rt-r-m-7,
  .md\:rt-r-m-8,
  .md\:rt-r-m-9,
  .md\:-rt-r-m-1,
  .md\:-rt-r-m-2,
  .md\:-rt-r-m-3,
  .md\:-rt-r-m-4,
  .md\:-rt-r-m-5,
  .md\:-rt-r-m-6,
  .md\:-rt-r-m-7,
  .md\:-rt-r-m-8,
  .md\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .md\:rt-r-m {
    --margin-top: var(--m-md);
    --margin-right: var(--m-md);
    --margin-bottom: var(--m-md);
    --margin-left: var(--m-md)
  }
  .md\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }
  .md\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }
  .md\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }
  .md\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }
  .md\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }
  .md\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }
  .md\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }
  .md\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }
  .md\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }
  .md\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }
  .md\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-m,
  .lg\:rt-r-m-0,
  .lg\:rt-r-m-1,
  .lg\:rt-r-m-2,
  .lg\:rt-r-m-3,
  .lg\:rt-r-m-4,
  .lg\:rt-r-m-5,
  .lg\:rt-r-m-6,
  .lg\:rt-r-m-7,
  .lg\:rt-r-m-8,
  .lg\:rt-r-m-9,
  .lg\:-rt-r-m-1,
  .lg\:-rt-r-m-2,
  .lg\:-rt-r-m-3,
  .lg\:-rt-r-m-4,
  .lg\:-rt-r-m-5,
  .lg\:-rt-r-m-6,
  .lg\:-rt-r-m-7,
  .lg\:-rt-r-m-8,
  .lg\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .lg\:rt-r-m {
    --margin-top: var(--m-lg);
    --margin-right: var(--m-lg);
    --margin-bottom: var(--m-lg);
    --margin-left: var(--m-lg)
  }
  .lg\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }
  .lg\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }
  .lg\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }
  .lg\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }
  .lg\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }
  .lg\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }
  .lg\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }
  .lg\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }
  .lg\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }
  .lg\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }
  .lg\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-m,
  .xl\:rt-r-m-0,
  .xl\:rt-r-m-1,
  .xl\:rt-r-m-2,
  .xl\:rt-r-m-3,
  .xl\:rt-r-m-4,
  .xl\:rt-r-m-5,
  .xl\:rt-r-m-6,
  .xl\:rt-r-m-7,
  .xl\:rt-r-m-8,
  .xl\:rt-r-m-9,
  .xl\:-rt-r-m-1,
  .xl\:-rt-r-m-2,
  .xl\:-rt-r-m-3,
  .xl\:-rt-r-m-4,
  .xl\:-rt-r-m-5,
  .xl\:-rt-r-m-6,
  .xl\:-rt-r-m-7,
  .xl\:-rt-r-m-8,
  .xl\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .xl\:rt-r-m {
    --margin-top: var(--m-xl);
    --margin-right: var(--m-xl);
    --margin-bottom: var(--m-xl);
    --margin-left: var(--m-xl)
  }
  .xl\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }
  .xl\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }
  .xl\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }
  .xl\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }
  .xl\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }
  .xl\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }
  .xl\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }
  .xl\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }
  .xl\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }
  .xl\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }
  .xl\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}
.rt-r-mx,
.rt-r-mx-0,
.rt-r-mx-1,
.rt-r-mx-2,
.rt-r-mx-3,
.rt-r-mx-4,
.rt-r-mx-5,
.rt-r-mx-6,
.rt-r-mx-7,
.rt-r-mx-8,
.rt-r-mx-9,
.-rt-r-mx-1,
.-rt-r-mx-2,
.-rt-r-mx-3,
.-rt-r-mx-4,
.-rt-r-mx-5,
.-rt-r-mx-6,
.-rt-r-mx-7,
.-rt-r-mx-8,
.-rt-r-mx-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
  margin-right: var(--margin-right-override, var(--margin-right));
}
.rt-r-mx {
  --margin-left: var(--ml);
  --margin-right: var(--mr)
}
.rt-r-mx-0 {
  --margin-left: 0px;
  --margin-right: 0px;
}
.rt-r-mx-1 {
  --margin-left: var(--space-1);
  --margin-right: var(--space-1);
}
.rt-r-mx-2 {
  --margin-left: var(--space-2);
  --margin-right: var(--space-2);
}
.rt-r-mx-3 {
  --margin-left: var(--space-3);
  --margin-right: var(--space-3);
}
.rt-r-mx-4 {
  --margin-left: var(--space-4);
  --margin-right: var(--space-4);
}
.rt-r-mx-5 {
  --margin-left: var(--space-5);
  --margin-right: var(--space-5);
}
.rt-r-mx-6 {
  --margin-left: var(--space-6);
  --margin-right: var(--space-6);
}
.rt-r-mx-7 {
  --margin-left: var(--space-7);
  --margin-right: var(--space-7);
}
.rt-r-mx-8 {
  --margin-left: var(--space-8);
  --margin-right: var(--space-8);
}
.rt-r-mx-9 {
  --margin-left: var(--space-9);
  --margin-right: var(--space-9);
}
.-rt-r-mx-1 {
  --margin-left: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
}
.-rt-r-mx-2 {
  --margin-left: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
}
.-rt-r-mx-3 {
  --margin-left: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
}
.-rt-r-mx-4 {
  --margin-left: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
}
.-rt-r-mx-5 {
  --margin-left: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
}
.-rt-r-mx-6 {
  --margin-left: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
}
.-rt-r-mx-7 {
  --margin-left: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
}
.-rt-r-mx-8 {
  --margin-left: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
}
.-rt-r-mx-9 {
  --margin-left: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-mx,
  .xs\:rt-r-mx-0,
  .xs\:rt-r-mx-1,
  .xs\:rt-r-mx-2,
  .xs\:rt-r-mx-3,
  .xs\:rt-r-mx-4,
  .xs\:rt-r-mx-5,
  .xs\:rt-r-mx-6,
  .xs\:rt-r-mx-7,
  .xs\:rt-r-mx-8,
  .xs\:rt-r-mx-9,
  .xs\:-rt-r-mx-1,
  .xs\:-rt-r-mx-2,
  .xs\:-rt-r-mx-3,
  .xs\:-rt-r-mx-4,
  .xs\:-rt-r-mx-5,
  .xs\:-rt-r-mx-6,
  .xs\:-rt-r-mx-7,
  .xs\:-rt-r-mx-8,
  .xs\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .xs\:rt-r-mx {
    --margin-left: var(--ml-xs);
    --margin-right: var(--mr-xs)
  }
  .xs\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }
  .xs\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }
  .xs\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }
  .xs\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }
  .xs\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }
  .xs\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }
  .xs\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }
  .xs\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }
  .xs\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }
  .xs\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }
  .xs\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-mx,
  .sm\:rt-r-mx-0,
  .sm\:rt-r-mx-1,
  .sm\:rt-r-mx-2,
  .sm\:rt-r-mx-3,
  .sm\:rt-r-mx-4,
  .sm\:rt-r-mx-5,
  .sm\:rt-r-mx-6,
  .sm\:rt-r-mx-7,
  .sm\:rt-r-mx-8,
  .sm\:rt-r-mx-9,
  .sm\:-rt-r-mx-1,
  .sm\:-rt-r-mx-2,
  .sm\:-rt-r-mx-3,
  .sm\:-rt-r-mx-4,
  .sm\:-rt-r-mx-5,
  .sm\:-rt-r-mx-6,
  .sm\:-rt-r-mx-7,
  .sm\:-rt-r-mx-8,
  .sm\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .sm\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md)
  }
  .sm\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }
  .sm\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }
  .sm\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }
  .sm\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }
  .sm\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }
  .sm\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }
  .sm\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }
  .sm\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }
  .sm\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }
  .sm\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }
  .sm\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-mx,
  .md\:rt-r-mx-0,
  .md\:rt-r-mx-1,
  .md\:rt-r-mx-2,
  .md\:rt-r-mx-3,
  .md\:rt-r-mx-4,
  .md\:rt-r-mx-5,
  .md\:rt-r-mx-6,
  .md\:rt-r-mx-7,
  .md\:rt-r-mx-8,
  .md\:rt-r-mx-9,
  .md\:-rt-r-mx-1,
  .md\:-rt-r-mx-2,
  .md\:-rt-r-mx-3,
  .md\:-rt-r-mx-4,
  .md\:-rt-r-mx-5,
  .md\:-rt-r-mx-6,
  .md\:-rt-r-mx-7,
  .md\:-rt-r-mx-8,
  .md\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .md\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md)
  }
  .md\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }
  .md\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }
  .md\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }
  .md\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }
  .md\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }
  .md\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }
  .md\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }
  .md\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }
  .md\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }
  .md\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }
  .md\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-mx,
  .lg\:rt-r-mx-0,
  .lg\:rt-r-mx-1,
  .lg\:rt-r-mx-2,
  .lg\:rt-r-mx-3,
  .lg\:rt-r-mx-4,
  .lg\:rt-r-mx-5,
  .lg\:rt-r-mx-6,
  .lg\:rt-r-mx-7,
  .lg\:rt-r-mx-8,
  .lg\:rt-r-mx-9,
  .lg\:-rt-r-mx-1,
  .lg\:-rt-r-mx-2,
  .lg\:-rt-r-mx-3,
  .lg\:-rt-r-mx-4,
  .lg\:-rt-r-mx-5,
  .lg\:-rt-r-mx-6,
  .lg\:-rt-r-mx-7,
  .lg\:-rt-r-mx-8,
  .lg\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .lg\:rt-r-mx {
    --margin-left: var(--ml-lg);
    --margin-right: var(--mr-lg)
  }
  .lg\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }
  .lg\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }
  .lg\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }
  .lg\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }
  .lg\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }
  .lg\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }
  .lg\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }
  .lg\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }
  .lg\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }
  .lg\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }
  .lg\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-mx,
  .xl\:rt-r-mx-0,
  .xl\:rt-r-mx-1,
  .xl\:rt-r-mx-2,
  .xl\:rt-r-mx-3,
  .xl\:rt-r-mx-4,
  .xl\:rt-r-mx-5,
  .xl\:rt-r-mx-6,
  .xl\:rt-r-mx-7,
  .xl\:rt-r-mx-8,
  .xl\:rt-r-mx-9,
  .xl\:-rt-r-mx-1,
  .xl\:-rt-r-mx-2,
  .xl\:-rt-r-mx-3,
  .xl\:-rt-r-mx-4,
  .xl\:-rt-r-mx-5,
  .xl\:-rt-r-mx-6,
  .xl\:-rt-r-mx-7,
  .xl\:-rt-r-mx-8,
  .xl\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .xl\:rt-r-mx {
    --margin-left: var(--ml-xl);
    --margin-right: var(--mr-xl)
  }
  .xl\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }
  .xl\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }
  .xl\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }
  .xl\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }
  .xl\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }
  .xl\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }
  .xl\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }
  .xl\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }
  .xl\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }
  .xl\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }
  .xl\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}
.rt-r-my,
.rt-r-my-0,
.rt-r-my-1,
.rt-r-my-2,
.rt-r-my-3,
.rt-r-my-4,
.rt-r-my-5,
.rt-r-my-6,
.rt-r-my-7,
.rt-r-my-8,
.rt-r-my-9,
.-rt-r-my-1,
.-rt-r-my-2,
.-rt-r-my-3,
.-rt-r-my-4,
.-rt-r-my-5,
.-rt-r-my-6,
.-rt-r-my-7,
.-rt-r-my-8,
.-rt-r-my-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}
.rt-r-my {
  --margin-top: var(--mt);
  --margin-bottom: var(--mb)
}
.rt-r-my-0 {
  --margin-top: 0px;
  --margin-bottom: 0px;
}
.rt-r-my-1 {
  --margin-top: var(--space-1);
  --margin-bottom: var(--space-1);
}
.rt-r-my-2 {
  --margin-top: var(--space-2);
  --margin-bottom: var(--space-2);
}
.rt-r-my-3 {
  --margin-top: var(--space-3);
  --margin-bottom: var(--space-3);
}
.rt-r-my-4 {
  --margin-top: var(--space-4);
  --margin-bottom: var(--space-4);
}
.rt-r-my-5 {
  --margin-top: var(--space-5);
  --margin-bottom: var(--space-5);
}
.rt-r-my-6 {
  --margin-top: var(--space-6);
  --margin-bottom: var(--space-6);
}
.rt-r-my-7 {
  --margin-top: var(--space-7);
  --margin-bottom: var(--space-7);
}
.rt-r-my-8 {
  --margin-top: var(--space-8);
  --margin-bottom: var(--space-8);
}
.rt-r-my-9 {
  --margin-top: var(--space-9);
  --margin-bottom: var(--space-9);
}
.-rt-r-my-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
}
.-rt-r-my-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
}
.-rt-r-my-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
}
.-rt-r-my-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
}
.-rt-r-my-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
}
.-rt-r-my-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
}
.-rt-r-my-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
}
.-rt-r-my-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
}
.-rt-r-my-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-my,
  .xs\:rt-r-my-0,
  .xs\:rt-r-my-1,
  .xs\:rt-r-my-2,
  .xs\:rt-r-my-3,
  .xs\:rt-r-my-4,
  .xs\:rt-r-my-5,
  .xs\:rt-r-my-6,
  .xs\:rt-r-my-7,
  .xs\:rt-r-my-8,
  .xs\:rt-r-my-9,
  .xs\:-rt-r-my-1,
  .xs\:-rt-r-my-2,
  .xs\:-rt-r-my-3,
  .xs\:-rt-r-my-4,
  .xs\:-rt-r-my-5,
  .xs\:-rt-r-my-6,
  .xs\:-rt-r-my-7,
  .xs\:-rt-r-my-8,
  .xs\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .xs\:rt-r-my {
    --margin-top: var(--mt-xs);
    --margin-bottom: var(--mb-xs)
  }
  .xs\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
  .xs\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }
  .xs\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }
  .xs\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }
  .xs\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }
  .xs\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }
  .xs\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }
  .xs\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }
  .xs\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }
  .xs\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }
  .xs\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-my,
  .sm\:rt-r-my-0,
  .sm\:rt-r-my-1,
  .sm\:rt-r-my-2,
  .sm\:rt-r-my-3,
  .sm\:rt-r-my-4,
  .sm\:rt-r-my-5,
  .sm\:rt-r-my-6,
  .sm\:rt-r-my-7,
  .sm\:rt-r-my-8,
  .sm\:rt-r-my-9,
  .sm\:-rt-r-my-1,
  .sm\:-rt-r-my-2,
  .sm\:-rt-r-my-3,
  .sm\:-rt-r-my-4,
  .sm\:-rt-r-my-5,
  .sm\:-rt-r-my-6,
  .sm\:-rt-r-my-7,
  .sm\:-rt-r-my-8,
  .sm\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .sm\:rt-r-my {
    --margin-top: var(--mt-sm);
    --margin-bottom: var(--mb-sm)
  }
  .sm\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
  .sm\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }
  .sm\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }
  .sm\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }
  .sm\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }
  .sm\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }
  .sm\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }
  .sm\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }
  .sm\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }
  .sm\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }
  .sm\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-my,
  .md\:rt-r-my-0,
  .md\:rt-r-my-1,
  .md\:rt-r-my-2,
  .md\:rt-r-my-3,
  .md\:rt-r-my-4,
  .md\:rt-r-my-5,
  .md\:rt-r-my-6,
  .md\:rt-r-my-7,
  .md\:rt-r-my-8,
  .md\:rt-r-my-9,
  .md\:-rt-r-my-1,
  .md\:-rt-r-my-2,
  .md\:-rt-r-my-3,
  .md\:-rt-r-my-4,
  .md\:-rt-r-my-5,
  .md\:-rt-r-my-6,
  .md\:-rt-r-my-7,
  .md\:-rt-r-my-8,
  .md\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .md\:rt-r-my {
    --margin-top: var(--mt-md);
    --margin-bottom: var(--mb-md)
  }
  .md\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
  .md\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }
  .md\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }
  .md\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }
  .md\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }
  .md\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }
  .md\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }
  .md\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }
  .md\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }
  .md\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }
  .md\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-my,
  .lg\:rt-r-my-0,
  .lg\:rt-r-my-1,
  .lg\:rt-r-my-2,
  .lg\:rt-r-my-3,
  .lg\:rt-r-my-4,
  .lg\:rt-r-my-5,
  .lg\:rt-r-my-6,
  .lg\:rt-r-my-7,
  .lg\:rt-r-my-8,
  .lg\:rt-r-my-9,
  .lg\:-rt-r-my-1,
  .lg\:-rt-r-my-2,
  .lg\:-rt-r-my-3,
  .lg\:-rt-r-my-4,
  .lg\:-rt-r-my-5,
  .lg\:-rt-r-my-6,
  .lg\:-rt-r-my-7,
  .lg\:-rt-r-my-8,
  .lg\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .lg\:rt-r-my {
    --margin-top: var(--mt-lg);
    --margin-bottom: var(--mb-lg)
  }
  .lg\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
  .lg\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }
  .lg\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }
  .lg\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }
  .lg\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }
  .lg\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }
  .lg\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }
  .lg\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }
  .lg\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }
  .lg\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }
  .lg\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-my,
  .xl\:rt-r-my-0,
  .xl\:rt-r-my-1,
  .xl\:rt-r-my-2,
  .xl\:rt-r-my-3,
  .xl\:rt-r-my-4,
  .xl\:rt-r-my-5,
  .xl\:rt-r-my-6,
  .xl\:rt-r-my-7,
  .xl\:rt-r-my-8,
  .xl\:rt-r-my-9,
  .xl\:-rt-r-my-1,
  .xl\:-rt-r-my-2,
  .xl\:-rt-r-my-3,
  .xl\:-rt-r-my-4,
  .xl\:-rt-r-my-5,
  .xl\:-rt-r-my-6,
  .xl\:-rt-r-my-7,
  .xl\:-rt-r-my-8,
  .xl\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .xl\:rt-r-my {
    --margin-top: var(--mt-xl);
    --margin-bottom: var(--mb-xl)
  }
  .xl\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }
  .xl\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }
  .xl\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }
  .xl\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }
  .xl\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }
  .xl\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }
  .xl\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }
  .xl\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }
  .xl\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }
  .xl\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }
  .xl\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
.rt-r-mt,
.rt-r-mt-0,
.rt-r-mt-1,
.rt-r-mt-2,
.rt-r-mt-3,
.rt-r-mt-4,
.rt-r-mt-5,
.rt-r-mt-6,
.rt-r-mt-7,
.rt-r-mt-8,
.rt-r-mt-9,
.-rt-r-mt-1,
.-rt-r-mt-2,
.-rt-r-mt-3,
.-rt-r-mt-4,
.-rt-r-mt-5,
.-rt-r-mt-6,
.-rt-r-mt-7,
.-rt-r-mt-8,
.-rt-r-mt-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
}
.rt-r-mt {
  --margin-top: var(--mt)
}
.rt-r-mt-0 {
  --margin-top: 0px;
}
.rt-r-mt-1 {
  --margin-top: var(--space-1);
}
.rt-r-mt-2 {
  --margin-top: var(--space-2);
}
.rt-r-mt-3 {
  --margin-top: var(--space-3);
}
.rt-r-mt-4 {
  --margin-top: var(--space-4);
}
.rt-r-mt-5 {
  --margin-top: var(--space-5);
}
.rt-r-mt-6 {
  --margin-top: var(--space-6);
}
.rt-r-mt-7 {
  --margin-top: var(--space-7);
}
.rt-r-mt-8 {
  --margin-top: var(--space-8);
}
.rt-r-mt-9 {
  --margin-top: var(--space-9);
}
.-rt-r-mt-1 {
  --margin-top: calc(-1 * var(--space-1));
}
.-rt-r-mt-2 {
  --margin-top: calc(-1 * var(--space-2));
}
.-rt-r-mt-3 {
  --margin-top: calc(-1 * var(--space-3));
}
.-rt-r-mt-4 {
  --margin-top: calc(-1 * var(--space-4));
}
.-rt-r-mt-5 {
  --margin-top: calc(-1 * var(--space-5));
}
.-rt-r-mt-6 {
  --margin-top: calc(-1 * var(--space-6));
}
.-rt-r-mt-7 {
  --margin-top: calc(-1 * var(--space-7));
}
.-rt-r-mt-8 {
  --margin-top: calc(-1 * var(--space-8));
}
.-rt-r-mt-9 {
  --margin-top: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-mt,
  .xs\:rt-r-mt-0,
  .xs\:rt-r-mt-1,
  .xs\:rt-r-mt-2,
  .xs\:rt-r-mt-3,
  .xs\:rt-r-mt-4,
  .xs\:rt-r-mt-5,
  .xs\:rt-r-mt-6,
  .xs\:rt-r-mt-7,
  .xs\:rt-r-mt-8,
  .xs\:rt-r-mt-9,
  .xs\:-rt-r-mt-1,
  .xs\:-rt-r-mt-2,
  .xs\:-rt-r-mt-3,
  .xs\:-rt-r-mt-4,
  .xs\:-rt-r-mt-5,
  .xs\:-rt-r-mt-6,
  .xs\:-rt-r-mt-7,
  .xs\:-rt-r-mt-8,
  .xs\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }
  .xs\:rt-r-mt {
    --margin-top: var(--mt-xs)
  }
  .xs\:rt-r-mt-0 {
    --margin-top: 0px;
  }
  .xs\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }
  .xs\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }
  .xs\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }
  .xs\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }
  .xs\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }
  .xs\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }
  .xs\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }
  .xs\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }
  .xs\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }
  .xs\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-mt,
  .sm\:rt-r-mt-0,
  .sm\:rt-r-mt-1,
  .sm\:rt-r-mt-2,
  .sm\:rt-r-mt-3,
  .sm\:rt-r-mt-4,
  .sm\:rt-r-mt-5,
  .sm\:rt-r-mt-6,
  .sm\:rt-r-mt-7,
  .sm\:rt-r-mt-8,
  .sm\:rt-r-mt-9,
  .sm\:-rt-r-mt-1,
  .sm\:-rt-r-mt-2,
  .sm\:-rt-r-mt-3,
  .sm\:-rt-r-mt-4,
  .sm\:-rt-r-mt-5,
  .sm\:-rt-r-mt-6,
  .sm\:-rt-r-mt-7,
  .sm\:-rt-r-mt-8,
  .sm\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }
  .sm\:rt-r-mt {
    --margin-top: var(--mt-sm)
  }
  .sm\:rt-r-mt-0 {
    --margin-top: 0px;
  }
  .sm\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }
  .sm\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }
  .sm\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }
  .sm\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }
  .sm\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }
  .sm\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }
  .sm\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }
  .sm\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }
  .sm\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }
  .sm\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-mt,
  .md\:rt-r-mt-0,
  .md\:rt-r-mt-1,
  .md\:rt-r-mt-2,
  .md\:rt-r-mt-3,
  .md\:rt-r-mt-4,
  .md\:rt-r-mt-5,
  .md\:rt-r-mt-6,
  .md\:rt-r-mt-7,
  .md\:rt-r-mt-8,
  .md\:rt-r-mt-9,
  .md\:-rt-r-mt-1,
  .md\:-rt-r-mt-2,
  .md\:-rt-r-mt-3,
  .md\:-rt-r-mt-4,
  .md\:-rt-r-mt-5,
  .md\:-rt-r-mt-6,
  .md\:-rt-r-mt-7,
  .md\:-rt-r-mt-8,
  .md\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }
  .md\:rt-r-mt {
    --margin-top: var(--mt-md)
  }
  .md\:rt-r-mt-0 {
    --margin-top: 0px;
  }
  .md\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }
  .md\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }
  .md\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }
  .md\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }
  .md\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }
  .md\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }
  .md\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }
  .md\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }
  .md\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }
  .md\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-mt,
  .lg\:rt-r-mt-0,
  .lg\:rt-r-mt-1,
  .lg\:rt-r-mt-2,
  .lg\:rt-r-mt-3,
  .lg\:rt-r-mt-4,
  .lg\:rt-r-mt-5,
  .lg\:rt-r-mt-6,
  .lg\:rt-r-mt-7,
  .lg\:rt-r-mt-8,
  .lg\:rt-r-mt-9,
  .lg\:-rt-r-mt-1,
  .lg\:-rt-r-mt-2,
  .lg\:-rt-r-mt-3,
  .lg\:-rt-r-mt-4,
  .lg\:-rt-r-mt-5,
  .lg\:-rt-r-mt-6,
  .lg\:-rt-r-mt-7,
  .lg\:-rt-r-mt-8,
  .lg\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }
  .lg\:rt-r-mt {
    --margin-top: var(--mt-lg)
  }
  .lg\:rt-r-mt-0 {
    --margin-top: 0px;
  }
  .lg\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }
  .lg\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }
  .lg\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }
  .lg\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }
  .lg\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }
  .lg\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }
  .lg\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }
  .lg\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }
  .lg\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }
  .lg\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-mt,
  .xl\:rt-r-mt-0,
  .xl\:rt-r-mt-1,
  .xl\:rt-r-mt-2,
  .xl\:rt-r-mt-3,
  .xl\:rt-r-mt-4,
  .xl\:rt-r-mt-5,
  .xl\:rt-r-mt-6,
  .xl\:rt-r-mt-7,
  .xl\:rt-r-mt-8,
  .xl\:rt-r-mt-9,
  .xl\:-rt-r-mt-1,
  .xl\:-rt-r-mt-2,
  .xl\:-rt-r-mt-3,
  .xl\:-rt-r-mt-4,
  .xl\:-rt-r-mt-5,
  .xl\:-rt-r-mt-6,
  .xl\:-rt-r-mt-7,
  .xl\:-rt-r-mt-8,
  .xl\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }
  .xl\:rt-r-mt {
    --margin-top: var(--mt-xl)
  }
  .xl\:rt-r-mt-0 {
    --margin-top: 0px;
  }
  .xl\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }
  .xl\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }
  .xl\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }
  .xl\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }
  .xl\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }
  .xl\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }
  .xl\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }
  .xl\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }
  .xl\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }
  .xl\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}
.rt-r-mr,
.rt-r-mr-0,
.rt-r-mr-1,
.rt-r-mr-2,
.rt-r-mr-3,
.rt-r-mr-4,
.rt-r-mr-5,
.rt-r-mr-6,
.rt-r-mr-7,
.rt-r-mr-8,
.rt-r-mr-9,
.-rt-r-mr-1,
.-rt-r-mr-2,
.-rt-r-mr-3,
.-rt-r-mr-4,
.-rt-r-mr-5,
.-rt-r-mr-6,
.-rt-r-mr-7,
.-rt-r-mr-8,
.-rt-r-mr-9 {
  margin-right: var(--margin-right-override, var(--margin-right));
}
.rt-r-mr {
  --margin-right: var(--mr)
}
.rt-r-mr-0 {
  --margin-right: 0px;
}
.rt-r-mr-1 {
  --margin-right: var(--space-1);
}
.rt-r-mr-2 {
  --margin-right: var(--space-2);
}
.rt-r-mr-3 {
  --margin-right: var(--space-3);
}
.rt-r-mr-4 {
  --margin-right: var(--space-4);
}
.rt-r-mr-5 {
  --margin-right: var(--space-5);
}
.rt-r-mr-6 {
  --margin-right: var(--space-6);
}
.rt-r-mr-7 {
  --margin-right: var(--space-7);
}
.rt-r-mr-8 {
  --margin-right: var(--space-8);
}
.rt-r-mr-9 {
  --margin-right: var(--space-9);
}
.-rt-r-mr-1 {
  --margin-right: calc(-1 * var(--space-1));
}
.-rt-r-mr-2 {
  --margin-right: calc(-1 * var(--space-2));
}
.-rt-r-mr-3 {
  --margin-right: calc(-1 * var(--space-3));
}
.-rt-r-mr-4 {
  --margin-right: calc(-1 * var(--space-4));
}
.-rt-r-mr-5 {
  --margin-right: calc(-1 * var(--space-5));
}
.-rt-r-mr-6 {
  --margin-right: calc(-1 * var(--space-6));
}
.-rt-r-mr-7 {
  --margin-right: calc(-1 * var(--space-7));
}
.-rt-r-mr-8 {
  --margin-right: calc(-1 * var(--space-8));
}
.-rt-r-mr-9 {
  --margin-right: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-mr,
  .xs\:rt-r-mr-0,
  .xs\:rt-r-mr-1,
  .xs\:rt-r-mr-2,
  .xs\:rt-r-mr-3,
  .xs\:rt-r-mr-4,
  .xs\:rt-r-mr-5,
  .xs\:rt-r-mr-6,
  .xs\:rt-r-mr-7,
  .xs\:rt-r-mr-8,
  .xs\:rt-r-mr-9,
  .xs\:-rt-r-mr-1,
  .xs\:-rt-r-mr-2,
  .xs\:-rt-r-mr-3,
  .xs\:-rt-r-mr-4,
  .xs\:-rt-r-mr-5,
  .xs\:-rt-r-mr-6,
  .xs\:-rt-r-mr-7,
  .xs\:-rt-r-mr-8,
  .xs\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .xs\:rt-r-mr {
    --margin-right: var(--mr-xs)
  }
  .xs\:rt-r-mr-0 {
    --margin-right: 0px;
  }
  .xs\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }
  .xs\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }
  .xs\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }
  .xs\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }
  .xs\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }
  .xs\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }
  .xs\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }
  .xs\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }
  .xs\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }
  .xs\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-mr,
  .sm\:rt-r-mr-0,
  .sm\:rt-r-mr-1,
  .sm\:rt-r-mr-2,
  .sm\:rt-r-mr-3,
  .sm\:rt-r-mr-4,
  .sm\:rt-r-mr-5,
  .sm\:rt-r-mr-6,
  .sm\:rt-r-mr-7,
  .sm\:rt-r-mr-8,
  .sm\:rt-r-mr-9,
  .sm\:-rt-r-mr-1,
  .sm\:-rt-r-mr-2,
  .sm\:-rt-r-mr-3,
  .sm\:-rt-r-mr-4,
  .sm\:-rt-r-mr-5,
  .sm\:-rt-r-mr-6,
  .sm\:-rt-r-mr-7,
  .sm\:-rt-r-mr-8,
  .sm\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .sm\:rt-r-mr {
    --margin-right: var(--mr-sm)
  }
  .sm\:rt-r-mr-0 {
    --margin-right: 0px;
  }
  .sm\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }
  .sm\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }
  .sm\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }
  .sm\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }
  .sm\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }
  .sm\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }
  .sm\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }
  .sm\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }
  .sm\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }
  .sm\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-mr,
  .md\:rt-r-mr-0,
  .md\:rt-r-mr-1,
  .md\:rt-r-mr-2,
  .md\:rt-r-mr-3,
  .md\:rt-r-mr-4,
  .md\:rt-r-mr-5,
  .md\:rt-r-mr-6,
  .md\:rt-r-mr-7,
  .md\:rt-r-mr-8,
  .md\:rt-r-mr-9,
  .md\:-rt-r-mr-1,
  .md\:-rt-r-mr-2,
  .md\:-rt-r-mr-3,
  .md\:-rt-r-mr-4,
  .md\:-rt-r-mr-5,
  .md\:-rt-r-mr-6,
  .md\:-rt-r-mr-7,
  .md\:-rt-r-mr-8,
  .md\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .md\:rt-r-mr {
    --margin-right: var(--mr-md)
  }
  .md\:rt-r-mr-0 {
    --margin-right: 0px;
  }
  .md\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }
  .md\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }
  .md\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }
  .md\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }
  .md\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }
  .md\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }
  .md\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }
  .md\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }
  .md\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }
  .md\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-mr,
  .lg\:rt-r-mr-0,
  .lg\:rt-r-mr-1,
  .lg\:rt-r-mr-2,
  .lg\:rt-r-mr-3,
  .lg\:rt-r-mr-4,
  .lg\:rt-r-mr-5,
  .lg\:rt-r-mr-6,
  .lg\:rt-r-mr-7,
  .lg\:rt-r-mr-8,
  .lg\:rt-r-mr-9,
  .lg\:-rt-r-mr-1,
  .lg\:-rt-r-mr-2,
  .lg\:-rt-r-mr-3,
  .lg\:-rt-r-mr-4,
  .lg\:-rt-r-mr-5,
  .lg\:-rt-r-mr-6,
  .lg\:-rt-r-mr-7,
  .lg\:-rt-r-mr-8,
  .lg\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .lg\:rt-r-mr {
    --margin-right: var(--mr-lg)
  }
  .lg\:rt-r-mr-0 {
    --margin-right: 0px;
  }
  .lg\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }
  .lg\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }
  .lg\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }
  .lg\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }
  .lg\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }
  .lg\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }
  .lg\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }
  .lg\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }
  .lg\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }
  .lg\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-mr,
  .xl\:rt-r-mr-0,
  .xl\:rt-r-mr-1,
  .xl\:rt-r-mr-2,
  .xl\:rt-r-mr-3,
  .xl\:rt-r-mr-4,
  .xl\:rt-r-mr-5,
  .xl\:rt-r-mr-6,
  .xl\:rt-r-mr-7,
  .xl\:rt-r-mr-8,
  .xl\:rt-r-mr-9,
  .xl\:-rt-r-mr-1,
  .xl\:-rt-r-mr-2,
  .xl\:-rt-r-mr-3,
  .xl\:-rt-r-mr-4,
  .xl\:-rt-r-mr-5,
  .xl\:-rt-r-mr-6,
  .xl\:-rt-r-mr-7,
  .xl\:-rt-r-mr-8,
  .xl\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }
  .xl\:rt-r-mr {
    --margin-right: var(--mr-xl)
  }
  .xl\:rt-r-mr-0 {
    --margin-right: 0px;
  }
  .xl\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }
  .xl\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }
  .xl\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }
  .xl\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }
  .xl\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }
  .xl\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }
  .xl\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }
  .xl\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }
  .xl\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }
  .xl\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}
.rt-r-mb,
.rt-r-mb-0,
.rt-r-mb-1,
.rt-r-mb-2,
.rt-r-mb-3,
.rt-r-mb-4,
.rt-r-mb-5,
.rt-r-mb-6,
.rt-r-mb-7,
.rt-r-mb-8,
.rt-r-mb-9,
.-rt-r-mb-1,
.-rt-r-mb-2,
.-rt-r-mb-3,
.-rt-r-mb-4,
.-rt-r-mb-5,
.-rt-r-mb-6,
.-rt-r-mb-7,
.-rt-r-mb-8,
.-rt-r-mb-9 {
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}
.rt-r-mb {
  --margin-bottom: var(--mb)
}
.rt-r-mb-0 {
  --margin-bottom: 0px;
}
.rt-r-mb-1 {
  --margin-bottom: var(--space-1);
}
.rt-r-mb-2 {
  --margin-bottom: var(--space-2);
}
.rt-r-mb-3 {
  --margin-bottom: var(--space-3);
}
.rt-r-mb-4 {
  --margin-bottom: var(--space-4);
}
.rt-r-mb-5 {
  --margin-bottom: var(--space-5);
}
.rt-r-mb-6 {
  --margin-bottom: var(--space-6);
}
.rt-r-mb-7 {
  --margin-bottom: var(--space-7);
}
.rt-r-mb-8 {
  --margin-bottom: var(--space-8);
}
.rt-r-mb-9 {
  --margin-bottom: var(--space-9);
}
.-rt-r-mb-1 {
  --margin-bottom: calc(-1 * var(--space-1));
}
.-rt-r-mb-2 {
  --margin-bottom: calc(-1 * var(--space-2));
}
.-rt-r-mb-3 {
  --margin-bottom: calc(-1 * var(--space-3));
}
.-rt-r-mb-4 {
  --margin-bottom: calc(-1 * var(--space-4));
}
.-rt-r-mb-5 {
  --margin-bottom: calc(-1 * var(--space-5));
}
.-rt-r-mb-6 {
  --margin-bottom: calc(-1 * var(--space-6));
}
.-rt-r-mb-7 {
  --margin-bottom: calc(-1 * var(--space-7));
}
.-rt-r-mb-8 {
  --margin-bottom: calc(-1 * var(--space-8));
}
.-rt-r-mb-9 {
  --margin-bottom: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-mb,
  .xs\:rt-r-mb-0,
  .xs\:rt-r-mb-1,
  .xs\:rt-r-mb-2,
  .xs\:rt-r-mb-3,
  .xs\:rt-r-mb-4,
  .xs\:rt-r-mb-5,
  .xs\:rt-r-mb-6,
  .xs\:rt-r-mb-7,
  .xs\:rt-r-mb-8,
  .xs\:rt-r-mb-9,
  .xs\:-rt-r-mb-1,
  .xs\:-rt-r-mb-2,
  .xs\:-rt-r-mb-3,
  .xs\:-rt-r-mb-4,
  .xs\:-rt-r-mb-5,
  .xs\:-rt-r-mb-6,
  .xs\:-rt-r-mb-7,
  .xs\:-rt-r-mb-8,
  .xs\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .xs\:rt-r-mb {
    --margin-bottom: var(--mb-xs)
  }
  .xs\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }
  .xs\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }
  .xs\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }
  .xs\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }
  .xs\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }
  .xs\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }
  .xs\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }
  .xs\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }
  .xs\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }
  .xs\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }
  .xs\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-mb,
  .sm\:rt-r-mb-0,
  .sm\:rt-r-mb-1,
  .sm\:rt-r-mb-2,
  .sm\:rt-r-mb-3,
  .sm\:rt-r-mb-4,
  .sm\:rt-r-mb-5,
  .sm\:rt-r-mb-6,
  .sm\:rt-r-mb-7,
  .sm\:rt-r-mb-8,
  .sm\:rt-r-mb-9,
  .sm\:-rt-r-mb-1,
  .sm\:-rt-r-mb-2,
  .sm\:-rt-r-mb-3,
  .sm\:-rt-r-mb-4,
  .sm\:-rt-r-mb-5,
  .sm\:-rt-r-mb-6,
  .sm\:-rt-r-mb-7,
  .sm\:-rt-r-mb-8,
  .sm\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .sm\:rt-r-mb {
    --margin-bottom: var(--mb-sm)
  }
  .sm\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }
  .sm\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }
  .sm\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }
  .sm\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }
  .sm\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }
  .sm\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }
  .sm\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }
  .sm\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }
  .sm\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }
  .sm\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }
  .sm\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-mb,
  .md\:rt-r-mb-0,
  .md\:rt-r-mb-1,
  .md\:rt-r-mb-2,
  .md\:rt-r-mb-3,
  .md\:rt-r-mb-4,
  .md\:rt-r-mb-5,
  .md\:rt-r-mb-6,
  .md\:rt-r-mb-7,
  .md\:rt-r-mb-8,
  .md\:rt-r-mb-9,
  .md\:-rt-r-mb-1,
  .md\:-rt-r-mb-2,
  .md\:-rt-r-mb-3,
  .md\:-rt-r-mb-4,
  .md\:-rt-r-mb-5,
  .md\:-rt-r-mb-6,
  .md\:-rt-r-mb-7,
  .md\:-rt-r-mb-8,
  .md\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .md\:rt-r-mb {
    --margin-bottom: var(--mb-md)
  }
  .md\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }
  .md\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }
  .md\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }
  .md\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }
  .md\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }
  .md\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }
  .md\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }
  .md\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }
  .md\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }
  .md\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }
  .md\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-mb,
  .lg\:rt-r-mb-0,
  .lg\:rt-r-mb-1,
  .lg\:rt-r-mb-2,
  .lg\:rt-r-mb-3,
  .lg\:rt-r-mb-4,
  .lg\:rt-r-mb-5,
  .lg\:rt-r-mb-6,
  .lg\:rt-r-mb-7,
  .lg\:rt-r-mb-8,
  .lg\:rt-r-mb-9,
  .lg\:-rt-r-mb-1,
  .lg\:-rt-r-mb-2,
  .lg\:-rt-r-mb-3,
  .lg\:-rt-r-mb-4,
  .lg\:-rt-r-mb-5,
  .lg\:-rt-r-mb-6,
  .lg\:-rt-r-mb-7,
  .lg\:-rt-r-mb-8,
  .lg\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .lg\:rt-r-mb {
    --margin-bottom: var(--mb-lg)
  }
  .lg\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }
  .lg\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }
  .lg\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }
  .lg\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }
  .lg\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }
  .lg\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }
  .lg\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }
  .lg\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }
  .lg\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }
  .lg\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }
  .lg\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-mb,
  .xl\:rt-r-mb-0,
  .xl\:rt-r-mb-1,
  .xl\:rt-r-mb-2,
  .xl\:rt-r-mb-3,
  .xl\:rt-r-mb-4,
  .xl\:rt-r-mb-5,
  .xl\:rt-r-mb-6,
  .xl\:rt-r-mb-7,
  .xl\:rt-r-mb-8,
  .xl\:rt-r-mb-9,
  .xl\:-rt-r-mb-1,
  .xl\:-rt-r-mb-2,
  .xl\:-rt-r-mb-3,
  .xl\:-rt-r-mb-4,
  .xl\:-rt-r-mb-5,
  .xl\:-rt-r-mb-6,
  .xl\:-rt-r-mb-7,
  .xl\:-rt-r-mb-8,
  .xl\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }
  .xl\:rt-r-mb {
    --margin-bottom: var(--mb-xl)
  }
  .xl\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }
  .xl\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }
  .xl\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }
  .xl\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }
  .xl\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }
  .xl\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }
  .xl\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }
  .xl\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }
  .xl\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }
  .xl\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }
  .xl\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}
.rt-r-ml,
.rt-r-ml-0,
.rt-r-ml-1,
.rt-r-ml-2,
.rt-r-ml-3,
.rt-r-ml-4,
.rt-r-ml-5,
.rt-r-ml-6,
.rt-r-ml-7,
.rt-r-ml-8,
.rt-r-ml-9,
.-rt-r-ml-1,
.-rt-r-ml-2,
.-rt-r-ml-3,
.-rt-r-ml-4,
.-rt-r-ml-5,
.-rt-r-ml-6,
.-rt-r-ml-7,
.-rt-r-ml-8,
.-rt-r-ml-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
}
.rt-r-ml {
  --margin-left: var(--ml)
}
.rt-r-ml-0 {
  --margin-left: 0px;
}
.rt-r-ml-1 {
  --margin-left: var(--space-1);
}
.rt-r-ml-2 {
  --margin-left: var(--space-2);
}
.rt-r-ml-3 {
  --margin-left: var(--space-3);
}
.rt-r-ml-4 {
  --margin-left: var(--space-4);
}
.rt-r-ml-5 {
  --margin-left: var(--space-5);
}
.rt-r-ml-6 {
  --margin-left: var(--space-6);
}
.rt-r-ml-7 {
  --margin-left: var(--space-7);
}
.rt-r-ml-8 {
  --margin-left: var(--space-8);
}
.rt-r-ml-9 {
  --margin-left: var(--space-9);
}
.-rt-r-ml-1 {
  --margin-left: calc(-1 * var(--space-1));
}
.-rt-r-ml-2 {
  --margin-left: calc(-1 * var(--space-2));
}
.-rt-r-ml-3 {
  --margin-left: calc(-1 * var(--space-3));
}
.-rt-r-ml-4 {
  --margin-left: calc(-1 * var(--space-4));
}
.-rt-r-ml-5 {
  --margin-left: calc(-1 * var(--space-5));
}
.-rt-r-ml-6 {
  --margin-left: calc(-1 * var(--space-6));
}
.-rt-r-ml-7 {
  --margin-left: calc(-1 * var(--space-7));
}
.-rt-r-ml-8 {
  --margin-left: calc(-1 * var(--space-8));
}
.-rt-r-ml-9 {
  --margin-left: calc(-1 * var(--space-9));
}
@media (min-width: 520px) {
  .xs\:rt-r-ml,
  .xs\:rt-r-ml-0,
  .xs\:rt-r-ml-1,
  .xs\:rt-r-ml-2,
  .xs\:rt-r-ml-3,
  .xs\:rt-r-ml-4,
  .xs\:rt-r-ml-5,
  .xs\:rt-r-ml-6,
  .xs\:rt-r-ml-7,
  .xs\:rt-r-ml-8,
  .xs\:rt-r-ml-9,
  .xs\:-rt-r-ml-1,
  .xs\:-rt-r-ml-2,
  .xs\:-rt-r-ml-3,
  .xs\:-rt-r-ml-4,
  .xs\:-rt-r-ml-5,
  .xs\:-rt-r-ml-6,
  .xs\:-rt-r-ml-7,
  .xs\:-rt-r-ml-8,
  .xs\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .xs\:rt-r-ml {
    --margin-left: var(--ml-xs)
  }
  .xs\:rt-r-ml-0 {
    --margin-left: 0px;
  }
  .xs\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }
  .xs\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }
  .xs\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }
  .xs\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }
  .xs\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }
  .xs\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }
  .xs\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }
  .xs\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }
  .xs\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }
  .xs\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }
  .xs\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }
  .xs\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }
  .xs\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }
  .xs\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }
  .xs\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }
  .xs\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }
  .xs\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }
  .xs\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-ml,
  .sm\:rt-r-ml-0,
  .sm\:rt-r-ml-1,
  .sm\:rt-r-ml-2,
  .sm\:rt-r-ml-3,
  .sm\:rt-r-ml-4,
  .sm\:rt-r-ml-5,
  .sm\:rt-r-ml-6,
  .sm\:rt-r-ml-7,
  .sm\:rt-r-ml-8,
  .sm\:rt-r-ml-9,
  .sm\:-rt-r-ml-1,
  .sm\:-rt-r-ml-2,
  .sm\:-rt-r-ml-3,
  .sm\:-rt-r-ml-4,
  .sm\:-rt-r-ml-5,
  .sm\:-rt-r-ml-6,
  .sm\:-rt-r-ml-7,
  .sm\:-rt-r-ml-8,
  .sm\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .sm\:rt-r-ml {
    --margin-left: var(--ml-sm)
  }
  .sm\:rt-r-ml-0 {
    --margin-left: 0px;
  }
  .sm\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }
  .sm\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }
  .sm\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }
  .sm\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }
  .sm\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }
  .sm\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }
  .sm\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }
  .sm\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }
  .sm\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }
  .sm\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }
  .sm\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }
  .sm\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }
  .sm\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }
  .sm\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }
  .sm\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }
  .sm\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }
  .sm\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }
  .sm\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-ml,
  .md\:rt-r-ml-0,
  .md\:rt-r-ml-1,
  .md\:rt-r-ml-2,
  .md\:rt-r-ml-3,
  .md\:rt-r-ml-4,
  .md\:rt-r-ml-5,
  .md\:rt-r-ml-6,
  .md\:rt-r-ml-7,
  .md\:rt-r-ml-8,
  .md\:rt-r-ml-9,
  .md\:-rt-r-ml-1,
  .md\:-rt-r-ml-2,
  .md\:-rt-r-ml-3,
  .md\:-rt-r-ml-4,
  .md\:-rt-r-ml-5,
  .md\:-rt-r-ml-6,
  .md\:-rt-r-ml-7,
  .md\:-rt-r-ml-8,
  .md\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .md\:rt-r-ml {
    --margin-left: var(--ml-md)
  }
  .md\:rt-r-ml-0 {
    --margin-left: 0px;
  }
  .md\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }
  .md\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }
  .md\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }
  .md\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }
  .md\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }
  .md\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }
  .md\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }
  .md\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }
  .md\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }
  .md\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }
  .md\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }
  .md\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }
  .md\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }
  .md\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }
  .md\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }
  .md\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }
  .md\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }
  .md\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-ml,
  .lg\:rt-r-ml-0,
  .lg\:rt-r-ml-1,
  .lg\:rt-r-ml-2,
  .lg\:rt-r-ml-3,
  .lg\:rt-r-ml-4,
  .lg\:rt-r-ml-5,
  .lg\:rt-r-ml-6,
  .lg\:rt-r-ml-7,
  .lg\:rt-r-ml-8,
  .lg\:rt-r-ml-9,
  .lg\:-rt-r-ml-1,
  .lg\:-rt-r-ml-2,
  .lg\:-rt-r-ml-3,
  .lg\:-rt-r-ml-4,
  .lg\:-rt-r-ml-5,
  .lg\:-rt-r-ml-6,
  .lg\:-rt-r-ml-7,
  .lg\:-rt-r-ml-8,
  .lg\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .lg\:rt-r-ml {
    --margin-left: var(--ml-lg)
  }
  .lg\:rt-r-ml-0 {
    --margin-left: 0px;
  }
  .lg\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }
  .lg\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }
  .lg\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }
  .lg\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }
  .lg\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }
  .lg\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }
  .lg\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }
  .lg\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }
  .lg\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }
  .lg\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }
  .lg\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }
  .lg\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }
  .lg\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }
  .lg\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }
  .lg\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }
  .lg\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }
  .lg\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }
  .lg\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-ml,
  .xl\:rt-r-ml-0,
  .xl\:rt-r-ml-1,
  .xl\:rt-r-ml-2,
  .xl\:rt-r-ml-3,
  .xl\:rt-r-ml-4,
  .xl\:rt-r-ml-5,
  .xl\:rt-r-ml-6,
  .xl\:rt-r-ml-7,
  .xl\:rt-r-ml-8,
  .xl\:rt-r-ml-9,
  .xl\:-rt-r-ml-1,
  .xl\:-rt-r-ml-2,
  .xl\:-rt-r-ml-3,
  .xl\:-rt-r-ml-4,
  .xl\:-rt-r-ml-5,
  .xl\:-rt-r-ml-6,
  .xl\:-rt-r-ml-7,
  .xl\:-rt-r-ml-8,
  .xl\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }
  .xl\:rt-r-ml {
    --margin-left: var(--ml-xl)
  }
  .xl\:rt-r-ml-0 {
    --margin-left: 0px;
  }
  .xl\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }
  .xl\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }
  .xl\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }
  .xl\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }
  .xl\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }
  .xl\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }
  .xl\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }
  .xl\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }
  .xl\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }
  .xl\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }
  .xl\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }
  .xl\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }
  .xl\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }
  .xl\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }
  .xl\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }
  .xl\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }
  .xl\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }
  .xl\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}
.rt-r-overflow-visible {
  overflow: visible;
}
.rt-r-overflow-hidden {
  overflow: hidden;
}
.rt-r-overflow-clip {
  overflow: clip;
}
.rt-r-overflow-scroll {
  overflow: scroll;
}
.rt-r-overflow-auto {
  overflow: auto;
}
.rt-r-ox-visible {
  overflow-x: visible;
}
.rt-r-ox-hidden {
  overflow-x: hidden;
}
.rt-r-ox-clip {
  overflow-x: clip;
}
.rt-r-ox-scroll {
  overflow-x: scroll;
}
.rt-r-ox-auto {
  overflow-x: auto;
}
.rt-r-oy-visible {
  overflow-y: visible;
}
.rt-r-oy-hidden {
  overflow-y: hidden;
}
.rt-r-oy-clip {
  overflow-y: clip;
}
.rt-r-oy-scroll {
  overflow-y: scroll;
}
.rt-r-oy-auto {
  overflow-y: auto;
}
@media (min-width: 520px) {
  .xs\:rt-r-overflow-visible {
    overflow: visible;
  }
  .xs\:rt-r-overflow-hidden {
    overflow: hidden;
  }
  .xs\:rt-r-overflow-clip {
    overflow: clip;
  }
  .xs\:rt-r-overflow-scroll {
    overflow: scroll;
  }
  .xs\:rt-r-overflow-auto {
    overflow: auto;
  }
  .xs\:rt-r-ox-visible {
    overflow-x: visible;
  }
  .xs\:rt-r-ox-hidden {
    overflow-x: hidden;
  }
  .xs\:rt-r-ox-clip {
    overflow-x: clip;
  }
  .xs\:rt-r-ox-scroll {
    overflow-x: scroll;
  }
  .xs\:rt-r-ox-auto {
    overflow-x: auto;
  }
  .xs\:rt-r-oy-visible {
    overflow-y: visible;
  }
  .xs\:rt-r-oy-hidden {
    overflow-y: hidden;
  }
  .xs\:rt-r-oy-clip {
    overflow-y: clip;
  }
  .xs\:rt-r-oy-scroll {
    overflow-y: scroll;
  }
  .xs\:rt-r-oy-auto {
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-overflow-visible {
    overflow: visible;
  }
  .sm\:rt-r-overflow-hidden {
    overflow: hidden;
  }
  .sm\:rt-r-overflow-clip {
    overflow: clip;
  }
  .sm\:rt-r-overflow-scroll {
    overflow: scroll;
  }
  .sm\:rt-r-overflow-auto {
    overflow: auto;
  }
  .sm\:rt-r-ox-visible {
    overflow-x: visible;
  }
  .sm\:rt-r-ox-hidden {
    overflow-x: hidden;
  }
  .sm\:rt-r-ox-clip {
    overflow-x: clip;
  }
  .sm\:rt-r-ox-scroll {
    overflow-x: scroll;
  }
  .sm\:rt-r-ox-auto {
    overflow-x: auto;
  }
  .sm\:rt-r-oy-visible {
    overflow-y: visible;
  }
  .sm\:rt-r-oy-hidden {
    overflow-y: hidden;
  }
  .sm\:rt-r-oy-clip {
    overflow-y: clip;
  }
  .sm\:rt-r-oy-scroll {
    overflow-y: scroll;
  }
  .sm\:rt-r-oy-auto {
    overflow-y: auto;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-overflow-visible {
    overflow: visible;
  }
  .md\:rt-r-overflow-hidden {
    overflow: hidden;
  }
  .md\:rt-r-overflow-clip {
    overflow: clip;
  }
  .md\:rt-r-overflow-scroll {
    overflow: scroll;
  }
  .md\:rt-r-overflow-auto {
    overflow: auto;
  }
  .md\:rt-r-ox-visible {
    overflow-x: visible;
  }
  .md\:rt-r-ox-hidden {
    overflow-x: hidden;
  }
  .md\:rt-r-ox-clip {
    overflow-x: clip;
  }
  .md\:rt-r-ox-scroll {
    overflow-x: scroll;
  }
  .md\:rt-r-ox-auto {
    overflow-x: auto;
  }
  .md\:rt-r-oy-visible {
    overflow-y: visible;
  }
  .md\:rt-r-oy-hidden {
    overflow-y: hidden;
  }
  .md\:rt-r-oy-clip {
    overflow-y: clip;
  }
  .md\:rt-r-oy-scroll {
    overflow-y: scroll;
  }
  .md\:rt-r-oy-auto {
    overflow-y: auto;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-overflow-visible {
    overflow: visible;
  }
  .lg\:rt-r-overflow-hidden {
    overflow: hidden;
  }
  .lg\:rt-r-overflow-clip {
    overflow: clip;
  }
  .lg\:rt-r-overflow-scroll {
    overflow: scroll;
  }
  .lg\:rt-r-overflow-auto {
    overflow: auto;
  }
  .lg\:rt-r-ox-visible {
    overflow-x: visible;
  }
  .lg\:rt-r-ox-hidden {
    overflow-x: hidden;
  }
  .lg\:rt-r-ox-clip {
    overflow-x: clip;
  }
  .lg\:rt-r-ox-scroll {
    overflow-x: scroll;
  }
  .lg\:rt-r-ox-auto {
    overflow-x: auto;
  }
  .lg\:rt-r-oy-visible {
    overflow-y: visible;
  }
  .lg\:rt-r-oy-hidden {
    overflow-y: hidden;
  }
  .lg\:rt-r-oy-clip {
    overflow-y: clip;
  }
  .lg\:rt-r-oy-scroll {
    overflow-y: scroll;
  }
  .lg\:rt-r-oy-auto {
    overflow-y: auto;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-overflow-visible {
    overflow: visible;
  }
  .xl\:rt-r-overflow-hidden {
    overflow: hidden;
  }
  .xl\:rt-r-overflow-clip {
    overflow: clip;
  }
  .xl\:rt-r-overflow-scroll {
    overflow: scroll;
  }
  .xl\:rt-r-overflow-auto {
    overflow: auto;
  }
  .xl\:rt-r-ox-visible {
    overflow-x: visible;
  }
  .xl\:rt-r-ox-hidden {
    overflow-x: hidden;
  }
  .xl\:rt-r-ox-clip {
    overflow-x: clip;
  }
  .xl\:rt-r-ox-scroll {
    overflow-x: scroll;
  }
  .xl\:rt-r-ox-auto {
    overflow-x: auto;
  }
  .xl\:rt-r-oy-visible {
    overflow-y: visible;
  }
  .xl\:rt-r-oy-hidden {
    overflow-y: hidden;
  }
  .xl\:rt-r-oy-clip {
    overflow-y: clip;
  }
  .xl\:rt-r-oy-scroll {
    overflow-y: scroll;
  }
  .xl\:rt-r-oy-auto {
    overflow-y: auto;
  }
}
.rt-r-p {
  padding: var(--p)
}
.rt-r-p-0 {
  padding: 0;
}
.rt-r-p-1 {
  padding: var(--space-1);
}
.rt-r-p-2 {
  padding: var(--space-2);
}
.rt-r-p-3 {
  padding: var(--space-3);
}
.rt-r-p-4 {
  padding: var(--space-4);
}
.rt-r-p-5 {
  padding: var(--space-5);
}
.rt-r-p-6 {
  padding: var(--space-6);
}
.rt-r-p-7 {
  padding: var(--space-7);
}
.rt-r-p-8 {
  padding: var(--space-8);
}
.rt-r-p-9 {
  padding: var(--space-9);
}
.rt-r-p-inset {
  padding-top: var(--inset-padding-top);
  padding-right: var(--inset-padding-right);
  padding-bottom: var(--inset-padding-bottom);
  padding-left: var(--inset-padding-left);
}
@media (min-width: 520px) {
  .xs\:rt-r-p {
    padding: var(--p-xs)
  }
  .xs\:rt-r-p-0 {
    padding: 0;
  }
  .xs\:rt-r-p-1 {
    padding: var(--space-1);
  }
  .xs\:rt-r-p-2 {
    padding: var(--space-2);
  }
  .xs\:rt-r-p-3 {
    padding: var(--space-3);
  }
  .xs\:rt-r-p-4 {
    padding: var(--space-4);
  }
  .xs\:rt-r-p-5 {
    padding: var(--space-5);
  }
  .xs\:rt-r-p-6 {
    padding: var(--space-6);
  }
  .xs\:rt-r-p-7 {
    padding: var(--space-7);
  }
  .xs\:rt-r-p-8 {
    padding: var(--space-8);
  }
  .xs\:rt-r-p-9 {
    padding: var(--space-9);
  }
  .xs\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-p {
    padding: var(--p-sm)
  }
  .sm\:rt-r-p-0 {
    padding: 0;
  }
  .sm\:rt-r-p-1 {
    padding: var(--space-1);
  }
  .sm\:rt-r-p-2 {
    padding: var(--space-2);
  }
  .sm\:rt-r-p-3 {
    padding: var(--space-3);
  }
  .sm\:rt-r-p-4 {
    padding: var(--space-4);
  }
  .sm\:rt-r-p-5 {
    padding: var(--space-5);
  }
  .sm\:rt-r-p-6 {
    padding: var(--space-6);
  }
  .sm\:rt-r-p-7 {
    padding: var(--space-7);
  }
  .sm\:rt-r-p-8 {
    padding: var(--space-8);
  }
  .sm\:rt-r-p-9 {
    padding: var(--space-9);
  }
  .sm\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-p {
    padding: var(--p-md)
  }
  .md\:rt-r-p-0 {
    padding: 0;
  }
  .md\:rt-r-p-1 {
    padding: var(--space-1);
  }
  .md\:rt-r-p-2 {
    padding: var(--space-2);
  }
  .md\:rt-r-p-3 {
    padding: var(--space-3);
  }
  .md\:rt-r-p-4 {
    padding: var(--space-4);
  }
  .md\:rt-r-p-5 {
    padding: var(--space-5);
  }
  .md\:rt-r-p-6 {
    padding: var(--space-6);
  }
  .md\:rt-r-p-7 {
    padding: var(--space-7);
  }
  .md\:rt-r-p-8 {
    padding: var(--space-8);
  }
  .md\:rt-r-p-9 {
    padding: var(--space-9);
  }
  .md\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-p {
    padding: var(--p-lg)
  }
  .lg\:rt-r-p-0 {
    padding: 0;
  }
  .lg\:rt-r-p-1 {
    padding: var(--space-1);
  }
  .lg\:rt-r-p-2 {
    padding: var(--space-2);
  }
  .lg\:rt-r-p-3 {
    padding: var(--space-3);
  }
  .lg\:rt-r-p-4 {
    padding: var(--space-4);
  }
  .lg\:rt-r-p-5 {
    padding: var(--space-5);
  }
  .lg\:rt-r-p-6 {
    padding: var(--space-6);
  }
  .lg\:rt-r-p-7 {
    padding: var(--space-7);
  }
  .lg\:rt-r-p-8 {
    padding: var(--space-8);
  }
  .lg\:rt-r-p-9 {
    padding: var(--space-9);
  }
  .lg\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-p {
    padding: var(--p-xl)
  }
  .xl\:rt-r-p-0 {
    padding: 0;
  }
  .xl\:rt-r-p-1 {
    padding: var(--space-1);
  }
  .xl\:rt-r-p-2 {
    padding: var(--space-2);
  }
  .xl\:rt-r-p-3 {
    padding: var(--space-3);
  }
  .xl\:rt-r-p-4 {
    padding: var(--space-4);
  }
  .xl\:rt-r-p-5 {
    padding: var(--space-5);
  }
  .xl\:rt-r-p-6 {
    padding: var(--space-6);
  }
  .xl\:rt-r-p-7 {
    padding: var(--space-7);
  }
  .xl\:rt-r-p-8 {
    padding: var(--space-8);
  }
  .xl\:rt-r-p-9 {
    padding: var(--space-9);
  }
  .xl\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}
.rt-r-px {
  padding-left: var(--pl);
  padding-right: var(--pr)
}
.rt-r-px-0 {
  padding-left: 0;
  padding-right: 0;
}
.rt-r-px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}
.rt-r-px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.rt-r-px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.rt-r-px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.rt-r-px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.rt-r-px-6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}
.rt-r-px-7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}
.rt-r-px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}
.rt-r-px-9 {
  padding-left: var(--space-9);
  padding-right: var(--space-9);
}
.rt-r-px-inset {
  padding-left: var(--inset-padding-left);
  padding-right: var(--inset-padding-right);
}
@media (min-width: 520px) {
  .xs\:rt-r-px {
    padding-left: var(--pl-xs);
    padding-right: var(--pr-xs)
  }
  .xs\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xs\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .xs\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .xs\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .xs\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .xs\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .xs\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }
  .xs\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }
  .xs\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
  .xs\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }
  .xs\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-px {
    padding-left: var(--pl-sm);
    padding-right: var(--pr-sm)
  }
  .sm\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .sm\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .sm\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .sm\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .sm\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .sm\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }
  .sm\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }
  .sm\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
  .sm\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }
  .sm\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-px {
    padding-left: var(--pl-md);
    padding-right: var(--pr-md)
  }
  .md\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .md\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .md\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .md\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .md\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .md\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }
  .md\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }
  .md\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
  .md\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }
  .md\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-px {
    padding-left: var(--pl-lg);
    padding-right: var(--pr-lg)
  }
  .lg\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .lg\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .lg\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .lg\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .lg\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .lg\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }
  .lg\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }
  .lg\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
  .lg\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }
  .lg\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-px {
    padding-left: var(--pl-xl);
    padding-right: var(--pr-xl)
  }
  .xl\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xl\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }
  .xl\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
  .xl\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
  .xl\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .xl\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }
  .xl\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }
  .xl\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }
  .xl\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
  .xl\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }
  .xl\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}
.rt-r-py {
  padding-top: var(--pt);
  padding-bottom: var(--pb)
}
.rt-r-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.rt-r-py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}
.rt-r-py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}
.rt-r-py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}
.rt-r-py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}
.rt-r-py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}
.rt-r-py-6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}
.rt-r-py-7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}
.rt-r-py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}
.rt-r-py-9 {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}
.rt-r-py-inset {
  padding-top: var(--inset-padding-top);
  padding-bottom: var(--inset-padding-bottom);
}
@media (min-width: 520px) {
  .xs\:rt-r-py {
    padding-top: var(--pt-xs);
    padding-bottom: var(--pb-xs)
  }
  .xs\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xs\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }
  .xs\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  .xs\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
  .xs\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  .xs\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .xs\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  .xs\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .xs\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }
  .xs\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .xs\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-py {
    padding-top: var(--pt-sm);
    padding-bottom: var(--pb-sm)
  }
  .sm\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }
  .sm\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  .sm\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
  .sm\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  .sm\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .sm\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  .sm\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .sm\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }
  .sm\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .sm\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-py {
    padding-top: var(--pt-md);
    padding-bottom: var(--pb-md)
  }
  .md\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }
  .md\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  .md\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
  .md\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  .md\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .md\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  .md\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .md\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }
  .md\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .md\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-py {
    padding-top: var(--pt-lg);
    padding-bottom: var(--pb-lg)
  }
  .lg\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }
  .lg\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  .lg\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
  .lg\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  .lg\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .lg\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  .lg\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .lg\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }
  .lg\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .lg\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-py {
    padding-top: var(--pt-xl);
    padding-bottom: var(--pb-xl)
  }
  .xl\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xl\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }
  .xl\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }
  .xl\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
  .xl\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }
  .xl\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }
  .xl\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }
  .xl\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }
  .xl\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }
  .xl\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }
  .xl\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}
.rt-r-pt {
  padding-top: var(--pt)
}
.rt-r-pt-0 {
  padding-top: 0;
}
.rt-r-pt-1 {
  padding-top: var(--space-1);
}
.rt-r-pt-2 {
  padding-top: var(--space-2);
}
.rt-r-pt-3 {
  padding-top: var(--space-3);
}
.rt-r-pt-4 {
  padding-top: var(--space-4);
}
.rt-r-pt-5 {
  padding-top: var(--space-5);
}
.rt-r-pt-6 {
  padding-top: var(--space-6);
}
.rt-r-pt-7 {
  padding-top: var(--space-7);
}
.rt-r-pt-8 {
  padding-top: var(--space-8);
}
.rt-r-pt-9 {
  padding-top: var(--space-9);
}
.rt-r-pt-inset {
  padding-top: var(--inset-padding-top);
}
@media (min-width: 520px) {
  .xs\:rt-r-pt {
    padding-top: var(--pt-xs)
  }
  .xs\:rt-r-pt-0 {
    padding-top: 0;
  }
  .xs\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }
  .xs\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }
  .xs\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }
  .xs\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }
  .xs\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }
  .xs\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }
  .xs\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }
  .xs\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }
  .xs\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }
  .xs\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-pt {
    padding-top: var(--pt-sm)
  }
  .sm\:rt-r-pt-0 {
    padding-top: 0;
  }
  .sm\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }
  .sm\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }
  .sm\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }
  .sm\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }
  .sm\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }
  .sm\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }
  .sm\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }
  .sm\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }
  .sm\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }
  .sm\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-pt {
    padding-top: var(--pt-md)
  }
  .md\:rt-r-pt-0 {
    padding-top: 0;
  }
  .md\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }
  .md\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }
  .md\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }
  .md\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }
  .md\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }
  .md\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }
  .md\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }
  .md\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }
  .md\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }
  .md\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-pt {
    padding-top: var(--pt-lg)
  }
  .lg\:rt-r-pt-0 {
    padding-top: 0;
  }
  .lg\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }
  .lg\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }
  .lg\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }
  .lg\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }
  .lg\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }
  .lg\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }
  .lg\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }
  .lg\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }
  .lg\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }
  .lg\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-pt {
    padding-top: var(--pt-xl)
  }
  .xl\:rt-r-pt-0 {
    padding-top: 0;
  }
  .xl\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }
  .xl\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }
  .xl\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }
  .xl\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }
  .xl\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }
  .xl\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }
  .xl\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }
  .xl\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }
  .xl\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }
  .xl\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}
.rt-r-pr {
  padding-right: var(--pr)
}
.rt-r-pr-0 {
  padding-right: 0;
}
.rt-r-pr-1 {
  padding-right: var(--space-1);
}
.rt-r-pr-2 {
  padding-right: var(--space-2);
}
.rt-r-pr-3 {
  padding-right: var(--space-3);
}
.rt-r-pr-4 {
  padding-right: var(--space-4);
}
.rt-r-pr-5 {
  padding-right: var(--space-5);
}
.rt-r-pr-6 {
  padding-right: var(--space-6);
}
.rt-r-pr-7 {
  padding-right: var(--space-7);
}
.rt-r-pr-8 {
  padding-right: var(--space-8);
}
.rt-r-pr-9 {
  padding-right: var(--space-9);
}
.rt-r-pr-inset {
  padding-right: var(--inset-padding-right);
}
@media (min-width: 520px) {
  .xs\:rt-r-pr {
    padding-right: var(--pr-xs)
  }
  .xs\:rt-r-pr-0 {
    padding-right: 0;
  }
  .xs\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }
  .xs\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }
  .xs\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }
  .xs\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }
  .xs\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }
  .xs\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }
  .xs\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }
  .xs\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }
  .xs\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }
  .xs\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-pr {
    padding-right: var(--pr-sm)
  }
  .sm\:rt-r-pr-0 {
    padding-right: 0;
  }
  .sm\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }
  .sm\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }
  .sm\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }
  .sm\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }
  .sm\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }
  .sm\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }
  .sm\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }
  .sm\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }
  .sm\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }
  .sm\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-pr {
    padding-right: var(--pr-md)
  }
  .md\:rt-r-pr-0 {
    padding-right: 0;
  }
  .md\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }
  .md\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }
  .md\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }
  .md\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }
  .md\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }
  .md\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }
  .md\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }
  .md\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }
  .md\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }
  .md\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-pr {
    padding-right: var(--pr-lg)
  }
  .lg\:rt-r-pr-0 {
    padding-right: 0;
  }
  .lg\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }
  .lg\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }
  .lg\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }
  .lg\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }
  .lg\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }
  .lg\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }
  .lg\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }
  .lg\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }
  .lg\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }
  .lg\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-pr {
    padding-right: var(--pr-xl)
  }
  .xl\:rt-r-pr-0 {
    padding-right: 0;
  }
  .xl\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }
  .xl\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }
  .xl\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }
  .xl\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }
  .xl\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }
  .xl\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }
  .xl\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }
  .xl\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }
  .xl\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }
  .xl\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}
.rt-r-pb {
  padding-bottom: var(--pb)
}
.rt-r-pb-0 {
  padding-bottom: 0;
}
.rt-r-pb-1 {
  padding-bottom: var(--space-1);
}
.rt-r-pb-2 {
  padding-bottom: var(--space-2);
}
.rt-r-pb-3 {
  padding-bottom: var(--space-3);
}
.rt-r-pb-4 {
  padding-bottom: var(--space-4);
}
.rt-r-pb-5 {
  padding-bottom: var(--space-5);
}
.rt-r-pb-6 {
  padding-bottom: var(--space-6);
}
.rt-r-pb-7 {
  padding-bottom: var(--space-7);
}
.rt-r-pb-8 {
  padding-bottom: var(--space-8);
}
.rt-r-pb-9 {
  padding-bottom: var(--space-9);
}
.rt-r-pb-inset {
  padding-bottom: var(--inset-padding-bottom);
}
@media (min-width: 520px) {
  .xs\:rt-r-pb {
    padding-bottom: var(--pb-xs)
  }
  .xs\:rt-r-pb-0 {
    padding-bottom: 0;
  }
  .xs\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }
  .xs\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }
  .xs\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }
  .xs\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }
  .xs\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }
  .xs\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }
  .xs\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }
  .xs\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }
  .xs\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }
  .xs\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-pb {
    padding-bottom: var(--pb-sm)
  }
  .sm\:rt-r-pb-0 {
    padding-bottom: 0;
  }
  .sm\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }
  .sm\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }
  .sm\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }
  .sm\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }
  .sm\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }
  .sm\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }
  .sm\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }
  .sm\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }
  .sm\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }
  .sm\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-pb {
    padding-bottom: var(--pb-md)
  }
  .md\:rt-r-pb-0 {
    padding-bottom: 0;
  }
  .md\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }
  .md\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }
  .md\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }
  .md\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }
  .md\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }
  .md\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }
  .md\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }
  .md\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }
  .md\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }
  .md\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-pb {
    padding-bottom: var(--pb-lg)
  }
  .lg\:rt-r-pb-0 {
    padding-bottom: 0;
  }
  .lg\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }
  .lg\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }
  .lg\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }
  .lg\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }
  .lg\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }
  .lg\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }
  .lg\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }
  .lg\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }
  .lg\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }
  .lg\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-pb {
    padding-bottom: var(--pb-xl)
  }
  .xl\:rt-r-pb-0 {
    padding-bottom: 0;
  }
  .xl\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }
  .xl\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }
  .xl\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }
  .xl\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }
  .xl\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }
  .xl\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }
  .xl\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }
  .xl\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }
  .xl\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }
  .xl\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}
.rt-r-pl {
  padding-left: var(--pl)
}
.rt-r-pl-0 {
  padding-left: 0;
}
.rt-r-pl-1 {
  padding-left: var(--space-1);
}
.rt-r-pl-2 {
  padding-left: var(--space-2);
}
.rt-r-pl-3 {
  padding-left: var(--space-3);
}
.rt-r-pl-4 {
  padding-left: var(--space-4);
}
.rt-r-pl-5 {
  padding-left: var(--space-5);
}
.rt-r-pl-6 {
  padding-left: var(--space-6);
}
.rt-r-pl-7 {
  padding-left: var(--space-7);
}
.rt-r-pl-8 {
  padding-left: var(--space-8);
}
.rt-r-pl-9 {
  padding-left: var(--space-9);
}
.rt-r-pl-inset {
  padding-left: var(--inset-padding-left);
}
@media (min-width: 520px) {
  .xs\:rt-r-pl {
    padding-left: var(--pl-xs)
  }
  .xs\:rt-r-pl-0 {
    padding-left: 0;
  }
  .xs\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }
  .xs\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }
  .xs\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }
  .xs\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }
  .xs\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }
  .xs\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }
  .xs\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }
  .xs\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }
  .xs\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }
  .xs\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-pl {
    padding-left: var(--pl-sm)
  }
  .sm\:rt-r-pl-0 {
    padding-left: 0;
  }
  .sm\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }
  .sm\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }
  .sm\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }
  .sm\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }
  .sm\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }
  .sm\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }
  .sm\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }
  .sm\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }
  .sm\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }
  .sm\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-pl {
    padding-left: var(--pl-md)
  }
  .md\:rt-r-pl-0 {
    padding-left: 0;
  }
  .md\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }
  .md\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }
  .md\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }
  .md\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }
  .md\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }
  .md\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }
  .md\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }
  .md\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }
  .md\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }
  .md\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-pl {
    padding-left: var(--pl-lg)
  }
  .lg\:rt-r-pl-0 {
    padding-left: 0;
  }
  .lg\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }
  .lg\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }
  .lg\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }
  .lg\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }
  .lg\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }
  .lg\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }
  .lg\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }
  .lg\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }
  .lg\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }
  .lg\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-pl {
    padding-left: var(--pl-xl)
  }
  .xl\:rt-r-pl-0 {
    padding-left: 0;
  }
  .xl\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }
  .xl\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }
  .xl\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }
  .xl\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }
  .xl\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }
  .xl\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }
  .xl\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }
  .xl\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }
  .xl\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }
  .xl\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}
.rt-r-position-static {
  position: static;
}
.rt-r-position-absolute {
  position: absolute;
}
.rt-r-position-relative {
  position: relative;
}
.rt-r-position-fixed {
  position: fixed;
}
.rt-r-position-sticky {
  position: -webkit-sticky;
  position: sticky;
}
@media (min-width: 520px) {
  .xs\:rt-r-position-static {
    position: static;
  }
  .xs\:rt-r-position-absolute {
    position: absolute;
  }
  .xs\:rt-r-position-relative {
    position: relative;
  }
  .xs\:rt-r-position-fixed {
    position: fixed;
  }
  .xs\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-position-static {
    position: static;
  }
  .sm\:rt-r-position-absolute {
    position: absolute;
  }
  .sm\:rt-r-position-relative {
    position: relative;
  }
  .sm\:rt-r-position-fixed {
    position: fixed;
  }
  .sm\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-position-static {
    position: static;
  }
  .md\:rt-r-position-absolute {
    position: absolute;
  }
  .md\:rt-r-position-relative {
    position: relative;
  }
  .md\:rt-r-position-fixed {
    position: fixed;
  }
  .md\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-position-static {
    position: static;
  }
  .lg\:rt-r-position-absolute {
    position: absolute;
  }
  .lg\:rt-r-position-relative {
    position: relative;
  }
  .lg\:rt-r-position-fixed {
    position: fixed;
  }
  .lg\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-position-static {
    position: static;
  }
  .xl\:rt-r-position-absolute {
    position: absolute;
  }
  .xl\:rt-r-position-relative {
    position: relative;
  }
  .xl\:rt-r-position-fixed {
    position: fixed;
  }
  .xl\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.rt-r-w {
  width: var(--width);
}
@media (min-width: 520px) {
  .xs\:rt-r-w {
    width: var(--width-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-w {
    width: var(--width-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-w {
    width: var(--width-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-w {
    width: var(--width-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-w {
    width: var(--width-xl);
  }
}
.rt-r-min-w {
  min-width: var(--min-width);
}
@media (min-width: 520px) {
  .xs\:rt-r-min-w {
    min-width: var(--min-width-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-min-w {
    min-width: var(--min-width-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-min-w {
    min-width: var(--min-width-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-min-w {
    min-width: var(--min-width-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-min-w {
    min-width: var(--min-width-xl);
  }
}
.rt-r-max-w {
  max-width: var(--max-width);
}
@media (min-width: 520px) {
  .xs\:rt-r-max-w {
    max-width: var(--max-width-xs);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-max-w {
    max-width: var(--max-width-sm);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-max-w {
    max-width: var(--max-width-md);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-max-w {
    max-width: var(--max-width-lg);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-max-w {
    max-width: var(--max-width-xl);
  }
}
.rt-r-weight-light {
  font-weight: var(--font-weight-light);
}
.rt-r-weight-regular {
  font-weight: var(--font-weight-regular);
}
.rt-r-weight-medium {
  font-weight: var(--font-weight-medium);
}
.rt-r-weight-bold {
  font-weight: var(--font-weight-bold);
}
@media (min-width: 520px) {
  .xs\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }
  .xs\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }
  .xs\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }
  .xs\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }
  .sm\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }
  .sm\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }
  .sm\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }
  .md\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }
  .md\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }
  .md\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }
  .lg\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }
  .lg\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }
  .lg\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }
  .xl\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }
  .xl\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }
  .xl\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}
.rt-r-lt-normal::before,
.rt-r-lt-end::before,
.rt-r-lt-normal::after,
.rt-r-lt-start::after {
  content: none;
}
.rt-r-lt-start::before,
.rt-r-lt-both::before,
.rt-r-lt-end::after,
.rt-r-lt-both::after {
  content: '';
  display: table;
}
.rt-r-lt-start::before,
.rt-r-lt-both::before {
  margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}
.rt-r-lt-end::after,
.rt-r-lt-both::after {
  margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}
@media (min-width: 520px) {
  .xs\:rt-r-lt-normal::before,
  .xs\:rt-r-lt-end::before,
  .xs\:rt-r-lt-normal::after,
  .xs\:rt-r-lt-start::after {
    content: none;
  }
  .xs\:rt-r-lt-start::before,
  .xs\:rt-r-lt-both::before,
  .xs\:rt-r-lt-end::after,
  .xs\:rt-r-lt-both::after {
    content: '';
    display: table;
  }
  .xs\:rt-r-lt-start::before,
  .xs\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
  .xs\:rt-r-lt-end::after,
  .xs\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-lt-normal::before,
  .sm\:rt-r-lt-end::before,
  .sm\:rt-r-lt-normal::after,
  .sm\:rt-r-lt-start::after {
    content: none;
  }
  .sm\:rt-r-lt-start::before,
  .sm\:rt-r-lt-both::before,
  .sm\:rt-r-lt-end::after,
  .sm\:rt-r-lt-both::after {
    content: '';
    display: table;
  }
  .sm\:rt-r-lt-start::before,
  .sm\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
  .sm\:rt-r-lt-end::after,
  .sm\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-lt-normal::before,
  .md\:rt-r-lt-end::before,
  .md\:rt-r-lt-normal::after,
  .md\:rt-r-lt-start::after {
    content: none;
  }
  .md\:rt-r-lt-start::before,
  .md\:rt-r-lt-both::before,
  .md\:rt-r-lt-end::after,
  .md\:rt-r-lt-both::after {
    content: '';
    display: table;
  }
  .md\:rt-r-lt-start::before,
  .md\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
  .md\:rt-r-lt-end::after,
  .md\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-lt-normal::before,
  .lg\:rt-r-lt-end::before,
  .lg\:rt-r-lt-normal::after,
  .lg\:rt-r-lt-start::after {
    content: none;
  }
  .lg\:rt-r-lt-start::before,
  .lg\:rt-r-lt-both::before,
  .lg\:rt-r-lt-end::after,
  .lg\:rt-r-lt-both::after {
    content: '';
    display: table;
  }
  .lg\:rt-r-lt-start::before,
  .lg\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
  .lg\:rt-r-lt-end::after,
  .lg\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-lt-normal::before,
  .xl\:rt-r-lt-end::before,
  .xl\:rt-r-lt-normal::after,
  .xl\:rt-r-lt-start::after {
    content: none;
  }
  .xl\:rt-r-lt-start::before,
  .xl\:rt-r-lt-both::before,
  .xl\:rt-r-lt-end::after,
  .xl\:rt-r-lt-both::after {
    content: '';
    display: table;
  }
  .xl\:rt-r-lt-start::before,
  .xl\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
  .xl\:rt-r-lt-end::after,
  .xl\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}
.rt-r-resize-none {
  resize: none;
}
.rt-r-resize-vertical {
  resize: vertical;
}
.rt-r-resize-horizontal {
  resize: horizontal;
}
.rt-r-resize-both {
  resize: both;
}
@media (min-width: 520px) {
  .xs\:rt-r-resize-none {
    resize: none;
  }
  .xs\:rt-r-resize-vertical {
    resize: vertical;
  }
  .xs\:rt-r-resize-horizontal {
    resize: horizontal;
  }
  .xs\:rt-r-resize-both {
    resize: both;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-resize-none {
    resize: none;
  }
  .sm\:rt-r-resize-vertical {
    resize: vertical;
  }
  .sm\:rt-r-resize-horizontal {
    resize: horizontal;
  }
  .sm\:rt-r-resize-both {
    resize: both;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-resize-none {
    resize: none;
  }
  .md\:rt-r-resize-vertical {
    resize: vertical;
  }
  .md\:rt-r-resize-horizontal {
    resize: horizontal;
  }
  .md\:rt-r-resize-both {
    resize: both;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-resize-none {
    resize: none;
  }
  .lg\:rt-r-resize-vertical {
    resize: vertical;
  }
  .lg\:rt-r-resize-horizontal {
    resize: horizontal;
  }
  .lg\:rt-r-resize-both {
    resize: both;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-resize-none {
    resize: none;
  }
  .xl\:rt-r-resize-vertical {
    resize: vertical;
  }
  .xl\:rt-r-resize-horizontal {
    resize: horizontal;
  }
  .xl\:rt-r-resize-both {
    resize: both;
  }
}
.rt-r-tl-auto {
  table-layout: auto;
}
.rt-r-tl-fixed {
  table-layout: fixed;
}
@media (min-width: 520px) {
  .xs\:rt-r-tl-auto {
    table-layout: auto;
  }
  .xs\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-tl-auto {
    table-layout: auto;
  }
  .sm\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-tl-auto {
    table-layout: auto;
  }
  .md\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-tl-auto {
    table-layout: auto;
  }
  .lg\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-tl-auto {
    table-layout: auto;
  }
  .xl\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}
.rt-r-ta-left {
  text-align: left;
}
.rt-r-ta-center {
  text-align: center;
}
.rt-r-ta-right {
  text-align: right;
}
@media (min-width: 520px) {
  .xs\:rt-r-ta-left {
    text-align: left;
  }
  .xs\:rt-r-ta-center {
    text-align: center;
  }
  .xs\:rt-r-ta-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-ta-left {
    text-align: left;
  }
  .sm\:rt-r-ta-center {
    text-align: center;
  }
  .sm\:rt-r-ta-right {
    text-align: right;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-ta-left {
    text-align: left;
  }
  .md\:rt-r-ta-center {
    text-align: center;
  }
  .md\:rt-r-ta-right {
    text-align: right;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-ta-left {
    text-align: left;
  }
  .lg\:rt-r-ta-center {
    text-align: center;
  }
  .lg\:rt-r-ta-right {
    text-align: right;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-ta-left {
    text-align: left;
  }
  .xl\:rt-r-ta-center {
    text-align: center;
  }
  .xl\:rt-r-ta-right {
    text-align: right;
  }
}
.rt-r-tw-wrap {
  white-space: normal;
}
.rt-r-tw-nowrap {
  white-space: nowrap;
}
.rt-r-tw-pretty {
  white-space: normal;
  text-wrap: pretty;
}
.rt-r-tw-balance {
  white-space: normal;
  text-wrap: balance;
}
@media (min-width: 520px) {
  .xs\:rt-r-tw-wrap {
    white-space: normal;
  }
  .xs\:rt-r-tw-nowrap {
    white-space: nowrap;
  }
  .xs\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }
  .xs\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-tw-wrap {
    white-space: normal;
  }
  .sm\:rt-r-tw-nowrap {
    white-space: nowrap;
  }
  .sm\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }
  .sm\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-tw-wrap {
    white-space: normal;
  }
  .md\:rt-r-tw-nowrap {
    white-space: nowrap;
  }
  .md\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }
  .md\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-tw-wrap {
    white-space: normal;
  }
  .lg\:rt-r-tw-nowrap {
    white-space: nowrap;
  }
  .lg\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }
  .lg\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-tw-wrap {
    white-space: normal;
  }
  .xl\:rt-r-tw-nowrap {
    white-space: nowrap;
  }
  .xl\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }
  .xl\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}
.rt-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rt-r-va-baseline {
  vertical-align: baseline;
}
.rt-r-va-top {
  vertical-align: top;
}
.rt-r-va-middle {
  vertical-align: middle;
}
.rt-r-va-bottom {
  vertical-align: bottom;
}
@media (min-width: 520px) {
  .xs\:rt-r-va-baseline {
    vertical-align: baseline;
  }
  .xs\:rt-r-va-top {
    vertical-align: top;
  }
  .xs\:rt-r-va-middle {
    vertical-align: middle;
  }
  .xs\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  .sm\:rt-r-va-baseline {
    vertical-align: baseline;
  }
  .sm\:rt-r-va-top {
    vertical-align: top;
  }
  .sm\:rt-r-va-middle {
    vertical-align: middle;
  }
  .sm\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1024px) {
  .md\:rt-r-va-baseline {
    vertical-align: baseline;
  }
  .md\:rt-r-va-top {
    vertical-align: top;
  }
  .md\:rt-r-va-middle {
    vertical-align: middle;
  }
  .md\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1280px) {
  .lg\:rt-r-va-baseline {
    vertical-align: baseline;
  }
  .lg\:rt-r-va-top {
    vertical-align: top;
  }
  .lg\:rt-r-va-middle {
    vertical-align: middle;
  }
  .lg\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1640px) {
  .xl\:rt-r-va-baseline {
    vertical-align: baseline;
  }
  .xl\:rt-r-va-top {
    vertical-align: top;
  }
  .xl\:rt-r-va-middle {
    vertical-align: middle;
  }
  .xl\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@font-face {
font-family: 'GeistSans';
src: url(/_next/static/media/e11418ac562b8ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'GeistSans Fallback';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_3a0388 {font-family: 'GeistSans', 'GeistSans Fallback'
}.__variable_3a0388 {--font-geist-sans: 'GeistSans', 'GeistSans Fallback'
}

